import { useCallback, useContext, useState } from "react"
import { useIntl } from "react-intl"
import { useMutation, useRelayEnvironment } from "react-relay"
import { fetchQuery } from "relay-hooks"
import graphql from "babel-plugin-relay/macro"
import { packDataBytes } from "@taquito/michel-codec"
import { TempleWallet } from "@temple-wallet/dapp"
import { KukaiEmbed } from "kukai-embed"
import { useNavigate } from "react-router-dom"

import { SignMessageAssociateWalletMutation } from "./__generated__/SignMessageAssociateWalletMutation.graphql"
import { SignMessageGetNonceQuery } from "./__generated__/SignMessageGetNonceQuery.graphql"

import { useTezosContext } from "../../web3/Web3Provider"
import { UserSetupStateContext } from "../../providers/UserSetupStateProvider/UserSetupStateProvider"
import { useUserInfoContext } from "../../providers/UserInfoProvider"
import ConfirmDialog from "../../components/ConfirmDialog"
import AssociateErrorDialog from "./components/AssociateErrorDialog"
import TrackImpression from "../../components/TrackImpression"
import { trackClick } from "../../utils/analytics"

import { ReactComponent as WalletIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/walletStroke.svg"
import { ReactComponent as EditIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/edit.svg"

const getNonceQuery = graphql`
  query SignMessageGetNonceQuery {
    nonce: getNonce
  }
`

const associateWalletMutation = graphql`
  mutation SignMessageAssociateWalletMutation($signature: String!, $address: String!) {
    associateWallet(signature: $signature, address: $address)
  }
`

export interface Props {
  open: boolean
  onClose: (success: boolean) => void
}

const SignMessageDialog = ({ open, onClose }: Props) => {
  const intl = useIntl()
  const { web3Provider } = useTezosContext()
  const relayEnvironment = useRelayEnvironment()
  const [commit, isInFlight] = useMutation<SignMessageAssociateWalletMutation>(associateWalletMutation)
  const [isLoading, setIsLoading] = useState(false)
  const { refresh } = useUserInfoContext()
  const [, setFtueState] = useContext(UserSetupStateContext)
  const [associateError, setAssociateError] = useState<Error | null>(null)
  const navigate = useNavigate()

  const associateWallet = useCallback(async () => {
    if (isInFlight || isLoading) return
    if (web3Provider) {
      setIsLoading(true)
      try {
        const getNonceResponse = await fetchQuery<SignMessageGetNonceQuery>(
          relayEnvironment,
          getNonceQuery,
          {}
        ).toPromise()
        if (!getNonceResponse) return

        const dataPacked = packDataBytes({ string: getNonceResponse.nonce }, { prim: "string" }).bytes

        let signedMessage = null
        let pk = null
        if (web3Provider instanceof TempleWallet) {
          signedMessage = await web3Provider.sign(dataPacked)
          pk = web3Provider.permission ? web3Provider.permission.publicKey : ""
        } else if (web3Provider instanceof KukaiEmbed) {
          signedMessage = await web3Provider.signExpr(dataPacked)
          pk = web3Provider.user?.pk ? web3Provider.user.pk : ""
        } else {
          // TODO Handle error
          signedMessage = ""
          pk = ""
        }

        commit({
          variables: {
            signature: signedMessage,
            address: pk,
          },
          onCompleted: () => {
            setFtueState("associate_done")
            refresh && refresh()
            setIsLoading(false)
          },
          onError: (error) => {
            setIsLoading(false)
            setAssociateError(error)
          },
        })
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    }
  }, [commit, isInFlight, isLoading, web3Provider, relayEnvironment, refresh, setFtueState])

  const handleClose = async (confirm: boolean) => {
    if (confirm) {
      trackClick({
        location: "pop in",
        locationDetail: "signature",
        category: "action",
        action: "sign message",
      })
      await associateWallet()
      onClose(true)
    } else {
      onClose(false)
    }
  }

  return (
    <>
      {open && <TrackImpression locationDetail="signature" />}
      <ConfirmDialog
        open={open}
        onClose={handleClose}
        icon={<WalletIcon />}
        title={intl.formatMessage({
          defaultMessage: "Security Checkpoint!",
          id: "sBK8DY",
          description: "[Connect Wallet] Sign message dialog title",
        })}
        content={intl.formatMessage({
          defaultMessage:
            "For increased security on Ubisoft Quartz, please sign a message with your crypto-wallet to continue.",
          id: "fzsLep",
          description: "[Connect Wallet] Sign message dialog description",
        })}
        confirmLabel={intl.formatMessage({
          defaultMessage: "Sign Message",
          id: "Zg0SEC",
          description: "[Connect Wallet] Sign message confirm button label",
        })}
        confirmIcon={<EditIcon />}
        confirmPending={isLoading}
        cancelLabel={intl.formatMessage({
          defaultMessage: "Cancel",
          id: "C3N3ck",
          description: "[Connect Wallet] Sign message cancel button label",
        })}
      />
      {associateError && (
        <AssociateErrorDialog open={!!associateError} onClose={() => navigate(0)} error={associateError} />
      )}
    </>
  )
}

export default SignMessageDialog
