import { useParams } from "react-router-dom"
import graphql from "babel-plugin-relay/macro"
import { useIntl } from "react-intl"
import { useQuery } from "relay-hooks"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import { SaleQuery, SaleQuery$data } from "./__generated__/SaleQuery.graphql"
import {
  SaleFreeDropCurrentPlayerQuery,
  SaleFreeDropCurrentPlayerQuery$data,
} from "./__generated__/SaleFreeDropCurrentPlayerQuery.graphql"

import FullPageError from "../../components/FullPageError"
import PageLayout from "../../components/PageLayout"
import PageHeader from "../../components/PageHeader"
import TokenClaim from "./components/TokenClaim"
import Product from "./components/Product"
import ListGroup, { ListGroupItem } from "../../components/ListGroup"
import Button from "../../components/Button"
import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import useClaimStatus, { ClaimStatus } from "../../hooks/useClaimStatus"
import useLocation from "../../hooks/useLocation"

import { ReactComponent as CalendarIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/calendar.svg"
import { ReactComponent as ProfileIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/profileStroke.svg"

import style from "./style.module.css"

const POLL_INTERVAL_MS = 15000

const saleQuery = graphql`
  query SaleQuery($id: ID!) {
    sale(id: $id) {
      price
      startDate
      archetype {
        primaryVariant {
          categorization {
            game {
              id
            }
          }
          ...TokenClaimFragment_variant
          ...ProductFragment_variant
        }
      }
      claimsCount {
        remaining
      }
      ...TokenClaimFragment_sale
      ...ProductFragment_sale
      ...useClaimStatus_sale
    }
  }
`

const saleFreeDropCurrentPlayerQuery = graphql`
  query SaleFreeDropCurrentPlayerQuery {
    currentPlayer {
      ...useClaimStatus_player
      ...ProductFragment_player
    }
  }
`

const SaleContent = ({
  sale,
  player,
}: {
  sale: SaleQuery$data["sale"]
  player: SaleFreeDropCurrentPlayerQuery$data["currentPlayer"] | null
}) => {
  const claimStatus = useClaimStatus(sale, player)

  if (sale.price !== 0 || claimStatus !== ClaimStatus.CAN_CLAIM) {
    return <Product sale={sale} variant={sale.archetype.primaryVariant} player={player} />
  }

  return <TokenClaim sale={sale} variant={sale.archetype.primaryVariant} />
}

const Sale = () => {
  const { user, isLoading: userLoading } = useUbisoftWebAuthSessionContext()
  const intl = useIntl()
  const location = useLocation<{ free?: boolean; claimStatus?: ClaimStatus }>()
  const { saleID } = useParams<{ gameID: string; saleID: string }>() as { gameID: string; saleID: string }
  const { data, isLoading, error } = useQuery<SaleQuery>(
    saleQuery,
    { id: saleID },
    { networkCacheConfig: { poll: POLL_INTERVAL_MS } }
  )
  const {
    data: playerData,
    isLoading: playerDataIsLoading,
    // error: playerDataError,
  } = useQuery<SaleFreeDropCurrentPlayerQuery>(saleFreeDropCurrentPlayerQuery, {}, { skip: !user.ticket })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const CardItemLoading = <div className={style.cardItemLoading} />

  if (isLoading || playerDataIsLoading || userLoading) {
    return location.state?.free && location.state.claimStatus === ClaimStatus.CAN_CLAIM ? (
      <PageLayout
        headerClassName={style.header}
        header={
          <>
            {isMobile && <PageHeader title="" />}
            <div className={style.productCardLoading}>
              <div className={style.imageLoading} />
              <div className={style.descriptionLoading}>
                <div className={style.nameLoading} />
                <div className={style.gameTitleLoading} />
                <div className={style.infoLoading} />
              </div>
            </div>
          </>
        }
        bodyClassName={style.bodyLoading}
        bodyPosition="right"
      >
        <div className={style.nameLoading} />
        <div className={style.infoLoading} />
        <div className={style.buttonLoading} />
      </PageLayout>
    ) : (
      <PageLayout header={isMobile && <PageHeader title="" />} bodyClassName={style.bodyLayout}>
        {!isMobile && <div className={style.imageLoading} />}
        <div className={style.columnGrid}>
          {isMobile ? (
            <div className={style.productCardLoading}>
              <div className={style.imageLoading} />
              <div className={style.descriptionLoading}>
                <div className={style.nameLoading} />
                <div className={style.gameTitleLoading} />
                <div className={style.infoLoading} />
              </div>
            </div>
          ) : (
            <>
              <div className={style.descriptionLoading}>
                <div className={style.nameLoading} />
                <div className={style.gameTitleLoading} />
                <div className={style.infoLoading} />
              </div>
            </>
          )}

          <div className={style.card}>
            <div className={style.priceLoading} />
            <Button disabled>{CardItemLoading}</Button>
          </div>

          {CardItemLoading}
          <ListGroup className={style.info}>
            <ListGroupItem
              icon={<ProfileIcon aria-hidden />}
              label={intl.formatMessage({
                defaultMessage: "Creator",
                id: "/j060C",
                description: "Item creator label",
              })}
            >
              {CardItemLoading}
            </ListGroupItem>
            <ListGroupItem
              icon={<CalendarIcon aria-hidden />}
              label={intl.formatMessage({
                defaultMessage: "Release date",
                id: "Dja2gR",
                description: "Item release date label",
              })}
            >
              {CardItemLoading}
            </ListGroupItem>
          </ListGroup>
        </div>
      </PageLayout>
    )
  }

  if (!data || error) {
    return (
      <FullPageError
        headerTitle={intl.formatMessage({ defaultMessage: "Error", id: "D7Dner", description: "Error page title" })}
        errorTitle={intl.formatMessage({
          defaultMessage: "Digit not found",
          id: "hxrl2f",
          description: "[Product Page] Not found error message",
        })}
        buttonLabel={intl.formatMessage({
          defaultMessage: "Back",
          id: "2t2oBC",
          description: "Go back button",
        })}
      />
    )
  }

  return <SaleContent sale={data.sale} player={playerData?.currentPlayer || null} />
}

export default Sale
