import { ReactNode } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import graphql from "babel-plugin-relay/macro"
import { useQuery } from "relay-hooks"

import { DashboardPayingDropQuery } from "./__generated__/DashboardPayingDropQuery.graphql"

import PageLayout from "../../components/PageLayout"
import EligibilitySnackBar from "../../components/EligibilitySnackBar"
import FullPageError from "../../components/FullPageError"
import SaleCardFragment from "../../components/SaleCard/SaleCardFragment"
import RainbowTitle from "../../components/RainbowTitle"
import DashboardHero from "../../components/DashboardHero"
import useEmptyCard from "../../hooks/useEmptyCard"

import rabbids from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/rabbidsConfused.png"

import style from "./style.module.css"

const ErrorPage = () => {
  const intl = useIntl()

  return (
    <FullPageError
      errorTitle={intl.formatMessage({
        defaultMessage: "Internal Error",
        id: "KcicQO",
        description: "[Dashboard] Error message title",
      })}
      errorImage={rabbids}
    >
      <FormattedMessage
        defaultMessage="An error occurred while trying to fetch data. Please try again in a minute. If the problem persists, contact support."
        id="bi2NOT"
        description="[Dashboard] Error message content"
      />
    </FullPageError>
  )
}

const LoadingSection = () => {
  const intl = useIntl()

  return (
    <>
      <RainbowTitle
        className={style.title}
        text={intl.formatMessage({ defaultMessage: "All Digits", id: "xer/Fx", description: "[Dashboard] body title" })}
      />
      <Grid>
        <li className={style.itemCardLoading} />
        <li className={style.itemCardLoading} />
        <li className={style.itemCardLoading} />
        <li className={style.itemCardLoading} />
        <li className={style.itemCardLoading} />
        <li className={style.itemCardLoading} />
      </Grid>
    </>
  )
}

interface GridProps {
  children: ReactNode[]
}

const Grid = ({ children }: GridProps) => {
  const emptyCards = [...Array(useEmptyCard(children.length))]

  return (
    <ul className={style.list}>
      {children}
      {emptyCards.map((_, index) => (
        <li className="emptyCard" key={index} aria-hidden />
      ))}
    </ul>
  )
}

const dashboardPayingDropQuery = graphql`
  query DashboardPayingDropQuery {
    allGames {
      id
      sales {
        id
        startDate
        archetype {
          primaryVariant {
            categorization {
              game {
                id
              }
            }
          }
        }
        ...SaleCardFragment_sale
      }
    }
  }
`

const DashboardPayingDrop = () => {
  const intl = useIntl()
  const { data, isLoading, error } = useQuery<DashboardPayingDropQuery>(dashboardPayingDropQuery)

  if (isLoading) {
    return (
      <PageLayout header={<DashboardHero />}>
        <LoadingSection />
      </PageLayout>
    )
  }

  if (!data || error) return <ErrorPage />

  // Merge all sales from all games in one array
  const sales = data.allGames.length
    ? data.allGames
        .map((game) => [...game.sales])
        .reduce((a, b) => a.concat(b))
        .sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate)) || []
    : [] // If no games

  return (
    <PageLayout header={<DashboardHero />}>
      <RainbowTitle
        className={style.title}
        text={intl.formatMessage({
          defaultMessage: "All Digits",
          id: "xer/Fx",
          description: "[Dashboard] body title",
        })}
      />
      {!sales.length ? (
        <p className={style.noResults}>
          <FormattedMessage
            tagName="span"
            id="D5P4nz"
            defaultMessage="No results found"
            description="[Sales List] No results message"
          />
        </p>
      ) : (
        <Grid>
          {sales.map((sale) => (
            <li key={sale.id}>
              <SaleCardFragment sale={sale} gameID={sale.archetype.primaryVariant.categorization?.game?.id || ""} />
            </li>
          ))}
        </Grid>
      )}

      <EligibilitySnackBar />
    </PageLayout>
  )
}

export default DashboardPayingDrop
