const PENDING_TOKENS_STORAGE_KEY = "pendingTokens"

export const getPendingTokens = (userID: string): string[] => {
  const stringValue = localStorage.getItem(`${userID}:${PENDING_TOKENS_STORAGE_KEY}`)

  return stringValue ? JSON.parse(stringValue) : []
}

export const setPendingTokens = (userID: string, ids: string[]) => {
  localStorage.setItem(`${userID}:${PENDING_TOKENS_STORAGE_KEY}`, JSON.stringify(ids))
}
