import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react"
import { Link, LinkProps, To } from "react-router-dom"

import style from "./style.module.css"

interface Props extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: ReactNode
  icon?: ReactNode
  onClick?: () => void
  to?: To
  state?: LinkProps["state"]
  href?: string
  target?: AnchorHTMLAttributes<HTMLAnchorElement>["target"]
  replace?: boolean
  invert?: boolean
  disabled?: boolean
  small?: boolean
  fullWidth?: boolean
}

const Button = ({
  children,
  icon,
  onClick,
  to,
  state,
  href,
  target,
  replace,
  className = "",
  invert,
  disabled,
  small,
  fullWidth,
  ...props
}: Props) => {
  const classes = `${style.container} ${className} ${invert ? style.invert : ""} ${disabled ? style.disabled : ""} ${
    small ? style.small : ""
  } ${fullWidth ? style.fullWidth : ""} ${icon ? style.withIcon : ""}`

  const content = (
    <>
      {icon}
      <div className={style.labelContainer}>
        <span className={style.label}>{children}</span>
        {invert && !disabled && <span className={style.labelHover}>{children}</span>}
      </div>
    </>
  )

  if (disabled && (to || href)) return <span className={classes}>{content}</span>

  if (to) {
    return (
      <Link to={to} state={state} onClick={onClick} className={classes} replace={replace}>
        {content}
      </Link>
    )
  }

  if (href) {
    return (
      <a href={href} target={target} onClick={onClick} className={classes}>
        {content}
      </a>
    )
  }

  return (
    <button {...props} className={classes} onClick={onClick} disabled={disabled}>
      {content}
    </button>
  )
}

export default Button
