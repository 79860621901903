/**
 * @generated SignedSource<<f7316e7342f558b0787ed40b96dc954f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SaleConditionOperator = "ADD" | "AND" | "EQ" | "GT" | "GTE" | "LT" | "LTE" | "NEQ" | "OR" | "%future added value";
export type ScalarType = "BOOL" | "FLOAT" | "INT" | "STRING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type buildConditionMap_saleCondition$data = {
  readonly expr: {
    readonly slug: string | null;
    readonly operator: SaleConditionOperator;
    readonly args: ReadonlyArray<{
      readonly key?: string;
      readonly spaceID?: string;
      readonly type?: ScalarType;
      readonly int?: number;
      readonly float?: number;
      readonly string?: string;
      readonly bool?: boolean;
      readonly slug?: string | null;
      readonly operator?: SaleConditionOperator;
      readonly args?: ReadonlyArray<{
        readonly key?: string;
        readonly spaceID?: string;
        readonly type?: ScalarType;
        readonly int?: number;
        readonly float?: number;
        readonly string?: string;
        readonly bool?: boolean;
        readonly operator?: SaleConditionOperator;
        readonly args?: ReadonlyArray<{
          readonly key?: string;
          readonly spaceID?: string;
          readonly type?: ScalarType;
          readonly int?: number;
          readonly float?: number;
          readonly string?: string;
          readonly bool?: boolean;
        }>;
      }>;
    }>;
  } | null;
};
export type buildConditionMap_saleCondition$key = {
  readonly " $data"?: buildConditionMap_saleCondition$data;
  readonly " $fragmentSpreads": FragmentRefs<"buildConditionMap_saleCondition">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "buildConditionMap_saleCondition"
};

(node as any).hash = "85081d9755b270e9f4bc614884d87107";

export default node;
