import { useEffect, useMemo, useState } from "react"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import clsx from "clsx"
import { useFragment } from "react-relay"
import graphql from "babel-plugin-relay/macro"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"
import { addMinutes } from "date-fns"

import { ProductFragment_sale$key } from "./__generated__/ProductFragment_sale.graphql"
import { ProductFragment_variant$key } from "./__generated__/ProductFragment_variant.graphql"
import { ProductFragment_player$key } from "./__generated__/ProductFragment_player.graphql"

import ListGroup, { ListGroupItem } from "../../../../components/ListGroup"
import PageHeader from "../../../../components/PageHeader"
import PageLayout from "../../../../components/PageLayout"
import ProductCardFragment from "../../../../components/Product/ProductCard/ProductCardFragment"
import Tokens from "../../../Tokens"
import ipfsToHttpGateway from "../../../../utils/ipfsToHttpGateway"
import { buildCustomParameter } from "../../../../utils/analytics"
import TrackPage from "../../../../components/TrackPage"
import Video from "../../../../components/Video"
import RainbowTitle from "../../../../components/RainbowTitle"
import ProductCardDescription from "../../../../components/Product/ProductCard/components/ProductCardDescription"
import useClaimStatus, { ClaimStatus } from "../../../../hooks/useClaimStatus"
import PriceSection from "../PriceSection"
import CardButton from "../../../../components/CardButton"
import saleConditionMessage from "../../../../utils/saleCondition/saleConditionMessage"
import MarketplaceLinks from "../../../../components/MarketplaceLinks"
import buildConditionMap from "../../../../utils/saleCondition/buildConditionMap"
import EligibilitySnackBar from "../../../../components/EligibilitySnackBar"

import { ReactComponent as CalendarIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/calendar.svg"
import { ReactComponent as ProfileIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/profileStroke.svg"
import { ReactComponent as UbisoftSwirl } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/ubisoftSwirl.svg"
import { ReactComponent as EditIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/edit.svg"
import StickerUbi from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/sticker.png"

import baseStyle from "../../style.module.css"
import style from "./style.module.css"

interface Props {
  sale: ProductFragment_sale$key
  variant: ProductFragment_variant$key
  player: ProductFragment_player$key | null
}

const Product = ({ sale, variant, player }: Props) => {
  const intl = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const saleData = useFragment(
    graphql`
      fragment ProductFragment_sale on Sale {
        id
        price
        startDate
        endDate
        claimsCount {
          remaining
        }
        condition {
          ...buildConditionMap_saleCondition
        }
        archetype {
          id
          maxInstances
          video
          primaryVariant {
            name
            description
            categorization {
              game {
                id
              }
            }
          }
        }
        ...ProductCardFragment_sale
        ...PriceSectionFragment_sale
        ...useClaimStatus_sale
      }
    `,
    sale
  )

  const variantData = useFragment(
    graphql`
      fragment ProductFragment_variant on Variant {
        name
        image
        categorization {
          game {
            name
          }
        }
      }
    `,
    variant
  )

  const playerData = useFragment(
    graphql`
      fragment ProductFragment_player on Player {
        inventory {
          id
          serialNumber
          archetype {
            id
          }
        }
        claims {
          sale {
            id
          }
        }
        reserved {
          archetype {
            id
          }
        }
        ...useClaimStatus_player
        ...ProductCardFragment_player
      }
    `,
    player
  )

  const claimStatus = useClaimStatus(saleData, playerData)
  const { gameID } = useParams() as { gameID: string; saleID: string }
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleContinueClick = () => {
    setOpen(true)
  }

  const handleCloseTokens = () => {
    setOpen(false)
  }

  const productFree = saleData.price === 0
  // TODO: wait for tokenCount feature to not query all tokens list which lead to performance issue
  // const availableTokens = saleData.tokens.edges.filter((token) => token.node?.available).length
  const availableClaims = saleData.claimsCount?.remaining
  // TODO: Update soldOut value for paid drops when tokenCount feature will be available (set to false to enable "select digit" button for tests)
  const soldOut = productFree ? availableClaims === 0 : false
  const playerToken = playerData?.inventory.find((token) => token.archetype.id === saleData.archetype.id) || null
  const descriptionHTML = useMemo(
    () => saleData.archetype.primaryVariant.description.replace(/\\n/g, "<br/>"),
    [saleData.archetype.primaryVariant.description]
  )

  const conditionMap = useMemo(
    () => (saleData.condition ? buildConditionMap(saleData.condition) : undefined),
    [saleData]
  )
  const isActive = useMemo(
    () =>
      !soldOut &&
      saleData.startDate &&
      Date.parse(saleData.startDate) <= date.getTime() &&
      (!saleData.endDate || Date.parse(saleData.endDate) > date.getTime()),
    [soldOut, date, saleData]
  )
  const claimed = useMemo(
    () =>
      playerData?.claims.some((claim) => claim.sale.id === saleData.id) ||
      playerData?.reserved.some((token) => token.archetype.id === saleData.archetype.id) ||
      playerData?.inventory.some((token) => token.archetype.id === saleData.archetype.id),
    [playerData, saleData]
  )

  const showClaimSection =
    claimStatus === ClaimStatus.ALREADY_CLAIMED ||
    claimStatus === ClaimStatus.CONDITION_NOT_MET ||
    claimStatus === ClaimStatus.CAN_CLAIM ||
    claimStatus === ClaimStatus.CLAIM_NOT_STARTED ||
    claimStatus === ClaimStatus.CLAIM_ENDED

  const hasMintingStarted = useMemo(
    () =>
      (!saleData.endDate && (availableClaims || 0) < saleData.archetype.maxInstances) ||
      (saleData.endDate && addMinutes(Date.parse(saleData.endDate), 10).getTime() < date.getTime()),
    [availableClaims, saleData, date]
  )

  return (
    <>
      <TrackPage
        siteSection="product"
        pageName="product page"
        customParameter={buildCustomParameter(
          variantData.categorization?.game?.name || "",
          variantData.name,
          "",
          0,
          saleData.price,
          soldOut,
          false // TODO use productFree
        )}
      />
      <PageLayout header={isMobile && <PageHeader title={variantData.name} />} bodyClassName={baseStyle.bodyLayout}>
        {!isMobile &&
          (saleData.archetype.video ? (
            <Video
              video={saleData.archetype.video}
              image={variantData.image}
              alt={variantData.name}
              className={style.thumbnail}
            />
          ) : (
            <img
              className={style.thumbnail}
              src={ipfsToHttpGateway(variantData.image)}
              alt={variantData.name}
              data-testid="product-image"
            />
          ))}
        <div className={baseStyle.columnGrid}>
          {isMobile ? (
            <ProductCardFragment
              gameID={gameID}
              sale={saleData}
              token={null}
              player={playerData}
              className={style.productCard}
            />
          ) : (
            <ProductCardDescription
              priceDeFi={saleData.price}
              startDate={new Date(saleData.startDate)}
              endDate={saleData.endDate ? new Date(saleData.endDate) : null}
              currencyDeFi={"EURL"} /* TODO currencyDeFi should not be hard coded */
              price={saleData.price} /* TODO convert crypto to fiat price */
              currency={"EUR"} /* TODO currency fiat should not be hard coded */
              variantName={variantData.name || ""}
              game={variantData.categorization?.game?.name || ""}
              // TODO also use `availableTokens` for paying drops
              remainingInstances={availableClaims}
              maxInstances={saleData.archetype.maxInstances}
              hideEditButton
              hidePrice={productFree}
              className={style.productCardDescription}
              conditionTag={(conditionMap && saleConditionMessage(intl, conditionMap)) || undefined}
              claimed={claimed}
            />
          )}

          {isActive && showClaimSection && (
            <section>
              <PriceSection
                sale={saleData}
                date={date}
                soldOut={soldOut}
                claimStatus={claimStatus}
                selectDigit={handleContinueClick}
              />
            </section>
          )}

          {playerToken && (
            <section>
              <RainbowTitle
                className={style.sectionTitle}
                text={intl.formatMessage({
                  defaultMessage: "Your Digit",
                  description: "[Product Page] Your digit section label",
                  id: "2ez6Oh",
                })}
              />
              <CardButton
                iconLeft={<img src={StickerUbi} className={style.sticker} aria-hidden alt="" />}
                title={variantData.name}
                description={`#${playerToken.serialNumber}/${saleData.archetype.maxInstances}`}
                to={`/token/${playerToken.id}`}
              />
            </section>
          )}

          <section className={style.marketplaceLinks}>
            <RainbowTitle
              className={style.sectionTitle}
              text={
                hasMintingStarted
                  ? intl.formatMessage({
                      defaultMessage: "Check Availability",
                      description: "[Product Page] Marketplaces links section title",
                      id: "vstSlF",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Previous Editions",
                      description: "[Product Page] Marketplaces links section title",
                      id: "5mZ/PD",
                    })
              }
            />
            <MarketplaceLinks
              description={
                hasMintingStarted
                  ? intl.formatMessage({
                      defaultMessage: "Find Digits from this Edition on secondary markets such as:",
                      id: "5uryxj",
                      description: "[Product Page] Marketplaces links description label",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Find Digits from previous Editions on secondary markets such as:",
                      id: "LlVqev",
                      description: "[Product Page] Marketplaces links description label",
                    })
              }
              tokenName={hasMintingStarted ? saleData.archetype.primaryVariant.name : undefined}
            />
          </section>

          <section>
            {/* TODO : Update the text */}
            <RainbowTitle
              className={style.sectionTitle}
              text={intl.formatMessage({
                defaultMessage: "Details",
                description: "[Product Page] Metadata section label",
                id: "sjYZyJ",
              })}
            />
            <ListGroup className={style.info}>
              <ListGroupItem icon={<EditIcon />} label="">
                <div className="caption" dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
              </ListGroupItem>
              <ListGroupItem
                icon={<ProfileIcon aria-hidden />}
                label={intl.formatMessage({
                  defaultMessage: "Creator",
                  id: "cKT28u",
                  description: "[Product Page] Item creator label",
                })}
              >
                {/* TODO: make creator dynamic */}
                <div className={style.ubisoftName}>
                  <UbisoftSwirl aria-hidden />
                  <span className={clsx("numbers", style.creator)}>
                    <FormattedMessage defaultMessage="Ubisoft" id="PsoPj2" description="Creator label" />
                  </span>
                </div>
              </ListGroupItem>
              <ListGroupItem
                icon={<CalendarIcon aria-hidden />}
                label={intl.formatMessage({
                  defaultMessage: "Release date",
                  id: "P3+esi",
                  description: "[Product Page] Item release date label",
                })}
              >
                <span className="numbers">
                  <FormattedDate value={new Date(saleData.startDate)} />
                </span>
              </ListGroupItem>
            </ListGroup>
          </section>
        </div>
        {!productFree && <Tokens open={open} onClose={handleCloseTokens} />}
        <EligibilitySnackBar />
      </PageLayout>
    </>
  )
}

export default Product
