/**
 * @generated SignedSource<<374216e17bf10157f03c4b813055dc10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductFragment_variant$data = {
  readonly name: string;
  readonly image: string;
  readonly categorization: {
    readonly game: {
      readonly name: string;
    } | null;
  } | null;
  readonly " $fragmentType": "ProductFragment_variant";
};
export type ProductFragment_variant$key = {
  readonly " $data"?: ProductFragment_variant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductFragment_variant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductFragment_variant",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Categorization",
      "kind": "LinkedField",
      "name": "categorization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Game",
          "kind": "LinkedField",
          "name": "game",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Variant",
  "abstractKey": null
};
})();

(node as any).hash = "cd769674023a5bfe467566eb6dcad530";

export default node;
