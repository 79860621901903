import kukaiLogo from "https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/wallets/kukai.svg"
import templeLogo from "https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/wallets/temple.svg"

import { connect as connectKukai } from "./providers/kukai"
import { connect as connectTemple } from "./providers/temple"

const walletProviders = {
  kukai: {
    name: "Kukai",
    logo: kukaiLogo,
    connect: connectKukai,
  },
  temple: {
    name: "Temple",
    logo: templeLogo,
    connect: connectTemple,
  },
}

export type ProviderName = keyof typeof walletProviders

export default walletProviders
