import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { TempleWallet } from "@temple-wallet/dapp"
import { KukaiEmbed } from "kukai-embed"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import { useTezosContext } from "../web3/Web3Provider"
import { useUserInfoContext } from "../providers/UserInfoProvider"
import { useGeolocationInfoContext } from "../providers/GeolocationInfoProvider"

const useWalletConnect = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const navigate = useNavigate()
  const { userInfo, isLoading: userInfoLoading, refresh, refreshQuartzInfo } = useUserInfoContext()
  const { refresh: refreshGeolocationInfo } = useGeolocationInfoContext()
  const { web3Provider, providerLoading, unsetProvider } = useTezosContext()
  const [acceptTermsDismissed, setAcceptTermsDismissed] = useState(false)
  const [selectedWallet, setSelectedWallet] = useState<string | null>(null)
  const [showWrongWalletError, setShowWrongWalletError] = useState(false)
  const showSignMessage = useMemo(
    () => !!(web3Provider && !userInfo.walletAddress && selectedWallet),
    [web3Provider, userInfo, selectedWallet]
  )
  const [associationComplete, setAssociationComplete] = useState(false)

  const saveWalletInLocalStorage = useCallback(() => {
    let wallet = ""
    if (web3Provider instanceof TempleWallet) {
      wallet = "temple"
    } else if (web3Provider instanceof KukaiEmbed) {
      wallet = "kukai"
    }
    localStorage.setItem("userId", userInfo.id)
    localStorage.setItem("walletProvider", wallet)
  }, [web3Provider, userInfo.id])

  useEffect(() => {
    if (!web3Provider || !userInfo.walletAddress) return

    let pkh = null
    if (web3Provider instanceof TempleWallet) {
      pkh = web3Provider.permission?.pkh
    } else if (web3Provider instanceof KukaiEmbed) {
      pkh = web3Provider.user?.pkh
    }

    if (!pkh || pkh !== userInfo.walletAddress) {
      setShowWrongWalletError(true)
      unsetProvider()
    } else {
      saveWalletInLocalStorage()
      setAssociationComplete(true)
      setShowWrongWalletError(false)
    }
  }, [saveWalletInLocalStorage, web3Provider, userInfo.walletAddress, unsetProvider])

  // Refresh Quartz user's info on mount to make sure eligibility is up to date
  useEffect(() => {
    refreshGeolocationInfo && refreshGeolocationInfo()
    refreshQuartzInfo && refreshQuartzInfo()
  }, [refreshQuartzInfo, refreshGeolocationInfo])

  const handleAcceptTermsDismissed = async (accepted: boolean) => {
    if (!accepted) {
      setAcceptTermsDismissed(true)
      navigate("/")
      return
    }

    if (!refresh) return
    await refresh()
    setAcceptTermsDismissed(true)
  }

  const handleSignMessageDialogClose = (success: boolean) => {
    if (success) {
      setAssociationComplete(true)
    } else {
      setSelectedWallet(null)
    }
  }

  return {
    isDesktop,
    acceptTermsDismissed,
    selectedWallet,
    showWrongWalletError,
    showSignMessage,
    associationComplete,
    setSelectedWallet,
    setShowWrongWalletError,
    setAssociationComplete,
    handleAcceptTermsDismissed,
    handleSignMessageDialogClose,
    isLoading: providerLoading || userInfoLoading,
  }
}

export default useWalletConnect
