import { ReactNode } from "react"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"
import { To } from "react-router-dom"

import Footer from "../Footer"
import TopNav from "../TopNav"
import PageHeader from "../PageHeader"

import style from "./style.module.css"

export interface Props {
  header: ReactNode
  children: ReactNode
  footer?: ReactNode
  className?: string
  headerWrapperClassName?: string
  headerClassName?: string
  bodyWrapperClassName?: string
  bodyClassName?: string
  mainId?: string
  footerClassName?: string
  ubisoftFooterClassName?: string
  bodyPosition?: "bottom" | "right"
  floatingNav?: boolean
  mobileHeader?: { title: string; backLink?: To }
}

const PageLayout = ({
  header,
  children,
  footer,
  className = "",
  headerWrapperClassName = "",
  headerClassName = "",
  bodyWrapperClassName = "",
  bodyClassName = "",
  footerClassName = "",
  ubisoftFooterClassName = "",
  mainId,
  bodyPosition = "bottom",
  floatingNav = false,
  mobileHeader,
}: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <>
      {isMobile ? (
        mobileHeader && (
          <PageHeader title={mobileHeader.title} className={floatingNav ? style.overlap : style.bottomPadding} />
        )
      ) : (
        <TopNav
          invertColor={bodyPosition === "right"}
          className={`${bodyPosition === "right" ? style.overlap : ""} ${
            floatingNav ? style.overlap : style.bottomPadding
          }`}
        />
      )}
      <div
        className={`${style.container} ${
          bodyPosition === "bottom" || isMobile ? style.containerColumn : style.containerRow
        } ${className}`}
      >
        <div className={`${style.headerWrapper} ${headerWrapperClassName}`}>
          <div className={`${style.header} ${headerClassName}`}>{header}</div>
        </div>
        <div className={`${style.bodyWrapper} ${bodyWrapperClassName}`}>
          <div className={`${style.body} ${bodyClassName}`} id={mainId}>
            {children}
          </div>
        </div>
        {footer && <div className={`${style.footer} ${footerClassName}`}>{footer}</div>}
      </div>
      {!isMobile && bodyPosition !== "right" && (
        <Footer className={`${style.ubisoftFooter} ${ubisoftFooterClassName}`} />
      )}
    </>
  )
}

export default PageLayout
