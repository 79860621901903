import { useState } from "react"
import { useIntl } from "react-intl"

import FaqItem from "../FaqItem"
import getCategories from "../../views/Faq/utils/getCategories"

import style from "./style.module.css"

const FaqList = () => {
  const intl = useIntl()
  const [expanded, setExpanded] = useState<string | false>(false)
  const categories = getCategories(intl)

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      {categories.map(
        ({ id: categoryID, name, content, home }) =>
          home && (
            <ul key={categoryID} className={style.category}>
              <li>{name}</li>
              {content.map(
                ({ id, question, response, home }) =>
                  home && (
                    <li key={id}>
                      <FaqItem
                        id={`${categoryID}.${id}`}
                        title={question}
                        content={response}
                        expanded={expanded}
                        handleChange={handleChange}
                      />
                    </li>
                  )
              )}
            </ul>
          )
      )}
    </>
  )
}

export default FaqList
