import { useCallback, useState } from "react"
import { useMutation } from "react-relay"
import graphql from "babel-plugin-relay/macro"
import { TempleWallet } from "@temple-wallet/dapp"
import { KukaiEmbed } from "kukai-embed"

import { useTezosContext } from "../../../web3/Web3Provider"
import { useUserInfoContext } from "../../../providers/UserInfoProvider"
import { useUbisoftWebAuthSessionContext } from "../../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import { getPendingTokens, setPendingTokens } from "../../../utils/pendingTokensStorage"

export enum TransactionErrorKey {
  NO_WALLET_ASSOCIATED = "no-associated-wallet",
  UNINITIALIZED_PROVIDER = "provider-not-initialized",
  UNDEFINED_MINTER_ADDRESS = "undefined-minter-address",
  ASSOCIATE_WALLET_UNAVAILABLE = "associated-wallet-unavailable",
  OTHER = "other",
}

const buyTokenMutation = graphql`
  mutation useBuyTokenMutation($tokenID: ID!, $signature: String!) {
    buy(tokenID: $tokenID, signature: $signature)
  }
`

const useBuyToken = (tokenID: string, vault?: string) => {
  const { signTransaction, web3Provider } = useTezosContext()
  const [commit, isInFlight] = useMutation(buyTokenMutation)
  const [error, setError] = useState<Error | null>(null)
  const [success, setSuccess] = useState(false)
  const { user } = useUbisoftWebAuthSessionContext()
  const { userInfo } = useUserInfoContext()
  const [signaturePending, setSignaturePending] = useState(false)

  const reset = () => setError(null)

  const buyToken = useCallback(
    async (price: number) => {
      setSuccess(false)

      if (!userInfo.walletAddress) {
        setError(new Error(TransactionErrorKey.NO_WALLET_ASSOCIATED))

        return
      }

      if (!signTransaction || !web3Provider) {
        setError(new Error(TransactionErrorKey.UNINITIALIZED_PROVIDER))

        return
      }

      if (!vault) {
        setError(new Error(TransactionErrorKey.OTHER))

        return
      }

      try {
        setSignaturePending(true)
        const signature = await signTransaction(userInfo.walletAddress, vault, price)
        setSignaturePending(false)

        commit({
          variables: {
            tokenID,
            signature,
          },
          onError: (e) => setError(e),
          onCompleted: () => {
            setPendingTokens(user.userID, [...getPendingTokens(user.userID), tokenID])
            setSuccess(true)
          },
        })
      } catch (e) {
        setSignaturePending(false)

        if (web3Provider instanceof TempleWallet && (e as any).name === "NotGrantedTempleWalletError") return
        if (web3Provider instanceof KukaiEmbed && (e as Error).message === "Signing Failed: ABORTED_BY_USER") return

        setError(e as Error)
      }
    },
    [signTransaction, commit, tokenID, userInfo.walletAddress, web3Provider, vault, user]
  )

  return {
    buyToken,
    isInFlight: isInFlight,
    success,
    error,
    reset,
    signaturePending,
  }
}

export default useBuyToken
