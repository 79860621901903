import blockchainConfig from "../../config/blockchainConfig"
import getLedgerValue from "./getLedgerValue"

export const getEURLBalance = async function (publicKeyHash: string) {
  const value = await getLedgerValue(publicKeyHash)

  if (!value) return "0"

  const balance = value.balance
  const ratio = 10 ** blockchainConfig.decimals
  return balance.dividedBy(ratio).toString()
}
