/**
 * @generated SignedSource<<749cd04f557c72f08361f4c62116b632>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CannotPurchaseError_token$data = {
  readonly serialNumber: number;
  readonly " $fragmentType": "CannotPurchaseError_token";
};
export type CannotPurchaseError_token$key = {
  readonly " $data"?: CannotPurchaseError_token$data;
  readonly " $fragmentSpreads": FragmentRefs<"CannotPurchaseError_token">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CannotPurchaseError_token",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    }
  ],
  "type": "Token",
  "abstractKey": null
};

(node as any).hash = "4ea857cfd2a9f3767450dc1e76ef4770";

export default node;
