import { useEffect, useMemo, useState } from "react"
import { FormattedDate, FormattedMessage } from "react-intl"

import CardTag from "../../../../CardTag"
import PriceTag from "../../../../PriceTag"
import isFeatureEnabled from "../../../../../utils/isFeatureEnabled"
import IconButton from "../../../../IconButton"
import ProductTitle from "../../../ProductTitle"
import Countdown from "../../../../Countdown"
import Tag from "../../../../Tag"

import StickerUbi from "../../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/sticker.png"
import { ReactComponent as EditIcon } from "../../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/edit.svg"
import { ReactComponent as CheckIcon } from "../../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/check.svg"

import style from "./style.module.css"

const NoLongerAvailable = () => (
  <FormattedMessage
    defaultMessage="No Longer Available"
    id="utSh3E"
    description="[Product Card] No longer available text"
  />
)

const RemainingDigits = ({ availableTokens }: { availableTokens: number }) => (
  <FormattedMessage
    defaultMessage="{count, plural, =0 {No Longer Available} one {1 Digit remaining} other {# Digits remaining}}"
    id="dcEJUb"
    description="[Product Card] Remaining digits title"
    values={{
      count: availableTokens,
    }}
  />
)

const FreeDropTitle = ({
  startDate,
  endDate,
  currentDate,
  availableClaims = 0,
  serialNumber,
  maxInstances = 0,
}: {
  startDate: Date | null
  endDate: Date
  currentDate: Date
  availableClaims: number
  serialNumber?: number
  maxInstances: number
}) => {
  if (serialNumber && maxInstances)
    return (
      <div>
        {`#${serialNumber}`}
        <span className={style.maxInstances}>{`/${maxInstances}`}</span>
      </div>
    )

  if (!startDate)
    return (
      <FormattedMessage defaultMessage="Coming Soon" id="cyuTiQ" description="[Product card] to be announced label" />
    )

  if (endDate && endDate.getTime() < currentDate.getTime()) return <NoLongerAvailable />

  if (startDate.getTime() > currentDate.getTime())
    return <FormattedDate value={startDate} dateStyle="full" timeStyle="short" />

  return <RemainingDigits availableTokens={availableClaims} />
}

const NotFreeDropTitle = ({
  availableTokens = 0,
  maxInstances,
  serialNumber,
  hideEditButton,
  onEditSerialNumber,
}: {
  availableTokens: number
  maxInstances: number
  serialNumber?: number
  hideEditButton?: boolean
  onEditSerialNumber?: () => void
}) => {
  if (serialNumber)
    return (
      <>
        <div>
          {`#${serialNumber}`}
          <span className={style.maxInstances}>{`/${maxInstances}`}</span>
        </div>
        {!hideEditButton && (
          <IconButton className={style.iconButton} onClick={onEditSerialNumber}>
            <EditIcon />
          </IconButton>
        )}
      </>
    )

  return <RemainingDigits availableTokens={availableTokens} />
}

interface Props {
  variantName: string
  game: string
  startDate?: Date | null
  endDate?: Date | null
  priceDeFi?: number
  currencyDeFi?: string
  price?: number
  currency?: string
  serialNumber?: number
  maxInstances: number
  remainingInstances?: number
  conditionTag?: string
  hidePrice?: boolean
  hideEditButton?: boolean
  onEditSerialNumber?: () => void
  isPending?: boolean
  className?: string
  claimed?: boolean
}

const ProductCardDescription = ({
  variantName,
  game,
  startDate,
  endDate,
  priceDeFi,
  currencyDeFi,
  price,
  currency,
  serialNumber,
  remainingInstances = 0,
  maxInstances,
  conditionTag,
  hidePrice = true,
  hideEditButton,
  onEditSerialNumber,
  isPending = false,
  className = "",
  claimed = false,
}: Props) => {
  // Updating current Date only at relevant times (startDate or endDate reached)
  const [date, setDate] = useState(new Date())
  useEffect(() => {
    const date = new Date()
    if (!startDate || date.getTime() >= startDate.getTime()) return
    const interval = setInterval(() => setDate(new Date()), startDate.getTime() - date.getTime())
    return () => clearInterval(interval)
  }, [startDate])
  useEffect(() => {
    const date = new Date()
    if (!endDate || date.getTime() >= endDate.getTime()) return
    const interval = setInterval(() => setDate(new Date()), endDate.getTime() - date.getTime())
    return () => clearInterval(interval)
  }, [endDate])

  const isFreeDrop = price === 0
  const showActive = useMemo(
    () =>
      !serialNumber &&
      !claimed &&
      remainingInstances > 0 &&
      startDate &&
      startDate.getTime() <= date.getTime() &&
      (!endDate || endDate.getTime() > date.getTime()),
    [serialNumber, claimed, date, endDate, remainingInstances, startDate]
  )
  const showAlreadyClaimed = useMemo(() => claimed && !serialNumber, [claimed, serialNumber])
  const sticker = useMemo(() => {
    /* My Token View : Sticker icon */
    if (serialNumber && hideEditButton) {
      return (
        <div className={style.stickerContainer} aria-hidden>
          <img src={StickerUbi} alt="" className={style.sticker} />
        </div>
      )
    }

    /* Already Claimed : Check icon */
    if (claimed) {
      return <CheckIcon className={style.iconClaimed} />
    }

    return null
  }, [serialNumber, hideEditButton, claimed])

  return (
    <div className={`${style.description} ${className}`}>
      {conditionTag && <CardTag label={conditionTag} color="primaryGradient" />}
      <ProductTitle name={variantName} gameTitle={game} processingTag={isPending} />
      <div className={`${style.info} ${isFreeDrop ? style.twoColumns : ""} ${showActive ? style.highlight : ""}`}>
        <div className={style.middleContainer}>
          {sticker}
          <div>
            <div className={style.subtitle}>
              {startDate && startDate.getTime() > date.getTime() ? (
                <FormattedMessage
                  defaultMessage="Limited Edition available on"
                  id="kprb0Q"
                  description="[Product Card] Edition label"
                  values={{
                    count: maxInstances,
                    b: (chunks: string) => <strong>{chunks}</strong>,
                  }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="{count, plural, =0 {Limited Edition} one {Unique Digit} other {Limited Edition: <b>#</b>}}"
                  id="gf/mRE"
                  description="[Product Card] Edition count"
                  values={{
                    count: maxInstances,
                    b: (chunks: string) => <strong>{chunks}</strong>,
                  }}
                />
              )}
            </div>
            <div className={style.title}>
              {showAlreadyClaimed ? (
                <FormattedMessage
                  defaultMessage="Already Claimed"
                  id="fAUKOe"
                  description="[Product Card] already claimed message"
                />
              ) : isFreeDrop ? (
                <FreeDropTitle
                  startDate={startDate || null}
                  endDate={endDate!}
                  currentDate={date}
                  availableClaims={remainingInstances}
                  serialNumber={serialNumber}
                  maxInstances={maxInstances}
                />
              ) : (
                <NotFreeDropTitle
                  availableTokens={remainingInstances}
                  maxInstances={maxInstances}
                  serialNumber={serialNumber}
                  hideEditButton={hideEditButton}
                  onEditSerialNumber={onEditSerialNumber}
                />
              )}
            </div>
          </div>
        </div>
        {showActive &&
          (endDate ? (
            <Countdown type="clock" endDate={endDate} zeroPadTime={2} />
          ) : (
            <Tag color="primaryGradient" className={style.tag}>
              <FormattedMessage
                defaultMessage="ACTIVE"
                id="L0Od2q"
                description="[Product Card] active drop tag label"
              />
            </Tag>
          ))}
        {!hidePrice && priceDeFi && currencyDeFi && (
          <div className={style.rightContainer}>
            <PriceTag data-testid="price" value={priceDeFi} currency={currencyDeFi} />
            {/* TODO: add FIAT price equivalent */}
            {isFeatureEnabled("euroValue") && (
              <span className={style.priceFiat}>{price ? `${price} ${currency}` : `-.--`}</span>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductCardDescription
