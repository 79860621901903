import UbisoftSpinner from "../../UbisoftSpinner"

import style from "./style.module.css"

interface Props {
  message?: string
}

const TransactionPendingPage = ({ message }: Props) => {
  return (
    <div className={style.container}>
      <UbisoftSpinner />
      <div className={style.message}>{message}</div>
    </div>
  )
}

export default TransactionPendingPage
