import { useFragment } from "react-relay"
import graphql from "babel-plugin-relay/macro"

import { ClaimStatusSectionFragment_sale$key } from "./__generated__/ClaimStatusSectionFragment_sale.graphql"

import { ClaimStatus } from "../../hooks/useClaimStatus"

import ClaimStatusSection from "."

export interface Props {
  sale: ClaimStatusSectionFragment_sale$key
  claimStatus:
    | ClaimStatus.ALREADY_CLAIMED
    | ClaimStatus.CONDITION_NOT_MET
    | ClaimStatus.CAN_CLAIM
    | ClaimStatus.CLAIM_NOT_STARTED
    | ClaimStatus.CLAIM_ENDED
  className?: string
}

const ClaimStatusSectionFragment = ({ sale, claimStatus, className = "" }: Props) => {
  const saleData = useFragment(
    graphql`
      fragment ClaimStatusSectionFragment_sale on Sale {
        endDate
        archetype {
          primaryVariant {
            name
          }
        }
      }
    `,
    sale
  )

  return (
    <ClaimStatusSection
      variantName={saleData.archetype.primaryVariant.name}
      endDate={saleData.endDate ? new Date(saleData.endDate) : null}
      claimStatus={claimStatus}
      className={className}
    />
  )
}

export default ClaimStatusSectionFragment
