/**
 * @generated SignedSource<<bb3ef48f4cb2d3bf2e79f476a89b8101>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useClaimDigitMutation$variables = {
  saleID: string;
};
export type useClaimDigitMutation$data = {
  readonly registerFreeDrop: boolean;
};
export type useClaimDigitMutation = {
  variables: useClaimDigitMutation$variables;
  response: useClaimDigitMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "saleID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "saleID",
        "variableName": "saleID"
      }
    ],
    "kind": "ScalarField",
    "name": "registerFreeDrop",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useClaimDigitMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useClaimDigitMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4c906255748d8141405699a2dda15fcb",
    "id": null,
    "metadata": {},
    "name": "useClaimDigitMutation",
    "operationKind": "mutation",
    "text": "mutation useClaimDigitMutation(\n  $saleID: ID!\n) {\n  registerFreeDrop(saleID: $saleID)\n}\n"
  }
};
})();

(node as any).hash = "f959fd0cfc59540d308e6471598efffe";

export default node;
