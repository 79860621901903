import { FormattedMessage, useIntl } from "react-intl"
import clsx from "clsx"
import { Link, To } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"

import ipfsToHttpGateway from "../../../utils/ipfsToHttpGateway"
import Video from "../../Video"
import ProductCardDescription from "./components/ProductCardDescription"
import saleConditionMessage, { Operator } from "../../../utils/saleCondition/saleConditionMessage"
import Tag from "../../Tag"

import style from "./style.module.css"

export interface Props {
  variantName: string
  game: string
  image: string
  video?: string
  startDate?: Date | null
  endDate?: Date | null
  priceDeFi?: number
  currencyDeFi?: string
  price?: number
  currency?: string
  serialNumber?: number
  maxInstances: number
  remainingInstances?: number
  saleConditions?: Record<
    string,
    {
      operator: Operator
      value: number
    }
  >
  hidePrice?: boolean
  hideEditButton?: boolean
  onEditSerialNumber?: () => void
  isPending?: boolean
  isLoading?: boolean
  className?: string
  productDescriptionClassName?: string
  to?: To
  showControls?: boolean
  claimed?: boolean
}

const ProductCard = ({
  variantName,
  game,
  image,
  video,
  startDate,
  endDate,
  priceDeFi,
  currencyDeFi,
  price,
  currency,
  serialNumber,
  maxInstances,
  remainingInstances = 0,
  saleConditions,
  hidePrice,
  hideEditButton,
  onEditSerialNumber,
  isPending = false,
  isLoading = false,
  className = "",
  productDescriptionClassName = "",
  to,
  showControls = false,
  claimed = false,
}: Props) => {
  const intl = useIntl()

  // Updating current Date only at relevant times (startDate or endDate reached)
  const [date, setDate] = useState(new Date())
  useEffect(() => {
    const date = new Date()
    if (!startDate || date.getTime() >= startDate.getTime()) return
    const interval = setInterval(() => setDate(new Date()), startDate.getTime() - date.getTime())
    return () => clearInterval(interval)
  }, [startDate])
  useEffect(() => {
    const date = new Date()
    if (!endDate || date.getTime() >= endDate.getTime()) return
    const interval = setInterval(() => setDate(new Date()), endDate.getTime() - date.getTime())
    return () => clearInterval(interval)
  }, [endDate])
  const showUpcomingTag = useMemo(
    () => !serialNumber && (!startDate || startDate.getTime() > date.getTime()),
    [serialNumber, startDate, date]
  )

  const isActive = useMemo(
    () =>
      !claimed &&
      remainingInstances > 0 &&
      startDate &&
      startDate.getTime() <= date.getTime() &&
      (!endDate || endDate.getTime() > date.getTime()),
    [claimed, remainingInstances, date, startDate, endDate]
  )

  if (isLoading) {
    return (
      <div className={clsx(style.cardLoading, className)}>
        <div className={style.imageLoading} />
        <div className={style.description}>
          <div className={style.nameLoading} />
          <div className={style.gameTitleLoading} />
          <div className={style.infoLoading} />
        </div>
      </div>
    )
  }

  const content = (
    <div className={clsx(style.card, className, isActive ? style.hightlight : "")}>
      {video ? (
        <Video video={video} image={image} alt={variantName} className={style.visual} showControls={showControls} />
      ) : (
        <img
          className={`${style.visual} ${style.withBackground}`}
          src={ipfsToHttpGateway(image)}
          alt={variantName}
          data-testid="product-image"
        />
      )}

      <ProductCardDescription
        priceDeFi={priceDeFi}
        startDate={startDate}
        endDate={endDate}
        serialNumber={serialNumber}
        currencyDeFi={currencyDeFi}
        price={price}
        currency={currency}
        variantName={variantName || ""}
        game={game || ""}
        maxInstances={maxInstances}
        remainingInstances={remainingInstances}
        onEditSerialNumber={onEditSerialNumber}
        isPending={isPending}
        hideEditButton={hideEditButton}
        hidePrice={hidePrice}
        conditionTag={(saleConditions && saleConditionMessage(intl, saleConditions)) || undefined}
        className={`${style.descriptionCard} ${productDescriptionClassName}`}
        claimed={claimed}
      />
      {showUpcomingTag && (
        <Tag className={style.upcomingTag} color="black">
          <FormattedMessage
            defaultMessage="Upcoming Drop"
            id="XPZE6P"
            description="[Product Card] Upcoming tag label"
          />
        </Tag>
      )}
    </div>
  )

  if (to) {
    return (
      <Link to={to} className={style.link}>
        {content}
      </Link>
    )
  }

  return content
}

export default ProductCard
