import { useEffect, useMemo, useState } from "react"
import { useFragment } from "react-relay"
import graphql from "babel-plugin-relay/macro"
import { useQuery } from "relay-hooks"

import { useSaleStatusFragment_sale$key } from "./__generated__/useSaleStatusFragment_sale.graphql"
import { useSaleStatusPendingClaimsQuery } from "./__generated__/useSaleStatusPendingClaimsQuery.graphql"

import { useUbisoftWebAuthSessionContext } from "../ubisoftWebAuth/UbisoftWebAuthSessionProvider"

export enum SaleStatus {
  NOT_STARTED,
  END_DATE_REACHED,
  ALREADY_CLAIMED,
  NO_TOKEN_AVAILABLE,
  AVAILABLE,
}

const pendingClaimsQuery = graphql`
  query useSaleStatusPendingClaimsQuery {
    currentPlayer {
      claims {
        sale {
          id
        }
      }
    }
  }
`

const useSaleStatus = (sale: useSaleStatusFragment_sale$key) => {
  const { user } = useUbisoftWebAuthSessionContext()
  const {
    data: playerData,
    isLoading: playerDataIsLoading,
    // TODO: Handle error currentplayer query error when logged
    // error: playerDataError,
  } = useQuery<useSaleStatusPendingClaimsQuery>(pendingClaimsQuery, {}, { skip: !user.ticket })

  const saleData = useFragment(
    graphql`
      fragment useSaleStatusFragment_sale on Sale {
        id
        price
        startDate
        endDate
        claimsCount {
          remaining
        }
      }
    `,
    sale
  )

  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 5000)
    return () => {
      clearInterval(interval)
    }
  })

  const status = useMemo(() => {
    if (Date.parse(saleData.startDate) > date.getTime()) {
      return SaleStatus.NOT_STARTED
    }
    if (saleData.endDate && Date.parse(saleData.endDate) < date.getTime()) {
      return SaleStatus.END_DATE_REACHED
    }
    if (playerData?.currentPlayer.claims.some((claim) => claim.sale.id === saleData.id))
      return SaleStatus.ALREADY_CLAIMED

    // TODO: Update value for paid drops when tokenCount feature will be available for now value may be wrong
    const availableTokens = saleData.price === 0 ? saleData.claimsCount!.remaining > 0 : 0
    if (!availableTokens) {
      return SaleStatus.NO_TOKEN_AVAILABLE
    }

    return SaleStatus.AVAILABLE
  }, [saleData, date, playerData])

  return { status, isLoading: playerDataIsLoading }
}

export default useSaleStatus
