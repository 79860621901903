/**
 * @generated SignedSource<<0506414030511ca69951db825a501f80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuySectionFragment_token$data = {
  readonly id: string;
  readonly available: boolean;
  readonly serialNumber: number;
  readonly " $fragmentSpreads": FragmentRefs<"CannotPurchaseError_token">;
  readonly " $fragmentType": "BuySectionFragment_token";
};
export type BuySectionFragment_token$key = {
  readonly " $data"?: BuySectionFragment_token$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuySectionFragment_token">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuySectionFragment_token",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CannotPurchaseError_token"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "available",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    }
  ],
  "type": "Token",
  "abstractKey": null
};

(node as any).hash = "47780a59556278748ec3eb9189d4319b";

export default node;
