/**
 * @generated SignedSource<<2bda09e4fe0e13dd52f8e67f5c559712>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SaleConditionOperator = "ADD" | "AND" | "EQ" | "GT" | "GTE" | "LT" | "LTE" | "NEQ" | "OR" | "%future added value";
export type ScalarType = "BOOL" | "FLOAT" | "INT" | "STRING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type extractStatsNames_condition$data = {
  readonly expr: {
    readonly args: ReadonlyArray<{
      readonly key?: string;
      readonly spaceID?: string;
      readonly type?: ScalarType;
      readonly operator?: SaleConditionOperator;
      readonly args?: ReadonlyArray<{
        readonly key?: string;
        readonly spaceID?: string;
        readonly type?: ScalarType;
        readonly args?: ReadonlyArray<{
          readonly key?: string;
          readonly spaceID?: string;
          readonly type?: ScalarType;
        }>;
      }>;
    }>;
  } | null;
};
export type extractStatsNames_condition$key = {
  readonly " $data"?: extractStatsNames_condition$data;
  readonly " $fragmentSpreads": FragmentRefs<"extractStatsNames_condition">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "extractStatsNames_condition"
};

(node as any).hash = "980f9a72d58fe85c5176ead7e6bda5ea";

export default node;
