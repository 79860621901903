import { useMemo } from "react"
import { useIntl } from "react-intl"
import { useLocation } from "react-router"
import graphql from "babel-plugin-relay/macro"
import { useFragment } from "react-relay"

import { CannotPurchaseError_sale$key } from "./__generated__/CannotPurchaseError_sale.graphql"
import { CannotPurchaseError_variant$key } from "./__generated__/CannotPurchaseError_variant.graphql"
import { CannotPurchaseError_token$key } from "./__generated__/CannotPurchaseError_token.graphql"

import { PurchaseStatus } from "../../../hooks/useCanPurchase"
import { useUbisoftWebAuthSessionContext } from "../../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import BasePurchaseError from "../BasePurchaseError"
import TrackPage from "../../TrackPage"
import { buildCustomParameter } from "../../../utils/analytics"
import { Operator } from "../../../utils/saleCondition/saleConditionMessage"
import buildConditionMap from "../../../utils/saleCondition/buildConditionMap"
import useDashboardRedirectInfo from "../../../hooks/useDashboardRedirectInfo"

import { ReactComponent as WalletIcon } from "../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/walletStroke.svg"
import { ReactComponent as ArrowRightIcon } from "../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"

interface ErrorProps {
  trackingCustomParameter: string
  requirements: Record<string, { operator: Operator; value: number }> | undefined
}

const LoggedOutError = ({ trackingCustomParameter }: ErrorProps) => {
  const intl = useIntl()
  const { login } = useUbisoftWebAuthSessionContext()

  return (
    <>
      <TrackPage
        siteSection="product"
        pageName="message : login to your ubisoft account"
        customParameter={trackingCustomParameter}
      />
      <BasePurchaseError
        title={intl.formatMessage({
          defaultMessage: "Log in to your Ubisoft account",
          id: "Oc1NGJ",
          description: "[Product Page] User not logged in error title",
        })}
        message={intl.formatMessage({
          defaultMessage:
            "Only Ghost Recon Breakpoint players that reached XP Level 5 can claim Digits. Be sure to connect with the same account as the one you play with.",
          id: "fICkTb",
          description: "[Product Page] User not logged in error description",
        })}
        buttonLabel={intl.formatMessage({
          defaultMessage: "Log in",
          id: "Vv6vi0",
          description: "Link to Login",
        })}
        buttonIcon={<ArrowRightIcon />}
        onClick={login}
      />
    </>
  )
}

const NoAssociatedWalletError = ({ trackingCustomParameter }: ErrorProps) => {
  const intl = useIntl()
  const location = useLocation()

  return (
    <>
      <TrackPage
        siteSection="product"
        pageName="message : wallet not setup"
        customParameter={trackingCustomParameter}
      />
      <BasePurchaseError
        title={intl.formatMessage({
          defaultMessage: "No crypto-wallet!",
          id: "22CQ9H",
          description: "[Product Page] Purchase section title when user has no associated wallet",
        })}
        message={intl.formatMessage({
          defaultMessage:
            "You need to set up a crypto-wallet to Ubisoft Quartz to perform transactions and to host your Digits once acquired.",
          id: "h4ZM5/",
          description: "[Product Page] Wallet not setup error description",
        })}
        buttonLabel={intl.formatMessage({
          defaultMessage: "Set up now",
          id: "r/lNuK",
          description: "[Product Page] Purchase section link to wallet creation/connection",
        })}
        buttonIcon={<WalletIcon />}
        buttonInvert={false}
        to={{ pathname: "/user/wallet/connect", search: "?view=p" }}
        state={{ referrer: location }}
      />
    </>
  )
}

const NoConnectedWalletError = ({ trackingCustomParameter }: ErrorProps) => {
  const intl = useIntl()
  const location = useLocation()

  return (
    <>
      <TrackPage
        siteSection="product"
        pageName="message : wallet not connected"
        customParameter={trackingCustomParameter}
      />
      <BasePurchaseError
        title={intl.formatMessage({
          defaultMessage: "Crypto-wallet not connected",
          id: "oq2FQH",
          description: "[Product Page] Wallet not connected",
        })}
        message={intl.formatMessage({
          defaultMessage: "You need to connect your crypto-wallet to perform transactions in Ubisoft Quartz.",
          id: "EOw5pu",
          description: "[Product Page] Wallet not connected description",
        })}
        buttonLabel={intl.formatMessage({
          defaultMessage: "Connect now",
          id: "qEL3nz",
          description: "[Product Page] Link to wallet connection",
        })}
        buttonIcon={<WalletIcon />}
        buttonInvert={false}
        to={{ pathname: "/user/wallet/connect" }}
        state={{ referrer: location }}
      />
    </>
  )
}

const MaxOwnedTokenError = ({ trackingCustomParameter }: ErrorProps) => {
  const intl = useIntl()
  const { to, label } = useDashboardRedirectInfo()

  return (
    <>
      <TrackPage
        siteSection="product"
        pageName="message : nice try only 1 token"
        customParameter={trackingCustomParameter}
      />
      <BasePurchaseError
        title={intl.formatMessage({
          defaultMessage: "Nice try!",
          id: "cdHkro",
          description: "[Product Page] Max owned token error message title",
        })}
        message={intl.formatMessage({
          defaultMessage:
            "You cannot own more than one Digit per Edition. You can look for other Editions on the dashboard!",
          id: "vITIbm",
          description: "[Product Page] Max owned token error message title",
        })}
        buttonLabel={label}
        buttonIcon={<ArrowRightIcon />}
        buttonInvert={false}
        to={to}
      />
    </>
  )
}

const NotEligibleError = ({ trackingCustomParameter }: ErrorProps) => {
  const intl = useIntl()

  return (
    <>
      <TrackPage siteSection="product" pageName="message : not eligible" customParameter={trackingCustomParameter} />
      <BasePurchaseError
        title={intl.formatMessage({
          defaultMessage: "You are currently not eligible!",
          id: "/K19iV",
          description: "[Product Page] Not eligible title",
        })}
        message={intl.formatMessage({
          defaultMessage: "We're sorry, for some reason you aren't eligible to acquire Digits on Ubisoft Quartz.",
          id: "j6eHg2",
          description: "[Product Page] Not eligible description",
        })}
        buttonLabel={intl.formatMessage({
          defaultMessage: "More info",
          id: "S2EWiS",
          description: "[Product Page] Link to eligibility recap",
        })}
        buttonIcon={<ArrowRightIcon />}
        buttonInvert={false}
        to="/user/not-eligible/purchase"
      />
    </>
  )
}

// TODO Replace the generic message with better description of the requirement for the user
const ConditionNotMetError = ({ trackingCustomParameter }: ErrorProps) => {
  const intl = useIntl()
  const { to, label } = useDashboardRedirectInfo()

  return (
    <>
      <TrackPage
        siteSection="product"
        pageName="message : requirement not met"
        customParameter={trackingCustomParameter}
      />
      <BasePurchaseError
        title={intl.formatMessage({
          defaultMessage: "Sorry!",
          id: "N9dQzQ",
          description: "[Product Page] Not eligible title",
        })}
        message={intl.formatMessage({
          defaultMessage: "You player status does not allow you to acquire this Digit on Ubisoft Quartz.",
          id: "YkKLJF",
          description: "[Product Page] Requirement not met description",
        })}
        buttonLabel={label}
        buttonIcon={<ArrowRightIcon />}
        buttonInvert={false}
        to={to}
      />
    </>
  )
}

const ERROR_MESSAGE_MAP: Record<PurchaseStatus, ((props: ErrorProps) => JSX.Element) | null> = {
  [PurchaseStatus.NOT_LOGGED_IN]: LoggedOutError,
  [PurchaseStatus.NO_ASSOCIATED_WALLET]: NoAssociatedWalletError,
  [PurchaseStatus.NO_CONNECTED_WALLET]: NoConnectedWalletError,
  [PurchaseStatus.MAX_OWNED_TOKEN_REACHED]: MaxOwnedTokenError,
  [PurchaseStatus.NOT_ELIGIBLE]: NotEligibleError,
  [PurchaseStatus.CONDITION_NOT_MET]: ConditionNotMetError,
  [PurchaseStatus.CAN_PURCHASE]: null,
}

export interface Props {
  error: PurchaseStatus
  sale: CannotPurchaseError_sale$key | null
  token: CannotPurchaseError_token$key | null
  variant: CannotPurchaseError_variant$key
  freeDrop?: boolean
}

const CannotPurchaseError = ({ error, sale, token, variant, freeDrop = false }: Props) => {
  const component = ERROR_MESSAGE_MAP[error]
  const saleData = useFragment(
    graphql`
      fragment CannotPurchaseError_sale on Sale {
        condition {
          ...buildConditionMap_saleCondition
        }
        price
        archetype {
          maxInstances
        }
      }
    `,
    sale
  )
  const tokenData = useFragment(
    graphql`
      fragment CannotPurchaseError_token on Token {
        serialNumber
      }
    `,
    token
  )
  const variantData = useFragment(
    graphql`
      fragment CannotPurchaseError_variant on Variant {
        name
        categorization {
          game {
            name
          }
        }
      }
    `,
    variant
  )

  const conditionMap = useMemo(
    () => (saleData?.condition ? buildConditionMap(saleData.condition) : undefined),
    [saleData]
  )

  const trackingCustomParameter = useMemo(
    () =>
      buildCustomParameter(
        variantData.categorization?.game?.name || "",
        variantData.name,
        tokenData?.serialNumber || "",
        saleData?.archetype.maxInstances || 0,
        saleData?.price || 0,
        // TODO: Update value below when tokenCount feature will be available for now value may be wrong
        false,
        freeDrop
      ),
    [saleData, tokenData, variantData, freeDrop]
  )

  if (!component) return null

  return <>{component({ trackingCustomParameter, requirements: conditionMap })}</>
}

export default CannotPurchaseError
