import React, { useMemo } from "react"
import { HTMLAttributes } from "react"

import { ReactComponent as TagPiece } from "./tag.svg"

import style from "./style.module.css"
import { useIntl } from "react-intl"

export interface Props extends HTMLAttributes<HTMLDivElement> {
  value: number
  currency: string
}

const PriceTag = ({ value, currency, ...props }: Props) => {
  const intl = useIntl()
  const formattedValue = useMemo(() => new Intl.NumberFormat(intl.locale).format(value), [value, intl])

  return (
    <div className={style.container} {...props}>
      <TagPiece />
      <div className={style.textContent}>
        {formattedValue} <span className={style.currency}>{currency}</span>
      </div>
    </div>
  )
}

export default PriceTag
