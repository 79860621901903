import { useEffect, useMemo } from "react"
import graphql from "babel-plugin-relay/macro"
import { useQuery } from "relay-hooks"
import { useFragment } from "react-relay"

import { useCanPurchaseFragment_sale$key } from "./__generated__/useCanPurchaseFragment_sale.graphql"
import { useCanPurchaseInventoryQuery } from "./__generated__/useCanPurchaseInventoryQuery.graphql"

import { useUbisoftWebAuthSessionContext } from "../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import { useUserInfoContext } from "../providers/UserInfoProvider"
import { useTezosContext } from "../web3/Web3Provider"
import { useGeolocationInfoContext } from "../providers/GeolocationInfoProvider"
import isUserEligible from "../utils/isUserEligible"
import { useUbiPlayerStatsContext } from "../providers/UbiPlayerStatsProvider"
import extractStatsNames from "../utils/saleCondition/extractStatsNames"
import playerStatsMeetCondition from "../utils/saleCondition/playerStatsMeetCondition"

const inventoryQuery = graphql`
  query useCanPurchaseInventoryQuery {
    currentPlayer {
      inventory {
        archetype {
          id
        }
      }
    }
  }
`

export enum PurchaseStatus {
  NOT_LOGGED_IN,
  NO_ASSOCIATED_WALLET,
  NO_CONNECTED_WALLET,
  MAX_OWNED_TOKEN_REACHED,
  NOT_ELIGIBLE,
  CONDITION_NOT_MET,
  CAN_PURCHASE,
}

const useCanPurchase = (sale: useCanPurchaseFragment_sale$key) => {
  const { user, twoFaActive, isLoading: userLoading } = useUbisoftWebAuthSessionContext()
  const { data, isLoading, error } = useQuery<useCanPurchaseInventoryQuery>(inventoryQuery, {}, { skip: !user.ticket })
  const saleData = useFragment(
    graphql`
      fragment useCanPurchaseFragment_sale on Sale {
        price
        condition {
          ...extractStatsNames_condition
          ...playerStatsMeetCondition_saleCondition
        }
        archetype {
          id
          maxInstancesPerUser
        }
      }
    `,
    sale
  )
  const statsNames = useMemo(() => extractStatsNames(saleData.condition), [saleData.condition])
  const { data: stats, isLoading: statsLoading, fetch } = useUbiPlayerStatsContext()

  useEffect(() => fetch(statsNames), [fetch, statsNames])

  const { isCurrentIpLocationAllowed, isLoading: geolocationIsLoading } = useGeolocationInfoContext()
  const { userInfo, isLoading: userInfoLoading } = useUserInfoContext()
  const { signTransaction } = useTezosContext()

  const status = useMemo(() => {
    if (!user.ticket || error) return PurchaseStatus.NOT_LOGGED_IN

    if (!isUserEligible(userInfo, isCurrentIpLocationAllowed) || !twoFaActive) return PurchaseStatus.NOT_ELIGIBLE

    const meetCondition = !statsLoading && (!saleData.condition || playerStatsMeetCondition(stats, saleData.condition))
    if (user.ticket && !meetCondition) return PurchaseStatus.CONDITION_NOT_MET

    if (!userInfo.walletAddress) return PurchaseStatus.NO_ASSOCIATED_WALLET

    if (saleData.price > 0 && !signTransaction) return PurchaseStatus.NO_CONNECTED_WALLET

    if (
      data &&
      data.currentPlayer.inventory.filter(({ archetype: { id } }) => id === saleData.archetype.id).length >=
        saleData.archetype.maxInstancesPerUser
    ) {
      return PurchaseStatus.MAX_OWNED_TOKEN_REACHED
    }

    return PurchaseStatus.CAN_PURCHASE
  }, [
    saleData,
    data,
    error,
    signTransaction,
    user.ticket,
    userInfo,
    isCurrentIpLocationAllowed,
    twoFaActive,
    statsLoading,
    stats,
  ])

  return {
    status,
    isLoading: isLoading || userLoading || userInfoLoading || geolocationIsLoading || statsLoading,
  }
}

export default useCanPurchase
