import { useCallback, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "relay-hooks"
import { useIntl } from "react-intl"
import graphql from "babel-plugin-relay/macro"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import { TokenPurchaseQuery } from "./__generated__/TokenPurchaseQuery.graphql"

import { useUserInfoContext } from "../../providers/UserInfoProvider"
import useBuyToken from "./hooks/useBuyToken"
import PageHeader from "../../components/PageHeader"
import PageLayout from "../../components/PageLayout"
import { useTezosContext } from "../../web3/Web3Provider"
import TransactionPendingPage from "../../components/TokenPurchase/TransactionPendingPage"
import TransactionErrorPage from "../../components/TokenPurchase/TransactionErrorPage"
import ProductCardFragment from "../../components/Product/ProductCard/ProductCardFragment"
import walletProviders from "../../web3/walletProviders"
import Tokens from "../Tokens"
import UbisoftSpinner from "../../components/UbisoftSpinner"
import BuySection from "./components/BuySection"
import SuccessPage from "../../components/TokenPurchase/SuccessPage"
import EligibilitySnackBar from "../../components/EligibilitySnackBar"
import FullPageError from "../../components/FullPageError"
import { trackClick } from "../../utils/analytics"
import { extractErrorCode } from "../../api/errors"

import style from "./style.module.css"

const tokenPurchaseQuery = graphql`
  query TokenPurchaseQuery($saleID: ID!, $tokenID: ID!) {
    sale(id: $saleID) {
      price
      startDate
      archetype {
        id
        maxInstances
        maxInstancesPerUser
        vault
        primaryVariant {
          name
          categorization {
            game {
              id
              name
            }
          }
          image
        }
      }
      ...ProductCardFragment_sale
      ...BuySectionFragment_sale
      ...useTrackingCustomParameter_sale
    }

    token(id: $tokenID) {
      id
      serialNumber
      available
      ...ProductCardFragment_token
      ...BuySectionFragment_token
      ...useTrackingCustomParameter_token
    }
  }
`

const TokenPurchase = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"))
  const intl = useIntl()
  const { gameID, saleID, tokenID } = useParams() as { gameID: string; saleID: string; tokenID: string }
  const { data, isLoading, error } = useQuery<TokenPurchaseQuery>(tokenPurchaseQuery, { saleID, tokenID })
  const {
    buyToken,
    isInFlight,
    success,
    error: buyError,
    reset,
    signaturePending,
  } = useBuyToken(tokenID, data?.sale.archetype.vault)
  const { providerName } = useTezosContext()
  const { userInfo, isLoading: isUserInfoLoading } = useUserInfoContext()
  const [open, setOpen] = useState(false)

  const handleBuyClick = useCallback(async () => {
    if (!data) return

    trackClick({
      location: "ubisoft quartz",
      locationDetail: "checkout",
      category: "action",
      action: "buy this digit",
    })
    try {
      await buyToken(data.sale.price)
    } catch (e) {}
  }, [buyToken, data])

  const handleCloseTokens = () => setOpen(false)
  const handleEditTokens = () => setOpen(true)

  const walletInfo = useMemo(
    () =>
      providerName && userInfo.walletAddress
        ? { provider: providerName, address: userInfo.walletAddress, logo: walletProviders[providerName].logo }
        : null,
    [providerName, userInfo.walletAddress]
  )

  if (isLoading) {
    return (
      <PageLayout
        headerClassName={style.header}
        header={
          <>
            {isMobile && <PageHeader title="" />}
            <div className={style.cardLoading}>
              <div className={style.imageLoading} />
              <div className={style.descriptionLoading}>
                <div className={style.nameLoading} />
                <div className={style.gameTitleLoading} />
                <div className={style.infoLoading} />
              </div>
            </div>
          </>
        }
        bodyClassName={style.bodyLoading}
        bodyPosition="right"
      >
        <div className={style.nameLoading} />
        <div className={style.infoLoading} />
        <div className={style.buttonLoading} />
      </PageLayout>
    )
  }

  if (error || !data) {
    return (
      <FullPageError
        headerTitle={intl.formatMessage({ defaultMessage: "Error", id: "D7Dner", description: "Error page title" })}
        errorTitle={intl.formatMessage({
          defaultMessage: "Digit not found",
          id: "tT+byY",
          description: "Error message",
        })}
        buttonLabel={intl.formatMessage({
          defaultMessage: "Back",
          id: "2t2oBC",
          description: "Go back button",
        })}
      />
    )
  }

  if (isInFlight) {
    return (
      <TransactionPendingPage
        message={intl.formatMessage({
          defaultMessage: "Transaction Pending...",
          id: "tGfDRy",
          description: "Pending transaction loading message",
        })}
      />
    )
  }

  if (buyError) {
    return <TransactionErrorPage code={extractErrorCode(buyError)} walletInfo={walletInfo} onBackClick={reset} />
  }

  const variant = data.sale.archetype.primaryVariant

  if (success) {
    return (
      <SuccessPage
        to={`/token/${tokenID}`}
        itemName={`${variant.name} #${data.token.serialNumber}`}
        type="purchase"
        sale={data.sale}
        token={data.token || null}
      />
    )
  }

  return (
    <>
      <PageLayout
        header={
          <>
            {isMobile && (
              <PageHeader
                title={intl.formatMessage(
                  {
                    defaultMessage: "{variant} #{serialNumber}<b>/{count}</b>",
                    id: "brpn1b",
                    description: "[Product Page] Token Page title",
                  },
                  {
                    variant: variant.name,
                    serialNumber: data.token.serialNumber,
                    count: data.sale.archetype.maxInstances,
                    b: (chunks) => <span className={style.count}>{chunks}</span>,
                  }
                )}
              />
            )}
            <ProductCardFragment
              gameID={gameID}
              sale={data.sale}
              token={data.token}
              player={null}
              onEditSerialNumber={handleEditTokens}
              isLoading={isLoading}
              className={style.productCard}
              showControls
            />
          </>
        }
        bodyPosition="right"
      >
        {isUserInfoLoading ? (
          <div className={style.sectionLoading}>
            <UbisoftSpinner contrast />
          </div>
        ) : (
          <BuySection
            sale={data.sale}
            token={data.token}
            walletInfo={walletInfo}
            onBuyClick={handleBuyClick}
            disableBuy={signaturePending}
          />
        )}
        <Tokens open={open} onClose={handleCloseTokens} />
        <EligibilitySnackBar />
      </PageLayout>
    </>
  )
}

export default TokenPurchase
