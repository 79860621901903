import { trackClick } from "../../utils/analytics"
import Button from "../Button"

import { ReactComponent as LogoRarible } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/logo-rarible-black.svg"

import style from "./style.module.css"
import { useMemo } from "react"

type Props = {
  description?: string
  tokenName?: string
}

const MarketplaceLinks = ({ description, tokenName }: Props) => {
  const handleTrackClick = (marketplace: "rarible" | "objkt") => {
    trackClick({
      location: "ubisoft quartz",
      locationDetail: "product page",
      category: "action",
      action: `marketplace: ${marketplace}`,
    })
  }

  const raribleURL = useMemo(() => {
    const baseURL = "https://rarible.com/ubisoft-quartz/items"

    if (!tokenName) return baseURL

    return `${baseURL}?filter[filter][traits][Archetype][key]=Archetype&filter[filter][traits][Archetype][values][]=${encodeURI(
      tokenName
    )}`
  }, [tokenName])

  return (
    <div className={style.card}>
      {description && <p className={style.description}>{description}</p>}
      <div className={style.buttons}>
        <Button fullWidth href={raribleURL} target="_blank" onClick={() => handleTrackClick("rarible")}>
          <LogoRarible />
        </Button>
      </div>
    </div>
  )
}

export default MarketplaceLinks
