/**
 * @generated SignedSource<<afab2ffc298535fd9ec9151e48df8d64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClaimSectionFragment_variant$data = {
  readonly name: string;
  readonly categorization: {
    readonly game: {
      readonly name: string;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SaleUnavailableError_variant" | "CannotPurchaseError_variant">;
  readonly " $fragmentType": "ClaimSectionFragment_variant";
};
export type ClaimSectionFragment_variant$key = {
  readonly " $data"?: ClaimSectionFragment_variant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClaimSectionFragment_variant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClaimSectionFragment_variant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleUnavailableError_variant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CannotPurchaseError_variant"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Categorization",
      "kind": "LinkedField",
      "name": "categorization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Game",
          "kind": "LinkedField",
          "name": "game",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Variant",
  "abstractKey": null
};
})();

(node as any).hash = "874b7b7101cee4614505fa249c6f1980";

export default node;
