export enum TransactionErrorCode {
  // GENERIC
  internalError = "1.1",
  invalidArgs = "1.2",
  notFound = "1.3",
  getterNotImplemented = "1.4",
  blockchainError = "1.5",

  // ARCHETYPES
  noVariants = "2.1",
  severalPrimaryVariants = "2.2",
  existingSecStoreID = "2.3",
  existingVariant = "2.4",

  // PLAYER
  noWalletAssociated = "3.1",
  invalidSignature = "3.2",
  twoFADisabled = "3.3",
  twoFAUnused = "3.4",
  invalidCountry = "3.5",
  wrongSettingVersion = "3.6",
  walletAlreadyAssociated = "3.7",
  playerHasAWallet = "3.8",
  shouldWaitForDissociate = "3.9",

  // SALE
  saleNotStarted = "4.1",
  saleHasEnded = "4.2",
  tokenInfoNotFound = "4.3",
  cannotBuyAFreedrop = "4.4",
  tokenNotAvailable = "4.5",
  notFreedrop = "4.6",
  alreadyClaimed = "4.7",
  noTokenAvailable = "4.8",
  maxOwnedTokenReached = "4.9",
  saleRequirementsError = "4.10",

  // HARBOUR
  harbourCode = "5",

  // WHITELIST
  whitelistCode = "6",

  // PRIVILEGES
  invalidPrivileges = "7.1",
  ticketExpired = "7.2",

  // EMAILS
  sendingError = "8.1",
}

export const extractErrorCode = (error: Error) => {
  const quartzError = error as unknown as { source: { errors: Array<{ extensions: { code: string } }> } }

  if (quartzError?.source?.errors?.length === 0 || !quartzError?.source?.errors[0]?.extensions?.code)
    return error.message

  return quartzError.source.errors[0].extensions.code
}
