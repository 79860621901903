import { useMemo } from "react"

import config from "../../config/ubisoftServicesConfig"
import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import useGETQuery from "./useGETQuery"

interface PlayerStatResponse {
  stats: {
    [key: string]: {
      endDate: string | null
      obj: any
      startDate: string
      value: string
      lastModified?: string
    }
  }
}

const usePlayerStats = (profileID: string, spaceID: string, statNames: string[]) => {
  const { user } = useUbisoftWebAuthSessionContext()

  const headers = useMemo(
    () => ({
      Authorization: "ubi_v1 t=" + user.ticket,
      "Ubi-AppId": config.applicationId,
      "Ubi-SessionId": user.sessionID,
    }),
    [user]
  )

  return useGETQuery<PlayerStatResponse>(
    `${config.ubiServicesURL}/v1/profiles/${profileID}/stats?spaceId=${spaceID}&statNames=${statNames}`,
    headers
  )
}

export default usePlayerStats
