import axios from "axios"

import config from "../config/ubisoftServicesConfig"
import {
  UbiServicesGetUserResponse,
  UbiServicesGet2FAStateResponse,
  UbiServicesGetProfileAvatarResponse,
  UbiServicesGetIpLocationResponse,
  UbiServicesGetEntitiesResponse,
  UbiServicesPlaytimeResponse,
  UbiServicesProfilesReponse,
} from "./types"

const setHeaders = (token: string, sessionId: string) => {
  return {
    Authorization: "ubi_v1 t=" + token,
    "Ubi-AppId": config.applicationId,
    "Ubi-SessionId": sessionId,
  }
}

export const getAvatar = (token: string, sessionId: string, profileId: string) => {
  const cancelTokenSource = axios.CancelToken.source()
  const url = `${config.ubiServicesURL}/v1/profiles/${profileId}/avatars`
  const headers = setHeaders(token, sessionId)

  return {
    avatar: axios
      .get<UbiServicesGetProfileAvatarResponse>(url, { headers, cancelToken: cancelTokenSource.token })
      .then((res) => {
        return res.data.imageVariations[0].url
      })
      .catch(() => ""),
    cancelAvatar: cancelTokenSource.cancel,
  }
}

export const get2FAStatus = (token: string, sessionId: string) => {
  const cancelTokenSource = axios.CancelToken.source()
  const url = `${config.ubiServicesURL}/v3/profiles/me/2fa`
  const headers = setHeaders(token, sessionId)

  return {
    status2FA: axios
      .get<UbiServicesGet2FAStateResponse>(url, { headers, cancelToken: cancelTokenSource.token })
      .then((res) => res.data.active),
    cancel2FAStatus: cancelTokenSource.cancel,
  }
}

export const getUserInfo = (token: string, sessionId: string) => {
  const cancelTokenSource = axios.CancelToken.source()
  const url = `${config.ubiServicesURL}/v3/users/me`
  const headers = setHeaders(token, sessionId)

  return {
    userInfo: axios
      .get<UbiServicesGetUserResponse>(url, { headers, cancelToken: cancelTokenSource.token })
      .then((res) => res.data),
    cancelUserInfo: cancelTokenSource.cancel,
  }
}

export const getGeolocation = () => {
  const cancelTokenSource = axios.CancelToken.source()
  const url = `${config.ubiServicesURL}/v2/profiles/me/iplocation`
  const headers = {
    "Ubi-AppId": config.applicationId,
  }

  return {
    geolocationInfo: axios
      .get<UbiServicesGetIpLocationResponse>(url, { headers, cancelToken: cancelTokenSource.token })
      .then((res) => res.data),
    cancelGeolocationInfo: cancelTokenSource.cancel,
  }
}

export const getEntities = (token: string, sessionId: string, profileId: string, spaceId: string, type: string) => {
  const cancelTokenSource = axios.CancelToken.source()
  const url = `${config.ubiServicesURL}/v2/profiles/${profileId}/entities?spaceId=${spaceId}&type=${type}`
  const headers = setHeaders(token, sessionId)

  return {
    entities: axios
      .get<UbiServicesGetEntitiesResponse>(url, { headers, cancelToken: cancelTokenSource.token })
      .then((res) => res.data),
    cancelEntities: cancelTokenSource.cancel,
  }
}

export const getStats = (token: string, sessionId: string, profileId: string, spaceId: string, stats: string[]) => {
  const cancelTokenSource = axios.CancelToken.source()
  const url = `${config.ubiServicesURL}/v1/profiles/${profileId}/stats?spaceId=${spaceId}&statNames=${stats}`
  const headers = setHeaders(token, sessionId)

  return {
    stats: axios
      .get<UbiServicesPlaytimeResponse>(url, { headers, cancelToken: cancelTokenSource.token })
      .then((res) => res.data),
    cancelStats: cancelTokenSource.cancel,
  }
}

export const getProfiles = (token: string, sessionId: string, ids: string[]) => {
  const cancelTokenSource = axios.CancelToken.source()
  const url = `${config.ubiServicesURL}/v3/profiles?profileIds=${ids}`
  const headers = setHeaders(token, sessionId)

  return {
    profiles: axios
      .get<UbiServicesProfilesReponse>(url, { headers, cancelToken: cancelTokenSource.token })
      .then((res) => res.data),
    cancelProfiles: cancelTokenSource.cancel,
  }
}
