/**
 * @generated SignedSource<<e9e07ee7521c890ab687ec319f75c60f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useClaimStatus_player$data = {
  readonly inventory: ReadonlyArray<{
    readonly archetype: {
      readonly id: string;
    };
  }>;
  readonly reserved: ReadonlyArray<{
    readonly archetype: {
      readonly id: string;
    };
  }>;
  readonly claims: ReadonlyArray<{
    readonly sale: {
      readonly id: string;
    };
  }>;
  readonly " $fragmentType": "useClaimStatus_player";
};
export type useClaimStatus_player$key = {
  readonly " $data"?: useClaimStatus_player$data;
  readonly " $fragmentSpreads": FragmentRefs<"useClaimStatus_player">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Archetype",
    "kind": "LinkedField",
    "name": "archetype",
    "plural": false,
    "selections": (v0/*: any*/),
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useClaimStatus_player",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Token",
      "kind": "LinkedField",
      "name": "inventory",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Token",
      "kind": "LinkedField",
      "name": "reserved",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Claim",
      "kind": "LinkedField",
      "name": "claims",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Sale",
          "kind": "LinkedField",
          "name": "sale",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Player",
  "abstractKey": null
};
})();

(node as any).hash = "ba65054b779c442c5ba7e092636cc0d8";

export default node;
