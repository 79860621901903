import { useIntl } from "react-intl"

import Tag from "../Tag"

export type TagLabel = "last copies" | "sold out"

export interface Props {
  label: TagLabel
  className?: string
}

const SaleTag = ({ label, className = "" }: Props) => {
  const intl = useIntl()
  const labelMap: Record<string, { label: string; color: "warning" | "danger" }> = {
    "last copies": {
      label: intl.formatMessage({
        defaultMessage: "Hot!",
        id: "zW8oo5",
        description: "[Product Card] Tag label: last copies of a digit",
      }),
      color: "warning",
    },
    "sold out": {
      label: intl.formatMessage({
        defaultMessage: "Sold Out",
        id: "tduQui",
        description: "[Product Card] Tag label: sold out digit",
      }),
      color: "danger",
    },
  }

  return (
    <Tag className={`${className}`} color={labelMap[label].color}>
      {labelMap[label].label}
    </Tag>
  )
}

export default SaleTag
