import { readInlineData } from "relay-runtime"
import graphql from "babel-plugin-relay/macro"

import {
  playerStatsMeetCondition_saleCondition$key,
  SaleConditionOperator,
} from "./__generated__/playerStatsMeetCondition_saleCondition.graphql"

type Argument = {
  int?: number
  float?: number
  bool?: boolean
  key?: string
  operator?: SaleConditionOperator
  args?: ReadonlyArray<Argument>
}

type LogicOperator = Extract<SaleConditionOperator, "AND" | "OR">
type ComparisonOperator = Extract<SaleConditionOperator, "EQ" | "GT" | "GTE" | "LT" | "LTE" | "NEQ">

/*
 * Compare stats against the condition tree and returns weither or not the stats meet the conditions
 * @param stats - key => value map of the player's stats
 * @param conditionRef - A relay fragment ref of a condition tree
 * @returns true if the stats meet all the conditions given in parameter, returns false otherwise
 */
const playerStatsMeetCondition = (
  stats: Record<string, number>,
  conditionRef: playerStatsMeetCondition_saleCondition$key
) => {
  const condition = readInlineData(
    graphql`
      fragment playerStatsMeetCondition_saleCondition on SaleCondition @inline @relay(mask: false) {
        expr {
          operator
          args {
            ... on SaleConditionStat {
              key
            }
            ... on IntVal {
              int
            }
            ... on FloatVal {
              float
            }
            ... on BoolVal {
              bool
            }
            ... on SaleConditionExpr {
              operator
              args {
                ... on SaleConditionStat {
                  key
                }
                ... on IntVal {
                  int
                }
                ... on FloatVal {
                  float
                }
                ... on BoolVal {
                  bool
                }
                ... on SaleConditionExpr {
                  operator
                  args {
                    ... on SaleConditionStat {
                      key
                    }
                    ... on IntVal {
                      int
                    }
                    ... on FloatVal {
                      float
                    }
                    ... on BoolVal {
                      bool
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    conditionRef
  )

  const resolveAddition = (args: readonly Argument[]): number => {
    return args.reduce((sum, arg) => sum + (resolveValue(arg) as number), 0)
  }

  const resolveLogic = (operator: LogicOperator, args: readonly Argument[]): boolean => {
    return args.reduce((result, arg) => {
      if (operator === "OR") return result || (resolveValue(arg) as boolean)
      return result && (resolveValue(arg) as boolean)
    }, operator === "AND")
  }

  const resolveComparison = (operator: ComparisonOperator, args: readonly Argument[]): boolean => {
    const leftOperand = resolveValue(args[0]) as number
    const rightOperand = resolveValue(args[1]) as number
    if (!args[0] || !args[1] || isNaN(leftOperand) || isNaN(rightOperand))
      throw Error(`Invalid or missing operand: ${leftOperand} ${rightOperand}`)
    if (operator === "EQ") return leftOperand === rightOperand
    if (operator === "GT") return leftOperand > rightOperand
    if (operator === "GTE") return leftOperand >= rightOperand
    if (operator === "LT") return leftOperand < rightOperand
    if (operator === "LTE") return leftOperand <= rightOperand
    if (operator === "NEQ") return leftOperand !== rightOperand
    return false
  }

  const resolveExpression = (operator: SaleConditionOperator, args: readonly Argument[]) => {
    if (operator === "AND" || operator === "OR") return resolveLogic(operator, args)
    if (
      operator === "EQ" ||
      operator === "GT" ||
      operator === "GTE" ||
      operator === "LT" ||
      operator === "LTE" ||
      operator === "NEQ"
    )
      return resolveComparison(operator, args)
    return resolveAddition(args)
  }

  const resolveValue = (value: Argument) => {
    if (value.operator && value.args) return resolveExpression(value.operator, value.args)
    if (value.int !== undefined) return value.int
    if (value.float !== undefined) return value.float
    if (value.bool !== undefined) return value.bool
    if (value.key) return stats[value.key] || 0
  }

  return !condition.expr || resolveExpression(condition.expr.operator, condition.expr.args)
}

export default playerStatsMeetCondition
