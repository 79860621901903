import { TezosToolkit } from "@taquito/taquito"
import { TempleWallet } from "@temple-wallet/dapp"

import blockchainConfig from "../../config/blockchainConfig"

const Tezos = new TezosToolkit(blockchainConfig.rpcNode)

export const connect = async () => {
  const available = await TempleWallet.isAvailable()

  if (!available) {
    throw new Error("Temple Wallet not installed")
  }

  const wallet = new TempleWallet("QuartzDApp")
  await wallet.connect(blockchainConfig.temple.network)
  Tezos.setWalletProvider(wallet)

  return wallet
}
