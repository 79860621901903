/**
 * @generated SignedSource<<f4993144a8758c72e5af97ba8ca368db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GameDashboardPlayerQuery$variables = {};
export type GameDashboardPlayerQuery$data = {
  readonly currentPlayer: {
    readonly " $fragmentSpreads": FragmentRefs<"useClaimStatus_player" | "ProductCardFragment_player">;
  };
};
export type GameDashboardPlayerQuery = {
  variables: GameDashboardPlayerQuery$variables;
  response: GameDashboardPlayerQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Archetype",
    "kind": "LinkedField",
    "name": "archetype",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GameDashboardPlayerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useClaimStatus_player"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProductCardFragment_player"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GameDashboardPlayerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Token",
            "kind": "LinkedField",
            "name": "inventory",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Token",
            "kind": "LinkedField",
            "name": "reserved",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Claim",
            "kind": "LinkedField",
            "name": "claims",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Sale",
                "kind": "LinkedField",
                "name": "sale",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b85ac06d0c23f8ad2fd67b331debfbb0",
    "id": null,
    "metadata": {},
    "name": "GameDashboardPlayerQuery",
    "operationKind": "query",
    "text": "query GameDashboardPlayerQuery {\n  currentPlayer {\n    ...useClaimStatus_player\n    ...ProductCardFragment_player\n    id\n  }\n}\n\nfragment ProductCardFragment_player on Player {\n  inventory {\n    archetype {\n      id\n    }\n    id\n  }\n  reserved {\n    archetype {\n      id\n    }\n    id\n  }\n  claims {\n    sale {\n      id\n    }\n    id\n  }\n}\n\nfragment useClaimStatus_player on Player {\n  inventory {\n    archetype {\n      id\n    }\n    id\n  }\n  reserved {\n    archetype {\n      id\n    }\n    id\n  }\n  claims {\n    sale {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "89df66575e3fd8ef3726db70701d155a";

export default node;
