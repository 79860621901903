import { FormattedMessage } from "react-intl"
import graphql from "babel-plugin-relay/macro"
import { useQuery } from "relay-hooks"
import { Link } from "react-router-dom"

import { IntroSectionQuery } from "./__generated__/IntroSectionQuery.graphql"

import { ReactComponent as ArrowRightIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"
import { ReactComponent as GrbLogo } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/logo-grb.svg"
import twoRabbids from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/rabbidsWithFakeMini.png"
import nomad from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/HomePage-Nomad@2x.png"

import style from "./style.module.css"
import sharedStyle from "../../style.module.css"

const introSectionQuery = graphql`
  query IntroSectionQuery {
    allGames {
      id
    }
  }
`

const IntroSection = () => {
  const { data, isLoading } = useQuery<IntroSectionQuery>(introSectionQuery, {})

  return (
    <section className={`${sharedStyle.section} ${style.background}`} id={style.section1}>
      <div className={`${style.topContainer} ${sharedStyle.maxLayoutWidth}`}>
        <div>
          <h1 className={style.title}>
            <FormattedMessage
              id="+Ql8X0"
              description="[Home] Title"
              defaultMessage="Collect, Play{linebreak}<textGradient>& Control</textGradient>"
              values={{
                linebreak: <br />,
                textGradient: (text: string) => <span className={sharedStyle.highlight}>{text}</span>,
              }}
            />
          </h1>
          <div className={sharedStyle.subtitle}>
            <FormattedMessage
              id="nTge8i"
              description="[Home] Subtitle"
              defaultMessage="Ubisoft Quartz is a new experience for our players, built upon our vision of creating an ever-greater
          connection between you and the game worlds you love."
            />
          </div>
        </div>
        <img src={twoRabbids} alt="" className={style.rabbids} />
      </div>
      {!data || isLoading ? (
        <div className={`${style.slider} ${style.sliderLoading} ${sharedStyle.maxLayoutWidth}`}>
          <div>
            <div className={`${style.game} ${style.gameLoading}`} />
            <div className={`${style.game} ${style.gameEmptyRight} ${style.gameLoading} ${style.desktopOnly}`} />
          </div>
        </div>
      ) : (
        <div className={`${style.slider} ${sharedStyle.maxLayoutWidth}`}>
          <Link to={`/${data.allGames[0].id}`} className={style.link}>
            <div className={`${style.game} ${style.grb}`}>
              <img src={nomad} alt="" className={style.nomad} />
              <div>
                <div>
                  <GrbLogo />
                  <div className={style.explore}>
                    <ArrowRightIcon />
                    <FormattedMessage
                      tagName="span"
                      id="KVnuhA"
                      description="[Home] Explore game button"
                      defaultMessage="Explore Now"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <div className={`${style.game} ${style.gameEmptyRight} ${style.desktopOnly}`} />
        </div>
      )}
    </section>
  )
}

export default IntroSection
