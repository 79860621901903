/**
 * @generated SignedSource<<993a94d412fc320907e3d6cc143ef41c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PriceSectionFragment_sale$data = {
  readonly price: number;
  readonly " $fragmentSpreads": FragmentRefs<"ClaimStatusSectionFragment_sale">;
  readonly " $fragmentType": "PriceSectionFragment_sale";
};
export type PriceSectionFragment_sale$key = {
  readonly " $data"?: PriceSectionFragment_sale$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriceSectionFragment_sale">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriceSectionFragment_sale",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClaimStatusSectionFragment_sale"
    }
  ],
  "type": "Sale",
  "abstractKey": null
};

(node as any).hash = "11649ee2a6ef13689e389ccb385dcf45";

export default node;
