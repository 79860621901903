import { useEffect, useState } from "react"
import { IntlProvider } from "react-intl"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { RelayEnvironmentProvider } from "react-relay"
import { ThemeProvider, Theme, StyledEngineProvider, createTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import BottomNav from "./components/BottomNav"
import UbisoftWebAuthSessionProvider, {
  useUbisoftWebAuthSessionContext,
} from "./ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import UserInfoProvider from "./providers/UserInfoProvider"
import MyToken from "./views/MyToken"
import User from "./views/User"
import Faq from "./views/Faq"
import NotFound from "./views/NotFound"
import Loading from "./views/Loading"
import Web3Provider from "./web3/Web3Provider"
import useRelayEnvironment from "./hooks/useRelayEnvironment"
import UserSetupStateProvider from "./providers/UserSetupStateProvider/UserSetupStateProvider"
import SetupSuccessDrawer from "./components/SetupSuccessDrawer"
import GeolocationInfoProvider from "./providers/GeolocationInfoProvider"
import Sale from "./views/Sale"
import TokenPurchase from "./views/TokenPurchase"
import DashboardPayingDrop from "./views/DashboardPayingDrop"
import Home from "./views/Home"
import isFeatureEnabled from "./utils/isFeatureEnabled"
import TrackingContextProvider from "./components/TrackingContextProvider"
import UbiPlayerStatsProvider from "./providers/UbiPlayerStatsProvider"
import GameDashboard from "./views/GameDashboard"
import BottomNavDisplayProvider from "./components/BottomNav/BottomNavDisplayProvider"

import en from "./lang-dist/en.json"

import style from "./style.module.css"

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

type Locale = "en" | "fr" | "de" | "es" | "it" | "pt"

interface ContentProps {
  locale: Locale
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 375,
      sm: 552,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
})

const Content = ({ locale }: ContentProps) => {
  const session = useUbisoftWebAuthSessionContext()
  const publicRelayEnv = useRelayEnvironment(session.user)
  const [messages, setMessages] = useState(en)
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { pathname } = useLocation()
  const [messageLoading, setMessageLoading] = useState(true)

  useEffect(() => {
    setMessageLoading(true)
    import(`../src/lang-dist/${locale}.json`)
      .then((messages) => setMessages(messages))
      .finally(() => setMessageLoading(false))
  }, [locale])

  // Scroll to top on every pathname change
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <IntlProvider messages={messages} locale={locale} defaultLocale="en">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <div className={style.container}>
              {session.isLoading || messageLoading ? (
                <Loading />
              ) : (
                <RelayEnvironmentProvider environment={publicRelayEnv}>
                  <GeolocationInfoProvider>
                    <UserInfoProvider>
                      <UbiPlayerStatsProvider>
                        <TrackingContextProvider>
                          <Web3Provider>
                            <BottomNavDisplayProvider>
                              <Routes>
                                {/* Pages without bottom nav */}
                                {/* Kept in case we need it back */}
                                {/* <Route exact path="/terms-of-use">
                                <TermsOfUse />
                              </Route> */}

                                {/* Pages with bottom nav */}
                                <Route
                                  path="/*"
                                  element={
                                    <>
                                      <Routes>
                                        <Route path="user/*" element={<User />} />
                                        <Route path="faq/*" element={<Faq />} />
                                        {isFeatureEnabled("sales") && (
                                          <Route path="sales" element={<DashboardPayingDrop />} />
                                        )}
                                        <Route path="welcome" element={<Navigate to="/" replace />} />
                                        <Route path="dashboard" element={<Navigate to="/" replace />} />
                                        <Route path={":gameID"} element={<GameDashboard />} />
                                        <Route path={`:gameID/sales/:saleID`} element={<Sale />} />
                                        <Route path={`:gameID/sales/:saleID/:tokenID`} element={<TokenPurchase />} />
                                        <Route path={`token/:tokenID`} element={<MyToken />} />
                                        <Route path="/" element={<Home />} />
                                        <Route path="*" element={<NotFound />} />
                                      </Routes>
                                      {isMobile && (
                                        <BottomNav className={style.bottomFixed} loggedIn={!!session.user.ticket} />
                                      )}
                                    </>
                                  }
                                />
                              </Routes>
                              <SetupSuccessDrawer />
                            </BottomNavDisplayProvider>
                          </Web3Provider>
                        </TrackingContextProvider>
                      </UbiPlayerStatsProvider>
                    </UserInfoProvider>
                  </GeolocationInfoProvider>
                </RelayEnvironmentProvider>
              )}
            </div>
          </ThemeProvider>
        </StyledEngineProvider>
      </IntlProvider>
    </>
  )
}

interface Props {
  locale?: Locale
}

function App({ locale = "en" }: Props) {
  return (
    <>
      <UserSetupStateProvider>
        <UbisoftWebAuthSessionProvider>
          <Content locale={locale} />
        </UbisoftWebAuthSessionProvider>
      </UserSetupStateProvider>
    </>
  )
}

export default App
