/**
 * @generated SignedSource<<02538873700e01437f99ec7e3bc5408c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleFreeDropCurrentPlayerQuery$variables = {};
export type SaleFreeDropCurrentPlayerQuery$data = {
  readonly currentPlayer: {
    readonly " $fragmentSpreads": FragmentRefs<"useClaimStatus_player" | "ProductFragment_player">;
  };
};
export type SaleFreeDropCurrentPlayerQuery = {
  variables: SaleFreeDropCurrentPlayerQuery$variables;
  response: SaleFreeDropCurrentPlayerQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Archetype",
  "kind": "LinkedField",
  "name": "archetype",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SaleFreeDropCurrentPlayerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useClaimStatus_player"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProductFragment_player"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SaleFreeDropCurrentPlayerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Token",
            "kind": "LinkedField",
            "name": "inventory",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serialNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Token",
            "kind": "LinkedField",
            "name": "reserved",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Claim",
            "kind": "LinkedField",
            "name": "claims",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Sale",
                "kind": "LinkedField",
                "name": "sale",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d5564b351d911a2dce75965afe5f43e",
    "id": null,
    "metadata": {},
    "name": "SaleFreeDropCurrentPlayerQuery",
    "operationKind": "query",
    "text": "query SaleFreeDropCurrentPlayerQuery {\n  currentPlayer {\n    ...useClaimStatus_player\n    ...ProductFragment_player\n    id\n  }\n}\n\nfragment ProductCardFragment_player on Player {\n  inventory {\n    archetype {\n      id\n    }\n    id\n  }\n  reserved {\n    archetype {\n      id\n    }\n    id\n  }\n  claims {\n    sale {\n      id\n    }\n    id\n  }\n}\n\nfragment ProductFragment_player on Player {\n  inventory {\n    id\n    serialNumber\n    archetype {\n      id\n    }\n  }\n  claims {\n    sale {\n      id\n    }\n    id\n  }\n  reserved {\n    archetype {\n      id\n    }\n    id\n  }\n  ...useClaimStatus_player\n  ...ProductCardFragment_player\n}\n\nfragment useClaimStatus_player on Player {\n  inventory {\n    archetype {\n      id\n    }\n    id\n  }\n  reserved {\n    archetype {\n      id\n    }\n    id\n  }\n  claims {\n    sale {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6571f43b872d3440692463e7b3514adc";

export default node;
