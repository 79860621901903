import Portal from "@mui/material/Portal"
import { useMemo, useRef } from "react"
import { FormattedMessage } from "react-intl"

import SnackBar from "../SnackBar"
import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import { useUserInfoContext } from "../../providers/UserInfoProvider"
import { useGeolocationInfoContext } from "../../providers/GeolocationInfoProvider"
import isUserEligible from "../../utils/isUserEligible"

import { ReactComponent as ArrowRightIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"

import style from "./style.module.css"

const EligibilitySnackBar = () => {
  const { userInfo, isLoading: userInfoLoading } = useUserInfoContext()
  const { isCurrentIpLocationAllowed, isLoading: geolocationIsLoading } = useGeolocationInfoContext()
  const { user, twoFaActive } = useUbisoftWebAuthSessionContext()
  const container = useRef(null)
  const isEligible = useMemo(
    () => isUserEligible(userInfo, isCurrentIpLocationAllowed) && twoFaActive,
    [userInfo, isCurrentIpLocationAllowed, twoFaActive]
  )

  const open = useMemo(
    () => !!user.ticket && !userInfoLoading && !geolocationIsLoading && !isEligible,
    [user, userInfoLoading, geolocationIsLoading, isEligible]
  )

  return (
    <>
      {open && <div className={style.spacer}></div>}
      <Portal container={container.current}>
        <SnackBar
          open={open}
          message={
            <FormattedMessage
              tagName="span"
              defaultMessage="We’re sorry, for some reason you aren’t eligible to acquire Digits on Ubisoft Quartz."
              id="zawOQd"
              description="[SnackBar] eligibility message"
              values={{
                b: (chunks: string) => <strong>{chunks}</strong>,
              }}
            />
          }
          icon={<ArrowRightIcon />}
          withBottomNav
          to="/user/not-eligible/purchase"
        />
      </Portal>
    </>
  )
}

export default EligibilitySnackBar
