import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"

import { ReactComponent as ChevronLeft } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/chevronLeft.svg"

import style from "./style.module.css"

export interface Props {
  title: ReactNode
  level?: "primary" | "secondary"
  backgroundImage?: string
  action?: ReactNode
  hideBackButton?: boolean
  className?: string
}

const PageHeader = ({
  title,
  level = "secondary",
  backgroundImage,
  action,
  hideBackButton = false,
  className = "",
}: Props) => {
  const navigate = useNavigate()

  const handleBackClick = () => navigate(-1)

  return (
    <header
      className={`${style.container} ${className}`}
      style={backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {}}
    >
      {level === "primary" ? (
        <div className={`${style.content} ${style.contentPrimaryLevel}`}>
          <h3>{title}</h3>
          <div>{action}</div>
        </div>
      ) : (
        <div className={style.content}>
          <div className={style.centered}>
            <h4>{title}</h4>
          </div>
          <div>
            {!hideBackButton && (
              <button role="link" aria-label="back" className={style.back} onClick={handleBackClick}>
                <ChevronLeft />
              </button>
            )}
          </div>
          <div>{action}</div>
        </div>
      )}
    </header>
  )
}

export default PageHeader
