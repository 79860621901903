import { useCallback, useEffect, useMemo } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import graphql from "babel-plugin-relay/macro"
import { useFragment } from "react-relay"
import { useRefetchable } from "relay-hooks"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import { TokenClaimFragment_sale$key } from "./__generated__/TokenClaimFragment_sale.graphql"
import { TokenClaimFragment_variant$key } from "./__generated__/TokenClaimFragment_variant.graphql"

import PageHeader from "../../../../components/PageHeader"
import PageLayout from "../../../../components/PageLayout"
import ProductCardFragment from "../../../../components/Product/ProductCard/ProductCardFragment"
import ClaimSection from "./ClaimSection"
import useClaimDigit from "../../hooks/useClaimDigit"
import TransactionPendingPage from "../../../../components/TokenPurchase/TransactionPendingPage"
import TransactionErrorPage from "../../../../components/TokenPurchase/TransactionErrorPage"
import SuccessPage from "../../../../components/TokenPurchase/SuccessPage"
import { useUbisoftWebAuthSessionContext } from "../../../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import { useUserInfoContext } from "../../../../providers/UserInfoProvider"
import { useTezosContext } from "../../../../web3/Web3Provider"
import walletProviders from "../../../../web3/walletProviders"
import { trackClick } from "../../../../utils/analytics"
import { extractErrorCode } from "../../../../api/errors"
import { useShowBottomNav } from "../../../../components/BottomNav/BottomNavDisplayProvider"

import style from "./style.module.css"

interface Props {
  sale: TokenClaimFragment_sale$key
  variant: TokenClaimFragment_variant$key
}

const TokenClaim = ({ sale, variant }: Props) => {
  const { data: saleData, refetch } = useRefetchable(
    graphql`
      fragment TokenClaimFragment_sale on Sale @refetchable(queryName: "TokenClaimRefetchQuery") {
        id
        endDate
        ...ClaimSectionFragment_sale
        ...ProductCardFragment_sale
        ...useTrackingCustomParameter_sale
      }
    `,
    sale
  )

  const variantData = useFragment(
    graphql`
      fragment TokenClaimFragment_variant on Variant {
        ...ClaimSectionFragment_variant
        name
        categorization {
          game {
            id
            name
          }
        }
      }
    `,
    variant
  )

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const intl = useIntl()
  const { gameID } = useParams() as { gameID: string; saleID: string }
  const { claimDigit, pending, success, error: claimError, reset } = useClaimDigit(saleData.id)
  const { checkTwoFAStatus } = useUbisoftWebAuthSessionContext()
  const { userInfo } = useUserInfoContext()
  const { providerName } = useTezosContext()
  const walletInfo = useMemo(
    () =>
      providerName && userInfo.walletAddress
        ? { provider: providerName, address: userInfo.walletAddress, logo: walletProviders[providerName].logo }
        : null,
    [providerName, userInfo.walletAddress]
  )

  useEffect(() => {
    checkTwoFAStatus()
  }, [checkTwoFAStatus])

  useShowBottomNav(false)

  const handleClaimDigit = useCallback(async () => {
    trackClick({
      location: "ubisoft quartz",
      locationDetail: "product page",
      category: "action",
      action: `claim my digit : ${variantData.name}`,
    })
    try {
      await claimDigit()
    } catch (e) {}
  }, [claimDigit, variantData])

  const handleRefetch = useCallback(() => refetch({}), [refetch])

  const handleRetryTransaction = () => {
    reset()
    handleRefetch()
  }

  if (pending) {
    return (
      <TransactionPendingPage
        message={intl.formatMessage({
          defaultMessage: "Transaction pending...",
          id: "nq0TtU",
          description: "Pending transaction loading message",
        })}
      />
    )
  }

  if (claimError) {
    return (
      <TransactionErrorPage
        code={extractErrorCode(claimError)}
        walletInfo={walletInfo}
        onBackClick={handleRetryTransaction}
      />
    )
  }

  if (success) {
    return (
      <SuccessPage
        to="/user/wallet"
        itemName={variantData.name}
        type={!saleData.endDate ? "claimOnDemand" : "claimOnEndDate"}
        sale={saleData}
        token={null}
      />
    )
  }

  return (
    <>
      <PageLayout
        header={
          <>
            {isMobile && <PageHeader title={variantData.name} />}
            <ProductCardFragment
              gameID={gameID}
              sale={saleData}
              token={null}
              player={null}
              className={style.productCard}
            />
          </>
        }
        bodyPosition="right"
      >
        <ClaimSection sale={saleData} variant={variantData} onClaimClick={handleClaimDigit} />
      </PageLayout>
    </>
  )
}

export default TokenClaim
