import { FormattedMessage } from "react-intl"
import { To } from "react-router-dom"
import ReactCanvasConfetti from "react-canvas-confetti"
import { CreateTypes, Options } from "canvas-confetti"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import { useTrackingCustomParameter_token$key } from "../../../hooks/__generated__/useTrackingCustomParameter_token.graphql"
import { useTrackingCustomParameter_sale$key } from "../../../hooks/__generated__/useTrackingCustomParameter_sale.graphql"

import Button from "../../../components/Button"
import TopNav from "../../TopNav"
import TrackPage from "../../TrackPage"
import Logo from "../../Logo"
import useTrackingCustomParameter from "../../../hooks/useTrackingCustomParameter"
import { trackClick } from "../../../utils/analytics"

import { ReactComponent as ArrowRight } from "../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"
import StickerUbi from "../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/sticker.png"

import style from "./style.module.css"

export interface Props {
  to?: To
  itemName: string
  type?: "claimOnEndDate" | "claimOnDemand" | "purchase"
  sale: useTrackingCustomParameter_sale$key
  token: useTrackingCustomParameter_token$key | null
}

const makeShot = (instance: CreateTypes | null, particleRatio: number, opts: Options) => {
  instance &&
    instance({
      ...opts,
      origin: { y: 0.4 },
      particleCount: Math.floor(200 * particleRatio),
      colors: ["#5856de", "#5856de", "#af52de", "#af52de", "#af52de", "#ffebfd"],
    })
}

const getInstance = (instance: CreateTypes | null) => {
  makeShot(instance, 0.25, {
    spread: 26,
    startVelocity: 55,
  })

  makeShot(instance, 0.2, {
    spread: 60,
  })

  makeShot(instance, 0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  })

  makeShot(instance, 0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  })

  makeShot(instance, 0.1, {
    spread: 120,
    startVelocity: 45,
  })
}

const SuccessPage = ({ to, itemName, type, sale, token }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const customParameter = useTrackingCustomParameter(sale, token)

  const handleClick = () => {
    trackClick({
      location: "ubisoft quartz",
      locationDetail: "checkout",
      category: "action",
      action: type === "purchase" ? "go to my digit" : "go to wallet",
    })
  }

  return (
    <>
      <TrackPage siteSection="checkout" pageName="congratulations" customParameter={customParameter} />
      {!isMobile && <TopNav />}
      <div className={style.container}>
        {isMobile && <Logo className={style.logo} />}
        <ReactCanvasConfetti
          refConfetti={getInstance}
          style={{
            zIndex: 0,
            position: "fixed",
            pointerEvents: "none",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
          }}
        />
        <div className={style.centerContent}>
          <img src={StickerUbi} alt="" className={style.sticker} aria-hidden />
          <h3>
            <FormattedMessage
              defaultMessage="Congratulations!"
              id="Ry9/Uo"
              description="[Purchase/Claim Success] Congratulations label"
            />
          </h3>
          <div className={style.inFront}>
            {type === "purchase" ? (
              <>
                <p className="paragraph">
                  <FormattedMessage
                    defaultMessage="You successfully purchased{br} <b>{item}</b>!"
                    id="PkqFfd"
                    description="[Purchase Success] Success message"
                    values={{
                      item: itemName,
                      b: (chunks: string) => <span className={style.itemName}>{chunks}</span>,
                      br: <br />,
                    }}
                  />
                </p>
                <p className="paragraph">
                  <FormattedMessage
                    defaultMessage="This Digit will be available in-game in a few minutes."
                    id="tzhiHB"
                    description="[Purchase Success] Success message details"
                  />
                </p>
              </>
            ) : (
              <>
                <p className="paragraph">
                  <FormattedMessage
                    defaultMessage="You successfully claimed your Digit{br} <b>{item}</b>!"
                    id="8S8DNT"
                    description="[Claim Success] Claim success message"
                    values={{
                      item: itemName,
                      b: (chunks: string) => <span className={style.itemName}>{chunks}</span>,
                      br: <br />,
                    }}
                  />
                </p>
                {type === "claimOnDemand" ? (
                  <p className="paragraph">
                    <FormattedMessage
                      defaultMessage="This Digit will be available in-game in a few minutes."
                      id="DiZL6S"
                      description="[ClaimOnDemand Success] Success message details"
                    />
                  </p>
                ) : (
                  <p className="paragraph">
                    <FormattedMessage
                      defaultMessage="It will appear in your inventory when the claim period ends."
                      id="RV0+VA"
                      description="[Claim Success] Information after claim"
                    />
                  </p>
                )}
              </>
            )}
          </div>
          <Button
            invert
            icon={<ArrowRight aria-hidden />}
            fullWidth
            className={style.button}
            to={to}
            replace
            onClick={handleClick}
          >
            {type === "purchase" ? (
              <FormattedMessage
                defaultMessage="Go to my Digit"
                id="+brfcR"
                description="[Purchase Success] Go to my Digit link label"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Go to crypto-wallet"
                id="JU6JLx"
                description="[Claim Success] Go to crypto-wallet link label"
              />
            )}
          </Button>
        </div>
        {type === "purchase" && (
          <p className={style.email}>
            <FormattedMessage
              defaultMessage="An email has been sent to you with information regarding your new Digit."
              id="37Bchi"
              description="[Purchase/Claim Success] Email sent information notice"
            />
          </p>
        )}
      </div>
    </>
  )
}

export default SuccessPage
