import { Link } from "react-router-dom"
import { FormattedMessage, IntlShape } from "react-intl"

import style from "../style.module.css"

const ubisoftQuartzDigits = (intl: IntlShape) => [
  {
    id: "what-is-ubisoft-quartz",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "What is Ubisoft Quartz?",
      id: "y5Om8l",
      description: "Faq ubisoft quartz and digits question 0",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Ubisoft Quartz is a new experience available to our players, built upon our vision of creating ever-greater connection between you and the game worlds you love. The Ubisoft Quartz platform is the place where you will be able to acquire Digits, the first Ubisoft NFTs (non-fungible tokens), playable in a HD game and relying on an <link>energy-efficient technology</link>. Digits will be playable on the Ubisoft Connect PC version of Tom Clancy’s Ghost Recon® Breakpoint."
          id="9pjVaF"
          description="Faq ubisoft quartz and digits response 0 paragraph 1"
          values={{
            link: (chunks: string) => <Link to="/faq/technology/what-technology-powers-ubisoft-quartz">{chunks}</Link>,
          }}
        />
      </>
    ),
    home: true,
  },
  {
    id: "what-is-a-digit",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "What is a Digit?",
      id: "PqFOJ/",
      description: "Faq ubisoft quartz and digits question 1",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="The Digits are exclusive NFTs (non-fungible tokens) that represent a unique collectible and in-game cosmetic playable item. These can range from in-game vehicles to weapons through pieces of equipment."
          id="UwaQ+d"
          description="Faq ubisoft quartz and digits response 1 paragraph 1"
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="For this first experience, Digits are only available to Tom Clancy’s Ghost Recon® Breakpoint Ubisoft Connect PC players. They are created in limited number and each collection of Digits is called an Edition."
          id="yJjrjP"
          description="Faq ubisoft quartz and digits response 1 paragraph 2"
        />
      </>
    ),
    popular: true,
    home: true,
  },
  {
    id: "how-many-digits-will-there-be-in-a-single-edition",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "How many Digits will there be in a single Edition?",
      id: "Jj6nky",
      description: "Faq ubisoft quartz and digits question 2",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Each Edition will be different and feature a limited number of Digits. Depending on the item, it can range from a few units to a few thousands."
          id="IazTW2"
          description="Faq ubisoft quartz and digits response 2 paragraph 1"
        />
      </>
    ),
  },
  {
    id: "what-makes-digits-unique",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "What makes Digits unique?",
      id: "crjTBX",
      description: "Faq ubisoft quartz and digits question 3",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Every Digit within a single Edition is unique based on its serial number and the history of its previous owners. Be the first owner of a particular Digit or chase the one of your favorite streamer."
          id="jyIAO2"
          description="Faq ubisoft quartz and digits response 3 paragraph 1"
        />
      </>
    ),
  },
  {
    id: "what-does-it-bring-to-my-gaming-experience",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "What does it bring to my gaming experience?",
      id: "8poZ6i",
      description: "Faq ubisoft quartz and digits question 4",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="The Ubisoft Quartz platform has been conceived to transform the experience of our players around three pillars:"
          id="svKbJU"
          description="Faq ubisoft quartz and digits response 4 paragraph 1"
        />
        <FormattedMessage
          tagName="ul"
          defaultMessage="<listitem><strong>Uniqueness</strong> – Each Ubisoft Quartz Digit is unique and features its own serial number. When you acquire it, your Ubisoft player name is stored in its metadata, therefore keeping track of all its previous owners. Owning a Digit will make you an actual part of its history.</listitem><listitem><strong>Exclusive experience</strong> – Stand out while blending in and live a unique experience with beautifully crafted items from limited Editions. Each Edition is only issued once, and the total number of Digits for each new Edition is secured on the Digit’s metadata for everyone to see.</listitem><listitem><strong>New opportunities</strong> – With Ubisoft Quartz Digits, your in-game items are no longer bound to remain stuck in your inventory. Once you’re done playing with them, you can put them on sale on an <link>authorized third-party marketplace</link>. If it gets purchased by another Tom Clancy’s Ghost Recon® Breakpoint player, you would then be able to acquire a new one or use your proceeds as you see fit.</listitem>"
          id="nnETw/"
          description="Faq ubisoft quartz and digits response 4 list"
          values={{
            link: (chunks: string) => <Link to="/faq/selling-digits/can-i-sell-my-digits">{chunks}</Link>,
            listitem: (item: string) => <li className={style.listitem}>{item}</li>,
            strong: (text: string) => <strong>{text}</strong>,
          }}
        />
      </>
    ),
  },
  {
    id: "what-are-metadata-how-do-i-access-them",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "What are metadata? How do I access them?",
      id: "vCEojl",
      description: "Faq ubisoft quartz and digits question 5",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Metadata are all the pieces of information tied to your Digit: serial number, edition volume, item name, video collectible, previous owners’ Ubisoft playername. You can consult them directly from the Ubisoft Quartz platform or on the <link>authorized third-party marketplaces</link>"
          id="arBtz/"
          description="Faq ubisoft quartz and digits response 5 paragraph 1"
          values={{
            link: (chunks: string) => <Link to="/faq/selling-digits/can-i-sell-my-digits">{chunks}</Link>,
          }}
        />
      </>
    ),
  },
]

export default ubisoftQuartzDigits
