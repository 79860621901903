import { NavLink } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { Slide, useScrollTrigger } from "@mui/material"

import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import { useDisplayBottomNavContext } from "./BottomNavDisplayProvider"

import { ReactComponent as DashboardIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/dashboard.svg"
import { ReactComponent as DashboardStrokeIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/dashboardStroke.svg"
import { ReactComponent as WalletIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/wallet.svg"
import { ReactComponent as WalletStrokeIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/walletStroke.svg"
import { ReactComponent as ProfileIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/profile.svg"
import { ReactComponent as ProfileStrokeIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/profileStroke.svg"

import style from "./style.module.css"
import { useEffect } from "react"

export interface Props {
  loggedIn?: boolean
  className?: string
}

const BottomNav = ({ className = "", loggedIn = false }: Props) => {
  const { login } = useUbisoftWebAuthSessionContext()
  const trigger = useScrollTrigger({ threshold: 200 })
  const { show, setVisible } = useDisplayBottomNavContext()

  useEffect(() => {
    setVisible(show && !trigger)
  }, [setVisible, show, trigger])

  if (!show) return null

  return (
    <Slide appear={false} direction="up" in={!trigger}>
      <nav className={`${className} ${style.container}`}>
        <NavLink to="/" className={({ isActive }) => (isActive ? style.active : "")} end>
          <div className={style.iconContainer}>
            <DashboardStrokeIcon />
            <DashboardIcon className={style.activeIcon} />
          </div>
          <FormattedMessage
            tagName="span"
            defaultMessage="Home"
            id="47jic0"
            description="[Bottom/Top Nav] Label of home page link"
          />
        </NavLink>
        {loggedIn ? (
          <>
            <NavLink to="/user/wallet" className={({ isActive }) => (isActive ? style.active : "")}>
              <div className={style.iconContainer}>
                <WalletStrokeIcon />
                <WalletIcon className={style.activeIcon} />
              </div>
              <FormattedMessage
                tagName="span"
                defaultMessage="Crypto-wallet"
                id="rWLeqf"
                description="[Bottom/Top Nav] Label of wallet link"
              />
            </NavLink>
            <NavLink to="/user" end className={({ isActive }) => (isActive ? style.active : "")}>
              <div className={style.iconContainer}>
                <ProfileStrokeIcon />
                <ProfileIcon className={style.activeIcon} />
              </div>
              <FormattedMessage
                tagName="span"
                id="ymihAp"
                defaultMessage="Profile"
                description="[Bottom/Top Nav] Label of profile link"
              />
            </NavLink>
          </>
        ) : (
          <button onClick={login}>
            <div className={style.iconContainer}>
              <ProfileStrokeIcon />
            </div>
            <FormattedMessage
              tagName="span"
              id="CpndX9"
              defaultMessage="Login"
              description="[Bottom/Top Nav] Label of login link"
            />
          </button>
        )}
      </nav>
    </Slide>
  )
}

export default BottomNav
