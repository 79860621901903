import { useMemo } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

const useDashboardRedirectInfo = () => {
  const intl = useIntl()
  const { gameID } = useParams<{ gameID: string }>()
  const redirect = useMemo(
    () =>
      gameID
        ? {
            to: `/${gameID}`,
            label: intl.formatMessage({
              defaultMessage: "Go to Dashboard",
              id: "OnBFq2",
              description: "[Product Page] button label",
            }),
          }
        : {
            to: "/",
            label: intl.formatMessage({
              defaultMessage: "Go to Home Page",
              id: "axDv2Y",
              description: "[Product Page] button label",
            }),
          },
    [gameID, intl]
  )

  return redirect
}

export default useDashboardRedirectInfo
