import { createContext, ReactNode, useEffect, useMemo, useState } from "react"
import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"

const STORAGE_KEY = "loginState"

export const TrackingContext = createContext<{ sCodeReady: boolean; triggerAccountEvent: (() => void) | null }>({
  sCodeReady: false,
  triggerAccountEvent: null,
})

interface Props {
  children: ReactNode
}

const TrackingContextProvider = ({ children }: Props) => {
  const { user, isLoading } = useUbisoftWebAuthSessionContext()
  const [pageEventSent, setPageEventSent] = useState(false)
  const [sCodeReady, setSCodeReady] = useState(false)
  const value = useMemo(() => ({ sCodeReady, triggerAccountEvent: () => setPageEventSent(true) }), [sCodeReady])

  useEffect(() => {
    setSCodeReady(window.adobeAnalytics.sCodeReady)

    const eventListener = (event: MessageEvent<any>) => {
      if (event.data === "S_CODE_READY") {
        setSCodeReady(true)
      }
      if (event.data === "S_CODE_REMOVED") {
        setSCodeReady(false)
      }
    }
    window.addEventListener("message", eventListener)

    return () => window.removeEventListener("message", eventListener)
  }, [])

  useEffect(() => {
    if (!pageEventSent || isLoading || !sCodeReady) return

    const lastUserID = sessionStorage.getItem(STORAGE_KEY)

    if (lastUserID && user.ticket) {
      adobeAnalytics.track("accountevent", {
        ubisoftId: user.userID,
        loggedIn: "true",
        loggedOut: "false",
        action: "navigation - login - signed in",
      })
      sessionStorage.setItem(STORAGE_KEY, user.userID)
    } else if (!lastUserID && !user.ticket) {
      adobeAnalytics.track("accountevent", {
        ubisoftId: user.userID,
        loggedIn: "false",
        loggedOut: "true",
        action: "navigation - login - signed out",
      })
      sessionStorage.setItem(STORAGE_KEY, "")
    } else if (!lastUserID && user.ticket) {
      adobeAnalytics.track("accountevent", {
        ubisoftId: user.userID,
        loggedIn: "true",
        loggedOut: "false",
        action: "navigation - login - signing in",
      })
      sessionStorage.setItem(STORAGE_KEY, user.userID)
    } else if (lastUserID && !user.ticket) {
      adobeAnalytics.track("accountevent", {
        ubisoftId: lastUserID,
        loggedIn: "false",
        loggedOut: "true",
        action: "navigation - login - signing out",
      })
      sessionStorage.setItem(STORAGE_KEY, "")
    }
  }, [pageEventSent, user.ticket, user.userID, isLoading, user, sCodeReady])

  return <TrackingContext.Provider value={value}>{children}</TrackingContext.Provider>
}

export default TrackingContextProvider
