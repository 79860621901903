import { TezosToolkit } from "@taquito/taquito"

import blockchainConfig from "../../config/blockchainConfig"

async function getLedgerValue(publicKeyHash: string) {
  const eurl = process.env.REACT_APP_TEZOS_EURL_CONTRACT_ADDRESS
  if (!eurl) return null

  const tezosC = new TezosToolkit(blockchainConfig.rpcNode)

  const myContract = await tezosC.contract.at(eurl)
  const storage: any = await myContract.storage()
  if (storage && storage.ledger) {
    const value = await storage.ledger.get({ "0": publicKeyHash, "1": "0" }) // key:  address user * tokenid lugh
    return value
  }
  return null
}

export default getLedgerValue
