import { useFragment } from "react-relay"
import graphql from "babel-plugin-relay/macro"

import { SaleCardFragment_sale$key } from "./__generated__/SaleCardFragment_sale.graphql"

import SaleCard from "."
import { trackClick } from "../../utils/analytics"

interface Props {
  gameID: string
  sale: SaleCardFragment_sale$key
}

const SaleCardFragment = ({ gameID, sale }: Props) => {
  const data = useFragment(
    graphql`
      fragment SaleCardFragment_sale on Sale {
        id
        price
        archetype {
          id
          maxInstances
          variants {
            name
            image
            categorization {
              game {
                id
                name
              }
            }
          }
        }
      }
    `,
    sale
  )

  const variant = data.archetype.variants.find((variant) => variant.categorization?.game?.id === gameID)
  // TODO: wait for tokenCount feature to not query all tokens list which lead to performance issue
  // const availableTokens = data.tokens.edges.filter((token) => token.node?.available).length

  // TODO replace with line below when tokenCount will be available
  // const tag = !availableTokens ? "sold out" : undefined
  const tag = "sold out"

  const isFreeDrop = data.price === 0

  return (
    <SaleCard
      to={{ pathname: `/${gameID}/sales/${data.id}` }}
      state={{ free: isFreeDrop }}
      onClick={() =>
        trackClick({
          location: "ubisoft quartz",
          locationDetail: "all digits",
          category: "action",
          action: `all digits: ${variant?.name}`,
        })
      }
      thumbnail={variant?.image || ""}
      label={variant?.name || ""}
      priceDeFi={data.price}
      currencyDeFi="EURL" /* TODO currencyDeFi should not be hard coded */
      price={null} /* TODO convert crypto to fiat price */
      currency="EUR" /* TODO currency fiat should not be hard coded */
      gameTitle={variant?.categorization?.game?.name || ""}
      // TODO: replace value with `availableTokens` variable when uncommented above
      availableTokens={0}
      maxTokens={data.archetype.maxInstances}
      tag={tag}
    />
  )
}

export default SaleCardFragment
