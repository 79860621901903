/**
 * @generated SignedSource<<df12288442e42b973d7e38fdb6286340>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SaleConditionOperator = "ADD" | "AND" | "EQ" | "GT" | "GTE" | "LT" | "LTE" | "NEQ" | "OR" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type playerStatsMeetCondition_saleCondition$data = {
  readonly expr: {
    readonly operator: SaleConditionOperator;
    readonly args: ReadonlyArray<{
      readonly key?: string;
      readonly int?: number;
      readonly float?: number;
      readonly bool?: boolean;
      readonly operator?: SaleConditionOperator;
      readonly args?: ReadonlyArray<{
        readonly key?: string;
        readonly int?: number;
        readonly float?: number;
        readonly bool?: boolean;
        readonly operator?: SaleConditionOperator;
        readonly args?: ReadonlyArray<{
          readonly key?: string;
          readonly int?: number;
          readonly float?: number;
          readonly bool?: boolean;
        }>;
      }>;
    }>;
  } | null;
};
export type playerStatsMeetCondition_saleCondition$key = {
  readonly " $data"?: playerStatsMeetCondition_saleCondition$data;
  readonly " $fragmentSpreads": FragmentRefs<"playerStatsMeetCondition_saleCondition">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "playerStatsMeetCondition_saleCondition"
};

(node as any).hash = "4001704b08777c5657ae6300fb2b8ef5";

export default node;
