import React from "react"
import { FormattedMessage } from "react-intl"
import Countries from "i18n-iso-countries"
import clsx from "clsx"
import en from "i18n-iso-countries/langs/en.json"

import ListGroup, { ListGroupItem } from "../../../../components/ListGroup"
import IconButton from "../../../../components/IconButton"
import ubisoftServicesConfig from "../../../../config/ubisoftServicesConfig"

import { ReactComponent as EditIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/edit.svg"

import style from "./style.module.css"

Countries.registerLocale(en)

export interface Props {
  email: string
  preferredLanguage: string
  country: string
  isLoading: boolean
  className?: string
}

function truncateEmailAddress(address: string) {
  return address
    .replace(
      /\B.+@/g,
      (c) =>
        c
          .split("")
          .slice(0, -1)
          .map((v) => "*")
          .join("") + "@"
    )
    .replace(
      /@[\w-]+/g,
      (c) =>
        "@" +
        c
          .split("")
          .slice(1)
          .map((v, index) => (index > 0 ? "*" : v))
          .join("")
    )
}

const ProfileUserInfo = ({ email, preferredLanguage, country, isLoading, className = "" }: Props) => {
  const CardItemLoading = <div className={style.cardItemLoading} />

  return (
    <div className={`${style.container} ${className}`}>
      <div className={style.header}>
        <h4 className={style.rainbow}>
          <FormattedMessage
            defaultMessage="Ubisoft account information"
            id="CJ7TtS"
            description="[Profile] section title"
          />
        </h4>
        <IconButton
          onClick={() => {
            window.open(ubisoftServicesConfig.accountURL, "_blank")
          }}
        >
          <EditIcon />
        </IconButton>
      </div>
      <ListGroup className={style.body}>
        <ListGroupItem
          label={<FormattedMessage defaultMessage="Email address" id="LNCrED" description="[Profile] email label" />}
        >
          {isLoading ? CardItemLoading : <span className="numbers">{truncateEmailAddress(email)}</span>}
        </ListGroupItem>
        <ListGroupItem
          label={
            <FormattedMessage
              defaultMessage="Preferred language"
              id="kt3cNd"
              description="[Profile] Preferred language label"
            />
          }
        >
          {/* TODO: get formatted language */}
          {isLoading ? CardItemLoading : <span className={clsx("numbers", style.language)}>{preferredLanguage}</span>}
        </ListGroupItem>
        <ListGroupItem
          label={<FormattedMessage defaultMessage="Country/Region" id="q3Wjx1" description="[Profile] country label" />}
        >
          {isLoading ? (
            CardItemLoading
          ) : (
            <span className="numbers">{Countries.getName(country, "en", { select: "official" })}</span>
          )}
        </ListGroupItem>
      </ListGroup>
    </div>
  )
}

export default ProfileUserInfo
