import { Theme } from "@mui/material"
import { default as MuiDialog } from "@mui/material/Dialog"
import makeStyles from "@mui/styles/makeStyles"
import { ReactNode } from "react"

import IconButton from "../../components/IconButton"

import { ReactComponent as CloseIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/close.svg"

import style from "./style.module.css"

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    padding: "1.5rem",
    borderRadius: "1rem",
    boxShadow: "0 -1.5rem 1.25rem -2rem rgba(0, 0, 0, 0.5)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "1.225rem",
    position: "relative",
  },
  fixedSizeDialog: {
    maxWidth: 335,
    width: "100%",
    boxSizing: "border-box",
  },
  mobileFullscreen: {
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "0 0.25rem",
      maxHeight: "unset",
      height: "95%",
      width: "100%",
      maxWidth: "unset",
    },
  },
}))

interface Props {
  open: boolean
  onClose: (result?: unknown) => void
  children: ReactNode
  hideTopCloseButton?: boolean
  fixedSize?: boolean
  className?: string
  mobileFullscreen?: boolean
}

const Dialog = ({
  open,
  onClose,
  children,
  hideTopCloseButton = false,
  fixedSize = false,
  className = "",
  mobileFullscreen = false,
}: Props) => {
  const classes = useStyles()

  return (
    <MuiDialog
      open={open}
      classes={{
        paper: `${classes.dialog} ${fixedSize && classes.fixedSizeDialog} ${
          mobileFullscreen && classes.mobileFullscreen
        }`,
      }}
    >
      {!hideTopCloseButton && (
        <IconButton className={style.closeButton} onClick={onClose}>
          <CloseIcon className={style.close} />
        </IconButton>
      )}
      <div className={className}>{children}</div>
    </MuiDialog>
  )
}

export default Dialog
