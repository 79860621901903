import { createContext, ReactNode, useCallback, useContext, useReducer, useEffect } from "react"

import { getStats } from "../api/ubiservices"
import ubisoftServicesConfig from "../config/ubisoftServicesConfig"
import { useUbisoftWebAuthSessionContext } from "../ubisoftWebAuth/UbisoftWebAuthSessionProvider"

interface UbiPlayerStats {
  data: Record<string, number>
  isLoading: boolean
  fetch: (stats: string[]) => void
}

const UbiPlayerStatsContext = createContext<UbiPlayerStats>({
  data: {},
  isLoading: true,
  fetch: () => {},
})

interface State {
  data: Record<string, number>
  isLoading: boolean
  statsToFetch?: string[]
  error?: Error
}

type Action =
  | { type: "requestStats"; statsNames: string[] }
  | { type: "fetchStart" }
  | { type: "fetchDone"; data: Record<string, number> }
  | { type: "fetchError"; error: Error }

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "requestStats":
      return { ...state, statsToFetch: Array.from(new Set([...(state.statsToFetch || []), ...action.statsNames])) }
    case "fetchStart":
      return { ...state, isLoading: true }
    case "fetchDone":
      return { data: { ...state.data, ...action.data }, isLoading: false }
    case "fetchError":
      return { error: state.error, data: {}, isLoading: false }
    default:
      return state
  }
}

const initialState = { data: {}, isLoading: true, statsNames: [] }

const useUbiPlayerStats = () => {
  const { user } = useUbisoftWebAuthSessionContext()
  const [{ data, statsToFetch, isLoading }, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const fetch = (statsToFetch: string[]) => {
      const { stats: statsResponse, cancelStats } = getStats(
        user.ticket,
        user.sessionID,
        user.userID,
        ubisoftServicesConfig.games["grb"].spaceId,
        statsToFetch
      )

      dispatch({ type: "fetchStart" })
      statsResponse
        .then((response) => {
          const newStats: Record<string, number> = {}
          statsToFetch.forEach((statName) => {
            newStats[statName] = +response.stats[statName]?.value || 0
          })

          dispatch({ type: "fetchDone", data: newStats })
        })
        .catch(() => dispatch({ type: "fetchError", error: new Error() }))

      return () => {
        cancelStats()
        dispatch({ type: "fetchDone", data })
      }
    }

    const statsNotCached = data ? statsToFetch?.filter((key) => !(key in data)) : statsToFetch
    if (statsNotCached && statsNotCached.length > 0) {
      return fetch(statsNotCached)
    }
  }, [user, statsToFetch, data])

  const requestStats = useCallback((statsNames: string[]) => {
    dispatch({ type: "requestStats", statsNames })
  }, [])

  return {
    data,
    isLoading,
    fetch: requestStats,
  }
}

export const useUbiPlayerStatsContext = () => {
  const context = useContext(UbiPlayerStatsContext)
  if (context === undefined) {
    throw new Error("useUbiPlayerStatsContext must be used within a UbiPlayerStatsProvider")
  }
  return context
}

interface Props {
  children: ReactNode
}

const UbiPlayerStatsProvider = ({ children }: Props) => {
  const state = useUbiPlayerStats()

  return <UbiPlayerStatsContext.Provider value={state}>{children}</UbiPlayerStatsContext.Provider>
}

export default UbiPlayerStatsProvider
