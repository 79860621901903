import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import { IntlShape, useIntl } from "react-intl"

import FullPageError from "../../FullPageError"
import { TransactionErrorKey } from "../../../views/TokenPurchase/hooks/useBuyToken"
import TrackPage from "../../TrackPage"
import { TransactionErrorCode } from "../../../api/errors"
import useDashboardRedirectInfo from "../../../hooks/useDashboardRedirectInfo"

import { ReactComponent as RefreshIcon } from "../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/refresh.svg"
import { ReactComponent as ArrowRightIcon } from "../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"

export interface Props {
  code: TransactionErrorKey | string
  walletInfo: {
    provider: string
    address: string
  } | null
  onBackClick?: () => void
}

export interface ErrorDetail {
  trackingLocationDetail: string
  title: string
  description: ReactNode
  actionType?: "retry" | "goToDashboard"
}

const buildErrorMessage = (intl: IntlShape, code: string) => {
  const errorMap: Partial<Record<TransactionErrorKey | TransactionErrorCode, ErrorDetail>> = {
    // FRONT ERRORS
    [TransactionErrorKey.NO_WALLET_ASSOCIATED]: {
      trackingLocationDetail: "No wallet associated error",
      title: intl.formatMessage({
        defaultMessage: "No wallet associated",
        id: "P08y+P",
        description: "[Transaction Error] No wallet associated error title",
      }),
      description: intl.formatMessage(
        {
          defaultMessage:
            "It seems your account have no associated wallet. Please try to verify your crypto-wallet is properly associated!{br} If the issue persists, please contact Ubisoft customer support.",
          id: "P6P4tS",
          description: "[Transaction Error] No wallet associated error description",
        },
        { br: <br /> }
      ),
      actionType: "retry",
    },
    [TransactionErrorKey.UNINITIALIZED_PROVIDER]: {
      trackingLocationDetail: "Uninitialized provider error",
      title: intl.formatMessage({
        defaultMessage: "Crypto-wallet provider not initialized",
        id: "hyo0/u",
        description: "[Transaction Error] Uninitiliazed provider error title",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "A Crypto-wallet provider is necessary to make the transaction. Please try refreshing the page and connecting with your Crypto-wallet provider again.",
        id: "PFNHCx",
        description: "[Transaction Error] No wallet associated error description",
      }),
      actionType: "retry",
    },
    [TransactionErrorKey.UNDEFINED_MINTER_ADDRESS]: {
      trackingLocationDetail: "Undefined minter address error",
      title: intl.formatMessage({
        defaultMessage: "Internal error",
        id: "mliY+x",
        description: "[Transaction Error] undefined minter address error title",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Sorry! Something seems to have gone wrong. Please try again! If the issue persists, please contact Ubisoft customer support.",
        id: "70OHYW",
        description: "Buy error message",
      }),
      actionType: "retry",
    },
    [TransactionErrorKey.ASSOCIATE_WALLET_UNAVAILABLE]: {
      trackingLocationDetail: "Associated wallet unavailable error",
      title: intl.formatMessage({
        defaultMessage: "Crypto-wallet unavailable",
        id: "toLNNz",
        description: "[Transaction Error] Associated wallet unavailable title",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Your associated crypto-wallet could not be found. Please make sure you connected the wallet associated with your Ubisoft account",
        id: "stPbIp",
        description: "Buy error message",
      }),
      actionType: "retry",
    },
    [TransactionErrorKey.OTHER]: {
      trackingLocationDetail: "Other error",
      title: intl.formatMessage({
        defaultMessage: "Internal error",
        id: "WVrCTG",
        description: "[Transaction Error] buy error description",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Sorry! Something seems to have gone wrong. Please try again! If the issue persists, please contact Ubisoft customer support.",
        id: "70OHYW",
        description: "Buy error message",
      }),
      actionType: "retry",
    },

    // PLAYER ERRORS
    [TransactionErrorCode.noWalletAssociated]: {
      trackingLocationDetail: "No wallet associated error",
      title: intl.formatMessage({
        defaultMessage: "No wallet associated",
        id: "P08y+P",
        description: "[Transaction Error] No wallet associated error title",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Sorry! Something seems to have gone wrong. Please try again! If the issue persists, please contact Ubisoft customer support.",
        id: "70OHYW",
        description: "Buy error message",
      }),
      actionType: "retry",
    },
    [TransactionErrorCode.invalidSignature]: {
      trackingLocationDetail: "Invalid signature error",
      title: intl.formatMessage({
        defaultMessage: "Invalid signature!",
        id: "8dtWC7",
        description: "[Transaction Error] invalid signature error title",
      }),
      description: intl.formatMessage({
        defaultMessage: "Sorry, your signature is not valid. Please get in touch with your crypto-wallet provider!",
        id: "62u1cu",
        description: "[Transaction Error] invalid signature error description",
      }),
      actionType: "retry",
    },
    [TransactionErrorCode.twoFADisabled]: {
      trackingLocationDetail: "2FA verification (disabled) error",
      title: intl.formatMessage({
        defaultMessage: "2FA verification failed",
        id: "5BBLU1",
        description: "[Transaction Error] 2FA error title",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Sorry! Something seems to have gone wrong. Please try again! If the issue persists, please contact Ubisoft customer support.",
        id: "UaSCPP",
        description: "[Transaction Error] 2FA error description",
      }),
      actionType: "retry",
    },
    [TransactionErrorCode.twoFAUnused]: {
      trackingLocationDetail: "2FA verification (unused) error",
      title: intl.formatMessage({
        defaultMessage: "2FA verification failed",
        id: "5BBLU1",
        description: "[Transaction Error] 2FA error title",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Sorry! Something seems to have gone wrong. Please try again! If the issue persists, please contact Ubisoft customer support.",
        id: "UaSCPP",
        description: "[Transaction Error] 2FA error description",
      }),
      actionType: "retry",
    },
    [TransactionErrorCode.invalidCountry]: {
      trackingLocationDetail: "Invalid country error",
      title: intl.formatMessage({
        defaultMessage: "Invalid country",
        id: "4b7yq5",
        description: "[Transaction Error] invalid country error title",
      }),
      description: intl.formatMessage(
        {
          defaultMessage:
            "Sorry! Something seems to have gone wrong. Please try again! If the issue persists, please contact Ubisoft customer support.",
          id: "aPPqCI",
          description: "[Transaction Error] invalid country error description",
        },
        { br: <br /> }
      ),
      actionType: "goToDashboard",
    },

    // SALE ERRORS
    [TransactionErrorCode.saleNotStarted]: {
      trackingLocationDetail: "Sale not started error",
      title: intl.formatMessage({
        defaultMessage: "Coming soon!",
        id: "QIXELn",
        description: "[Transaction Error] Sale not started error title",
      }),
      description: intl.formatMessage({
        defaultMessage: "Sorry! The sale has not started yet. Please try again later!",
        id: "HiMgYG",
        description: "[Transaction Error] Sale not started error description",
      }),
      actionType: "goToDashboard",
    },
    [TransactionErrorCode.saleHasEnded]: {
      trackingLocationDetail: "Sale has ended error",
      title: intl.formatMessage({
        defaultMessage: "Too late!",
        id: "YLGOYo",
        description: "[Transaction Error] sale has ended error title",
      }),
      description: intl.formatMessage({
        defaultMessage: "Sorry! The sale has ended.",
        id: "WlajXC",
        description: "[Transaction Error] Sale has ended error description",
      }),
      actionType: "goToDashboard",
    },
    [TransactionErrorCode.tokenInfoNotFound]: {
      trackingLocationDetail: "Token info not found error",
      title: intl.formatMessage({
        defaultMessage: "Internal error",
        id: "v9TCOC",
        description: "[Transaction Error] token info not found error title",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Sorry! Something seems to have gone wrong. Please try again! If the issue persists, please contact Ubisoft customer support.",
        id: "JPTf4X",
        description: "[Transaction Error] token info not found error description",
      }),
      actionType: "goToDashboard",
    },
    [TransactionErrorCode.cannotBuyAFreedrop]: {
      trackingLocationDetail: "Cannot buy a free drop error",
      title: intl.formatMessage({
        defaultMessage: "Internal error",
        id: "yaXLtP",
        description: "[Transaction Error] cannot buy a freedrop error title",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Sorry! Something seems to have gone wrong. Please try again! If the issue persists, please contact Ubisoft customer support.",
        id: "3SixcB",
        description: "[Transaction Error] cannot buy a freedrop error description",
      }),
      actionType: "goToDashboard",
    },
    [TransactionErrorCode.tokenNotAvailable]: {
      trackingLocationDetail: "Token not available error",
      title: intl.formatMessage({
        defaultMessage: "Too late!",
        id: "Mr41IE",
        description: "[Transaction Error] token not available error title",
      }),
      description: intl.formatMessage({
        defaultMessage: "Sorry! This token is not available anymore.",
        id: "qZqXAg",
        description: "[Transaction Error] token not available error description",
      }),
      actionType: "goToDashboard",
    },
    [TransactionErrorCode.notFreedrop]: {
      trackingLocationDetail: "Not freedrop error",
      title: intl.formatMessage({
        defaultMessage: "Internal error",
        id: "bgxjej",
        description: "[Transaction Error] not freedrop error title",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Sorry! Something seems to have gone wrong. Please try again! If the issue persists, please contact Ubisoft customer support.",
        id: "IbPT3Q",
        description: "[Transaction Error] not freedrop error description",
      }),
      actionType: "goToDashboard",
    },
    [TransactionErrorCode.alreadyClaimed]: {
      trackingLocationDetail: "Already claimed error",
      title: intl.formatMessage({
        defaultMessage: "Nice try!",
        id: "/0w4H2",
        description: "[Transaction Error] already claimed error title",
      }),
      description: intl.formatMessage({
        defaultMessage: "You already claimed a Digit from this Edition!",
        id: "Qhsw+u",
        description: "[Transaction Error] already claimed error description",
      }),
      actionType: "goToDashboard",
    },
    [TransactionErrorCode.noTokenAvailable]: {
      trackingLocationDetail: "Sold out error",
      title: intl.formatMessage({
        defaultMessage: "Sold out!",
        id: "QzYMFK",
        description: "[Transaction Error] sold out error title",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Sorry, all the Digits from this Edition have already been claimed. You can look for other Digits on the dashboard!",
        id: "499z/x",
        description: "[Transaction Error] sold out error description",
      }),
      actionType: "goToDashboard",
    },
    [TransactionErrorCode.maxOwnedTokenReached]: {
      trackingLocationDetail: "Max owned token error",
      title: intl.formatMessage({
        defaultMessage: "Nice try!",
        id: "NNTdjH",
        description: "[Transaction Error] max owned token error title",
      }),
      description: intl.formatMessage({
        defaultMessage: "You already claimed a Digit from this Edition!",
        id: "iUo/Ev",
        description: "[Transaction Error] max owned token error description",
      }),
      actionType: "goToDashboard",
    },
    [TransactionErrorCode.saleRequirementsError]: {
      trackingLocationDetail: "Sale requirements not met error",
      title: intl.formatMessage({
        defaultMessage: "Internal error",
        id: "Bp47Gc",
        description: "[Transaction Error] sale requirements not met error description",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Sorry! Something seems to have gone wrong. Please try again! If the issue persists, please contact Ubisoft customer support.",
        id: "hBkgfD",
        description: "[Transaction Error] sale requirements not met error description",
      }),
      actionType: "goToDashboard",
    },
  }

  return (
    errorMap[code as TransactionErrorKey | TransactionErrorCode] || {
      trackingLocationDetail: "Other error on transaction page",
      title: intl.formatMessage({
        defaultMessage: "Internal error",
        id: "WVrCTG",
        description: "[Transaction Error] buy error description",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Sorry! Something seems to have gone wrong. Please try again! If the issue persists, please contact Ubisoft customer support.",
        id: "zTDG9Q",
        description: "[Transaction Error] Buy error message",
      }),
      actionType: "retry",
    }
  )
}

const TransactionErrorPage = ({ code, walletInfo, onBackClick }: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const errorMessage = buildErrorMessage(intl, code)
  const { to, label } = useDashboardRedirectInfo()

  const retryButton = {
    handleAction: onBackClick,
    actionLabel: intl.formatMessage({
      defaultMessage: "Retry",
      id: "7/51jc",
      description: "[Transaction Error] Retry button label",
    }),
    actionIcon: <RefreshIcon />,
  }

  const goToDashboardButton = {
    handleAction: () => navigate(to),
    actionLabel: label,
    actionIcon: <ArrowRightIcon />,
  }

  const action = errorMessage.actionType === "goToDashboard" ? goToDashboardButton : retryButton

  return (
    <>
      <TrackPage
        siteSection="error"
        pageName="transaction error"
        siteSubSection={errorMessage.trackingLocationDetail}
      />
      <FullPageError
        errorTitle={errorMessage.title}
        buttonLabel={action.actionLabel}
        buttonIcon={action.actionIcon}
        onClick={action.handleAction}
      >
        <p>{errorMessage.description}</p>
      </FullPageError>
    </>
  )
}

export default TransactionErrorPage
