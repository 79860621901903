/*
 * Checks if the given URL is an IPFS URL (ipfs://abcdef or ipfs://abcdef/123.abc123) and return the HTTP gateway equivalent if that is the case, otherwise returns the URL as is.
 */
const ipfsToHttpGateway = (url: string) => {
  const cid = /(?:ipfs:\/\/)(\w+(\/\d+\.\w+)?)/i.exec(url)

  if (!cid) return url

  return `${process.env.REACT_APP_IPFS_GATEWAY}/${cid[1]}`
}

export default ipfsToHttpGateway
