import { FormattedMessage, useIntl } from "react-intl"
import { useContext } from "react"
import { default as DrawerMui } from "@mui/material/Drawer"
import makeStyles from "@mui/styles/makeStyles"

import Button from "../Button"
import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import { UserSetupStateContext } from "../../providers/UserSetupStateProvider/UserSetupStateProvider"
import { useUserInfoContext } from "../../providers/UserInfoProvider"
import RainbowTitle from "../RainbowTitle"
import TrackImpression from "../TrackImpression"
import { trackClick } from "../../utils/analytics"
import { useTezosContext } from "../../web3/Web3Provider"

import { ReactComponent as ArrowRight } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"
import rabbidsHug from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/rabbidsHug.png"

import style from "./style.module.css"

const useStyles = makeStyles({
  paper: {
    backgroundColor: "transparent",
    paddingTop: "calc(var(--imageSize) * 0.5)",
  },
})

const SetupSuccessDrawer = () => {
  const intl = useIntl()
  const [userSetupState, setUserSetupState] = useContext(UserSetupStateContext)
  const { user } = useUbisoftWebAuthSessionContext()
  const { userInfo, isLoading } = useUserInfoContext()
  const classes = useStyles()
  const { providerName } = useTezosContext()

  const handleClose = () => {
    setUserSetupState(null)
  }

  const handleContinueClick = () => {
    trackClick({ location: "pop in", locationDetail: "account fully set up", category: "action", action: "continue" })
    handleClose()
  }

  const open = userSetupState === "associate_done" && !isLoading && !!userInfo.walletAddress && !!user.ticket

  return (
    <DrawerMui
      anchor="bottom"
      style={{ zIndex: 1500 }}
      classes={{ ...classes, root: style.root }}
      open={open}
      onClose={handleClose}
      elevation={0}
    >
      <>
        <TrackImpression locationDetail="account fully set up" customParameter={`wallet provider : ${providerName}`} />
        <div className={style.container}>
          <img className={style.image} src={rabbidsHug} alt="" aria-hidden />
          <RainbowTitle
            className={style.title}
            text={intl.formatMessage({
              defaultMessage: "Your account is fully set up!",
              id: "2XB086",
              description: "[Setup Done Drawer] title",
            })}
          />
          <p className="paragraph">
            <FormattedMessage
              defaultMessage="You can now browse our Editions and acquire the Digits you want."
              id="f0HW37"
              description="[Setup Done Drawer] description"
            />
          </p>
          <Button invert fullWidth onClick={handleContinueClick} icon={<ArrowRight />}>
            <FormattedMessage defaultMessage="Continue" id="6WliFZ" description="Close drawer button" />
          </Button>
        </div>
      </>
    </DrawerMui>
  )
}

export default SetupSuccessDrawer
