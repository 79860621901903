import { ReactElement, useCallback } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import { usePagination, useQuery } from "relay-hooks"
import { FormattedMessage, useIntl } from "react-intl"
import graphql from "babel-plugin-relay/macro"
import { useEffect } from "react"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import { TokensQuery } from "./__generated__/TokensQuery.graphql"
import { TokensList_sale$key } from "./__generated__/TokensList_sale.graphql"

import ListGroup, { ListGroupItem } from "../../components/ListGroup"
import Tag from "../../components/Tag"
import Drawer from "../../components/Drawer"
import Spinner from "../../components/Spinner"

import { ReactComponent as SerialNumberIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/serialNumbers.svg"
import { ReactComponent as ChevronRightIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/chevronRight.svg"
import { ReactComponent as ChevronBottomIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/chevronBottom.svg"

import style from "./style.module.css"
import Button from "../../components/Button"

interface TokensListProps {
  sale: TokensList_sale$key
  variantName: string
  onClose: () => void
}

const fragment = graphql`
  fragment TokensList_sale on Sale
  @argumentDefinitions(count: { type: "Int", defaultValue: 100 }, cursor: { type: "String" })
  @refetchable(queryName: "TokensRefetchQuery") {
    tokens(first: $count, after: $cursor) @connection(key: "Tokens_tokens") {
      edges {
        node {
          id
          serialNumber
          available
        }
      }
      pageInfo {
        total
      }
    }
  }
`

const TokensList = ({ sale, variantName, onClose }: TokensListProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { gameID, saleID } = useParams<{ gameID: string; saleID: string }>()
  const { data, isLoading, isLoadingNext, hasNext, loadNext } = usePagination(fragment, sale)

  const loadMore = () => {
    if (!hasNext || isLoading || isLoadingNext) {
      return
    }

    loadNext(100)
  }

  const handleSelectToken = useCallback(
    (tokenID: string, tokenAvailable: boolean) => {
      if (tokenAvailable) {
        if (pathname.match("Token")) {
          navigate(`/${gameID}/sales/${saleID}/${tokenID}`, { replace: true })
          onClose()
        } else {
          navigate(`${pathname}/${tokenID}`)
        }
      }
      return
    },
    [pathname, navigate, gameID, saleID, onClose]
  )

  return (
    <div className={style.listWrapper}>
      <ListGroup className={style.list}>
        {data.tokens.edges.map(({ node: token }) => {
          if (!token) return null

          const tagLabel = token.available
            ? intl.formatMessage({ id: "yAJAyy", defaultMessage: "AVAILABLE", description: "Available tag label" })
            : intl.formatMessage({
                id: "/S65yv",
                defaultMessage: "Sold",
                description: "[Serial Selection] serial tag label",
              })

          return (
            <ListGroupItem
              key={token.id}
              label={
                <div className={style.customLabel}>
                  {variantName}
                  <span className={style.serialNumber}>#{token.serialNumber}</span>
                </div>
              }
              className={`${style.item} ${!token.available ? style.disabled : ""}`}
              onClick={() => handleSelectToken(token!.id, token.available)}
              disabled={!token.available}
            >
              {token?.available ? (
                <ChevronRightIcon className={style.chevron} />
              ) : (
                <Tag color={"danger"} className={style.tag}>
                  {tagLabel}
                </Tag>
              )}
            </ListGroupItem>
          )
        })}
      </ListGroup>
      {hasNext && (
        <Button
          icon={isLoadingNext ? <Spinner /> : <ChevronBottomIcon />}
          onClick={loadMore}
          className={style.loadMore}
          disabled={isLoading || isLoadingNext}
        >
          <FormattedMessage
            defaultMessage="Load more"
            id="N6DpcD"
            description="[Serial Selection] Load more button label"
          />
        </Button>
      )}
    </div>
  )
}

const tokensQuery = graphql`
  query TokensQuery($id: ID!) {
    sale(id: $id) {
      archetype {
        primaryVariant {
          name
          categorization {
            game {
              id
            }
          }
        }
      }
      ...TokensList_sale
    }
  }
`

interface Props {
  open: boolean
  onClose: () => void
}

const Tokens = ({ open, onClose }: Props) => {
  const { saleID } = useParams() as { gameID: string; saleID: string }
  const { data, isLoading, error, retry } = useQuery<TokensQuery>(tokensQuery, { id: saleID }, { skip: true })
  const intl = useIntl()
  let drawerContent: ReactElement
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  useEffect(() => {
    if (open) {
      retry()
    }
  }, [open, retry])

  if (isLoading) {
    drawerContent = <LoadingSection />
  } else if (!data || error) {
    drawerContent = <ErrorSection />
  } else {
    const variantName = data.sale.archetype.primaryVariant.name
    drawerContent = <TokensList sale={data.sale} variantName={variantName} onClose={onClose} />
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      icon={<SerialNumberIcon aria-hidden />}
      title={intl.formatMessage({
        defaultMessage: "Serial number",
        id: "NQkNzT",
        description: "[Serial Selection] title",
      })}
      anchor={isMobile ? "bottom" : "right"}
    >
      <div className={isMobile ? style.anchorBottom : ""}>{drawerContent}</div>
    </Drawer>
  )
}

const LoadingSection = () => {
  const CardItemLoading = <div className={style.cardItemLoading} />
  const LabelItemLoading = <div className={style.labelItemLoading} />
  return (
    <ListGroup className={style.list}>
      <ListGroupItem label={LabelItemLoading} className={style.item}>
        {CardItemLoading}
      </ListGroupItem>
      <ListGroupItem label={LabelItemLoading} className={style.item}>
        {CardItemLoading}
      </ListGroupItem>
      <ListGroupItem label={LabelItemLoading} className={style.item}>
        {CardItemLoading}
      </ListGroupItem>
      <ListGroupItem label={LabelItemLoading} className={style.item}>
        {CardItemLoading}
      </ListGroupItem>
    </ListGroup>
  )
}

const ErrorSection = () => {
  return (
    <div>
      <h3 className={style.error}>
        <FormattedMessage
          defaultMessage="An error occurred"
          id="35uljG"
          description="[Serial Selection] Generic error message"
        />
      </h3>
    </div>
  )
}

export default Tokens
