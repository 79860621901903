import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Navigate, useNavigate } from "react-router-dom"
import { Location } from "history"
import { RampInstantEventTypes, RampInstantSDK } from "@ramp-network/ramp-instant-sdk"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import PageLayout from "../../components/PageLayout"
import PageHeader from "../../components/PageHeader"
import RainbowTitle from "../../components/RainbowTitle"
import CardButton from "../../components/CardButton"
import InfoBox from "../../components/InfoBox"
import MoonpayDialog from "./MoonpayDialog"
import { useUserInfoContext } from "../../providers/UserInfoProvider"
import { useGeolocationInfoContext } from "../../providers/GeolocationInfoProvider"
import topUpConfig from "../../config/topUpConfig"
import useLocation from "../../hooks/useLocation"

import RampLogo from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/wallets/ramp.svg"
import MoonpayLogo from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/wallets/moonpay.svg"
import WertLogo from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/wallets/wert.svg"
import TrackPage from "../../components/TrackPage"

import style from "./style.module.css"

const DEFAULT_CURRENCY_AMOUNT = 20

const WalletTopUp = () => {
  const intl = useIntl()
  const { isCurrentIpLocationAllowed, isLoading: isGeolocLoading } = useGeolocationInfoContext()
  const { isLoading: isUserInfoLoading, userInfo } = useUserInfoContext()
  const navigate = useNavigate()
  const [rampInstant, setRampInstant] = useState<RampInstantSDK | null>(null)
  const [rampEmbedded, setRampEmbedded] = useState(false)
  const [moonpayInstance, setMoonpayInstance] = useState(false)
  const location = useLocation<{ referrer: Location }>()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  useEffect(() => {
    if (!rampInstant) return

    return () => {
      // TODO: find another way to detect POP action with react router v6
      // if (navigate.action === "POP") {
      rampInstant.close()
      navigate(location.state?.referrer || "/user/wallet", { replace: true })
      // }
    }
  }, [navigate, rampInstant, location.state])

  const handleRampClick = () => {
    if (!userInfo.walletAddress) return

    // RampInstantSDK configuration, see: https://docs.ramp.network/configuration
    const container = document.getElementById("topUpContainer")

    // Use embedded mobile if the container is high enough
    let embedded = false
    if (container && container.clientHeight >= 667) {
      embedded = true
      setRampEmbedded(embedded)
    }

    setRampInstant(
      new RampInstantSDK({
        hostAppName: "Ubisoft Quartz",
        hostLogoUrl: "https://geronimo.akamaized.net/rabbidstoken.ubisoft.comhttps://ubi-web-part.akamaized.net/quartz/prod/static/img/UbisoftStackedBlack.png",
        swapAsset: "TEZOS_EURL",
        userAddress: userInfo.walletAddress,
        hostApiKey: topUpConfig.ramp.apiKey,
        url: topUpConfig.ramp.url,
        selectedCountryCode: "TEST" /* TODO Get from user's country */,
        variant: embedded ? "embedded-mobile" : "auto",
        containerNode: document.getElementById("topUpContainer") || undefined,
      })
        .on(RampInstantEventTypes.WIDGET_CLOSE, () => {
          navigate(location.state?.referrer || "/user/wallet", { replace: true })
        })
        .show()
    )
  }

  const handleOpenMoonpay = () => {
    setMoonpayInstance(true)
  }

  const handleCloseMoonpay = () => {
    setMoonpayInstance(false)
  }

  const handleWertClick = () => {
    if (!userInfo.walletAddress) return

    const wertUrl = new URL(topUpConfig.wert.url)
    wertUrl.searchParams.append("commodity", "EURL")
    wertUrl.searchParams.append("blockchain", "Tezos")
    wertUrl.searchParams.append("currency_amount", DEFAULT_CURRENCY_AMOUNT.toString())
    wertUrl.searchParams.append("currency", "USD")
    wertUrl.searchParams.append("address", userInfo.walletAddress)

    window.open(`${wertUrl.href}`)
  }

  if (
    (!isUserInfoLoading && !userInfo.eligibility.registeredCountryCriteria) ||
    (!isGeolocLoading && !isCurrentIpLocationAllowed)
  ) {
    return <Navigate to="/user/not-eligible/topup" replace />
  }

  /* TODO Track by provider once top up changes are done */

  return (
    <>
      <TrackPage siteSection="wallet" pageName="top up" />
      <PageLayout
        mainId="topUpContainer"
        header={
          isMobile && (
            <PageHeader
              title={
                rampEmbedded
                  ? intl.formatMessage({
                      id: "yO2WMr",
                      defaultMessage: "Buy on Ramp",
                      description: "[Wallet Top Up] Ramp top up page title",
                    })
                  : intl.formatMessage({
                      id: "QaHWaW",
                      defaultMessage: "Top Up",
                      description: "Top up page title",
                    })
              }
            />
          )
        }
        bodyClassName={`${style.content} ${rampEmbedded ? style.rampPage : ""}`}
        bodyPosition="right"
      >
        {rampEmbedded ? (
          <React.Fragment />
        ) : (
          <section>
            <RainbowTitle
              text={intl.formatMessage({
                id: "4HM4sp",
                defaultMessage: "Select provider",
                description: "[Wallet Top Up] page title",
              })}
            />
            <p className="paragraph">
              <FormattedMessage
                defaultMessage="To acquire EURL, you need to use the services of a third-party provider. You may use one of the providers listed below."
                id="7BvVpJ"
                description="[Connect Wallet] page description"
              />
            </p>
            <ul>
              <li className={style.topupItem}>
                <CardButton
                  iconLeft={<img className={style.icon} src={RampLogo} alt="" aria-hidden />}
                  title={intl.formatMessage({
                    id: "GpPc6F",
                    defaultMessage: "Ramp",
                    description: "[Wallet Top Up] Ramp button",
                  })}
                  onClick={handleRampClick}
                />
              </li>

              <li className={style.topupItem}>
                <CardButton
                  iconLeft={<img className={style.icon} src={MoonpayLogo} alt="" aria-hidden />}
                  title={intl.formatMessage({
                    id: "Y5aKF4",
                    defaultMessage: "Moonpay",
                    description: "[Wallet Top Up] Moonpay top up button",
                  })}
                  onClick={handleOpenMoonpay}
                />
              </li>

              <li className={style.topupItem}>
                <CardButton
                  iconLeft={<img className={style.icon} src={WertLogo} alt="" aria-hidden />}
                  title={intl.formatMessage({
                    id: "lq2Q/S",
                    defaultMessage: "Top-up with Wert",
                    description: "[Wallet Top Up] Wert top-up button",
                  })}
                  onClick={handleWertClick}
                />
              </li>
            </ul>
            <InfoBox>
              <FormattedMessage
                defaultMessage="By selecting any of these third-party payment providers, you will be redirected to an external website. Specific terms and conditions such as fees may apply."
                id="ZweDPA"
                description="[Wallet Top Up] terms disclaimer"
                values={{
                  br: <br />,
                }}
              />
            </InfoBox>
          </section>
        )}
        <MoonpayDialog open={moonpayInstance} onClose={handleCloseMoonpay} />
      </PageLayout>
    </>
  )
}

export default WalletTopUp
