/**
 * @generated SignedSource<<a82ba7acef09633254d258f90ab4fb5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TokensQuery$variables = {
  id: string;
};
export type TokensQuery$data = {
  readonly sale: {
    readonly archetype: {
      readonly primaryVariant: {
        readonly name: string;
        readonly categorization: {
          readonly game: {
            readonly id: string;
          } | null;
        } | null;
      };
    };
    readonly " $fragmentSpreads": FragmentRefs<"TokensList_sale">;
  };
};
export type TokensQuery = {
  variables: TokensQuery$variables;
  response: TokensQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Categorization",
  "kind": "LinkedField",
  "name": "categorization",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Game",
      "kind": "LinkedField",
      "name": "game",
      "plural": false,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TokensQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Sale",
        "kind": "LinkedField",
        "name": "sale",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Archetype",
            "kind": "LinkedField",
            "name": "archetype",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Variant",
                "kind": "LinkedField",
                "name": "primaryVariant",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TokensList_sale"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TokensQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Sale",
        "kind": "LinkedField",
        "name": "sale",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Archetype",
            "kind": "LinkedField",
            "name": "archetype",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Variant",
                "kind": "LinkedField",
                "name": "primaryVariant",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "TokenConnection",
            "kind": "LinkedField",
            "name": "tokens",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TokenEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Token",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "serialNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "available",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "tokens(first:100)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Tokens_tokens",
            "kind": "LinkedHandle",
            "name": "tokens"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a3068193522d1678d156b6265f3b76ec",
    "id": null,
    "metadata": {},
    "name": "TokensQuery",
    "operationKind": "query",
    "text": "query TokensQuery(\n  $id: ID!\n) {\n  sale(id: $id) {\n    archetype {\n      primaryVariant {\n        name\n        categorization {\n          game {\n            id\n          }\n        }\n        id\n      }\n      id\n    }\n    ...TokensList_sale\n    id\n  }\n}\n\nfragment TokensList_sale on Sale {\n  tokens(first: 100) {\n    edges {\n      node {\n        id\n        serialNumber\n        available\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      total\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "3731ac00087f65f34493e83d02cadf8d";

export default node;
