import crypto from "crypto-browserify"
import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import Backdrop from "@mui/material/Backdrop"
import makeStyles from "@mui/styles/makeStyles"

import { useUserInfoContext } from "../../../providers/UserInfoProvider"
import Dialog from "../../../components/Dialog"
import Spinner from "../../../components/Spinner"
import topUpConfig from "../../../config/topUpConfig"

import style from "./style.module.css"

const DEFAULT_CURRENCY_AMOUNT = 20

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "var(--white)",
  },
}))

interface Props {
  open: boolean
  onClose: () => void
}

const MoonpayDialog = ({ open, onClose }: Props) => {
  const classes = useStyles()
  const { userInfo, isLoading } = useUserInfoContext()
  const [moonpayUrl, setMoonpayUrl] = useState<string>()

  useEffect(() => {
    if (!userInfo.walletAddress || isLoading) return

    // Moonpay redirection page: https://www.moonpay.com/dashboard/getting_started/
    const url = new URL(topUpConfig.moonpay.url)
    url.searchParams.append("apiKey", topUpConfig.moonpay.apiKey)
    url.searchParams.append("currencyCode", "eurl")
    url.searchParams.append("walletAddress", userInfo.walletAddress)
    url.searchParams.append("baseCurrencyAmount", DEFAULT_CURRENCY_AMOUNT.toString())
    url.searchParams.append("baseCurrencyCode", "eur")

    const signature = crypto.createHmac("sha256", topUpConfig.moonpay.apiSecret).update(url.search).digest("base64")

    if (!url.href || !signature) {
      return
    }

    setMoonpayUrl(`${url.href}&signature=${encodeURIComponent(signature)}`)
  }, [userInfo, isLoading])

  if (isLoading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <Spinner />
      </Backdrop>
    )
  }

  return (
    <Dialog open={open} onClose={onClose} className={style.container} mobileFullscreen>
      {!moonpayUrl ? (
        <div className={style.error}>
          <FormattedMessage
            defaultMessage="An error occurred.{linebreak}Please try again later."
            id="LW/rs7"
            description="[Moonpay Pop up] Generic error message"
            values={{ linebreak: <br /> }}
          />
        </div>
      ) : (
        <iframe
          title="moonpay-dialog"
          allow="accelerometer; autoplay; camera; gyroscope; payment"
          height="100%"
          src={moonpayUrl}
          width="100%"
          className={style.iframe}
        >
          <FormattedMessage
            defaultMessage="Your browser does not support iframes."
            id="KxU5n3"
            description="[Moonpay Pop up] iframe error message"
          />
        </iframe>
      )}
    </Dialog>
  )
}

export default MoonpayDialog
