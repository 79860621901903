/**
 * @generated SignedSource<<e1c8a2cfd68f9980ba5627528ee17576>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MyTokenQuery$variables = {
  tokenID: string;
};
export type MyTokenQuery$data = {
  readonly token: {
    readonly serialNumber: number;
    readonly video: string;
    readonly archetype: {
      readonly maxInstances: number;
      readonly primaryVariant: {
        readonly name: string;
        readonly image: string;
        readonly categorization: {
          readonly game: {
            readonly name: string;
          } | null;
        } | null;
      };
    };
    readonly " $fragmentSpreads": FragmentRefs<"TokenDetails_token">;
  };
};
export type MyTokenQuery = {
  variables: MyTokenQuery$variables;
  response: MyTokenQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tokenID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tokenID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serialNumber",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxInstances",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MyTokenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Token",
        "kind": "LinkedField",
        "name": "token",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Archetype",
            "kind": "LinkedField",
            "name": "archetype",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Variant",
                "kind": "LinkedField",
                "name": "primaryVariant",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Categorization",
                    "kind": "LinkedField",
                    "name": "categorization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Game",
                        "kind": "LinkedField",
                        "name": "game",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TokenDetails_token"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MyTokenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Token",
        "kind": "LinkedField",
        "name": "token",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Archetype",
            "kind": "LinkedField",
            "name": "archetype",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Variant",
                "kind": "LinkedField",
                "name": "primaryVariant",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Categorization",
                    "kind": "LinkedField",
                    "name": "categorization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Game",
                        "kind": "LinkedField",
                        "name": "game",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "releaseDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PreviousOwner",
            "kind": "LinkedField",
            "name": "previousOwners",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "playerUbiID",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "txDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a9344c9bcbfa6c302f13e8fbdef409ca",
    "id": null,
    "metadata": {},
    "name": "MyTokenQuery",
    "operationKind": "query",
    "text": "query MyTokenQuery(\n  $tokenID: ID!\n) {\n  token(id: $tokenID) {\n    serialNumber\n    video\n    archetype {\n      maxInstances\n      primaryVariant {\n        name\n        image\n        categorization {\n          game {\n            name\n            id\n          }\n        }\n        id\n      }\n      id\n    }\n    ...TokenDetails_token\n    id\n  }\n}\n\nfragment TokenDetails_token on Token {\n  archetype {\n    primaryVariant {\n      name\n      description\n      id\n    }\n    id\n  }\n  releaseDate\n  previousOwners {\n    playerUbiID\n    txDate\n  }\n}\n"
  }
};
})();

(node as any).hash = "6858472f2eb86bbb6f5b53a058bc76f2";

export default node;
