/* eslint-disable react/style-prop-object */
import { Link, LinkProps, To } from "react-router-dom"
import { FormattedNumber } from "react-intl"
import { DOMAttributes } from "react"

import SaleTag, { TagLabel } from "../SaleTag"
import PriceTag from "../PriceTag"
import ipfsToHttpGateway from "../../utils/ipfsToHttpGateway"
import isFeatureEnabled from "../../utils/isFeatureEnabled"

import style from "./style.module.css"

export interface Props {
  to: To
  state?: LinkProps["state"]
  thumbnail: string
  tag?: TagLabel
  availableTokens: number
  maxTokens: number
  label: string
  gameTitle: string
  priceDeFi: number
  currencyDeFi: string
  price: number | null
  currency: string
  onClick?: DOMAttributes<HTMLAnchorElement>["onClick"]
}

const SaleCard = ({
  to,
  state,
  thumbnail,
  tag,
  availableTokens,
  maxTokens,
  label,
  gameTitle,
  priceDeFi,
  currencyDeFi = "",
  price,
  currency = "",
  onClick,
}: Props) => (
  <Link to={to} state={state} onClick={onClick} className={style.container}>
    <div className={style.thumbnail}>
      <img src={ipfsToHttpGateway(thumbnail)} alt={label} />
    </div>
    <h4>{label}</h4>
    <div className={style.game} data-testid="gameTitle">
      {gameTitle}
    </div>
    <div className={style.priceDeFi}>
      <PriceTag value={priceDeFi} currency={currencyDeFi} data-testid="priceDeFi" />
      {isFeatureEnabled("euroValue") && (
        <span className={style.price} data-testid="price">
          {price !== null ? <FormattedNumber value={price} style="currency" currency={currency} /> : "€X.XX"}
        </span>
      )}
    </div>
    {tag && <SaleTag className={style.tag} label={tag} />}
    <div className={style.tokenCount} data-testid="tokenCount">
      <strong>{availableTokens}</strong>/{maxTokens}
    </div>
  </Link>
)

export default SaleCard
