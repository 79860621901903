import { ReactNode } from "react"
import { useRef } from "react"
import { useSpring, animated } from "react-spring"

import useElementSize from "../../hooks/useElementSize"

import style from "./style.module.css"

export interface Props {
  tabs: Array<string | ReactNode>
  currentIndex: number
  onChange?: (tabIndex: number) => void
  className?: string
}

export default function TabBar({ tabs, currentIndex, onChange, className = "" }: Props) {
  const ref = useRef(null)
  const { width } = useElementSize(ref)

  const props = useSpring({
    transform: `translateX(calc((${width}px / ${tabs.length}) * ${currentIndex}))`,
  })

  const handleButtonClick = (index: number) => () => onChange && onChange(index)

  return (
    <div className={`${className} ${style.container}`} ref={ref}>
      {tabs.map((label, index) => (
        <button key={index} onClick={handleButtonClick(index)}>
          {label}
        </button>
      ))}
      <animated.div
        className={style.indicator}
        style={{
          width: `calc(100% / ${tabs.length} - 0.125rem * 2)`,
          ...props,
        }}
      ></animated.div>
    </div>
  )
}
