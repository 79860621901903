import { useEffect, useMemo, useState } from "react"
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from "react-intl"
import { useFragment } from "react-relay"
import clsx from "clsx"
import graphql from "babel-plugin-relay/macro"

import { TokenDetails_token$key } from "./__generated__/TokenDetails_token.graphql"

import RainbowTitle from "../../../../components/RainbowTitle"
import ListGroup, { ListGroupItem } from "../../../../components/ListGroup"
import { getAvatar, getProfiles } from "../../../../api/ubiservices"
import { useUbisoftWebAuthSessionContext } from "../../../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import TabBar from "../../../../components/TabBar"
import MarketplaceLinks from "../../../../components/MarketplaceLinks"
import EmptySection from "../../../../components/EmptySection"

import { ReactComponent as EditIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/edit.svg"
import { ReactComponent as ProfileIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/profileStroke.svg"
import { ReactComponent as UbisoftSwirl } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/ubisoftSwirl.svg"
import { ReactComponent as CalendarIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/calendar.svg"
import defaultAvatar from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/avatarPlaceholder.jpg"
import ubisoftBadge from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/sticker.png"
import rabbids from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/rabbidsGamepadFight.png"

import style from "./style.module.css"

interface Player {
  id: string | null
  name: string
  acquisitionDate: Date | null
  avatar: string | null
}

interface PlayerCardProps {
  player: Player
  owner?: boolean
}

const PlayerCard = ({ player, owner = false }: PlayerCardProps) => {
  const { user } = useUbisoftWebAuthSessionContext()
  const [avatar, setAvatar] = useState<string | null>(null)

  useEffect(() => {
    if (!player.id) return
    if (player.avatar) {
      setAvatar(player.avatar)

      return
    }

    const { avatar, cancelAvatar } = getAvatar(user.ticket, user.sessionID, player.id)

    avatar.then((avatar) => setAvatar(avatar))

    return cancelAvatar
  }, [player.id, player.avatar, user.ticket, user.sessionID])

  return (
    <li className={`${style.card} ${style.userCard}`}>
      <div className={style.avatarContainer}>
        <img className={style.avatar} src={avatar || defaultAvatar} alt="" aria-hidden />
        {owner && <img className={style.sticker} src={ubisoftBadge} alt="" aria-hidden />}
      </div>
      {owner ? (
        <div className={style.ownerName}>
          <h4 className={style.rainbow}>{player.name}</h4>
        </div>
      ) : (
        <div className={style.historyName}>{player.name}</div>
      )}
      <div className="caption">
        {player.acquisitionDate && (
          <>
            <div className={style.historyDate}>
              <FormattedDate value={player.acquisitionDate} />
            </div>
            <div className={style.historyTime}>
              <FormattedTime value={player.acquisitionDate} />
            </div>
          </>
        )}
      </div>
    </li>
  )
}

const PlayerCardLoading = () => {
  return (
    <div className={`${style.card} ${style.userCard}`}>
      <div className={style.avatarContainer}>
        <img className={style.avatar} src={defaultAvatar} alt="" aria-hidden />
      </div>
      <div className={style.usernameLoading} />
      <div className={style.dateLoading} />
    </div>
  )
}

export const TokenDetailsLoading = () => {
  const intl = useIntl()

  return (
    <div>
      <section className={style.section}>
        <RainbowTitle
          text={intl.formatMessage({
            defaultMessage: "Owner",
            id: "per5Tt",
            description: "[My Token Page] section title",
          })}
        />
        <PlayerCardLoading />
      </section>
      <TabBar
        className={style.tabBar}
        currentIndex={0}
        tabs={[
          intl.formatMessage({
            defaultMessage: "Details",
            id: "nb/5zZ",
            description: "[My Token] tab bar title",
          }),
          intl.formatMessage({
            defaultMessage: "History",
            id: "s5OL4x",
            description: "[My Token] tab bar title",
          }),
        ]}
      />
      <section className={style.section}>
        <ListGroup className={style.card}>
          <ListGroupItem icon={<EditIcon />} label="">
            <div className={style.descriptionLoading} />
          </ListGroupItem>
          <ListGroupItem
            icon={<ProfileIcon aria-hidden />}
            label={intl.formatMessage({
              defaultMessage: "Creator",
              id: "GRLCIo",
              description: "[My Token] item label",
            })}
          >
            <div className={style.itemValueLoading} />
          </ListGroupItem>
          <ListGroupItem
            icon={<CalendarIcon />}
            label={intl.formatMessage({
              defaultMessage: "Release date",
              id: "zP5OKL",
              description: "[My Token] item label",
            })}
          >
            <div className={style.itemValueLoading} />
          </ListGroupItem>
        </ListGroup>
      </section>
    </div>
  )
}

export interface Props {
  token: TokenDetails_token$key
}

const TokenDetails = ({ token }: Props) => {
  const { user, isLoading: sessionIsLoading, login } = useUbisoftWebAuthSessionContext()
  const intl = useIntl()
  const tokenData = useFragment(
    graphql`
      fragment TokenDetails_token on Token {
        archetype {
          primaryVariant {
            name
            description
          }
        }
        releaseDate
        previousOwners {
          playerUbiID
          txDate
        }
      }
    `,
    token
  )

  const [loading, setLoading] = useState(true)
  const [currentOwner, setCurrentOwner] = useState<Player | null>(null)
  const [previousOwners, setPreviousOwners] = useState<Array<Player>>([])
  const sortedPreviousOwners = useMemo(
    () => [...tokenData.previousOwners].sort((a, b) => new Date(b.txDate).getTime() - new Date(a.txDate).getTime()),
    [tokenData.previousOwners]
  )
  const descriptionHTML = useMemo(
    () => tokenData.archetype.primaryVariant.description.replace(/\\n/g, "<br/>"),
    [tokenData.archetype.primaryVariant.description]
  )

  useEffect(() => {
    const profilesIDs = sortedPreviousOwners.map((owner) => owner.playerUbiID).filter((id) => !!id) as string[]
    const unknownOwnerLabel = intl.formatMessage({
      defaultMessage: "Unknown Owner",
      id: "PBPzen",
      description: "[My Token] unknown user name",
    })

    if (!user.ticket || !profilesIDs.length) {
      setLoading(false)
      return
    }

    const { profiles, cancelProfiles } = getProfiles(user.ticket, user.sessionID, profilesIDs)
    setLoading(true)
    profiles
      .then((response) => response.profiles || [])
      .then((profiles) =>
        sortedPreviousOwners.map((owner) => {
          const profile = profiles.find(({ profileId }) => owner.playerUbiID === profileId)

          return {
            id: profile ? profile.profileId : null,
            name: profile ? profile.nameOnPlatform : unknownOwnerLabel,
            avatar: profile ? null : defaultAvatar,
            acquisitionDate: new Date(owner.txDate),
          }
        })
      )
      .then((owners) => {
        setCurrentOwner(
          owners.length > 0
            ? owners[0]
            : {
                id: null,
                avatar: defaultAvatar,
                name: unknownOwnerLabel,
                acquisitionDate: null,
              }
        )
        setPreviousOwners(owners.slice(1))
      })
      .finally(() => setLoading(false))

    return cancelProfiles
  }, [sortedPreviousOwners, user.sessionID, user.ticket, intl])

  const [tabIndex, setTabIndex] = useState(0)
  const handleTabChange = (index: number) => setTabIndex(index)

  return (
    <div>
      {currentOwner && (
        <section className={style.section}>
          <RainbowTitle
            text={intl.formatMessage({
              defaultMessage: "Owner",
              id: "per5Tt",
              description: "[My Token Page] section title",
            })}
          />
          <PlayerCard player={currentOwner} owner />
        </section>
      )}

      <section className={style.section}>
        <RainbowTitle
          className={style.sectionTitle}
          text={intl.formatMessage({
            defaultMessage: "Check Availability",
            description: "[Product Page] Marketplaces links section title",
            id: "vstSlF",
          })}
        />
        <MarketplaceLinks tokenName={tokenData.archetype.primaryVariant.name} />
      </section>

      <TabBar
        className={style.tabBar}
        currentIndex={tabIndex}
        onChange={handleTabChange}
        tabs={[
          intl.formatMessage({
            defaultMessage: "Details",
            id: "nb/5zZ",
            description: "[My Token] tab bar title",
          }),
          intl.formatMessage({
            defaultMessage: "History",
            id: "s5OL4x",
            description: "[My Token] tab bar title",
          }),
        ]}
      />
      {tabIndex === 0 && (
        <section className={style.section}>
          <ListGroup className={style.card}>
            <ListGroupItem icon={<EditIcon />} label="">
              <div className="caption" dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
            </ListGroupItem>
            <ListGroupItem
              icon={<ProfileIcon aria-hidden />}
              label={intl.formatMessage({
                defaultMessage: "Creator",
                id: "GRLCIo",
                description: "[My Token] item label",
              })}
            >
              <div className={style.ubisoftName}>
                <UbisoftSwirl aria-hidden />
                <span className={clsx("numbers", style.creator)}>
                  <FormattedMessage defaultMessage="Ubisoft" id="mhWC2Z" description="[My Token] Creator name" />
                </span>
              </div>
            </ListGroupItem>
            <ListGroupItem
              icon={<CalendarIcon />}
              label={intl.formatMessage({
                defaultMessage: "Release date",
                id: "zP5OKL",
                description: "[My Token] item label",
              })}
            >
              <span className="numbers">
                <FormattedDate value={tokenData.releaseDate} />
              </span>
            </ListGroupItem>
          </ListGroup>
        </section>
      )}
      {tabIndex === 1 && (
        <section className={style.section}>
          {loading || sessionIsLoading ? (
            <PlayerCardLoading />
          ) : !user.ticket ? (
            // TODO: Find a solution to get usernames when user not logged
            <EmptySection
              title={
                <FormattedMessage
                  id="od8tg3"
                  defaultMessage="You need to log in to see the history."
                  description="[My Token] history user not logged message"
                />
              }
              buttonLabel={intl.formatMessage({
                defaultMessage: "Log in",
                id: "h+VxsE",
                description: "[My Token] Login button label",
              })}
              onClick={login}
            />
          ) : previousOwners.length > 0 ? (
            <ul className={style.ownerList}>
              {previousOwners.map((player, index) => (
                <PlayerCard key={index} player={player} />
              ))}
            </ul>
          ) : (
            <EmptySection
              title={
                <FormattedMessage
                  id="XLdOI8"
                  defaultMessage="This Digit has not been traded at this time."
                  description="[My Token] no history found message"
                />
              }
              img={rabbids}
              imgClassName={style.rabbids}
            />
          )}
        </section>
      )}
    </div>
  )
}

export default TokenDetails
