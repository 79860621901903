import Reveal from "react-awesome-reveal"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { FormattedMessage } from "react-intl"

import fadeInUp from "../../../../utils/animations/fade/fadeInUp"

import style from "./style.module.css"
import sharedStyle from "../../style.module.css"

const CTABanner = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <>
      <section className={sharedStyle.section} id={style.section4}>
        <Reveal duration={700} keyframes={fadeInUp(20)} triggerOnce>
          <h4 className={sharedStyle.content}>
            <FormattedMessage
              id="wwaNCQ"
              description="[Home] section subtitle"
              defaultMessage="The combination of a serial number and ownership history makes each Digit truly unique."
            />
          </h4>
          <h3>
            <FormattedMessage
              id="k2CkCV"
              description="[Home] section title"
              defaultMessage="Each Digit has a story and, {linebreak}<textGradient>you are part of it!</textGradient>"
              values={{
                linebreak: isMobile ? <br /> : null,
                textGradient: (text: string) => <span className={sharedStyle.highlight}>{text}</span>,
              }}
            />
          </h3>
        </Reveal>
      </section>
    </>
  )
}
export default CTABanner
