import { FormattedMessage } from "react-intl"
import graphql from "babel-plugin-relay/macro"
import { useFragment } from "react-relay"
import { useMemo } from "react"

import { ClaimSectionFragment_sale$key } from "./__generated__/ClaimSectionFragment_sale.graphql"
import { ClaimSectionFragment_variant$key } from "./__generated__/ClaimSectionFragment_variant.graphql"

import useSaleStatus, { SaleStatus } from "../../../../../hooks/useSaleStatus"
import useWalletConnect from "../../../../../hooks/useWalletConnect"
import useCanPurchase, { PurchaseStatus } from "../../../../../hooks/useCanPurchase"
import { useUserInfoContext } from "../../../../../providers/UserInfoProvider"
import { buildCustomParameter } from "../../../../../utils/analytics"
import Button from "../../../../../components/Button"
import CannotPurchaseError from "../../../../../components/TokenPurchase/CannotPurchaseError"
import UbisoftSpinner from "../../../../../components/UbisoftSpinner"
import SaleUnavaiblableError from "./components/SaleUnavailableError"
import TrackPage from "../../../../../components/TrackPage"
import InfoBox from "../../../../../components/InfoBox"
import WalletConnectSection from "../../../../../components/WalletConnectSection"
import WrongWalletDialog from "../../../../../components/WrongWalletDialog"
import AcceptTermsDialog from "../../../../../components/AcceptTermsDialog"
import SignMessageDialog from "../../../../../components/SignMessage"

import { ReactComponent as CheckIcon } from "../../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/check.svg"

import style from "./style.module.css"

interface Props {
  sale: ClaimSectionFragment_sale$key
  variant: ClaimSectionFragment_variant$key
  onClaimClick: () => void
}

const ClaimSection = ({ sale, variant, onClaimClick }: Props) => {
  const saleData = useFragment(
    graphql`
      fragment ClaimSectionFragment_sale on Sale {
        ...useSaleStatusFragment_sale
        ...useClaimStatus_sale
        ...useCanPurchaseFragment_sale
        ...CannotPurchaseError_sale
      }
    `,
    sale
  )
  const variantData = useFragment(
    graphql`
      fragment ClaimSectionFragment_variant on Variant {
        ...SaleUnavailableError_variant
        ...CannotPurchaseError_variant
        name
        categorization {
          game {
            name
          }
        }
      }
    `,
    variant
  )
  const { status, isLoading: canPurchaseStatusLoading } = useCanPurchase(saleData)
  const { status: saleStatus, isLoading: saleStatusLoading } = useSaleStatus(saleData)
  const { userInfo, isLoading: userInfoLoading } = useUserInfoContext()
  const {
    isDesktop,
    setSelectedWallet,
    setShowWrongWalletError,
    acceptTermsDismissed,
    showSignMessage,
    showWrongWalletError,
    handleAcceptTermsDismissed,
    handleSignMessageDialogClose,
    isLoading: walletConnectLoading,
  } = useWalletConnect()

  const trackingCustomParameter = useMemo(() => {
    return buildCustomParameter(variantData.categorization?.game?.name || "", variantData.name, "", 0, 0, false, true)
  }, [variantData])

  if (canPurchaseStatusLoading || saleStatusLoading || userInfoLoading)
    return (
      <div className={style.loading}>
        <UbisoftSpinner contrast />
      </div>
    )

  if (saleStatus !== SaleStatus.AVAILABLE) {
    return <SaleUnavaiblableError error={saleStatus} variant={variantData} />
  }

  if (
    (status === PurchaseStatus.NO_ASSOCIATED_WALLET ||
      status === PurchaseStatus.NO_CONNECTED_WALLET ||
      walletConnectLoading) &&
    isDesktop
  )
    return (
      <>
        <WalletConnectSection onSelectedWallet={setSelectedWallet} purchaseStatus={status} />
        {userInfo.walletAddress && (
          <WrongWalletDialog
            open={showWrongWalletError}
            onClose={() => setShowWrongWalletError(false)}
            address={userInfo.walletAddress}
          />
        )}
        <AcceptTermsDialog dismissed={acceptTermsDismissed} onClose={handleAcceptTermsDismissed} />
        <SignMessageDialog open={showSignMessage} onClose={handleSignMessageDialogClose} />
      </>
    )

  if (status !== PurchaseStatus.CAN_PURCHASE) {
    return <CannotPurchaseError error={status} sale={saleData} token={null} variant={variantData} freeDrop />
  }

  return (
    <>
      <TrackPage siteSection="product" pageName="product page" customParameter={trackingCustomParameter} />
      <h3 className={style.title}>
        <FormattedMessage
          defaultMessage="Claim your free Digit!"
          id="VdDhJi"
          description="[Free Sale] Claim free digit title"
        />
      </h3>
      <p>
        <FormattedMessage
          defaultMessage="Each Digit has a story, start writing your part. All Editions are limited, so make sure to get your Digit now!"
          id="hFs7qq"
          description="[Free Sale] Claim free digit message"
        />
      </p>
      <Button fullWidth invert icon={<CheckIcon />} onClick={onClaimClick}>
        <FormattedMessage
          defaultMessage="Claim my Digit now"
          id="45EQd5"
          description="[Free Sale] Claim free digit message"
        />
      </Button>
      <InfoBox type="warning" className={style.info}>
        <FormattedMessage
          defaultMessage="<b>Only <u>Ubisoft Connect PC players</u> can see and use their Digits in-game.</b>{br}{br}Ubisoft Quartz is only supported on Ubisoft Connect PC launcher."
          id="iWzWBA"
          description="[Free Sale] Epic/Luna restriction message"
          values={{
            b: (chunks: string) => <strong>{chunks}</strong>,
            u: (chunks: string) => <u>{chunks}</u>,
            br: <br />,
          }}
        />
      </InfoBox>
    </>
  )
}

export default ClaimSection
