import React, { ReactNode } from "react"

import style from "./style.module.css"

export interface Props {
  text: string
  smallText?: string
  callToAction?: ReactNode
  className?: string
}

const RainbowTitle = ({ text, smallText = "", callToAction, className = "" }: Props) => {
  return (
    <div className={`${style.container} ${className}`}>
      <h3>
        <span className="text-rainbow-horizontal">{text}</span>{" "}
        {smallText && <small className="paragraph">{smallText}</small>}
      </h3>
      {callToAction}
    </div>
  )
}

export default RainbowTitle
