/**
 * @generated SignedSource<<939003b67698e669eb0367edd337055f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductFragment_sale$data = {
  readonly id: string;
  readonly price: number;
  readonly startDate: string;
  readonly endDate: string | null;
  readonly claimsCount: {
    readonly remaining: number;
  } | null;
  readonly condition: {
    readonly " $fragmentSpreads": FragmentRefs<"buildConditionMap_saleCondition">;
  } | null;
  readonly archetype: {
    readonly id: string;
    readonly maxInstances: number;
    readonly video: string;
    readonly primaryVariant: {
      readonly name: string;
      readonly description: string;
      readonly categorization: {
        readonly game: {
          readonly id: string;
        } | null;
      } | null;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"ProductCardFragment_sale" | "PriceSectionFragment_sale" | "useClaimStatus_sale">;
  readonly " $fragmentType": "ProductFragment_sale";
};
export type ProductFragment_sale$key = {
  readonly " $data"?: ProductFragment_sale$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductFragment_sale">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operator",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "spaceID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "SaleConditionStat",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "int",
      "storageKey": null
    }
  ],
  "type": "IntVal",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "float",
      "storageKey": null
    }
  ],
  "type": "FloatVal",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "string",
      "storageKey": null
    }
  ],
  "type": "StringVal",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bool",
      "storageKey": null
    }
  ],
  "type": "BoolVal",
  "abstractKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductFragment_sale",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClaimsCount",
      "kind": "LinkedField",
      "name": "claimsCount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remaining",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SaleCondition",
      "kind": "LinkedField",
      "name": "condition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "buildConditionMap_saleCondition",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SaleConditionExpr",
              "kind": "LinkedField",
              "name": "expr",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "args",
                  "plural": true,
                  "selections": [
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "args",
                          "plural": true,
                          "selections": [
                            (v3/*: any*/),
                            (v4/*: any*/),
                            (v5/*: any*/),
                            (v6/*: any*/),
                            (v7/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": null,
                                  "kind": "LinkedField",
                                  "name": "args",
                                  "plural": true,
                                  "selections": [
                                    (v3/*: any*/),
                                    (v4/*: any*/),
                                    (v5/*: any*/),
                                    (v6/*: any*/),
                                    (v7/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "type": "SaleConditionExpr",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "SaleConditionExpr",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archetype",
      "kind": "LinkedField",
      "name": "archetype",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxInstances",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "video",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Variant",
          "kind": "LinkedField",
          "name": "primaryVariant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Categorization",
              "kind": "LinkedField",
              "name": "categorization",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Game",
                  "kind": "LinkedField",
                  "name": "game",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductCardFragment_sale"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PriceSectionFragment_sale"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useClaimStatus_sale"
    }
  ],
  "type": "Sale",
  "abstractKey": null
};
})();

(node as any).hash = "4e4eac2c820c6a373e4fc24e738850db";

export default node;
