/**
 * @generated SignedSource<<12ec091939b76d68d4ea25e08c3a4683>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GeolocationInfoProviderQuery$variables = {};
export type GeolocationInfoProviderQuery$data = {
  readonly allowedCountries: ReadonlyArray<string>;
};
export type GeolocationInfoProviderQuery = {
  variables: GeolocationInfoProviderQuery$variables;
  response: GeolocationInfoProviderQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": "allowedCountries",
    "args": null,
    "kind": "ScalarField",
    "name": "getAllowedCountries",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GeolocationInfoProviderQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GeolocationInfoProviderQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d4642bc627403efedc9fbdce596cbacb",
    "id": null,
    "metadata": {},
    "name": "GeolocationInfoProviderQuery",
    "operationKind": "query",
    "text": "query GeolocationInfoProviderQuery {\n  allowedCountries: getAllowedCountries\n}\n"
  }
};
})();

(node as any).hash = "5cc91cc4669cf4a4838c81b7c0e910f8";

export default node;
