/**
 * @generated SignedSource<<d314acf806beb6f23effcbefa6a3559b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClaimState = "DISTRIBUTED" | "MINTED" | "PENDING" | "%future added value";
export type WalletQuery$variables = {};
export type WalletQuery$data = {
  readonly currentPlayer: {
    readonly inventory: ReadonlyArray<{
      readonly id: string;
      readonly archetype: {
        readonly id: string;
        readonly primaryVariant: {
          readonly name: string;
        };
      };
      readonly " $fragmentSpreads": FragmentRefs<"TokenCardFragment_token">;
    }>;
    readonly reserved: ReadonlyArray<{
      readonly id: string;
      readonly archetype: {
        readonly id: string;
        readonly primaryVariant: {
          readonly name: string;
        };
      };
      readonly " $fragmentSpreads": FragmentRefs<"TokenCardFragment_token">;
    }>;
    readonly claims: ReadonlyArray<{
      readonly id: string;
      readonly sale: {
        readonly archetype: {
          readonly id: string;
          readonly primaryVariant: {
            readonly name: string;
          };
        };
        readonly endDate: string | null;
      };
      readonly state: ClaimState;
      readonly " $fragmentSpreads": FragmentRefs<"PendingClaimCardFragment_claim">;
    }>;
  };
};
export type WalletQuery = {
  variables: WalletQuery$variables;
  response: WalletQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Archetype",
  "kind": "LinkedField",
  "name": "archetype",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Variant",
      "kind": "LinkedField",
      "name": "primaryVariant",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "TokenCardFragment_token"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Archetype",
  "kind": "LinkedField",
  "name": "archetype",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Variant",
      "kind": "LinkedField",
      "name": "primaryVariant",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Categorization",
          "kind": "LinkedField",
          "name": "categorization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Game",
              "kind": "LinkedField",
              "name": "game",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  (v0/*: any*/),
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "serialNumber",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WalletQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Token",
            "kind": "LinkedField",
            "name": "inventory",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Token",
            "kind": "LinkedField",
            "name": "reserved",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Claim",
            "kind": "LinkedField",
            "name": "claims",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Sale",
                "kind": "LinkedField",
                "name": "sale",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PendingClaimCardFragment_claim"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WalletQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Token",
            "kind": "LinkedField",
            "name": "inventory",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Token",
            "kind": "LinkedField",
            "name": "reserved",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Claim",
            "kind": "LinkedField",
            "name": "claims",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Sale",
                "kind": "LinkedField",
                "name": "sale",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v4/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8a3243001ec834126085bc338cd598d4",
    "id": null,
    "metadata": {},
    "name": "WalletQuery",
    "operationKind": "query",
    "text": "query WalletQuery {\n  currentPlayer {\n    inventory {\n      id\n      archetype {\n        id\n        primaryVariant {\n          name\n          id\n        }\n      }\n      ...TokenCardFragment_token\n    }\n    reserved {\n      id\n      archetype {\n        id\n        primaryVariant {\n          name\n          id\n        }\n      }\n      ...TokenCardFragment_token\n    }\n    claims {\n      id\n      sale {\n        archetype {\n          id\n          primaryVariant {\n            name\n            id\n          }\n        }\n        endDate\n        id\n      }\n      state\n      ...PendingClaimCardFragment_claim\n    }\n    id\n  }\n}\n\nfragment PendingClaimCardFragment_claim on Claim {\n  sale {\n    archetype {\n      primaryVariant {\n        name\n        image\n        categorization {\n          game {\n            name\n            id\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment TokenCardFragment_token on Token {\n  id\n  serialNumber\n  archetype {\n    primaryVariant {\n      name\n      image\n      categorization {\n        game {\n          name\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "66220350956e3d2094aa8f4cbca1ee39";

export default node;
