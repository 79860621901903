import { useMemo } from "react"
import { Environment, RecordSource, Store } from "relay-runtime"
import {
  Headers,
  RelayNetworkLayer,
  retryMiddleware,
  uploadMiddleware,
  urlMiddleware,
} from "react-relay-network-modern"

const RETRAY_DELAYS_MS = [1000, 3000, 15000]

const useRelayEnvironment = (user: { ticket: string; sessionID: string }, adminEndpoint?: "admin" | "frontline") => {
  return useMemo(() => {
    const network = new RelayNetworkLayer([
      urlMiddleware({
        url: () => {
          const endPoint = adminEndpoint ? adminEndpoint : user.ticket ? "player" : "public"

          return process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/${endPoint}` : `/${endPoint}`
        },
        headers: () => {
          const headers: Headers = {}

          if (user.ticket && user.sessionID) {
            headers["Authorization"] = `ubi_v1 t=${user.ticket}`
            headers["Ubi-SessionId"] = user.sessionID
          }

          return headers
        },
      }),
      retryMiddleware({
        retryDelays: (attempt) => RETRAY_DELAYS_MS[Math.min(attempt, RETRAY_DELAYS_MS.length - 1)],
      }),
      uploadMiddleware(),
    ])

    return new Environment({
      network: network,
      store: new Store(new RecordSource()),
    })
  }, [user, adminEndpoint])
}

export default useRelayEnvironment
