import axios from "axios"
import { useEffect, useState } from "react"

function useGETQuery<T>(url: string, headers?: Record<string, string | number | boolean>) {
  const [cancelTokenSource] = useState(axios.CancelToken.source())
  const [state, setState] = useState<{ data: T | null; loading: boolean; error: Error | null }>({
    data: null,
    loading: true,
    error: null,
  })

  useEffect(() => {
    setState({ data: null, loading: true, error: null })
    axios
      .get<T>(url, { headers, cancelToken: cancelTokenSource.token })
      .then((res) => setState({ data: res.data, loading: false, error: null }))
      .catch((error) => setState({ data: null, loading: false, error }))
  }, [url, headers, cancelTokenSource])

  return state
}

export default useGETQuery
