/**
 * @generated SignedSource<<8f7ce5a1919378027d2c64eb766c6c95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MyTokenInventoryQuery$variables = {};
export type MyTokenInventoryQuery$data = {
  readonly currentPlayer: {
    readonly inventory: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly reserved: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type MyTokenInventoryQuery = {
  variables: MyTokenInventoryQuery$variables;
  response: MyTokenInventoryQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Token",
  "kind": "LinkedField",
  "name": "inventory",
  "plural": true,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Token",
  "kind": "LinkedField",
  "name": "reserved",
  "plural": true,
  "selections": (v1/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyTokenInventoryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MyTokenInventoryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6cfc8fed8b84aa8d49c824d3e0fe0ffb",
    "id": null,
    "metadata": {},
    "name": "MyTokenInventoryQuery",
    "operationKind": "query",
    "text": "query MyTokenInventoryQuery {\n  currentPlayer {\n    inventory {\n      id\n    }\n    reserved {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "851509fc25257a83dcfd78bca26dec99";

export default node;
