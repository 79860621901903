import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import Reveal from "react-awesome-reveal"

import fadeInUp from "../../../../utils/animations/fade/fadeInUp"

import style from "./style.module.css"
import sharedStyle from "../../style.module.css"

const HowItWorksSection = () => {
  return (
    <>
      <section className={sharedStyle.section} id={style.section8}>
        <div>
          <Reveal duration={700} keyframes={fadeInUp(20)} triggerOnce>
            <h1 className={sharedStyle.title}>
              <FormattedMessage
                id="zzOTC5"
                description="[Home] section title"
                defaultMessage="The choice of an{linebreak}<textGradient>energy-efficient technology</textGradient>"
                values={{
                  linebreak: <br />,
                  textGradient: (text: string) => <span className={sharedStyle.highlight}>{text}</span>,
                }}
              />
            </h1>
          </Reveal>
          <Reveal delay={500} duration={700} keyframes={fadeInUp(20)} triggerOnce>
            <div className={`${sharedStyle.content} ${style.content}`}>
              <FormattedMessage
                tagName="p"
                id="vIa3JB"
                description="[Home] energy efficent tech content"
                defaultMessage="Digits are the first Ubisoft NFTs, playable in a HD game and stored on a Proof-of-Stake (PoS) blockchain."
              />
              <FormattedMessage
                tagName="p"
                id="P0eTOM"
                description="[Home] energy efficent tech content"
                defaultMessage="Compared to the Proof-of-Work protocol used with Bitcoin, the PoS protocol offers a more advanced and environmentally sustainable alternative. PoS blockchains work on a consensus mechanism that does not rely on complex computational efforts to validate transactions and therefore does not incentivize extreme amounts of energy consumption."
                values={{ linebreak: <br /> }}
              />
              <FormattedMessage
                tagName="p"
                id="66x2wL"
                description="[Home] energy efficent tech content"
                defaultMessage="If you are curious about Proof-of-Stake, feel free to visit our <link>FAQs</link>."
                values={{ link: (text: string) => <Link to="/faq">{text}</Link> }}
              />
            </div>
          </Reveal>
        </div>
      </section>
    </>
  )
}

export default HowItWorksSection
