import { Link } from "react-router-dom"
import { FormattedMessage, IntlShape } from "react-intl"

import style from "../style.module.css"

const requirements = (intl: IntlShape) => [
  {
    id: "who-can-acquire-digits",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Who can acquire Digits?",
      id: "UPv/wA",
      description: "Faq requirements question 0",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="The Ubisoft Quartz platform is made for players, and for players only. For this reason, we will make sure that anyone who wants to acquire Digits is an active player of Tom Clancy’s Ghost Recon® Breakpoint under the following requirements:"
          id="bmkdo9"
          description="Faq requirements response 0 paragraph 1"
        />
        <FormattedMessage
          tagName="div"
          defaultMessage="<list><listitem>Play the game on Ubisoft Connect PC platform</listitem><listitem>Reach at least the XP Level 5 in the game</listitem><listitem>Have activated the 2-Factor Authentication for Ubisoft Connect</listitem><listitem>Be at least 18 years old</listitem><listitem>Be a resident of an <link>eligible territory</link></listitem><listitem>And be located in an <link>eligible territory</link></listitem></list>"
          id="ASTiKf"
          description="Faq requirements response 0 list"
          values={{
            list: (list: string) => <ul className={style.miniList}>{list}</ul>,
            listitem: (item: string) => <li className={style.listitem}>{item}</li>,
            link: (chunks: string) => (
              <Link to="/faq/requirements/in-which-territories-will-ubisoft-quartz-be-available">{chunks}</Link>
            ),
          }}
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="<b>Additional eligibility criteria</b> may be applicable to claim specific Editions. We will make them available to you, if applicable, on the Ubisoft Quartz platform."
          id="UEBKdo"
          description="Faq requirements response 0 paragraph 3"
          values={{ b: (chunks: string) => <strong>{chunks}</strong> }}
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="Please note that if you are not eligible, you will not be able to acquire Digits whether on the Ubisoft Quartz platform or an authorized third-party marketplace, nor have another player transfer their Digits to you."
          id="iDb/yV"
          description="Faq requirements response 0 paragraph 4"
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="For more information about the full list of criteria to be eligible to connect to the Ubisoft Quartz platform and/or acquire Digits, please read the <link>Ubisoft Quartz Terms of Use</link>. "
          id="SEc39E"
          description="Faq requirements response 0 paragraph 5"
          values={{
            link: (chunks: string) => (
              <a href="https://legal.ubi.com/ubisoftquartzterms" target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
          }}
        />
      </>
    ),
    popular: true,
    home: true,
  },
  {
    id: "is-there-a-minimum-age-requirement-to-acquire-digits",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Is there a minimum age requirement to acquire Digits?",
      id: "DDCQ9U",
      description: "Faq requirements question 1",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Yes, to be allowed to acquire a Digit, you must be at least 18 years old."
          id="tLVpB1"
          description="Faq requirements response 1 paragraph 1"
        />
      </>
    ),
  },
  {
    id: "in-which-territories-will-ubisoft-quartz-be-available",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "In which territories will Ubisoft Quartz be available?",
      id: "d3g3iy",
      description: "Faq requirements question 2",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Here is the list of the territories and regions in which Digits can be acquired. The place of residence is the one indicated in your Ubisoft Connect account information."
          id="BCRzUe"
          description="Faq requirements response 2 paragraph 1"
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="Eligible regions:"
          id="iI5XVc"
          description="Faq requirements response 2 paragraph 2"
        />
        <FormattedMessage
          tagName="div"
          defaultMessage="<list><listitem>USA</listitem><listitem>Canada</listitem><listitem>France</listitem><listitem>Germany</listitem><listitem>Belgium</listitem><listitem>Spain</listitem><listitem>Italy</listitem><listitem>Australia</listitem><listitem>Brazil</listitem></list>"
          id="BMtXh/"
          description="Faq requirements response 2 list"
          values={{
            list: (list: string) => <ul className={style.miniList}>{list}</ul>,
            listitem: (item: string) => <li className={style.listitem}>{item}</li>,
          }}
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="Note as well that the Ubisoft Quartz website will not be accessible from territories that are not listed above."
          id="BSOG1h"
          description="Faq requirements response 2 paragraph 4"
        />
      </>
    ),
  },
  {
    id: "how-many-digits-of-a-same-edition-can-i-acquire",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "How many Digits of a same Edition can I acquire?",
      id: "ayE/xs",
      description: "Faq requirements question 3",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="We want to make sure as many of our players can own a Digit and that they are first and foremost used for their intended gaming purpose. That’s why we have decided to limit to 1 the number of Digits of a single Edition that a player can own at any time. This means that every transaction that would add a second Digit from the same Edition to that player’s crypto-wallet will be automatically rejected."
          id="r02H3f"
          description="Faq requirements response 3 paragraph 1"
        />
      </>
    ),
  },
  {
    id: "can-i-still-acquire-digits-if-i-get-banned",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Can I still acquire Digits if I get banned?",
      id: "uB6sNI",
      description: "Faq requirements question 4",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="No, players currently banned at the game or at the account level will not be eligible to acquire Digits. In the case of a temporary ban, the player could become eligible at the end of the current banning period, should other requirements be met. If you get banned after having acquired a Digit, you will keep it and you can put it on sale as well."
          id="Wk0+Az"
          description="Faq requirements response 4 paragraph 1"
        />
      </>
    ),
  },
]

export default requirements
