import { useEffect, useMemo, useState } from "react"
import graphql from "babel-plugin-relay/macro"
import { useQuery } from "relay-hooks"
import { useNavigate, useParams } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import { MyTokenQuery } from "./__generated__/MyTokenQuery.graphql"
import { MyTokenInventoryQuery } from "./__generated__/MyTokenInventoryQuery.graphql"

import usePendingTokens from "../../hooks/usePendingTokens"
import PageLayout from "../../components/PageLayout"
import PageHeader from "../../components/PageHeader"
import FullPageError from "../../components/FullPageError"
import ProductCard from "../../components/Product/ProductCard"
import ipfsToHttpGateway from "../../utils/ipfsToHttpGateway"
import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import { getPendingTokens } from "../../utils/pendingTokensStorage"
import { buildCustomParameter } from "../../utils/analytics"
import TrackPage from "../../components/TrackPage"
import Video from "../../components/Video"
import TokenDetails, { TokenDetailsLoading } from "./components/TokenDetails"
import ProductTitle from "../../components/Product/ProductTitle"

import StickerUbi from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/sticker.png"

import style from "./style.module.css"

const myTokenQuery = graphql`
  query MyTokenQuery($tokenID: ID!) {
    token(id: $tokenID) {
      serialNumber
      video
      archetype {
        maxInstances
        primaryVariant {
          name
          image
          categorization {
            game {
              name
            }
          }
        }
      }
      ...TokenDetails_token
    }
  }
`

const inventoryQuery = graphql`
  query MyTokenInventoryQuery {
    currentPlayer {
      inventory {
        id
      }
      reserved {
        id
      }
    }
  }
`

const MyToken = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const { user, isLoading: userLoading } = useUbisoftWebAuthSessionContext()
  const { tokenID } = useParams<{ tokenID: string }>() as { tokenID: string }
  const { data, isLoading, error } = useQuery<MyTokenQuery>(myTokenQuery, { tokenID })
  const { data: playerData, isLoading: isInventoryLoading } = useQuery<MyTokenInventoryQuery>(
    inventoryQuery,
    {},
    { skip: !user.ticket }
  )
  const tokensIDs = useMemo(() => playerData?.currentPlayer.inventory.map(({ id }) => id), [playerData])
  const pendingTokens = usePendingTokens(tokensIDs)
  const [isPending, setIsPending] = useState(false)

  useEffect(() => {
    if (isInventoryLoading || isLoading || pendingTokens.isLoading || userLoading) return

    if (
      getPendingTokens(user.userID).includes(tokenID) ||
      playerData?.currentPlayer.reserved.some((token) => token.id === tokenID)
    ) {
      setIsPending(true)
    }
  }, [playerData, isInventoryLoading, isLoading, tokenID, navigate, pendingTokens, user, userLoading])

  const trackingCustomParameter = useMemo(() => {
    if (!data) return undefined

    return buildCustomParameter(
      data.token.archetype.primaryVariant.categorization?.game?.name || "",
      data.token.archetype.primaryVariant.name,
      data.token.serialNumber,
      data.token.archetype.maxInstances,
      0 /* TODO Get price from transfer history */,
      "",
      true /* TODO Set value according to price */
    )
  }, [data])

  if (isLoading || isInventoryLoading || pendingTokens.isLoading) {
    return (
      <PageLayout header={isMobile && <PageHeader title="" />} bodyClassName={style.bodyLayout}>
        {!isMobile && <div className={style.imageLoading} />}
        {isMobile ? (
          <>
            <div className={style.productCardLoading}>
              <div className={style.imageLoading} />
              <div className={style.descriptionLoading}>
                <div className={style.nameLoading} />
                <div className={style.gameTitleLoading} />
                <div className={style.infoLoading} />
              </div>
            </div>
            <TokenDetailsLoading />
          </>
        ) : (
          <div>
            <div className={`${style.descriptionLoading} ${style.card}`}>
              <div className={style.nameLoading} />
              <div className={style.gameTitleLoading} />
              <div className={style.infoLoading} />
            </div>
            <TokenDetailsLoading />
          </div>
        )}
      </PageLayout>
    )
  }

  if (error || !data) {
    return (
      <FullPageError
        headerTitle={intl.formatMessage({ defaultMessage: "Error", id: "D7Dner", description: "Error page title" })}
        errorTitle={intl.formatMessage({
          defaultMessage: "Digit not found",
          id: "FgotfJ",
          description: "[Product Page] Error message",
        })}
        buttonLabel={intl.formatMessage({
          defaultMessage: "Back",
          id: "2t2oBC",
          description: "Go back button",
        })}
      />
    )
  }

  return (
    <>
      <TrackPage siteSection="wallet" pageName="product page" customParameter={trackingCustomParameter} />
      <PageLayout
        header={
          isMobile && (
            <PageHeader
              title={intl.formatMessage(
                {
                  defaultMessage: "{variant} #{serialNumber}<b>/{count}</b>",
                  id: "8mMk+b",
                  description: "MyToken Page title",
                },
                {
                  variant: data.token.archetype.primaryVariant.name,
                  serialNumber: data.token.serialNumber,
                  count: data.token.archetype.maxInstances,
                  b: (chunks) => <span className={style.count}>{chunks}</span>,
                }
              )}
            />
          )
        }
        bodyClassName={style.bodyLayout}
      >
        {isMobile ? (
          <>
            <ProductCard
              serialNumber={data.token.serialNumber}
              variantName={data.token.archetype.primaryVariant.name || ""}
              game={data.token.archetype.primaryVariant.categorization?.game?.name || ""}
              image={data.token.archetype.primaryVariant.image || ""}
              video={data.token.video || ""}
              maxInstances={data.token.archetype.maxInstances!}
              isPending={isPending}
              hidePrice
              hideEditButton
              showControls
              className={style.productCard}
            />
            <TokenDetails token={data.token} />
          </>
        ) : (
          <>
            {data.token.video ? (
              <Video
                video={data.token.video}
                image={data.token.archetype.primaryVariant.image}
                alt={data.token.archetype.primaryVariant.name}
                className={style.thumbnail}
                showControls
              />
            ) : (
              <img
                className={style.thumbnail}
                src={ipfsToHttpGateway(data.token.archetype.primaryVariant.image)}
                alt={data.token.archetype.primaryVariant.name}
                data-testid="product-image"
              />
            )}
            <div>
              <div className={style.description}>
                <ProductTitle
                  name={data.token.archetype.primaryVariant.name}
                  gameTitle={data.token.archetype.primaryVariant.categorization?.game?.name || ""}
                  processingTag={isPending}
                />
                <div className={style.editionDetails}>
                  <img src={StickerUbi} alt="" aria-hidden className={style.sticker} />
                  <div>
                    <div className={`caption ${style.caption}`}>
                      <FormattedMessage
                        defaultMessage="{count, plural, =0 {Limited Edition} one {Unique Digit} other {Limited Edition: <b>#</b>}}"
                        id="gf/mRE"
                        description="[Product Card] Edition count"
                        values={{
                          count: data.token.archetype.maxInstances,
                          b: (chunks: string) => <strong>{chunks}</strong>,
                        }}
                      />
                    </div>
                    <div className="subtitle">
                      <div>
                        {`#${data.token.serialNumber}`}
                        <span className={style.maxInstances}>{`/${data.token.archetype.maxInstances}`}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <TokenDetails token={data.token} />
            </div>
          </>
        )}
      </PageLayout>
    </>
  )
}

export default MyToken
