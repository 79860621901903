import Tag from "../Tag"

import { ReactComponent as LogoImage } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/ubisoftQuartzLogo.svg"

import style from "./style.module.css"

export interface Props {
  className?: string
}

const Logo = ({ className }: Props) => {
  return (
    <div className={className && className}>
      <LogoImage aria-hidden />
      <Tag color="primaryGradient" className={style.beta}>
        beta
      </Tag>
    </div>
  )
}

export default Logo
