import { ButtonHTMLAttributes, DetailedHTMLProps, ForwardedRef, forwardRef, ReactNode } from "react"

import style from "./style.module.css"

interface Props extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: ReactNode
}

const IconButton = ({ children, className, ...props }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  return (
    <button ref={ref} className={`${style.container} ${className}`} {...props}>
      {children}
    </button>
  )
}

export default forwardRef(IconButton)
