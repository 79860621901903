import { useMemo } from "react"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import { Link, useLocation, useParams } from "react-router-dom"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import makeStyles from "@mui/styles/makeStyles"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import PageLayout from "../../../../components/PageLayout"
import PageHeader from "../../../../components/PageHeader"
import RainbowTitle from "../../../../components/RainbowTitle"
import Question from "../Question"
import { Category } from "../../utils/getCategories"
import NotFound from "../../../NotFound"
import TrackPage from "../../../../components/TrackPage"

import { ReactComponent as ChevronRight } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/chevronRight.svg"

import style from "./style.module.css"

interface Props {
  categories: Category[]
  onClickQuestion: (location: string, categoryId: string, questionId: string) => void
}

const useStyles = makeStyles({
  root: {
    fontFamily: "Source sans pro, sans-serif",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    color: "var(--grey-caption)",
    marginBottom: "1rem",
    "& > * + *": {
      marginTop: "0.5rem",
    },
    "& a": {
      color: "var(--grey-caption)",
    },
  },
  separator: {
    color: "var(--primary)",
    "& svg": {
      width: "0.75rem",
      height: "0.75rem",
    },
  },
})

const Answer = ({ categories, onClickQuestion }: Props) => {
  const intl = useIntl()
  const theme = useTheme()
  const { pathname } = useLocation()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const classes = useStyles()
  const { categoryId, questionId } = useParams<{ categoryId: string; questionId: string }>()
  const category = useMemo(() => categories.find((category) => category.id === categoryId), [categories, categoryId])
  const answer = useMemo(() => category?.content.find((answer) => answer.id === questionId), [category, questionId])

  if (!category || !answer) {
    return <NotFound />
  }

  return (
    <>
      <TrackPage siteSection="faq" siteSubSection={category.name} pageName={answer.question.replace(/ ?\?/, "")} />
      <PageLayout
        header={
          isMobile && (
            <PageHeader
              title={intl.formatMessage({
                defaultMessage: "FAQ",
                id: "rbUzNa",
                description: "[FAQ] page header title",
              })}
            />
          )
        }
        bodyClassName={style.container}
      >
        <div className={style.content}>
          <Breadcrumbs separator={<ChevronRight />} classes={classes}>
            <Link to={{ pathname: "/faq" }}>FAQ</Link>
            <Link to={{ pathname: "/faq", search: `?categoryId=${category.id}` }}>{category.name}</Link>
            <span>{answer.question}</span>
          </Breadcrumbs>
          <RainbowTitle text={answer.question} />
          <span className={`${style.date} caption`}>
            <FormattedMessage
              defaultMessage="Last updated: {date}"
              id="JPhkaa"
              description="[FAQ] question update date"
              values={{ date: <FormattedDate value={answer.date} day="2-digit" month="long" year="numeric" /> }}
            />
          </span>
          <div className={style.answerContent}>{answer.response}</div>
        </div>
        <div className={style.related}>
          <RainbowTitle
            text={intl.formatMessage({
              defaultMessage: "Related Questions",
              id: "QQa2IH",
              description: "[FAQ] related questions",
            })}
          />
          <ul>
            {category.content.map(
              (related) =>
                related.id !== questionId && (
                  <li key={related.id}>
                    {isMobile ? (
                      <div
                        className={style.question}
                        onClick={() => onClickQuestion(pathname, category.id, related.id)}
                      >
                        <span className="subtitle">{related.question}</span>
                        <ChevronRight className={style.icon} aria-hidden />
                      </div>
                    ) : (
                      <Question
                        id={related.id}
                        question={related.question}
                        response={related.response}
                        categoryId={category.id}
                        onClick={onClickQuestion}
                      />
                    )}
                  </li>
                )
            )}
          </ul>
        </div>
      </PageLayout>
    </>
  )
}

export default Answer
