interface UserInfo {
  eligibility: {
    ageCriteria: boolean
    registeredCountryCriteria: boolean
    gameLevelCriteria: boolean
    exception: boolean
  }
  epicOrLunaState: "unknown" | "unplayed" | "played"
}

const isUserEligible = (userInfo: UserInfo, ipLocationAllowed: boolean) => {
  return (
    ((userInfo.eligibility.ageCriteria &&
      userInfo.eligibility.registeredCountryCriteria &&
      ipLocationAllowed &&
      userInfo.eligibility.gameLevelCriteria) ||
      userInfo.eligibility.exception) &&
    userInfo.epicOrLunaState !== "played"
  )
}

export default isUserEligible
