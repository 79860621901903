import { FormattedMessage, FormattedNumber } from "react-intl"
import { useFragment } from "react-relay"
import graphql from "babel-plugin-relay/macro"

import { PriceSectionFragment_sale$key } from "./__generated__/PriceSectionFragment_sale.graphql"

import isFeatureEnabled from "../../../../utils/isFeatureEnabled"
import ClaimStatusSectionFragment from "../../../../components/ClaimStatusSection/ClaimStatusSectionFragment"
import Button from "../../../../components/Button"
import { ClaimStatus } from "../../../../hooks/useClaimStatus"

import { ReactComponent as ArrowRightIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"

import style from "./style.module.css"

interface PriceSectionProps {
  sale: PriceSectionFragment_sale$key
  claimStatus:
    | ClaimStatus.ALREADY_CLAIMED
    | ClaimStatus.CONDITION_NOT_MET
    | ClaimStatus.CAN_CLAIM
    | ClaimStatus.CLAIM_NOT_STARTED
    | ClaimStatus.CLAIM_ENDED
  date: Date
  soldOut: boolean
  selectDigit: () => void
}

const PriceSection = ({ sale, claimStatus, soldOut, selectDigit }: PriceSectionProps) => {
  const saleData = useFragment(
    graphql`
      fragment PriceSectionFragment_sale on Sale {
        price
        ...ClaimStatusSectionFragment_sale
      }
    `,
    sale
  )

  const isFreeDrop = saleData.price === 0

  // TODO This is not related and should be moved out of the PriceSection component
  if (isFreeDrop) {
    return (
      <div className={style.card}>
        <ClaimStatusSectionFragment sale={saleData} claimStatus={claimStatus} />
      </div>
    )
  }

  return (
    <div className={style.card}>
      <div className={style.priceWrapper}>
        <h3 className={style.price}>
          {!soldOut ? <FormattedNumber value={saleData.price} /> : "-"} <span className={style.regular}>EURL</span>
        </h3>
        {/* TODO: handle FIAT price */}
        {isFeatureEnabled("euroValue") && <span>{!soldOut ? "X.XX€" : "- €"}</span>}
      </div>
      {!soldOut ? (
        <Button invert icon={<ArrowRightIcon />} onClick={selectDigit}>
          <FormattedMessage
            defaultMessage="Select Digit"
            id="AtOsyi"
            description="[Product Page] serial selection button label"
          />
        </Button>
      ) : (
        <div className={style.soldout}>
          <FormattedMessage defaultMessage="Sold Out" id="RaokrY" description="[Product Page] sold out button label" />
        </div>
      )}
    </div>
  )
}

export default PriceSection
