import Reveal from "react-awesome-reveal"
import { FormattedMessage } from "react-intl"

import fadeInLeft from "../../../../utils/animations/fade/fadeInLeft"
import fadeInRight from "../../../../utils/animations/fade/fadeInRight"

import mobileScreensImage from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/mobile-screens.png"

import style from "./style.module.css"
import sharedStyle from "../../style.module.css"

const CTASection = () => {
  return (
    <>
      <section className={sharedStyle.section} id={style.section6}>
        <div>
          <Reveal cascade duration={700} keyframes={fadeInLeft(20)} triggerOnce>
            <h1 className={sharedStyle.title}>
              <FormattedMessage
                id="5kCsBg"
                description="[Home] section title"
                defaultMessage="This is just{linebreak}the beginning…"
                values={{ linebreak: <br /> }}
              />
            </h1>
          </Reveal>
        </div>
        <Reveal delay={500} duration={700} keyframes={fadeInRight(20)} triggerOnce>
          <img src={mobileScreensImage} alt="mobile-preview" />
        </Reveal>
      </section>
    </>
  )
}

export default CTASection
