import { IntlShape, useIntl } from "react-intl"

import { extractErrorCode, TransactionErrorCode } from "../../../../api/errors"
import ConfirmDialog from "../../../ConfirmDialog"
import TrackImpression from "../../../TrackImpression"
import { ErrorDetail } from "../../../TokenPurchase/TransactionErrorPage"

import { ReactComponent as WalletIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/walletStroke.svg"

// TODO: populate with other errors
const buildErrorContent = (intl: IntlShape, code: string) => {
  const errorMap: Partial<Record<TransactionErrorCode, ErrorDetail>> = {
    [TransactionErrorCode.walletAlreadyAssociated]: {
      trackingLocationDetail: "wallet associated with another account error",
      title: intl.formatMessage({
        defaultMessage: "Crypto-wallet associated with another account",
        id: "abGb95",
        description: "[Connect Wallet] association error title",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "This crypto-wallet is already associated with another Ubisoft account. Please select another crypto-wallet.",
        id: "1eRxlT",
        description: "[Connect Wallet] association error description",
      }),
    },
    [TransactionErrorCode.twoFADisabled]: {
      trackingLocationDetail: "2FA verification error",
      title: intl.formatMessage({
        defaultMessage: "An error occurred with your 2FA verification",
        id: "t5wG0P",
        description: "[Connect Wallet] 2FA error title",
      }),
      description: intl.formatMessage(
        {
          defaultMessage:
            "Please log out and log back in from your Ubisoft account.{br} Then retry to set-up a crypto-wallet.",
          id: "8jkcVU",
          description: "[Connect Wallet] 2FA error description",
        },
        { br: <br /> }
      ),
    },
    [TransactionErrorCode.twoFAUnused]: {
      trackingLocationDetail: "2FA verification error",
      title: intl.formatMessage({
        defaultMessage: "An error occurred with your 2FA verification",
        id: "t5wG0P",
        description: "[Connect Wallet] 2FA error title",
      }),
      description: intl.formatMessage(
        {
          defaultMessage:
            "Please log out and log back in from your Ubisoft account.{br} Then retry to set-up a crypto-wallet.",
          id: "8jkcVU",
          description: "[Connect Wallet] 2FA error description",
        },
        { br: <br /> }
      ),
    },
    [TransactionErrorCode.playerHasAWallet]: {
      trackingLocationDetail: "wallet already setup error",
      title: intl.formatMessage({
        defaultMessage: "Crypto-wallet already set-up",
        id: "e2AnEJ",
        description: "[Connect Wallet] Player has a wallet error title",
      }),
      description: intl.formatMessage(
        {
          defaultMessage: "It seems a crypto-wallet has already been set-up.{br} Please try to refresh the page.",
          id: "ucrGGA",
          description: "[Connect Wallet] Player has a wallet error description",
        },
        { br: <br /> }
      ),
    },
  }

  return (
    errorMap[code as TransactionErrorCode] || {
      trackingLocationDetail: "internal error",
      title: intl.formatMessage({
        defaultMessage: "Internal error",
        id: "Ms7RaD",
        description: "[Connect Wallet] error title",
      }),
      description: intl.formatMessage({
        defaultMessage:
          "Sorry! Something seems to have gone wrong. Please try again! If the issue persists, please contact Ubisoft customer support.",
        id: "MNu7Tc",
        description: "[Connect Wallet] error description",
      }),
    }
  )
}

interface Props {
  open: boolean
  onClose: () => void
  error: Error
}

const AssociateErrorDialog = ({ open, onClose, error }: Props) => {
  const intl = useIntl()
  const errCode = extractErrorCode(error)
  const errorContent = buildErrorContent(intl, errCode)

  return (
    <>
      {open && <TrackImpression locationDetail={errorContent.trackingLocationDetail} />}
      <ConfirmDialog
        open={open}
        onClose={onClose}
        icon={<WalletIcon />}
        title={errorContent.title}
        content={errorContent.description}
        cancelLabel={intl.formatMessage({
          defaultMessage: "Close",
          id: "90rePX",
          description: "[Connect Wallet] Close dialog button label",
        })}
      />
    </>
  )
}

export default AssociateErrorDialog
