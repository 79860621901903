import { FormattedMessage } from "react-intl"
import Divider from "@mui/material/Divider"
import makeStyles from "@mui/styles/makeStyles"
import clsx from "clsx"

import { ReactComponent as CheckCircle } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/checkCircle.svg"

import style from "./style.module.css"

export type Achievement = {
  level: number
  description: string
}

export interface Props {
  game: string
  achievements: Achievement[]
  playerLevel: number
  className: string
  isLoading: boolean
}

const useStyles = makeStyles({
  dividerInset: {
    marginLeft: "4rem",
  },
})

const ProfileAchievement = ({ game, achievements, playerLevel, className = "", isLoading = false }: Props) => {
  const classes = useStyles()

  return (
    <div className={`${style.container} ${className}`}>
      <div className={style.gameContainer}>
        <h4 className={style.game}>{game}</h4>
        {isLoading ? (
          <div className={style.levelLoading} />
        ) : (
          <span className={style.playerLevel}>
            <FormattedMessage
              id="6coMTU"
              defaultMessage="XP Level {level}"
              description="[Profile] Level of the user"
              values={{ level: playerLevel }}
            />
          </span>
        )}
      </div>
      <ul>
        {achievements.map(({ level, description }, index) => (
          <li key={level} className={style.achievement}>
            <div className={style.wrapper}>
              {isLoading ? (
                <div className={clsx(style.progression, style.progressionLoading)} />
              ) : (
                <div className={style.progression}>
                  {playerLevel >= level ? (
                    <>
                      <CheckCircle className={style.check} />
                      {index !== 0 && <span className={clsx(style.connectorTop, style.connectorCheck)}></span>}
                      {index !== achievements.length - 1 && (
                        <span className={clsx(style.connectorBottom, style.connectorCheck)}></span>
                      )}
                    </>
                  ) : (
                    <>
                      <div className={style.circle}></div>
                      {index !== 0 && <span className={clsx(style.connectorTop, style.connectorUncheck)}></span>}
                      {index !== achievements.length - 1 && (
                        <span className={clsx(style.connectorBottom, style.connectorUncheck)}></span>
                      )}
                    </>
                  )}
                </div>
              )}
              <div
                className={clsx(style.achievementInfo, {
                  [style.disabled]: playerLevel < level,
                })}
              >
                <span className={style.level}>
                  <FormattedMessage
                    id="vZWmJF"
                    defaultMessage="XP Level {level}"
                    description="[Profile] Achievement level"
                    values={{ level }}
                  />
                </span>
                <span className={style.description}>{description}</span>
              </div>
            </div>
            {index < achievements.length - 1 && <Divider variant="inset" className={classes.dividerInset} />}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ProfileAchievement
