/**
 * @generated SignedSource<<b35257600cb585829a397d7867119363>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TokenClaimFragment_sale$data = {
  readonly id: string;
  readonly endDate: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ClaimSectionFragment_sale" | "ProductCardFragment_sale" | "useTrackingCustomParameter_sale">;
  readonly " $fragmentType": "TokenClaimFragment_sale";
};
export type TokenClaimFragment_sale$key = {
  readonly " $data"?: TokenClaimFragment_sale$data;
  readonly " $fragmentSpreads": FragmentRefs<"TokenClaimFragment_sale">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./TokenClaimRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "TokenClaimFragment_sale",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClaimSectionFragment_sale"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductCardFragment_sale"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTrackingCustomParameter_sale"
    }
  ],
  "type": "Sale",
  "abstractKey": null
};

(node as any).hash = "9ab149ed97e1fa259d916ea626d29ecd";

export default node;
