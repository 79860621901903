/**
 * @generated SignedSource<<fd15a7ea6767d7210cc146f6a379a485>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useCanPurchaseInventoryQuery$variables = {};
export type useCanPurchaseInventoryQuery$data = {
  readonly currentPlayer: {
    readonly inventory: ReadonlyArray<{
      readonly archetype: {
        readonly id: string;
      };
    }>;
  };
};
export type useCanPurchaseInventoryQuery = {
  variables: useCanPurchaseInventoryQuery$variables;
  response: useCanPurchaseInventoryQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Archetype",
  "kind": "LinkedField",
  "name": "archetype",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCanPurchaseInventoryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Token",
            "kind": "LinkedField",
            "name": "inventory",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useCanPurchaseInventoryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Token",
            "kind": "LinkedField",
            "name": "inventory",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00de807c62a05eb3b3631d8851b9a49a",
    "id": null,
    "metadata": {},
    "name": "useCanPurchaseInventoryQuery",
    "operationKind": "query",
    "text": "query useCanPurchaseInventoryQuery {\n  currentPlayer {\n    inventory {\n      archetype {\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa441811a61ba9a6541f5438ca9fbb84";

export default node;
