/**
 * @generated SignedSource<<a0c3cf16e093741053c134cea66dfc95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignMessageAssociateWalletMutation$variables = {
  signature: string;
  address: string;
};
export type SignMessageAssociateWalletMutation$data = {
  readonly associateWallet: boolean;
};
export type SignMessageAssociateWalletMutation = {
  variables: SignMessageAssociateWalletMutation$variables;
  response: SignMessageAssociateWalletMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "signature"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "address",
        "variableName": "address"
      },
      {
        "kind": "Variable",
        "name": "signature",
        "variableName": "signature"
      }
    ],
    "kind": "ScalarField",
    "name": "associateWallet",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignMessageAssociateWalletMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SignMessageAssociateWalletMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d71779cb471ca0b18ea3e4ecdc1b212a",
    "id": null,
    "metadata": {},
    "name": "SignMessageAssociateWalletMutation",
    "operationKind": "mutation",
    "text": "mutation SignMessageAssociateWalletMutation(\n  $signature: String!\n  $address: String!\n) {\n  associateWallet(signature: $signature, address: $address)\n}\n"
  }
};
})();

(node as any).hash = "3eeddb6c779a6725d9ae724575624fcd";

export default node;
