/**
 * @generated SignedSource<<98953c9b16e6b73ca10c60cb843e33a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserInfoProviderQuery$variables = {};
export type UserInfoProviderQuery$data = {
  readonly currentPlayer: {
    readonly id: string;
    readonly acceptedTerms: string | null;
    readonly walletAddress: string | null;
    readonly eligibility: {
      readonly acceptedTermsCriteria: boolean;
      readonly walletCriteria: boolean;
      readonly ageCriteria: boolean;
      readonly registeredCountryCriteria: boolean;
      readonly gameLevelCriteria: boolean;
      readonly platformCriteria: boolean;
      readonly exception: boolean;
    };
  };
};
export type UserInfoProviderQuery = {
  variables: UserInfoProviderQuery$variables;
  response: UserInfoProviderQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Player",
    "kind": "LinkedField",
    "name": "currentPlayer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acceptedTerms",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "walletAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Eligibility",
        "kind": "LinkedField",
        "name": "eligibility",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "acceptedTermsCriteria",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "walletCriteria",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ageCriteria",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registeredCountryCriteria",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gameLevelCriteria",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "platformCriteria",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "exception",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserInfoProviderQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserInfoProviderQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "63b9fb75e96d0f64df659daa47727250",
    "id": null,
    "metadata": {},
    "name": "UserInfoProviderQuery",
    "operationKind": "query",
    "text": "query UserInfoProviderQuery {\n  currentPlayer {\n    id\n    acceptedTerms\n    walletAddress\n    eligibility {\n      acceptedTermsCriteria\n      walletCriteria\n      ageCriteria\n      registeredCountryCriteria\n      gameLevelCriteria\n      platformCriteria\n      exception\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "df58d7e71799f53a72b9f81088cf7c6e";

export default node;
