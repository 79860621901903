interface Settings {
  termsVersion: string
}

const settings: Record<string, Settings> = {
  development: {
    termsVersion: "0.1.0",
  },
  production: {
    termsVersion: "0.1.0",
  },
}

export default settings[process.env.NODE_ENV]
