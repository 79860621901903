import { useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import PageHeader from "../PageHeader"
import PageLayout from "../PageLayout"
import RainbowTitle from "../RainbowTitle"
import CardButton from "../CardButton"
import Button from "../Button"
import config from "../../config/ubisoftServicesConfig"

import rabbids from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/rabbidsGamepadFight.png"
import { ReactComponent as Close } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/close.svg"
import { ReactComponent as Warning } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/warningStroke.svg"
import { ReactComponent as ArrowRight } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"

import style from "./style.module.css"

const LocalisationCriterion = () => {
  const intl = useIntl()

  return (
    <CardButton
      iconLeft={<Close className={style.danger} />}
      title={intl.formatMessage({
        defaultMessage: "Region",
        id: "SYCQtZ",
        description: "[Eligibility Criteria] title",
      })}
      description={intl.formatMessage({
        defaultMessage: "Eligible regions only",
        id: "uuaBJY",
        description: "[Eligibility Criteria] description",
      })}
    />
  )
}

const AgeCriterion = () => {
  const intl = useIntl()

  return (
    <CardButton
      iconLeft={<Close className={style.danger} />}
      title={intl.formatMessage({
        defaultMessage: "Minimum Age",
        id: "/2GyBY",
        description: "[Eligibility Criteria] title",
      })}
      description={intl.formatMessage({
        defaultMessage: "Players under 18 are not eligible",
        id: "C+T9lU",
        description: "[Eligibility Criteria] description",
      })}
    />
  )
}

const LevelCriterion = () => {
  const intl = useIntl()

  return (
    <CardButton
      iconLeft={<Warning className={style.warning} />}
      title={intl.formatMessage({
        defaultMessage: "Platform and Level",
        id: "0O3LRb",
        description: "[Eligibility Criteria] title",
      })}
      description={intl.formatMessage({
        defaultMessage: "You need to play Ghost Recon Breakpoint on Ubisoft Connect PC and reach XP Level 5",
        id: "mPrsNF",
        description: "[Eligibility Criteria] description",
      })}
      to="/faq/requirements/who-can-acquire-digits"
    />
  )
}

const TwoFactorsCriterion = () => {
  const intl = useIntl()

  return (
    <CardButton
      iconLeft={<Warning className={style.warning} />}
      title={intl.formatMessage({
        defaultMessage: "2-Step verification",
        id: "RBd2a3",
        description: "[Eligibility Criteria] title",
      })}
      description={intl.formatMessage({
        defaultMessage: "2-Step verification must be activated",
        id: "L6+h0D",
        description: "[Eligibility Criteria] description",
      })}
      href={`${config.accountURL}/security-settings`}
      target="_blank"
    />
  )
}

type Criteria = "localisation" | "age" | "level" | "2fa"

export interface Props {
  headerTitle: string
  bodyTitle: string
  bodyDescription: string
  criteriaMap: Record<Criteria, boolean>
}

const EligibilityRecap = ({ headerTitle, bodyTitle, bodyDescription, criteriaMap }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { checkTwoFAStatus } = useUbisoftWebAuthSessionContext()

  useEffect(() => {
    checkTwoFAStatus()
  }, [checkTwoFAStatus])

  return (
    <PageLayout
      header={isMobile && <PageHeader title={headerTitle} className={style.header} />}
      bodyClassName={style.container}
      bodyPosition="right"
    >
      <img src={rabbids} alt="" aria-hidden />
      <RainbowTitle text={bodyTitle} className={style.title} />
      <p className="paragraph">{bodyDescription}</p>
      <ul className={style.criteria}>
        {!criteriaMap.age && (
          <li>
            <AgeCriterion />
          </li>
        )}
        {!criteriaMap.localisation && (
          <li>
            <LocalisationCriterion />
          </li>
        )}
        {!criteriaMap["2fa"] && (
          <li>
            <TwoFactorsCriterion />
          </li>
        )}
        {!criteriaMap.level && (
          <li>
            <LevelCriterion />
          </li>
        )}
      </ul>
      <Button fullWidth icon={<ArrowRight />} to="/">
        <FormattedMessage defaultMessage="Go to Home" id="Px5jGZ" description="[Eligibility] Link to home label" />
      </Button>
    </PageLayout>
  )
}

export default EligibilityRecap
