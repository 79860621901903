/**
 * @generated SignedSource<<799157675f651b99e9a365c1fdd856f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductFragment_player$data = {
  readonly inventory: ReadonlyArray<{
    readonly id: string;
    readonly serialNumber: number;
    readonly archetype: {
      readonly id: string;
    };
  }>;
  readonly claims: ReadonlyArray<{
    readonly sale: {
      readonly id: string;
    };
  }>;
  readonly reserved: ReadonlyArray<{
    readonly archetype: {
      readonly id: string;
    };
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"useClaimStatus_player" | "ProductCardFragment_player">;
  readonly " $fragmentType": "ProductFragment_player";
};
export type ProductFragment_player$key = {
  readonly " $data"?: ProductFragment_player$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductFragment_player">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Archetype",
  "kind": "LinkedField",
  "name": "archetype",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductFragment_player",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Token",
      "kind": "LinkedField",
      "name": "inventory",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serialNumber",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Claim",
      "kind": "LinkedField",
      "name": "claims",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Sale",
          "kind": "LinkedField",
          "name": "sale",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Token",
      "kind": "LinkedField",
      "name": "reserved",
      "plural": true,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useClaimStatus_player"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductCardFragment_player"
    }
  ],
  "type": "Player",
  "abstractKey": null
};
})();

(node as any).hash = "fcf61d7c5793919abcdabb418e9a9298";

export default node;
