import { Link, To } from "react-router-dom"

import Tag from "../../../../components/Tag"

import style from "./style.module.css"

export interface Props {
  to?: To
  thumbnail: string
  serialNumber?: number
  name: string
  game: string
  tag?: string
  tagColor?: "warning" | "danger" | "primaryGradient"
}

const TokenCard = ({ to, thumbnail, serialNumber, name, game, tag, tagColor = "warning" }: Props) => {
  const content = (
    <div className={`${style.container} ${to ? style.interactive : ""}`}>
      {tag && (
        <Tag className={style.tag} color={tagColor}>
          {tag}
        </Tag>
      )}
      {serialNumber !== undefined && <span className={style.serialNumber}>#{serialNumber}</span>}
      <div className={style.thumbnail}>
        <img src={thumbnail} alt={name} />
      </div>
      <h4 className={style.name}>{name}</h4>
      <span className={style.game}>{game}</span>
    </div>
  )

  return to ? (
    <Link to={to} className={style.link}>
      {content}
    </Link>
  ) : (
    content
  )
}

export default TokenCard
