import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import addMinutes from "date-fns/addMinutes"

import useEmptyCard from "../../../../hooks/useEmptyCard"
import EmptySection from "../../../../components/EmptySection"
import PendingClaimCardFragment from "../TokenCard/PendingClaimCardFragment"
import TokenCardFragment from "../TokenCard/TokenCardFragment"
import InfoBox from "../../../../components/InfoBox"
import Countdown from "../../../../components/Countdown"
import { useUserInfoContext } from "../../../../providers/UserInfoProvider"

import { TokenCardFragment_token$key } from "../TokenCard/__generated__/TokenCardFragment_token.graphql"
import { PendingClaimCardFragment_claim$key } from "../TokenCard/__generated__/PendingClaimCardFragment_claim.graphql"

import rabbids from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/rabbidsHappyHead.png"

import style from "./style.module.css"

interface ClaimProps {
  claim: PendingClaimCardFragment_claim$key
  id: string
  name: string
  endDate: string | null
}

interface TokenProps {
  token: TokenCardFragment_token$key
  id: string
  pending?: boolean
}

export interface PendingProps {
  claim?: PendingClaimCardFragment_claim$key
  token?: TokenCardFragment_token$key
  id: string
  name?: string
  endDate?: string | null
  pending?: boolean
}

interface InventoryProps {
  items: {
    claimed: ClaimProps[]
    pending: PendingProps[] // Could be a claim or a token
    received: TokenProps[]
  }
  loading: boolean
}

const Inventory = ({ items, loading }: InventoryProps) => {
  const intl = useIntl()
  const location = useLocation()
  const emptyCard = useEmptyCard(items.received.length + items.claimed.length + items.pending.length || 0, loading)
  const [date, setDate] = useState(new Date())
  const { userInfo, isLoading } = useUserInfoContext()

  useEffect(() => {
    if (!items.claimed.length) return

    const firstDropDate = Date.parse(
      items.claimed.map((claim) => claim.endDate!).sort((a, b) => Date.parse(a) - Date.parse(b))[0]
    )
    const timer = setTimeout(() => {
      setDate(new Date())
    }, firstDropDate - addMinutes(new Date(), 1).getTime())
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.claimed])

  if (loading) {
    return (
      <ul className={style.inventory}>
        {[...Array(emptyCard)].map((e, index) => (
          <li className={style.itemLoading} key={index}></li>
        ))}
      </ul>
    )
  }

  if (
    (!items.received || items.received.length === 0) &&
    (!items.claimed || items.claimed.length === 0) &&
    (!items.pending || items.pending.length === 0)
  ) {
    return (
      <EmptySection
        title={
          <FormattedMessage
            id="5qWH5j"
            defaultMessage="Nothing here yet!"
            description="[Wallet] Message when no digits in wallet"
          />
        }
        buttonLabel={intl.formatMessage({
          defaultMessage: "Explore all Digits",
          id: "QiVBNh",
          description: "[Wallet] Empty inventory: Explore all Digits button label",
        })}
        to={{ pathname: "/" }}
        state={{ referrer: location }}
        img={rabbids}
        imgClassName={style.rabbids}
        className={style.imageContainer}
      />
    )
  }

  const pendingItemsWithName = items.pending.filter(({ name }) => name)

  return (
    <>
      {items.claimed.length > 0 && (
        <ul className={style.info}>
          {items.claimed.map((claim) => (
            <li key={claim.name}>
              <InfoBox>
                {claim.endDate && Date.parse(claim.endDate) > addMinutes(date, 1).getTime() ? (
                  <FormattedMessage
                    defaultMessage="Your Digit {variant} will become available in-game after the claim period ends in {date}."
                    id="IzEGau"
                    description="[Wallet] MyDigits claim message"
                    values={{
                      variant: <strong>{claim.name}</strong>,
                      date: <Countdown type="basic" endDate={claim.endDate} />,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Your Digit {variant} will become available in-game soon."
                    id="GHwFnV"
                    description="[Wallet] MyDigits claim message with ended date exceedeed"
                    values={{
                      variant: <strong>{claim.name}</strong>,
                    }}
                  />
                )}
              </InfoBox>
            </li>
          ))}
        </ul>
      )}
      {pendingItemsWithName.length > 0 && (
        <ul className={style.info}>
          {pendingItemsWithName.map(({ name }) => (
            <li key={name}>
              <InfoBox type="game">
                <FormattedMessage
                  defaultMessage="Your {variant} is currently being processed..."
                  id="tkZPZz"
                  description="[Inventory] MyDigits processing claim message"
                  values={{
                    variant: <strong>{name}</strong>,
                  }}
                />
              </InfoBox>
            </li>
          ))}
        </ul>
      )}

      <ul className={style.inventory}>
        {items.claimed &&
          items.claimed
            .map(({ claim, id }) => (
              <li key={id}>
                <PendingClaimCardFragment claim={claim} />
              </li>
            ))
            // TODO: remove reverse and sort by claim date when available
            .reverse()}
        {items.pending &&
          items.pending
            .map(({ token, claim, id, pending }) => {
              if (claim)
                return (
                  <li key={id}>
                    <PendingClaimCardFragment claim={claim} processing />
                  </li>
                )
              // TODO: should be checked when purchase feature will be back
              if (token)
                return (
                  <li key={id}>
                    <TokenCardFragment token={token} pending={pending} />
                  </li>
                )
              return null
            })
            // TODO: remove reverse and sort by claim date when available
            .reverse()}
        {items.received &&
          items.received
            .map(({ token, pending, id }) => (
              <li key={id}>
                <TokenCardFragment token={token} pending={pending} />
              </li>
            ))
            // TODO: remove reverse and sort by purchase date when available
            .reverse()}
        {[...Array(emptyCard)].map((e, index) => (
          <li className="emptyCard" key={index}></li>
        ))}
      </ul>
      {!isLoading && userInfo.epicOrLunaState !== "unplayed" && (
        <InfoBox type="warning" className={style.info}>
          <FormattedMessage
            defaultMessage="<b>Note <u>that only Ubisoft Connect</u> PC players can play with Digits.</b>"
            id="miFvnl"
            description="[Inventory] Epic/Luna restriction message"
            values={{
              b: (chunks: string) => <strong>{chunks}</strong>,
              u: (chunks: string) => <u>{chunks}</u>,
              br: <br />,
            }}
          />
        </InfoBox>
      )}
    </>
  )
}

export default Inventory
