import { FormattedMessage } from "react-intl"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { Link } from "react-router-dom"

import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import Button from "../Button"
import Logo from "../Logo"

import { ReactComponent as ArrowRightIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"
import nomad from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/nomadOpacityReduced.png"

import style from "./style.module.css"

const DashboardHero = () => {
  const { user, login } = useUbisoftWebAuthSessionContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <div className={style.container}>
      {isMobile && (
        <Link to="/">
          <Logo className={style.logo} aria-hidden />
        </Link>
      )}
      <h1>
        <FormattedMessage
          defaultMessage="<b>Be unique.</b>{br}Control. <c>Play.</c>"
          id="8CFtRd"
          description="[Dashboard] Page hero title"
          values={{
            b: (chunks: string) => <strong>{chunks}</strong>,
            c: (chunks: string) => <strong className={style.play}>{chunks}</strong>,
            br: <br />,
          }}
        />
      </h1>
      {!user.ticket && (
        <Button invert icon={<ArrowRightIcon aria-hidden />} className={style.cta} onClick={login}>
          <FormattedMessage
            tagName="span"
            id="b/ZFLz"
            defaultMessage="Log in"
            description="[Top Nav] Login button label"
          />
        </Button>
      )}
      {!isMobile && <img src={nomad} alt="" aria-hidden className={style.nomad} />}
    </div>
  )
}

export default DashboardHero
