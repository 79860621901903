import { default as CheckboxMui, CheckboxProps as CheckboxMuiProps } from "@mui/material/Checkbox"
import makeStyles from "@mui/styles/makeStyles"

import { ReactComponent as Check } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/check.svg"

import style from "./style.module.css"

const useStyles = makeStyles({
  root: {
    "&": {
      padding: 0,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
})

const Checkbox = (props: CheckboxMuiProps) => {
  const classes = useStyles()

  return (
    <CheckboxMui
      className={classes.root}
      disableRipple
      checkedIcon={
        <span className={`${style.icon} ${style.checked}`}>
          <Check aria-hidden />
        </span>
      }
      icon={<span className={style.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  )
}

export default Checkbox
