import { ReactNode, useEffect, useState } from "react"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { Navigate, useLocation, useParams } from "react-router-dom"
import graphql from "babel-plugin-relay/macro"
import { useFragment, useQuery } from "relay-hooks"

import truncateText from "../../../../utils/truncateText"
import { useTezosContext } from "../../../../web3/Web3Provider"
import { useUserInfoContext } from "../../../../providers/UserInfoProvider"
import { useUbisoftWebAuthSessionContext } from "../../../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import useCanPurchase, { PurchaseStatus } from "../../../../hooks/useCanPurchase"
import useWalletConnect from "../../../../hooks/useWalletConnect"
import { getEURLBalance } from "../../../../web3/utils/getBalance"
import { getPendingTokens } from "../../../../utils/pendingTokensStorage"
import { buildCustomParameter } from "../../../../utils/analytics"
import isFeatureEnabled from "../../../../utils/isFeatureEnabled"
import Button from "../../../../components/Button"
import Checkbox from "../../../../components/Checkbox"
import UbisoftSpinner from "../../../../components/UbisoftSpinner"
import CannotPurchaseError from "../../../../components/TokenPurchase/CannotPurchaseError"
import BasePurchaseError from "../../../../components/TokenPurchase/BasePurchaseError"
import TrackPage from "../../../../components/TrackPage"
import WalletConnectSection from "../../../../components/WalletConnectSection"
import AcceptTermsDialog from "../../../../components/AcceptTermsDialog"
import SignMessageDialog from "../../../../components/SignMessage"
import WrongWalletDialog from "../../../../components/WrongWalletDialog"

import { BuySectionFragment_sale$key } from "./__generated__/BuySectionFragment_sale.graphql"
import { BuySectionFragment_token$key } from "./__generated__/BuySectionFragment_token.graphql"
import { BuySectionInventoryQuery } from "./__generated__/BuySectionInventoryQuery.graphql"

import { ReactComponent as CartIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/cart.svg"
import { ReactComponent as PlusIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/plus.svg"
import { ReactComponent as ArrowRightIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"

import style from "./style.module.css"

const inventoryQuery = graphql`
  query BuySectionInventoryQuery {
    currentPlayer {
      inventory {
        id
      }
    }
  }
`

interface WalletInfoProps {
  logo: string
  name: string
  description: ReactNode
  address: string
  balance: number
  price: number
}

const WalletInfo = ({ logo, name, description, address, balance, price }: WalletInfoProps) => {
  const location = useLocation()

  return (
    <div className={style.walletCard}>
      <img className={style.logo} src={logo} alt={name} />
      <div className={style.nameContainer}>
        <h4>{name}</h4>
        <div>{description}</div>
      </div>
      {balance! > price ? (
        <span className={style.address}>{address && truncateText(address)}</span>
      ) : (
        <>
          {isFeatureEnabled("walletTopup") && (
            <Button
              small
              invert
              icon={<PlusIcon />}
              to={{ pathname: "/user/wallet/top-up" }}
              state={{ referrer: location }}
            >
              <FormattedMessage defaultMessage="Top Up" id="e0RwhW" description="[Product Page] Top up button label" />
            </Button>
          )}
        </>
      )}
    </div>
  )
}

interface Props {
  sale: BuySectionFragment_sale$key
  token: BuySectionFragment_token$key
  walletInfo: {
    provider: string
    address: string
    logo: string
  } | null
  onBuyClick: () => void
  disableBuy?: boolean
}

const BuySection = ({ sale, token, walletInfo, onBuyClick, disableBuy = false }: Props) => {
  const intl = useIntl()
  const { user } = useUbisoftWebAuthSessionContext()
  const { gameID, saleID, tokenID } = useParams<{ gameID: string; saleID: string; tokenID: string }>()
  const { data: playerData, isLoading: isInventoryLoading } = useQuery<BuySectionInventoryQuery>(
    inventoryQuery,
    {},
    { skip: !user.ticket }
  )
  const saleData = useFragment(
    graphql`
      fragment BuySectionFragment_sale on Sale {
        ...CannotPurchaseError_sale
        ...useCanPurchaseFragment_sale
        price
        archetype {
          maxInstances
          primaryVariant {
            ...CannotPurchaseError_variant
            name
            categorization {
              game {
                name
              }
            }
          }
        }
      }
    `,
    sale
  )
  const tokenData = useFragment(
    graphql`
      fragment BuySectionFragment_token on Token {
        ...CannotPurchaseError_token
        id
        available
        serialNumber
      }
    `,
    token
  )
  const { status, isLoading: canPurchaseStatusLoading } = useCanPurchase(saleData)
  const {
    isDesktop,
    setSelectedWallet,
    setShowWrongWalletError,
    acceptTermsDismissed,
    showSignMessage,
    showWrongWalletError,
    handleAcceptTermsDismissed,
    handleSignMessageDialogClose,
    isLoading: walletConnectLoading,
  } = useWalletConnect()
  const { userInfo, isLoading: userInfoLoading } = useUserInfoContext()
  const { web3Provider } = useTezosContext()
  const [acceptSalesTerms, setAcceptSalesTerms] = useState(false)
  const [acceptDisclaimer, setAcceptDisclaimer] = useState(false)
  const [balance, setBalance] = useState<number | null>(null)
  const [buyTokenInProgress, setBuyTokenInProgress] = useState(false)
  const [isMyOwnToken, setIsMyOwnToken] = useState(false)

  useEffect(() => {
    if (!userInfo.walletAddress || !isFeatureEnabled("walletBalance")) return

    async function aux(pkh: string) {
      const balance = await getEURLBalance(pkh)
      setBalance(balance)
    }
    const pkh = userInfo.walletAddress
    aux(pkh)
  }, [web3Provider, userInfo.walletAddress])

  useEffect(() => {
    if (!user) return

    if (getPendingTokens(user.userID).find((tokenId) => tokenId === tokenID)) {
      setBuyTokenInProgress(true)
    } else {
      setBuyTokenInProgress(false)
    }
  }, [tokenID, user])

  useEffect(() => {
    if (!isInventoryLoading && playerData?.currentPlayer.inventory?.some((digit) => digit.id === tokenID)) {
      setIsMyOwnToken(true)
    } else {
      setIsMyOwnToken(false)
    }
  }, [isInventoryLoading, playerData, tokenID])

  if (userInfoLoading || canPurchaseStatusLoading)
    return (
      <div className={style.loading}>
        <UbisoftSpinner contrast />
      </div>
    )

  if (buyTokenInProgress || isMyOwnToken) return <Navigate to={`/token/${tokenID}`} replace />

  if (!tokenData.available)
    return (
      <>
        <TrackPage
          siteSection="product"
          pageName="message : too slow"
          customParameter={buildCustomParameter(
            saleData.archetype.primaryVariant.categorization?.game?.name || "",
            saleData.archetype.primaryVariant.name,
            tokenData.serialNumber,
            saleData.archetype.maxInstances,
            saleData.price,
            // TODO: Update value below when tokenCount feature will be available for now value may be wrong
            false,
            false
          )}
        />
        <BasePurchaseError
          title={intl.formatMessage({
            defaultMessage: "Too slow!",
            id: "65Y/+8",
            description: "[Product Page] Token not available: title",
          })}
          message={intl.formatMessage({
            defaultMessage: "This Digit has already been purchased by another player.",
            id: "DHI6YA",
            description: "[Product Page] Token not available: description",
          })}
          buttonLabel={intl.formatMessage({
            defaultMessage: "Select another Digit",
            id: "vqvWfS",
            description: "[Product Page] Token not available: button label",
          })}
          buttonIcon={<ArrowRightIcon />}
          buttonInvert={false}
          to={{ pathname: `/${gameID}/sales/${saleID}` }}
        />
      </>
    )

  if (
    (status === PurchaseStatus.NO_ASSOCIATED_WALLET ||
      status === PurchaseStatus.NO_CONNECTED_WALLET ||
      walletConnectLoading) &&
    isDesktop
  )
    return (
      <>
        <WalletConnectSection onSelectedWallet={setSelectedWallet} purchaseStatus={status} />
        {userInfo.walletAddress && (
          <WrongWalletDialog
            open={showWrongWalletError}
            onClose={() => setShowWrongWalletError(false)}
            address={userInfo.walletAddress}
          />
        )}
        <AcceptTermsDialog dismissed={acceptTermsDismissed} onClose={handleAcceptTermsDismissed} />
        <SignMessageDialog open={showSignMessage} onClose={handleSignMessageDialogClose} />
      </>
    )

  if (status !== PurchaseStatus.CAN_PURCHASE)
    return (
      <CannotPurchaseError
        error={status}
        sale={saleData}
        token={tokenData}
        variant={saleData.archetype.primaryVariant}
      />
    )

  const cantAfford = (balance || 0) < saleData.price

  return (
    <>
      {balance !== null && (
        <TrackPage
          siteSection="checkout"
          pageName={`purchase confirmation : ${cantAfford ? "insufficient funds" : "sufficient funds"}`}
          customParameter={buildCustomParameter(
            saleData.archetype.primaryVariant.categorization?.game?.name || "",
            saleData.archetype.primaryVariant.name,
            tokenData.serialNumber,
            saleData.archetype.maxInstances,
            saleData.price,
            // TODO: Update value below when tokenCount feature will be available for now value may be wrong
            false,
            false
          )}
        />
      )}
      <h3 className={style.title}>
        <FormattedMessage
          defaultMessage="Purchase confirmation"
          id="oQ975I"
          description="[Product Page] Purchase section title"
        />
      </h3>
      <div className={style.purchase}>
        {balance !== null ? (
          <WalletInfo
            logo={walletInfo!.logo}
            name={walletInfo!.provider}
            description={
              <FormattedMessage
                defaultMessage="Balance: <b>{amount}</b> {currency}"
                id="DGz5Hu"
                description="Sales terms input label"
                values={{
                  amount: balance,
                  currency: "EURL",
                  b: (chunks: Array<string>) => (
                    <>
                      {chunks[0] !== "" ? (
                        <strong>
                          <FormattedNumber value={parseFloat(chunks[0])} />
                        </strong>
                      ) : (
                        <div className={style.balancePlaceholder} />
                      )}
                    </>
                  ),
                }}
              />
            }
            address={userInfo.walletAddress!}
            balance={balance}
            price={saleData.price}
          />
        ) : (
          <div className={style.walletItemPlaceholder} />
        )}

        <div className={style.input}>
          <Checkbox checked={acceptSalesTerms} onChange={(e) => setAcceptSalesTerms(e.target.checked)} />
          <label htmlFor="salesTerms">
            <FormattedMessage
              defaultMessage="I agree to the <b>Terms of Sale</b>."
              id="05pj/G"
              description="[Product Page] Sales terms checkbox label"
              values={{
                b: (chunks: string) => (
                  // TODO: edit link to term of sales when it will be added
                  <a href="https://legal.ubi.com/termsofsale" target="_blank" rel="noopener noreferrer">
                    <span className={style.link}>{chunks}</span>
                  </a>
                ),
              }}
            />
          </label>
        </div>
        <div className={style.input}>
          <Checkbox checked={acceptDisclaimer} onChange={(e) => setAcceptDisclaimer(e.target.checked)} />
          <label htmlFor="disclaimer">
            <FormattedMessage
              defaultMessage="I understand that I will be provided immediate access to digital content upon purchase completion, and that I expressly waive my right to withdraw from this purchase."
              id="TcRK08"
              description="[Product Page] Disclaimer checkbox label"
            />
          </label>
        </div>
        <Button
          fullWidth
          invert
          icon={<CartIcon />}
          onClick={onBuyClick}
          disabled={!acceptDisclaimer || !acceptSalesTerms || cantAfford || disableBuy}
          className={style.button}
        >
          <FormattedMessage defaultMessage="Buy this Digit" id="bz6A76" description="[Product Page] Buy button label" />
        </Button>
      </div>
    </>
  )
}

export default BuySection
