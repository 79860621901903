import { useMemo } from "react"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

const useEmptyCard = (itemsCount: number, loading?: boolean) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up("sm"))
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const lg = useMediaQuery(theme.breakpoints.up("lg"))
  const xl = useMediaQuery(theme.breakpoints.up("xl"))
  const xxl = useMediaQuery(theme.breakpoints.up(1920))

  const cardPerRow = useMemo(() => {
    if (xxl) {
      return 8
    } else if (xl) {
      return 6
    } else if (lg) {
      return 5
    } else if (md) {
      return 4
    } else if (sm) {
      return 3
    } else {
      return 2
    }
  }, [sm, md, lg, xl, xxl])

  const emptyCard = useMemo(() => {
    if (loading) {
      return cardPerRow
    }

    const itemsInLastRow = itemsCount % cardPerRow
    return itemsInLastRow ? cardPerRow - itemsInLastRow : 0
  }, [itemsCount, cardPerRow, loading])

  return emptyCard
}

export default useEmptyCard
