/**
 * @generated SignedSource<<c48e14d51ea9d1fe3984f8e519fe4e1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useBuyTokenMutation$variables = {
  tokenID: string;
  signature: string;
};
export type useBuyTokenMutation$data = {
  readonly buy: boolean;
};
export type useBuyTokenMutation = {
  variables: useBuyTokenMutation$variables;
  response: useBuyTokenMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "signature"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tokenID"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "signature",
        "variableName": "signature"
      },
      {
        "kind": "Variable",
        "name": "tokenID",
        "variableName": "tokenID"
      }
    ],
    "kind": "ScalarField",
    "name": "buy",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useBuyTokenMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useBuyTokenMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1cf5decd0b593ce7ac0f300a4b66e8b0",
    "id": null,
    "metadata": {},
    "name": "useBuyTokenMutation",
    "operationKind": "mutation",
    "text": "mutation useBuyTokenMutation(\n  $tokenID: ID!\n  $signature: String!\n) {\n  buy(tokenID: $tokenID, signature: $signature)\n}\n"
  }
};
})();

(node as any).hash = "b058695d7beb2a4bfadb740cf7ef3b82";

export default node;
