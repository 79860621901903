import { useCallback, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"

import Button from "../../../../components/Button"
import Spinner from "../../../../components/Spinner"

import { ReactComponent as RefreshIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/refresh.svg"

interface Props {
  disabled?: boolean
  onClick: () => void
  cooldownMs: number
}

const RefreshButton = ({ onClick, cooldownMs, disabled = false }: Props) => {
  const [refreshCooldown, setRefreshCooldown] = useState(false)
  const [timeoutHandle, setTimeoutHandle] = useState<ReturnType<typeof setTimeout>>()

  const handleClick = useCallback(() => {
    if (disabled) return

    setRefreshCooldown(true)
    onClick()
    const timeout = setTimeout(() => setRefreshCooldown(false), cooldownMs)
    setTimeoutHandle(timeout)
  }, [cooldownMs, disabled, onClick])

  useEffect(() => {
    return () => timeoutHandle && clearTimeout(timeoutHandle)
  }, [timeoutHandle])

  return (
    <Button
      onClick={handleClick}
      small
      icon={disabled ? <Spinner /> : <RefreshIcon />}
      disabled={disabled || refreshCooldown}
    >
      <FormattedMessage defaultMessage="Refresh" id="XvRHzT" description="[Inventory] Refresh button label" />
    </Button>
  )
}

export default RefreshButton
