import { defineMessages, IntlShape, MessageDescriptor } from "react-intl"
import formatPlaytime from "../formatPlaytime"

export type Operator = "EQ" | "GT" | "GTE" | "LT" | "LTE" | "NEQ"

const operatorMessages: Record<Operator, MessageDescriptor> = defineMessages({
  EQ: { defaultMessage: "=", id: "Ri1EJM", description: "Acquisition condition operator" },
  NEQ: { defaultMessage: "≠", id: "OBHdvv", description: "Acquisition condition operator" },
  GT: { defaultMessage: ">", id: "GlCymO", description: "Acquisition condition operator" },
  GTE: { defaultMessage: "≥", id: "iokSZb", description: "Acquisition condition operator" },
  LT: { defaultMessage: "<", id: "dU0Siw", description: "Acquisition condition operator" },
  LTE: { defaultMessage: "≤", id: "2BUpx+", description: "Acquisition condition operator" },
})

/*
 * returns the level condition if no other condition is present, otherwise returns the other condition.
 */
function selectHigherPriorityCondition(
  conditionMap: Record<
    ConditionKey,
    {
      operator: Operator
      value: number
    }
  >
) {
  let found = Object.entries(conditionMap).find(([key]) => key !== "level")
  if (found) {
    return {
      key: found[0] as ConditionKey,
      ...found[1],
    }
  }

  found = Object.entries(conditionMap).find(([key]) => key === "level")
  if (found) {
    return {
      key: found[0] as ConditionKey,
      ...found[1],
    }
  }

  return null
}

const saleConditionMessage = (
  intl: IntlShape,
  conditionMap: Record<
    ConditionKey,
    {
      operator: Operator
      value: number
    }
  >
) => {
  const condition = selectHigherPriorityCondition(conditionMap)
  if (!condition) return null

  const operator = intl.formatMessage(operatorMessages[condition.operator])
  const messages: Record<ConditionKey, string> = {
    cqcKillsTotal: intl.formatMessage(
      {
        defaultMessage: "CQC kills {operator} {value}",
        id: "A9MPqA",
        description: "Acquisition condition label",
      },
      { operator, value: condition.value }
    ),
    huntersKilledTotal: intl.formatMessage(
      {
        defaultMessage: "Wolves killed while being hunted in PVE {operator} {value}",
        id: "p5MDj3",
        description: "Acquisition condition label",
      },
      { operator, value: condition.value }
    ),
    longestKillDistance: intl.formatMessage(
      {
        defaultMessage: "Longest kill distance {operator} {value}",
        id: "Ksegfc",
        description: "Acquisition condition label",
      },
      { operator, value: intl.formatNumber(condition.value, { style: "unit", unit: "meter" }) }
    ),
    time: intl.formatMessage(
      {
        defaultMessage: "Global playtime {operator} {value}",
        id: "76kT1G",
        description: "Acquisition condition label",
      },
      { operator, value: formatPlaytime(intl, condition.value) }
    ),
    level: intl.formatMessage(
      {
        defaultMessage: "XP Level {operator} {value}",
        id: "YXek4v",
        description: "Acquisition condition label",
      },
      { operator, value: condition.value }
    ),
  }

  return messages[condition.key] || null
}

export default saleConditionMessage
