import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react"

interface BottomNavContext {
  show: boolean
  setShow: (show: boolean) => void
  visible: boolean
  setVisible: (show: boolean) => void
}

const BottomNavDisplayContext = createContext<BottomNavContext>({
  show: true,
  setShow: () => {},
  visible: true,
  setVisible: () => {},
})

export const useDisplayBottomNavContext = () => useContext(BottomNavDisplayContext)
export const useShowBottomNav = (show: boolean) => {
  const { setShow } = useDisplayBottomNavContext()
  useEffect(() => {
    setShow(show)

    return () => setShow(true)
  }, [show, setShow])
}

type Props = {
  children: ReactNode
}

const BottomNavDisplayProvider = ({ children }: Props) => {
  const [show, setShow] = useState(true)
  const [visible, setVisible] = useState(true)
  const state = useMemo(() => ({ show, setShow, visible, setVisible }), [show, visible])

  return <BottomNavDisplayContext.Provider value={state}>{children}</BottomNavDisplayContext.Provider>
}

export default BottomNavDisplayProvider
