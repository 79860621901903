import { useContext, useEffect } from "react"

import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import { TrackingContext } from "../TrackingContextProvider"

interface Props {
  siteSection: string
  pageName: string
  siteSubSection?: string
  customParameter?: string
  onPageEventSent?: () => void
}

const TrackPage = ({ siteSection, pageName, siteSubSection, customParameter, onPageEventSent }: Props) => {
  const { user } = useUbisoftWebAuthSessionContext()
  const { triggerAccountEvent, sCodeReady } = useContext(TrackingContext)

  useEffect(() => {
    if (!triggerAccountEvent || !sCodeReady) return

    const parameters = { siteSection, pageName, siteSubSection, customParameter, ubisoftId: user.userID }

    if (parameters.siteSubSection === undefined) delete parameters.siteSubSection
    if (parameters.customParameter === undefined) delete parameters.customParameter

    window.adobeAnalytics.track("pageevent", parameters)
    onPageEventSent && onPageEventSent()
    triggerAccountEvent()
  }, [
    triggerAccountEvent,
    siteSection,
    pageName,
    siteSubSection,
    customParameter,
    onPageEventSent,
    sCodeReady,
    user.userID,
  ])

  return null
}

export default TrackPage
