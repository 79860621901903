import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { TempleWallet } from "@temple-wallet/dapp"
import { KukaiEmbed } from "kukai-embed"

import signEurlTransfer from "./utils/signEurlTransfer"
import walletProviders, { ProviderName } from "./walletProviders"
import { useUserInfoContext } from "../providers/UserInfoProvider"
import isFeatureEnabled from "../utils/isFeatureEnabled"

export interface Web3Context {
  providerName: ProviderName | null
  web3Provider: TempleWallet | KukaiEmbed | null
  connectProvider: (name: ProviderName) => Promise<void>
  unsetProvider: () => void
  signTransaction: ReturnType<typeof signEurlTransfer> | null
  providerLoading: boolean
}

export const web3Context = createContext<Web3Context>({
  providerName: null,
  web3Provider: null,
  connectProvider: async () => {},
  unsetProvider: () => {},
  signTransaction: null,
  providerLoading: false,
})

export const useTezosContext = () => useContext<Web3Context>(web3Context)

const Web3Provider = ({ children }: { children: ReactNode }) => {
  const [web3Provider, setWeb3Provider] = useState<TempleWallet | KukaiEmbed | null>(null)
  const [providerLoading, setProviderLoading] = useState(false)
  const [providerName, setProviderName] = useState<ProviderName | null>(null)
  const { userInfo } = useUserInfoContext()

  const connectProvider = useCallback(async (name: ProviderName) => {
    setProviderLoading(true)
    try {
      setWeb3Provider(await walletProviders[name].connect())
      setProviderName(name)
    } catch (error) {
      console.error(error)
    } finally {
      setProviderLoading(false)
    }
  }, [])

  const unsetProvider = useCallback(async () => {
    localStorage.removeItem("userId")
    localStorage.removeItem("walletProvider")
    setWeb3Provider(null)
    setProviderName(null)
  }, [])

  const tryReconnect = useCallback(
    async (userId: string, walletProvider: string) => {
      if (userId !== userInfo.id) return

      const provider = Object.keys(walletProviders).find((value) => value === walletProvider)

      if (!provider) return

      try {
        await connectProvider(provider as ProviderName)
      } catch (e) {
        unsetProvider()
        console.error(e)
      } finally {
        if (web3Provider && userInfo.walletAddress) {
          let pkh = null
          if (web3Provider instanceof TempleWallet) {
            pkh = web3Provider.permission?.pkh
          } else if (web3Provider instanceof KukaiEmbed) {
            pkh = web3Provider.user?.pkh
          }
          if (!pkh || pkh !== userInfo.walletAddress) {
            unsetProvider()
          }
        }
      }
    },
    [connectProvider, unsetProvider, userInfo.id, userInfo.walletAddress, web3Provider]
  )

  useEffect(() => {
    if (!isFeatureEnabled("persistWallet")) return

    const userId = localStorage.getItem("userId")
    const walletProvider = localStorage.getItem("walletProvider")
    if (userId && walletProvider && userInfo.walletAddress && !web3Provider) tryReconnect(userId, walletProvider)
  }, [tryReconnect, userInfo.walletAddress, web3Provider])

  const context = useMemo(
    () => ({
      providerName,
      web3Provider,
      connectProvider,
      unsetProvider,
      signTransaction: web3Provider ? signEurlTransfer(web3Provider) : null,
      providerLoading,
    }),
    [web3Provider, providerName, providerLoading, connectProvider, unsetProvider]
  )

  return <web3Context.Provider value={context}>{children}</web3Context.Provider>
}

export default Web3Provider
