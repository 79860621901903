import { FormattedMessage, IntlShape } from "react-intl"
import { Link } from "react-router-dom"

const acquiringDigits = (intl: IntlShape) => [
  // {
  //   id: "how-do-i-purchase-a-digit-on-ubisoft-quartz",
  //   date: new Date(2021, 11, 7),
  //   question: intl.formatMessage({
  //     defaultMessage: "How do I purchase a Digit on Ubisoft Quartz?",
  //     id: "Ulush1",
  //     description: "Faq acquiring digits question 0",
  //   }),
  //   response: (
  //     <>
  //       <FormattedMessage
  //         tagName="p"
  //         defaultMessage="You first need to connect to your Ubisoft account on the Ubisoft Quartz platform. This will verify your eligibility to acquire Digits. If you are eligible, you can then select the Digit you want to purchase. This will prompt a request for connecting or creating a crypto-wallet. Once done, you can add <link1>EURL cryptocurrency</link1> into your crypto-wallet, using one of the providers we have listed on the <link2>dedicated page</link2>. And you can then finalize your purchase!"
  //         id="IUlmK9"
  //         description="Faq acquiring digits response 0 paragraph 1"
  //         values={{ link1: (chunks: string) => <Link to="/faq/acquiring-digits/what-is-eurl">{chunks}</Link> }}
  //       />
  //     </>
  //   ),
  // },
  {
    id: "how-do-i-claim-a-digit-on-ubisoft-quartz",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "How do I claim a Digit on Ubisoft Quartz?",
      id: "cMV+AK",
      description: "Faq claim digits question 0",
    }),
    response: (
      <p>
        <FormattedMessage
          defaultMessage="You first need to connect to your Ubisoft account on the Ubisoft Quartz platform. This will verify your eligibility to acquire Digits. If you are eligible, you can claim your Digit until the claim period ends or until all the Digits of that Edition have been claimed. This will prompt a request for connecting or creating a compatible crypto-wallet. Once done, you can then finalize the claiming process! You can consult the list of compatible crypto-wallets on the <link8>Crypto-wallet section</link8> of the Ubisoft Quartz website."
          id="JXAlou"
          description="Faq acquiring digits response 0 paragraph 1"
          values={{ link8: (chunks: string) => <Link to="/user/wallet">{chunks}</Link> }}
        />
      </p>
    ),
  },
  // {
  //   id: "what-currency-can-i-use-on-ubisoft-quartz",
  //   date: new Date(2021, 11, 7),
  //   question: intl.formatMessage({
  //     defaultMessage: "What currency can I use on Ubisoft Quartz? ",
  //     id: "Irr2gG",
  //     description: "Faq acquiring digits question 1",
  //   }),
  //   response: (
  //     <>
  //       <FormattedMessage
  //         tagName="p"
  //         defaultMessage="To purchase a Digit on Ubisoft Quartz, you will need to use a currency called <link3>EURL</link3>. EURL is a cryptocurrency with a value based on the Euro. This means that the EURL is designed to maintain a stable value of 1 Euro. Only EURL will be accepted on Ubisoft Quartz."
  //         id="r25YsX"
  //         description="Faq acquiring digits response 1 paragraph 1"
  //         values={{
  //           link3: (chunks: string) => (
  //             <a href="https://lugh.io" target="_blank" rel="noreferrer">
  //               {chunks}
  //             </a>
  //           ),
  //         }}
  //       />
  //     </>
  //   ),
  // },
  // {
  //   id: "why-cant-i-use-my-national-currency-to-buy-digits-on-ubisoft-quartz",
  //   date: new Date(2021, 11, 7),
  //   question: intl.formatMessage({
  //     defaultMessage: "Why can’t I use my national currency to buy Digits on Ubisoft Quartz?",
  //     id: "zYjls8",
  //     description: "Faq acquiring digits question 2",
  //   }),
  //   response: (
  //     <>
  //       <FormattedMessage
  //         tagName="p"
  //         defaultMessage="Purchase in cryptocurrency allows to leverage the decentralized nature of the blockchain by automatically handling the Digit transfer to your crypto-wallet."
  //         id="3JHs3L"
  //         description="Faq acquiring digits response 2 paragraph 1"
  //       />
  //     </>
  //   ),
  // },
  // {
  //   id: "what-is-eurl",
  //   date: new Date(2021, 11, 7),
  //   question: intl.formatMessage({
  //     defaultMessage: "What is EURL?",
  //     id: "w4mu32",
  //     description: "Faq acquiring digits question 3",
  //   }),
  //   response: (
  //     <>
  //       <FormattedMessage
  //         tagName="p"
  //         defaultMessage="EURL is a cryptocurrency with a value based on the Euro. This means that the EURL is designed to maintain a stable value of 1 Euro. Only EURL will be accepted on Ubisoft Quartz."
  //         id="z/ukR8"
  //         description="Faq acquiring digits response 3 paragraph 1"
  //       />
  //     </>
  //   ),
  // },
  // {
  //   id: "where-can-i-buy-eurl",
  //   date: new Date(2021, 11, 7),
  //   question: intl.formatMessage({
  //     defaultMessage: "Where can I buy EURL?",
  //     id: "4aRhWS",
  //     description: "Faq acquiring digits question 4",
  //   }),
  //   response: (
  //     <>
  //       <FormattedMessage
  //         tagName="p"
  //         defaultMessage="You can purchase some EURL on third-party platforms or exchanges. Some of them are <link2>listed</link2> on the Ubisoft Quartz platform. There you will be able to buy EURL using fiat currency or swap it with another cryptocurrency you already own."
  //         id="eCg1Yu"
  //         description="Faq acquiring digits response 4 paragraph 1"
  //         values={{ link2: (chunks: string) => <Link to="/user/wallet/top-up">{chunks}</Link> }}
  //       />
  //     </>
  //   ),
  // },
  {
    id: "how-do-i-purchase-a-digit-on-the-secondary-market",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "How do I purchase a Digit on the secondary market?",
      id: "r8VuLN",
      description: "Faq acquiring digits question 5",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="To acquire a Digit on the secondary market, you first need to follow the account and crypto-wallet creation process on the <link4>Ubisoft Quartz</link4> platform so that we can <link5>verify your eligibility</link5>"
          id="AmU8cQ"
          description="Faq acquiring digits response 5 paragraph 1"
          values={{
            link4: (chunks: string) => <Link to="/">{chunks}</Link>,
            link5: (chunks: string) => <Link to="/faq/requirements/who-can-acquire-digits">{chunks}</Link>,
          }}
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="Once connected to Ubisoft Quartz, you can go to the authorized marketplace listing the Digits: <link6>Rarible</link6>."
          id="1yNtKH"
          description="Faq acquiring digits response 5 paragraph 2"
          values={{
            link6: (chunks: string) => (
              <a href="https://rarible.com/ubisoft-quartz" target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
          }}
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="There you will be able to see all the Digits that have ever been created and filter for those currently on sale. Make sure to connect to the marketplace with the same <link9>crypto-wallet</link9> as the one connected to Quartz, otherwise you will not be able to purchase Digits."
          id="n19FBI"
          description="Faq acquiring digits response 5 paragraph 3"
          values={{
            link9: (chunks: string) => (
              <Link to="/faq/crypto-wallet/what-do-i-need-to-use-ubisoft-quartz">{chunks}</Link>
            ),
          }}
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="If you are not eligible, the transaction will fail."
          id="chFc8Y"
          description="Faq acquiring digits response 5 paragraph 4"
        />
      </>
    ),
  },
  // {
  //   id: "can-i-change-my-mind-and-get-reimbursed-if-i-am-not-satisfied-with-my-digit",
  //   date: new Date(2021, 11, 7),
  //   question: intl.formatMessage({
  //     defaultMessage: "Can I change my mind and get reimbursed if I am not satisfied with my Digit?",
  //     id: "hzimJ+",
  //     description: "Faq acquiring digits question 6",
  //   }),
  //   response: (
  //     <>
  //       <FormattedMessage
  //         tagName="p"
  //         defaultMessage="No, you will not be able to change your mind once the purchase of a Digit is completed. You can, however put it on sale for other eligible players to purchase it on the authorized marketplace, <link6>Rarible</link6>."
  //         id="L9noBK"
  //         description="Faq acquiring digits response 6 paragraph 1"
  //         values={{
  //           link6: (chunks: string) => (
  //             <a href="https://rarible.com/ubisoft-quartz" target="_blank" rel="noreferrer">
  //               {chunks}
  //             </a>
  //           ),
  //         }}
  //       />
  //     </>
  //   ),
  // },
]

export default acquiringDigits
