/**
 * @generated SignedSource<<dd16cacf36c27e1248486d24ef6c262a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useSaleStatusPendingClaimsQuery$variables = {};
export type useSaleStatusPendingClaimsQuery$data = {
  readonly currentPlayer: {
    readonly claims: ReadonlyArray<{
      readonly sale: {
        readonly id: string;
      };
    }>;
  };
};
export type useSaleStatusPendingClaimsQuery = {
  variables: useSaleStatusPendingClaimsQuery$variables;
  response: useSaleStatusPendingClaimsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Sale",
  "kind": "LinkedField",
  "name": "sale",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useSaleStatusPendingClaimsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Claim",
            "kind": "LinkedField",
            "name": "claims",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useSaleStatusPendingClaimsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Claim",
            "kind": "LinkedField",
            "name": "claims",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "54c45f96da5fe07725b529eb7f76f8f5",
    "id": null,
    "metadata": {},
    "name": "useSaleStatusPendingClaimsQuery",
    "operationKind": "query",
    "text": "query useSaleStatusPendingClaimsQuery {\n  currentPlayer {\n    claims {\n      sale {\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fbd61bdb5e5c814ef42ad7e4fbd6ed45";

export default node;
