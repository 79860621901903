import { useIntl } from "react-intl"

import Tag from "../../Tag"

import style from "./style.module.css"

type Props = {
  name: string
  gameTitle: string
  processingTag?: boolean
}

const ProductTitle = ({ name, gameTitle, processingTag = false }: Props) => {
  const intl = useIntl()

  return (
    <>
      <h3 className={style.title}>{name}</h3>
      <div className={style.tags}>
        <Tag>{gameTitle}</Tag>
        {processingTag && (
          <Tag color="primaryGradient">
            {intl.formatMessage({
              defaultMessage: "Processing...",
              id: "xVjiIJ",
              description: "Pending token",
            })}
          </Tag>
        )}
      </div>
    </>
  )
}

export default ProductTitle
