import { HTMLAttributes, ReactNode } from "react"

import style from "./style.module.css"

export interface ItemProps extends HTMLAttributes<HTMLLIElement> {
  icon?: ReactNode
  label?: string | ReactNode
  children: ReactNode
  onClick?: () => void
  disabled?: boolean
}

export const ListGroupItem = ({ icon, label, children, className = "", onClick, disabled = false }: ItemProps) => {
  return (
    <li onClick={onClick} className={className}>
      {icon}
      {label && <div className={`caption ${style.label} ${disabled ? style.disabled : ""}`}>{label}</div>}
      <div className={style.content}>{children}</div>
    </li>
  )
}

export interface Props extends HTMLAttributes<HTMLUListElement> {
  children: ReactNode
}

const ListGroup = ({ children, className = "", ...props }: Props) => {
  return (
    <ul className={`${style.container} ${className}`} {...props}>
      {children}
    </ul>
  )
}

export default ListGroup
