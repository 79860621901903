/**
 * @generated SignedSource<<f01bc5b4bb6787f3f72f96f24fa0b2f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuySectionFragment_sale$data = {
  readonly price: number;
  readonly archetype: {
    readonly maxInstances: number;
    readonly primaryVariant: {
      readonly name: string;
      readonly categorization: {
        readonly game: {
          readonly name: string;
        } | null;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"CannotPurchaseError_variant">;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"CannotPurchaseError_sale" | "useCanPurchaseFragment_sale">;
  readonly " $fragmentType": "BuySectionFragment_sale";
};
export type BuySectionFragment_sale$key = {
  readonly " $data"?: BuySectionFragment_sale$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuySectionFragment_sale">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuySectionFragment_sale",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CannotPurchaseError_sale"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCanPurchaseFragment_sale"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archetype",
      "kind": "LinkedField",
      "name": "archetype",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxInstances",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Variant",
          "kind": "LinkedField",
          "name": "primaryVariant",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CannotPurchaseError_variant"
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Categorization",
              "kind": "LinkedField",
              "name": "categorization",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Game",
                  "kind": "LinkedField",
                  "name": "game",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Sale",
  "abstractKey": null
};
})();

(node as any).hash = "b773231f777902e25be570a3992dbb32";

export default node;
