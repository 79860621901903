import { useState } from "react"
import { Navigate } from "react-router-dom"
import { Location } from "history"
import { FormattedMessage, useIntl } from "react-intl"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import useWalletConnect from "../../hooks/useWalletConnect"
import { useUserInfoContext } from "../../providers/UserInfoProvider"
import PageHeader from "../../components/PageHeader"
import PageLayout from "../../components/PageLayout"
import TrackPage from "../../components/TrackPage"
import WalletConnectSection from "../../components/WalletConnectSection"
import { useTezosContext } from "../../web3/Web3Provider"
import UbisoftSpinner from "../../components/UbisoftSpinner"
import AcceptTermsDialog from "../../components/AcceptTermsDialog"
import SignMessageDialog from "../../components/SignMessage"
import WrongWalletDialog from "../../components/WrongWalletDialog"
import { useShowBottomNav } from "../../components/BottomNav/BottomNavDisplayProvider"
import useLocation from "../../hooks/useLocation"

import style from "./style.module.css"

const WalletConnect = () => {
  const intl = useIntl()
  const location = useLocation<{ referrer: Location }>()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { userInfo, isLoading: userInfoLoading } = useUserInfoContext()
  const { web3Provider, providerLoading } = useTezosContext()
  const {
    acceptTermsDismissed,
    showSignMessage,
    showWrongWalletError,
    associationComplete,
    setSelectedWallet,
    setShowWrongWalletError,
    handleAcceptTermsDismissed,
    handleSignMessageDialogClose,
  } = useWalletConnect()
  const [trackingEventSent, setTrackingEventSent] = useState(false)

  const viewSearchParam = new URLSearchParams(location.search).get("view")
  useShowBottomNav(viewSearchParam !== "p")

  const pageHeader = (
    <PageHeader
      title={intl.formatMessage({
        defaultMessage: "Set up a crypto-wallet",
        id: "Ha80VU",
        description: "[Connect Wallet] page title",
      })}
      hideBackButton
    />
  )

  if (providerLoading || userInfoLoading) {
    return (
      <PageLayout header={isMobile && pageHeader} bodyClassName={style.loading} bodyPosition="right">
        <UbisoftSpinner className={style.spinner} contrast />
        <FormattedMessage
          defaultMessage="Initializing crypto-wallet provider..."
          id="N+EGnM"
          description="[Connect Wallet] provider loading message"
        />
      </PageLayout>
    )
  }

  if (web3Provider && userInfo.walletAddress && associationComplete) {
    return <Navigate to={location.state?.referrer?.pathname || "/"} replace />
  }

  return (
    <>
      {!trackingEventSent && (
        <TrackPage siteSection="wallet" pageName="create wallet" onPageEventSent={() => setTrackingEventSent(true)} />
      )}
      <PageLayout header={isMobile && pageHeader} bodyClassName={style.content} bodyPosition="right">
        <WalletConnectSection onSelectedWallet={setSelectedWallet} />
        {userInfo.walletAddress && (
          <WrongWalletDialog
            open={showWrongWalletError}
            onClose={() => setShowWrongWalletError(false)}
            address={userInfo.walletAddress}
          />
        )}
        <AcceptTermsDialog dismissed={acceptTermsDismissed} onClose={handleAcceptTermsDismissed} />
        <SignMessageDialog open={showSignMessage} onClose={handleSignMessageDialogClose} />
      </PageLayout>
    </>
  )
}

export default WalletConnect
