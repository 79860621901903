import { Navigate, Route, Routes } from "react-router-dom"

import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import Eligibility from "../Eligibility"
import Profile from "../Profile"
import Settings from "../Settings"
import Wallet from "../Wallet"
import WalletConnect from "../WalletConnect"
import WalletTopUp from "../WalletTopUp"
import isFeatureEnabled from "../../utils/isFeatureEnabled"
import NotFound from "../NotFound"

const User = () => {
  const { user } = useUbisoftWebAuthSessionContext()

  if (!user.ticket) {
    return <Navigate to="/" replace />
  }

  return (
    <Routes>
      <Route path={""} element={<Profile />} />
      <Route path={`/settings`} element={<Settings />} />
      <Route path={`/wallet`} element={<Wallet />} />
      <Route path={`/wallet/connect`} element={<WalletConnect />} />
      {isFeatureEnabled("walletTopup") && <Route path={`/wallet/top-up`} element={<WalletTopUp />} />}
      <Route path={`/not-eligible/purchase`} element={<Eligibility type="purchase" />} />
      <Route path={`/not-eligible/topup`} element={<Eligibility type="topup" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default User
