import { FormattedMessage, useIntl } from "react-intl"

import ConfirmDialog from "../../components/ConfirmDialog"
import TrackImpression from "../../components/TrackImpression"

import { ReactComponent as WalletIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/walletStroke.svg"

export interface Props {
  open: boolean
  onClose: () => void
  address: string
  venly?: boolean
  onManageWalletDone?: () => void
}

const WrongWalletDialog = ({ open, onClose, address, venly, onManageWalletDone }: Props) => {
  const intl = useIntl()

  return (
    <>
      {open && <TrackImpression locationDetail="invalid cryptowallet" />}
      <ConfirmDialog
        open={open}
        onClose={onClose}
        icon={<WalletIcon />}
        title={intl.formatMessage({
          defaultMessage: "This crypto-wallet is not valid!",
          id: "ShhbSx",
          description: "[Wallet Connect Error] Title of 'wrong wallet connected' error dialog",
        })}
        content={
          <>
            <p>
              <FormattedMessage
                defaultMessage="Your associated crypto-wallet <b>{address}</b> is not recognized."
                id="e3DeET"
                description="[Wallet Connect Error] Wrong wallet error description"
                values={{ address, b: (chunks: any) => <strong>{chunks}</strong> }}
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="Please make sure your current crypto-wallet is connected to Ubisoft Quartz on your provider."
                id="rqOYQ8"
                description="[Wallet Connect Error] Wrong wallet generic notice"
              />
            </p>
          </>
        }
        cancelLabel={intl.formatMessage({
          defaultMessage: "Close",
          id: "1Z8IPu",
          description: "[Wallet Connect Error] Close button label",
        })}
      />
    </>
  )
}

export default WrongWalletDialog
