import { useFragment, graphql } from "react-relay"
import { useIntl } from "react-intl"

import { PendingClaimCardFragment_claim$key } from "./__generated__/PendingClaimCardFragment_claim.graphql"

import TokenCard from "."

import ipfsToHttpGateway from "../../../../utils/ipfsToHttpGateway"

interface Props {
  claim: PendingClaimCardFragment_claim$key
  processing?: boolean
}

const PendingClaimCardFragment = ({ claim, processing }: Props) => {
  const data = useFragment(
    graphql`
      fragment PendingClaimCardFragment_claim on Claim {
        sale {
          archetype {
            primaryVariant {
              name
              image
              categorization {
                game {
                  name
                }
              }
            }
          }
        }
      }
    `,
    claim
  )
  const intl = useIntl()

  const tag = processing
    ? intl.formatMessage({
        defaultMessage: "Processing...",
        id: "Nk3LQm",
        description: "[Wallet Pending claim Card] Claim status",
      })
    : intl.formatMessage({
        defaultMessage: "Claimed",
        id: "5o/KZA",
        description: "[Wallet Pending claim Card] Claim status",
      })

  return (
    <TokenCard
      thumbnail={ipfsToHttpGateway(data.sale.archetype.primaryVariant.image)}
      name={data.sale.archetype.primaryVariant.name}
      game={data.sale.archetype.primaryVariant.categorization?.game?.name || ""}
      tag={tag}
      tagColor={processing ? "primaryGradient" : "warning"}
    />
  )
}

export default PendingClaimCardFragment
