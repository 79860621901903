import React, { useRef, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import Grow from "@mui/material/Grow"
import Popper from "@mui/material/Popper"
import ClickAwayListener from "@mui/material/ClickAwayListener"

import { useUserInfoContext } from "../../providers/UserInfoProvider"
import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import Button from "../Button"
import LogoutConfirmDialog from "../LogoutConfirmDialog"
import Logo from "../Logo"
import isFeatureEnabled from "../../utils/isFeatureEnabled"

import { ReactComponent as Wallet } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/walletStroke.svg"
import { ReactComponent as ArrowRight } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"
import { ReactComponent as ChevronBottom } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/chevronBottom.svg"
import { ReactComponent as Person } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/profile.svg"
import { ReactComponent as Off } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/logout.svg"

import style from "./style.module.css"

interface UserMenuProps {
  avatar: string
}

const UserMenu = ({ avatar }: UserMenuProps) => {
  const [open, setOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const handleLogoutClick = () => {
    setOpen(false)
    setConfirmDialogOpen(true)
  }

  const handleDialogClose = () => {
    setConfirmDialogOpen(false)
  }

  const handleToggle = () => {
    setOpen((prev) => !prev)
  }

  const handleClose = (event: MouseEvent | TouchEvent | React.MouseEvent<EventTarget>) => {
    if (buttonRef.current && buttonRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <button className={style.button} ref={buttonRef} onClick={handleToggle}>
        <ChevronBottom className={`${open ? style.flip : ""}`} />
        <img src={avatar} alt="" aria-hidden />
      </button>
      <Popper className={style.inFront} open={open} anchorEl={buttonRef.current} placement="bottom-end" transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps} timeout="auto" style={{ transformOrigin: "right top" }}>
              <div className={style.menu}>
                <ul className={style.menuList}>
                  <li className={style.item}>
                    <Link to="/user" onClick={handleClose}>
                      <FormattedMessage
                        defaultMessage="Profile"
                        id="oZrP0l"
                        description="[Top Nav] Link to user's profile"
                      />
                      <Person className={style.icon} />
                    </Link>
                  </li>
                  <li className={style.item}>
                    <Link to="/faq" onClick={handleClose}>
                      <FormattedMessage defaultMessage="FAQ" id="6pBXH8" description="[Top Nav] Link to FAQ" />
                    </Link>
                  </li>
                </ul>
                <ul className={style.menuList}>
                  <li className={style.item}>
                    <button onClick={handleLogoutClick}>
                      <FormattedMessage
                        defaultMessage="Log out"
                        id="kf5uSm"
                        description="[Top Nav] Log out button label"
                      />
                      <Off className={`${style.icon} ${style.danger}`} />
                    </button>
                  </li>
                </ul>
              </div>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
      <LogoutConfirmDialog open={confirmDialogOpen} onClose={handleDialogClose} />
    </>
  )
}

export interface Props {
  invertColor?: boolean
  className?: string
}

const TopNav = ({ invertColor = false, className = "" }: Props) => {
  const { user, login } = useUbisoftWebAuthSessionContext()
  const { userInfo, isLoading: isUserInfoLoading } = useUserInfoContext()

  const handleLogoClick = () => window.scrollTo(0, 0)

  return (
    <header className={`${style.container} ${invertColor ? style.invert : ""} ${className}`}>
      <h1>
        <Link to="/" onClick={handleLogoClick}>
          <Logo aria-hidden className="link-scale" />
        </Link>
      </h1>
      <nav>
        {user.ticket && !isUserInfoLoading ? (
          <>
            {!userInfo.walletAddress && isFeatureEnabled("setUpNow") ? (
              <Button to={"/user/wallet/connect"} invert small icon={<Wallet aria-hidden />}>
                <FormattedMessage
                  defaultMessage="Set up now"
                  id="MhEhIK"
                  description="[Bottom/Top Nav] Set up now button label"
                />
              </Button>
            ) : (
              <Link to="/user/wallet" className={style.link}>
                <Wallet aria-hidden />
                <FormattedMessage
                  tagName="span"
                  defaultMessage="Crypto-wallet"
                  id="rWLeqf"
                  description="[Bottom/Top Nav] Label of wallet link"
                />
              </Link>
            )}
            <UserMenu avatar={userInfo.avatar} />
          </>
        ) : (
          <Button invert small icon={<ArrowRight aria-hidden />} className={style.cta} onClick={login}>
            <FormattedMessage
              tagName="span"
              id="b/ZFLz"
              defaultMessage="Log in"
              description="[Top Nav] Login button label"
            />
          </Button>
        )}
      </nav>
    </header>
  )
}

export default TopNav
