import { useScrollTrigger } from "@mui/material"

import Footer from "../../components/Footer"
import CTABanner from "./components/CTABanner"
import CTASection from "./components/CTASection"
import DigitsInDetails from "./components/DigitsInDetailsSection"
import DoMoreSection from "./components/DoMoreSection"
import FaqSection from "./components/FaqSection"
import HowItWorksSection from "./components/HowItWorksSection"
import IntroSection from "./components/IntroSection"
import TopNav from "../../components/TopNav"

import style from "./style.module.css"
import bgStyle from "./background.module.scss"

const Home = () => {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 20 })

  return (
    <div className={style.container}>
      <main className={style.main}>
        {/* Background with blured particles */}
        <div aria-hidden>
          <div className={bgStyle.background}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {/* END */}

        <TopNav className={`${style.appBar} ${trigger ? style.appBarBgColor : ""}`} />

        <IntroSection />

        <DigitsInDetails />

        <CTABanner />

        <DoMoreSection />

        <HowItWorksSection />

        <CTASection />

        <FaqSection />
      </main>

      <Footer />
    </div>
  )
}

export default Home
