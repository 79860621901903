import { ReactNode } from "react"
import { IntlShape } from "react-intl"

import acquiringDigits from "../categories/acquiringDigits"
import cryptoWallet from "../categories/cryptoWallet"
import requirements from "../categories/requirements"
import sellingDigits from "../categories/sellingDigits"
import technology from "../categories/technology"
import ubisoftQuartzDigits from "../categories/ubisoftQuartzDigits"
import ubisoftQuartzGRB from "../categories/ubisoftQuartzGRB"

export interface Category {
  id: string
  name: string
  content:
    | {
        id: string
        date: Date
        question: string
        response: ReactNode
        popular?: boolean
        home?: boolean
      }[]
  home?: boolean
}

export interface Answer {
  id: string
  date: Date
  question: string
  response: ReactNode
  popular?: boolean
  home?: boolean
}

const getCategories = (intl: IntlShape) => {
  const categories: Category[] = [
    {
      id: "ubisoft-quartz-and-digits",
      name: intl.formatMessage({
        defaultMessage: "Ubisoft Quartz & Digits",
        id: "d6ZuC2",
        description: "Faq Ubisoft Quartz and Digits category",
      }),
      content: ubisoftQuartzDigits(intl),
      home: true,
    },
    {
      id: "ubisoft-quartz-and-grb",
      name: intl.formatMessage({
        defaultMessage: "Ubisoft Quartz & Tom Clancy’s Ghost Recon® Breakpoint",
        id: "Ah/7sD",
        description: "Faq Ubisoft Quartz and GRB category",
      }),
      content: ubisoftQuartzGRB(intl),
    },
    {
      id: "requirements",
      name: intl.formatMessage({
        defaultMessage: "Requirements",
        id: "lH60lM",
        description: "Faq requirements category",
      }),
      content: requirements(intl),
      home: true,
    },
    {
      id: "crypto-wallet",
      name: intl.formatMessage({
        defaultMessage: "Crypto-wallet",
        id: "zEtnUA",
        description: "Faq crypto-wallet category",
      }),
      content: cryptoWallet(intl),
    },
    {
      id: "acquiring-digits",
      name: intl.formatMessage({
        defaultMessage: "Acquiring Digits",
        id: "4VDx8A",
        description: "Faq Acquiring Digits category",
      }),
      content: acquiringDigits(intl),
    },
    {
      id: "selling-digits",
      name: intl.formatMessage({
        defaultMessage: "Selling Digits",
        id: "Xcc88G",
        description: "Faq Selling Digits category",
      }),
      content: sellingDigits(intl),
    },
    {
      id: "technology",
      name: intl.formatMessage({
        defaultMessage: "Technology",
        id: "Dsu/nc",
        description: "Faq Technology category",
      }),
      content: technology(intl),
      home: true,
    },
  ]

  return categories
}

export default getCategories
