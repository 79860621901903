/**
 * @generated SignedSource<<69a0679319e2611b4a5672436811abe3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BuySectionInventoryQuery$variables = {};
export type BuySectionInventoryQuery$data = {
  readonly currentPlayer: {
    readonly inventory: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type BuySectionInventoryQuery = {
  variables: BuySectionInventoryQuery$variables;
  response: BuySectionInventoryQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Token",
  "kind": "LinkedField",
  "name": "inventory",
  "plural": true,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BuySectionInventoryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BuySectionInventoryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "currentPlayer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ddca270960a7a5fe5d509205c9856f8d",
    "id": null,
    "metadata": {},
    "name": "BuySectionInventoryQuery",
    "operationKind": "query",
    "text": "query BuySectionInventoryQuery {\n  currentPlayer {\n    inventory {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b4bfb6adf960d5a81da9730c01dc6e04";

export default node;
