import { useEffect } from "react"

interface Props {
  locationDetail: string
  customParameter?: string
}

const TrackImpression = ({ locationDetail, customParameter }: Props) => {
  useEffect(() => {
    const parameters = {
      location: "pop in",
      locationDetail,
      category: "impression",
      action: "display",
      customParameter,
    }

    window.adobeAnalytics.track("impressionevent", parameters)
  }, [locationDetail, customParameter])

  return null
}

export default TrackImpression
