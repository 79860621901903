import { HTMLAttributes, ReactNode } from "react"

import style from "./style.module.css"

const COLOR_CLASS_MAP = {
  default: "",
  success: style.success,
  warning: style.warning,
  danger: style.danger,
  primaryGradient: style.primaryGradient,
  white: style.white,
  black: style.black,
}

export interface Props extends HTMLAttributes<HTMLDivElement> {
  children: string | ReactNode | undefined
  color?: "default" | "success" | "warning" | "danger" | "primaryGradient" | "white" | "black"
}

const Tag = ({ children, color = "default", className = "", ...props }: Props) => {
  return (
    <div className={`${style.container} ${COLOR_CLASS_MAP[color]} ${className}`} {...props}>
      {children}
    </div>
  )
}

export default Tag
