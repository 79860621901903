import { useEffect, useMemo, useState } from "react"
import { useFragment } from "react-relay"
import graphql from "babel-plugin-relay/macro"

import { useClaimStatus_sale$key } from "./__generated__/useClaimStatus_sale.graphql"
import { useClaimStatus_player$key } from "./__generated__/useClaimStatus_player.graphql"

import { useUbisoftWebAuthSessionContext } from "../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import extractStatsNames from "../utils/saleCondition/extractStatsNames"
import { useUbiPlayerStatsContext } from "../providers/UbiPlayerStatsProvider"
import playerStatsMeetCondition from "../utils/saleCondition/playerStatsMeetCondition"

export enum ClaimStatus {
  CAN_CLAIM,
  ALREADY_CLAIMED,
  CLAIM_OWNED,
  CONDITION_NOT_MET,
  CLAIM_NOT_STARTED,
  CLAIM_ENDED,
  CLAIM_SOLD_OUT,
}

const useClaimStatus = (sale: useClaimStatus_sale$key, player: useClaimStatus_player$key | null) => {
  const { user } = useUbisoftWebAuthSessionContext()
  const [date, setDate] = useState(new Date())
  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000)
    return () => clearInterval(interval)
  })

  const saleData = useFragment(
    graphql`
      fragment useClaimStatus_sale on Sale {
        id
        startDate
        endDate
        condition {
          ...extractStatsNames_condition
          ...playerStatsMeetCondition_saleCondition
        }
        claimsCount {
          remaining
        }
        archetype {
          id
        }
      }
    `,
    sale
  )
  const playerData = useFragment(
    graphql`
      fragment useClaimStatus_player on Player {
        inventory {
          archetype {
            id
          }
        }
        reserved {
          archetype {
            id
          }
        }
        claims {
          sale {
            id
          }
        }
      }
    `,
    player
  )

  const statsNames = useMemo(() => extractStatsNames(saleData.condition), [saleData])
  const { data: stats, isLoading, fetch } = useUbiPlayerStatsContext()

  useEffect(() => fetch(statsNames), [statsNames, fetch])

  // TODO Handle user whitelist status (age, geoloc, 2FA, etc.)
  const status = useMemo(() => {
    const meetCondition = !isLoading && (!saleData.condition || playerStatsMeetCondition(stats, saleData.condition))

    if (saleData.endDate && Date.parse(saleData.endDate) <= date.getTime()) return ClaimStatus.CLAIM_ENDED

    if (Date.parse(saleData.startDate) > date.getTime()) return ClaimStatus.CLAIM_NOT_STARTED

    if (
      playerData?.claims.some((claim) => claim.sale.id === saleData.id) ||
      playerData?.reserved.some((token) => token.archetype.id === saleData.archetype.id)
    )
      return ClaimStatus.ALREADY_CLAIMED

    if (playerData?.inventory.some((token) => token.archetype.id === saleData.archetype.id))
      return ClaimStatus.CLAIM_OWNED

    if (saleData.claimsCount && saleData.claimsCount.remaining === 0) return ClaimStatus.CLAIM_SOLD_OUT

    if (user.ticket && !meetCondition) return ClaimStatus.CONDITION_NOT_MET

    return ClaimStatus.CAN_CLAIM
  }, [saleData, date, playerData, user.ticket, stats, isLoading])

  return status
}

export default useClaimStatus
