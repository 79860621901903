import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import copy from "copy-to-clipboard"

import IconButton from "../../../../components/IconButton"

import { ReactComponent as CopyIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/copy.svg"

const COPIED_TOOLTIP_DURATION_MS = 2000

interface Props {
  value: string | null
}

const CopyButton = ({ value }: Props) => {
  const intl = useIntl()
  const [copied, setCopied] = useState(false)

  const handleClick = () => {
    if (!value) return

    copy(value, { format: "text/plain", onCopy: () => setCopied(true) })
  }

  useEffect(() => {
    if (!copied) return

    const timeout = setTimeout(() => setCopied(false), COPIED_TOOLTIP_DURATION_MS)

    return () => clearTimeout(timeout)
  }, [copied])

  const handleTooltipClose = () => {
    setCopied(false)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={copied}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={intl.formatMessage({
          defaultMessage: "Copied!",
          id: "jPQg3n",
          description: "[Wallet] Copied address tooltip label",
        })}
        componentsProps={{
          tooltip: {
            sx: {
              [`&.${tooltipClasses.tooltipPlacementBottom}`]: {
                mt: `0.125rem !important`,
              },
            },
          },
        }}
      >
        <IconButton onClick={handleClick}>
          <CopyIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default CopyButton
