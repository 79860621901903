import { CountdownRenderProps, zeroPad } from "react-countdown"

import style from "./style.module.css"

const ClockCountdown = ({ days, hours, minutes, seconds }: CountdownRenderProps) => {
  return (
    <div className={style.countdown}>
      <div className={style.timer}>
        <span>{zeroPad(days * 24 + hours)}</span>
        <span className={style.pulse}>:</span>
        <span>{zeroPad(minutes)}</span>
        <span className={style.pulse}>:</span>
        <span>{zeroPad(seconds)}</span>
      </div>
      <div className={style.suffix}>LEFT</div>
    </div>
  )
}

export default ClockCountdown
