import { KukaiEmbed } from "kukai-embed"

import blockchainConfig from "../../config/blockchainConfig"

// construct the embed instance using mainnet (also the default value)
const embed = new KukaiEmbed({
  net: blockchainConfig.kukai.net,
  icon: false,
})

export const connect = async () => {
  // wait for the embedded Kukai instance to be initialized
  if (!embed.initialized) {
    await embed.init()
  }

  if (embed.user == null) {
    await embed.login({
      buttonsVertically: true,
    })
  }

  return embed
}
