import React, { ReactNode } from "react"

import { ReactComponent as InfoIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/infoStroke.svg"
import { ReactComponent as WarningIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/warningStroke.svg"
import { ReactComponent as GameIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/game.svg"

import style from "./style.module.css"

const ICON_MAP = {
  info: <InfoIcon className={style.icon} />,
  warning: <WarningIcon className={style.warning} />,
  game: <GameIcon className={style.icon} />,
}

export interface Props {
  children: ReactNode
  className?: string
  type?: "info" | "warning" | "game"
}

const InfoBox = ({ children, type = "info", className = "" }: Props) => {
  return (
    <div className={`${className} ${style.disclaimer}`}>
      {ICON_MAP[type]}
      <div>{children}</div>
    </div>
  )
}

export default InfoBox
