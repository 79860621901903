import Tag, { Props as TagProps } from "../Tag"

import style from "./style.module.css"

type Props = {
  label: string
  color: TagProps["color"]
}

const CardTag = ({ label, color }: Props) => {
  return (
    <Tag color={color} className={style.tag}>
      {label}
    </Tag>
  )
}

export default CardTag
