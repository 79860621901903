import Reveal from "react-awesome-reveal"
import { FormattedMessage } from "react-intl"

import fadeInUp from "../../../../utils/animations/fade/fadeInUp"
import FaqList from "../../../../components/FaqList"

import style from "./style.module.css"
import sharedStyle from "../../style.module.css"

const FaqSection = () => {
  return (
    <>
      <section className={sharedStyle.section} id={style.section7}>
        <div>
          <Reveal keyframes={fadeInUp(20)} triggerOnce>
            <h3 className={sharedStyle.title}>
              <FormattedMessage
                id="KMC2k6"
                description="[Home] section title"
                defaultMessage="Frequently{linebreak}<textGradient>Asked Questions</textGradient>"
                values={{
                  linebreak: <br />,
                  textGradient: (text: string) => <span className={sharedStyle.highlight}>{text}</span>,
                }}
              />
            </h3>
            <FaqList />
          </Reveal>
        </div>
      </section>
    </>
  )
}

export default FaqSection
