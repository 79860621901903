import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import Chip from "@mui/material/Chip"
import makeStyles from "@mui/styles/makeStyles"

import { ReactComponent as CheckIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/check.svg"

import style from "./style.module.css"

export interface Props {
  id: string
  label: string
  selected: boolean
  count?: number
}

const useStyles = makeStyles({
  root: {
    fontFamily: "Source Sans Pro, sans-serif",
    backgroundColor: "var(--white)",
    color: "var(--primary)",
    "&:focus": {
      backgroundColor: "var(--white)",
    },
    "&:hover": {
      backgroundColor: "var(--primary-20)",
    },
    "& $icon": {
      color: "var(--primary)",
      marginLeft: "0.75rem",
      marginRight: "0.5rem",
    },
  },
  label: {
    fontSize: "0.75rem",
    padding: "0 1rem",
  },
  icon: {},
  selected: {
    fontWeight: 600,
    backgroundColor: "var(--primary-20)",
    "&:focus": {
      backgroundColor: "var(--primary-20)",
    },
    "&:hover": {
      backgroundColor: "var(--primary-20)",
    },
    "& $label": {
      padding: "0 1rem 0 0",
    },
  },
})

const FilterButton = ({ id, label, count, selected }: Props) => {
  const classes = useStyles()
  const location = useLocation()
  const [active, setActive] = useState(selected)

  useEffect(() => {
    setActive(selected)
  }, [selected])

  return (
    <Link
      className={style.link}
      to={{ pathname: location.pathname, search: id !== "all" ? `?categoryId=${id}` : undefined }}
      replace
    >
      <Chip
        label={`${label} ${count && count > 0 ? `(${count})` : ""}`}
        icon={active ? <CheckIcon /> : undefined}
        classes={{ root: classes.root, label: classes.label, icon: classes.icon }}
        className={`${style.container} ${active ? classes.selected : ""}`}
      />
    </Link>
  )
}

export default FilterButton
