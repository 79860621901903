import { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import Reveal from "react-awesome-reveal"

import fadeInUp from "../../../../utils/animations/fade/fadeInUp"

import { ReactComponent as CheckIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/check.svg"

import style from "./style.module.css"
import sharedStyle from "../../style.module.css"

const ListItem = ({ title, children }: { title: ReactNode; children: ReactNode }) => {
  return (
    <li className={style.listItem}>
      <div>
        <CheckIcon className={style.checkIcon} />
        <div className={style.verticalLine} />
      </div>
      <div>
        <h3 className={sharedStyle.highlight}>{title}</h3>
        <p>{children}</p>
      </div>
    </li>
  )
}

const DigitsInDetails = () => {
  return (
    <>
      <section className={sharedStyle.section} id={style.section3}>
        <div className={`${sharedStyle.maxLayoutWidth} ${style.sectionContent}`}>
          <div>
            <Reveal duration={700} keyframes={fadeInUp(20)} triggerOnce>
              <h1 className={sharedStyle.title}>
                <FormattedMessage
                  id="b+338i"
                  description="[Home] section title"
                  defaultMessage="Enjoy the uniqueness of"
                />
                <br />
                <FormattedMessage
                  id="eko4fr"
                  description="[Home] section title hightlight"
                  defaultMessage="<textGradient>your Digit!</textGradient>"
                  values={{ textGradient: (text: string) => <span className={sharedStyle.highlight}>{text}</span> }}
                />
              </h1>
            </Reveal>
          </div>
          <ul className={sharedStyle.content}>
            <Reveal delay={500} duration={700} keyframes={fadeInUp(20)} triggerOnce>
              <ListItem
                title={
                  <FormattedMessage
                    id="sxaxDG"
                    description="[Home] list title - limited editions"
                    defaultMessage="Limited Editions"
                  />
                }
              >
                <FormattedMessage
                  tagName="span"
                  id="Me6Yn9"
                  description="[Home] list content - limited editions"
                  defaultMessage="Each Digit is released as part of an Edition that is composed of an immutable number of items."
                />
                <FormattedMessage
                  tagName="span"
                  id="HTxaI+"
                  description="[Home] list content - limited editions"
                  defaultMessage="No new Digits will ever be added to an existing Edition."
                />
              </ListItem>
              <ListItem
                title={
                  <FormattedMessage
                    id="55Uit9"
                    description="[Home] list title - serial numbers"
                    defaultMessage="Serial Numbers"
                  />
                }
              >
                <FormattedMessage
                  tagName="span"
                  id="4zm1qB"
                  description="[Home] list content - serial numbers"
                  defaultMessage="Every single Digit features a serial number. This serial number is displayed on the collectible and on
                    the in-game item."
                />
                <FormattedMessage
                  tagName="span"
                  id="LHMxtb"
                  description="[Home] list content - serial numbers"
                  defaultMessage="Only you have control of this Digit!"
                />
              </ListItem>
              <ListItem
                title={
                  <FormattedMessage
                    id="VyGvv3"
                    description="[Home] list title - player names"
                    defaultMessage="Player Names"
                  />
                }
              >
                <FormattedMessage
                  id="oQmfzY"
                  description="[Home] list content - player names"
                  defaultMessage="Each Digit will also be tied to the player names of all its previous and current owners… bringing you
                  fame for years to come!"
                />
              </ListItem>
            </Reveal>
          </ul>
        </div>
      </section>
    </>
  )
}

export default DigitsInDetails
