import { ReactNode } from "react"

import Button from "../Button"
import Dialog from "../Dialog"
import Spinner from "../Spinner"

import style from "./style.module.css"

export interface Props {
  open: boolean
  onClose: (confirm: boolean) => void
  icon?: ReactNode
  title: ReactNode
  content?: ReactNode
  confirmLabel?: string
  confirmIcon?: ReactNode
  confirmPending?: boolean
  cancelLabel: string
  invertButtonsColor?: boolean
}

const ConfirmDialog = ({
  open,
  onClose,
  icon,
  title,
  content,
  confirmLabel,
  confirmIcon,
  confirmPending = false,
  cancelLabel,
  invertButtonsColor = false,
}: Props) => {
  const handleConfirm = () => onClose(true)
  const handleCancel = () => {
    if (confirmPending) return
    onClose(false)
  }

  return (
    <Dialog open={open} onClose={handleCancel} className={style.container} fixedSize>
      {icon}
      <h3 className={style.title}>{title}</h3>
      <div className={style.content}>{content}</div>
      {confirmLabel && (
        <Button
          invert={!invertButtonsColor}
          icon={confirmPending ? <Spinner /> : confirmIcon}
          onClick={handleConfirm}
          className={style.confirm}
          disabled={confirmPending}
        >
          {confirmLabel}
        </Button>
      )}
      <Button
        invert={invertButtonsColor}
        small
        onClick={handleCancel}
        className={style.cancel}
        disabled={confirmPending}
      >
        {cancelLabel}
      </Button>
    </Dialog>
  )
}

export default ConfirmDialog
