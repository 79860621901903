/**
 * @generated SignedSource<<7484125de484f0a5a291d367e7d9b78b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CannotPurchaseError_variant$data = {
  readonly name: string;
  readonly categorization: {
    readonly game: {
      readonly name: string;
    } | null;
  } | null;
  readonly " $fragmentType": "CannotPurchaseError_variant";
};
export type CannotPurchaseError_variant$key = {
  readonly " $data"?: CannotPurchaseError_variant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CannotPurchaseError_variant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CannotPurchaseError_variant",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Categorization",
      "kind": "LinkedField",
      "name": "categorization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Game",
          "kind": "LinkedField",
          "name": "game",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Variant",
  "abstractKey": null
};
})();

(node as any).hash = "6af8ac5c7176ddce1a6f995905db6a99";

export default node;
