import { useFragment } from "react-relay"
import graphql from "babel-plugin-relay/macro"
import { useIntl } from "react-intl"

import { TokenCardFragment_token$key } from "./__generated__/TokenCardFragment_token.graphql"

import ipfsToHttpGateway from "../../../../utils/ipfsToHttpGateway"
import TokenCard from "."

interface Props {
  token: TokenCardFragment_token$key
  pending?: boolean
}

const TokenCardFragment = ({ token, pending = false }: Props) => {
  const data = useFragment(
    graphql`
      fragment TokenCardFragment_token on Token {
        id
        serialNumber
        archetype {
          primaryVariant {
            name
            image
            categorization {
              game {
                name
              }
            }
          }
        }
      }
    `,
    token
  )
  const intl = useIntl()

  const tag = pending
    ? intl.formatMessage({
        defaultMessage: "Processing...",
        id: "sOf6gu",
        description: "[Wallet Pending claimOnDemand or token Card] status tag label",
      })
    : ""

  return (
    <TokenCard
      to={`/token/${data.id}`}
      thumbnail={ipfsToHttpGateway(data.archetype.primaryVariant.image)}
      serialNumber={data.serialNumber}
      name={data.archetype.primaryVariant.name}
      game={data.archetype.primaryVariant.categorization?.game?.name || ""}
      tag={tag}
      tagColor={"primaryGradient"}
    />
  )
}

export default TokenCardFragment
