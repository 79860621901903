import { MichelsonData, MichelsonType, packDataBytes, Parser } from "@taquito/michel-codec"
import { TempleWallet } from "@temple-wallet/dapp"
import { KukaiEmbed } from "kukai-embed"

import blockchainConfig from "../../config/blockchainConfig"
import { getCounter } from "./getCounter"

function exprMichelineToJson(input: string): any {
  return new Parser().parseMichelineExpression(input)
}

const signEurlTransfer = (provider: TempleWallet | KukaiEmbed) => async (from: string, to: string, v: number) => {
  const counter = await getCounter(from)

  const contractAddress = process.env.REACT_APP_TEZOS_EURL_CONTRACT_ADDRESS
  const wallet = provider

  let account = null
  const value = v * 10 ** blockchainConfig.decimals
  if (wallet instanceof TempleWallet) {
    account = await wallet.getPKH()
  } else if (wallet instanceof KukaiEmbed) {
    account = wallet.user?.pkh
  }

  if (account !== from) {
    throw new Error("associated-wallet-unavailable")
  }

  const transferFeelessData: MichelsonData = exprMichelineToJson(
    `(Pair ${value} (Pair "${contractAddress}" (Pair ${counter} (Pair "${from}" (Pair "${to}" 0)))))`
  )

  const transferFeelessParamType: MichelsonType = exprMichelineToJson(
    "(pair nat (pair address (pair int (pair address (pair address nat)))))"
  )

  const packData = packDataBytes(transferFeelessData, transferFeelessParamType)
  const payload = packData.bytes

  let response = null
  if (wallet instanceof TempleWallet) {
    response = await wallet.sign(payload)
  } else if (wallet instanceof KukaiEmbed) {
    response = await wallet.signExpr(payload)
  } else {
  }

  return response
}

export default signEurlTransfer
