var config = {
    UAT: {
        env: "UAT",
        applicationId: "dbe611e1-90d1-48d4-9e58-aa37992174f2",
        genomeId: "4d682c92-9496-4d83-b59f-1037ba9f0f0f",
        loginURL: "https://uat-connect.ubisoft.com/login",
        connectLanguage: "en-US",
        accountURL: "https://uat.account.ubisoft.com",
        ubiServicesURL: "https://uat-public-ubiservices.ubi.com",
        games: {
            grb: {
                spaceId: "e5af8b3f-a867-4ed4-8804-229b50c9bb1f",
            },
        },
    },
    PROD: {
        env: "PROD",
        applicationId: "dbe611e1-90d1-48d4-9e58-aa37992174f2",
        genomeId: "4d682c92-9496-4d83-b59f-1037ba9f0f0f",
        loginURL: "https://connect.ubisoft.com/login",
        connectLanguage: "en-US",
        accountURL: "https://account.ubisoft.com",
        ubiServicesURL: "https://public-ubiservices.ubi.com",
        games: {
            grb: {
                spaceId: "e5af8b3f-a867-4ed4-8804-229b50c9bb1f",
            },
        },
    },
};
export default config;
