import { useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/styles"
import { FormattedMessage, useIntl } from "react-intl"

import Button from "../../components/Button"
import CardButton from "../../components/CardButton"
import Tag from "../../components/Tag"
import { trackClick } from "../../utils/analytics"
import walletProviders from "../../web3/walletProviders"

import style from "./style.module.css"

const trackDownloadClick = () => {
  trackClick({
    location: "ubisoft quartz",
    locationDetail: "wallet",
    category: "action",
    action: "connect : temple download",
  })
}

interface Props {
  available: boolean
  onConnectClick: () => void
}

const TempleButton = ({ available, onConnectClick }: Props) => {
  const intl = useIntl()
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"))

  return (
    <>
      {available ? (
        <CardButton
          iconLeft={<img className={style.icon} src={walletProviders.temple.logo} alt="" aria-hidden />}
          title={walletProviders["temple"].name}
          description={intl.formatMessage({
            defaultMessage: "Web extension for your browser",
            id: "Ee/Ozg",
            description: "[Connect Wallet] temple description",
          })}
          onClick={onConnectClick}
        />
      ) : (
        <CardButton
          iconLeft={
            <img className={`${style.icon} ${style.disabled}`} src={walletProviders.temple.logo} alt="" aria-hidden />
          }
          title={walletProviders["temple"].name}
          description={intl.formatMessage({
            defaultMessage: "Web extension for your browser",
            id: "Ee/Ozg",
            description: "[Connect Wallet] temple description",
          })}
          disabled
        >
          {isMobile ? (
            <Tag color="warning">
              <FormattedMessage
                defaultMessage="Desktop Only"
                id="glAeVI"
                description="[Connect Wallet] Temple provider desktop only tag"
              />
            </Tag>
          ) : (
            <Button small href="https://templewallet.com/download" target="_blank" onClick={trackDownloadClick}>
              <FormattedMessage
                defaultMessage="Download"
                id="VSS7PB"
                description="[Connect Wallet] Download Template button label"
              />
            </Button>
          )}
        </CardButton>
      )}
    </>
  )
}

export default TempleButton
