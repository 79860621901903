import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { addMinutes } from "date-fns"

import Button from "../Button"
import Countdown from "../Countdown"
import InfoBox from "../InfoBox"
import { ClaimStatus } from "../../hooks/useClaimStatus"
import { trackClick } from "../../utils/analytics"

import { ReactComponent as ArrowRightIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"

import style from "./style.module.css"

const buttonLabelMap = {
  [ClaimStatus.CONDITION_NOT_MET]: (
    <FormattedMessage
      defaultMessage="You can't claim this Digit"
      id="mfEDh5"
      description="[Product/Dashboard] Claim button label"
    />
  ),
  [ClaimStatus.CAN_CLAIM]: (
    <FormattedMessage
      defaultMessage="Claim for free"
      id="Lg7U9d"
      description="[Product/Dashboard] Claim free digit button label"
    />
  ),
  [ClaimStatus.CLAIM_NOT_STARTED]: (
    <FormattedMessage
      defaultMessage="Coming soon"
      id="ob8t0c"
      description="[Product/Dashboard] Claim coming soon button label"
    />
  ),
}

export interface Props {
  variantName: string
  endDate: Date | null
  claimStatus:
    | ClaimStatus.ALREADY_CLAIMED
    | ClaimStatus.CONDITION_NOT_MET
    | ClaimStatus.CAN_CLAIM
    | ClaimStatus.CLAIM_NOT_STARTED
    | ClaimStatus.CLAIM_ENDED
  className?: string
}

const ClaimStatusSection = ({ variantName, endDate, claimStatus, className = "" }: Props) => {
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000)
    return () => clearInterval(interval)
  })

  return (
    <div className={`${style.column} ${className}`}>
      {claimStatus !== ClaimStatus.ALREADY_CLAIMED && (
        <Button
          invert
          fullWidth
          icon={claimStatus === ClaimStatus.CAN_CLAIM && <ArrowRightIcon />}
          disabled={claimStatus !== ClaimStatus.CAN_CLAIM}
          onClick={() =>
            trackClick({
              location: "ubisoft quartz",
              locationDetail: "claim your free digits",
              category: "action",
              action: `claim your free digits : ${variantName}`,
            })
          }
        >
          {claimStatus !== ClaimStatus.CLAIM_ENDED && buttonLabelMap[claimStatus]}
        </Button>
      )}
      {claimStatus === ClaimStatus.ALREADY_CLAIMED && (
        <InfoBox>
          {endDate && endDate.getTime() > addMinutes(date, 1).getTime() ? (
            <FormattedMessage
              defaultMessage="Your Digit {variant} will become available in-game after the claim period ends in {date}."
              id="qTwDRp"
              description="[Product/Dashboard] Claimed token disclaimer"
              values={{
                variant: <strong>{variantName}</strong>,
                date: <Countdown type="basic" endDate={endDate} />,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Your Digit {variant} will become available in-game soon."
              id="LJ2pZ+"
              description="[Product/Dashboard] Claimed token disclaimer with ended date exceedeed"
              values={{
                variant: <strong>{variantName}</strong>,
              }}
            />
          )}
        </InfoBox>
      )}
      {claimStatus === ClaimStatus.CONDITION_NOT_MET && (
        <InfoBox>
          <FormattedMessage
            defaultMessage="Your player status does not allow you to acquire this Digit on Ubisoft Quartz"
            id="l1eDDy"
            description="[Product/Dashboard] Claim button label"
          />
        </InfoBox>
      )}
    </div>
  )
}

export default ClaimStatusSection
