import { Link } from "react-router-dom"
import { defineMessage, useIntl } from "react-intl"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import PageLayout from "../../components/PageLayout"
import PageHeader from "../../components/PageHeader"
import ProfileDescription from "./components/ProfileDescription"
import ProfileAchievement from "./components/ProfileAchievement"
import ProfileUserInfo from "./components/ProfileUserInfo"
import config from "../../config/ubisoftServicesConfig"
import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import { useUserInfoContext } from "../../providers/UserInfoProvider"
import usePlayerStats from "../../api/hooks/usePlayerStats"
import TrackPage from "../../components/TrackPage"

import { ReactComponent as SettingsIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/settings.svg"

import style from "./style.module.css"

const games = [
  {
    title: defineMessage({
      defaultMessage: "Ghost Recon Breakpoint status",
      id: "on4BbH",
      description: "[Profile] Game title",
    }),
    achievements: [
      {
        level: 5,
        description: defineMessage({
          defaultMessage: "You are eligible to the Ubisoft Quartz experience.",
          id: "+KuxL8",
          description: "[Profile] Achievement label",
        }),
      },
    ],
  },
]

const usePlayerLevel = (spaceID: string) => {
  const { user } = useUbisoftWebAuthSessionContext()

  // we can use the userID as long as it's only on Uplay platform otherwise we should get user profileID
  const { data, loading, error } = usePlayerStats(user.userID, spaceID, ["PlayerLevel"])

  const playerLevel = data?.stats.PlayerLevel?.value || "0"

  // TODO: use error in Profile components to notify the user the player level can't be loaded
  return { level: parseInt(playerLevel), loading, error }
}

const Profile = () => {
  const intl = useIntl()
  const { level, loading: playerLevelLoading } = usePlayerLevel(config.games.grb.spaceId)
  const { userInfo, isLoading: userInfoLoading } = useUserInfoContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const settingsLink = (
    <Link className={style.link} to="/user/settings">
      <SettingsIcon />
    </Link>
  )

  return (
    <>
      <TrackPage siteSection="profile" pageName="main" />
      <PageLayout
        header={
          isMobile && (
            <PageHeader
              title={intl.formatMessage({
                defaultMessage: "Profile",
                id: "Bbj6eM",
                description: "[Profile] page header title",
              })}
              level="primary"
              action={settingsLink}
              hideBackButton
            />
          )
        }
      >
        <ProfileDescription
          picture={userInfo.avatar}
          username={userInfo.username}
          className={style.descriptionWrapper}
        />
        <div className={style.container}>
          <ProfileUserInfo {...userInfo} isLoading={userInfoLoading} className={style.userInfoWrapper} />
          <div>
            {games.map((game) => (
              <ProfileAchievement
                key={intl.formatMessage(game.title)}
                game={intl.formatMessage(game.title)}
                achievements={game.achievements.map((achievement) => ({
                  ...achievement,
                  description: intl.formatMessage(achievement.description),
                }))}
                playerLevel={level}
                className={style.achievementWrapper}
                isLoading={playerLevelLoading}
              />
            ))}
          </div>
        </div>
      </PageLayout>
    </>
  )
}

export default Profile
