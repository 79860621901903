/**
 * @generated SignedSource<<6d25d3e0d0d70fe00a934645ee5b377b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductCardFragment_token$data = {
  readonly serialNumber: number;
  readonly video: string;
  readonly " $fragmentType": "ProductCardFragment_token";
};
export type ProductCardFragment_token$key = {
  readonly " $data"?: ProductCardFragment_token$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductCardFragment_token">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductCardFragment_token",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "video",
      "storageKey": null
    }
  ],
  "type": "Token",
  "abstractKey": null
};

(node as any).hash = "ba6a6410c41c13e858d2ea914c3a9906";

export default node;
