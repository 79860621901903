/**
 * @generated SignedSource<<de676d12bd1c69a44cefbc44eacf5807>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCanPurchaseFragment_sale$data = {
  readonly price: number;
  readonly condition: {
    readonly " $fragmentSpreads": FragmentRefs<"extractStatsNames_condition" | "playerStatsMeetCondition_saleCondition">;
  } | null;
  readonly archetype: {
    readonly id: string;
    readonly maxInstancesPerUser: number;
  };
  readonly " $fragmentType": "useCanPurchaseFragment_sale";
};
export type useCanPurchaseFragment_sale$key = {
  readonly " $data"?: useCanPurchaseFragment_sale$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCanPurchaseFragment_sale">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "spaceID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "SaleConditionStat",
  "abstractKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operator",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    (v0/*: any*/)
  ],
  "type": "SaleConditionStat",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "int",
      "storageKey": null
    }
  ],
  "type": "IntVal",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "float",
      "storageKey": null
    }
  ],
  "type": "FloatVal",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bool",
      "storageKey": null
    }
  ],
  "type": "BoolVal",
  "abstractKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCanPurchaseFragment_sale",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SaleCondition",
      "kind": "LinkedField",
      "name": "condition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "extractStatsNames_condition",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SaleConditionExpr",
              "kind": "LinkedField",
              "name": "expr",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "args",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "args",
                          "plural": true,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": null,
                                  "kind": "LinkedField",
                                  "name": "args",
                                  "plural": true,
                                  "selections": [
                                    (v1/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "type": "SaleConditionExpr",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "SaleConditionExpr",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "playerStatsMeetCondition_saleCondition",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SaleConditionExpr",
              "kind": "LinkedField",
              "name": "expr",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "args",
                  "plural": true,
                  "selections": [
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "args",
                          "plural": true,
                          "selections": [
                            (v3/*: any*/),
                            (v4/*: any*/),
                            (v5/*: any*/),
                            (v6/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": null,
                                  "kind": "LinkedField",
                                  "name": "args",
                                  "plural": true,
                                  "selections": [
                                    (v3/*: any*/),
                                    (v4/*: any*/),
                                    (v5/*: any*/),
                                    (v6/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "type": "SaleConditionExpr",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "SaleConditionExpr",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archetype",
      "kind": "LinkedField",
      "name": "archetype",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxInstancesPerUser",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Sale",
  "abstractKey": null
};
})();

(node as any).hash = "7f4565b97210ad90e666d307b4efc0b6";

export default node;
