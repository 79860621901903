import { Networks } from "kukai-embed";
var config = {
    testnet: {
        name: "ithaca",
        chainId: "NetXbhmtAbMukLc",
        decimals: 6,
        rpcNode: "https://ithacanet.smartpy.io",
        kukai: {
            net: Networks.dev,
        },
        temple: {
            network: "ithacanet",
        },
        tzkt: {
            url: "https://ithacanet.tzkt.io",
        },
    },
    mainnet: {
        name: "main",
        chainId: "NetXdQprcVkpaWU",
        decimals: 6,
        rpcNode: "https://tezos.ubs.ubisoft.com",
        kukai: {
            net: Networks.mainnet,
        },
        temple: {
            network: "mainnet",
        },
        tzkt: {
            url: "https://tzkt.io",
        },
    },
};
export default config;
