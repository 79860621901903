import { ButtonHTMLAttributes, ReactNode } from "react"
import { Link, To } from "react-router-dom"

import Tag from "../Tag"

import { ReactComponent as ChevronRightIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/chevronRight.svg"

import style from "./style.module.css"

export interface Props {
  iconLeft?: ReactNode
  iconRight?: ReactNode
  title: string
  description?: string
  to?: To
  onClick?: ButtonHTMLAttributes<HTMLElement>["onClick"]
  href?: string
  target?: string
  tag?: string
  disabled?: boolean
  children?: ReactNode
}

const CardButton = ({
  iconLeft,
  iconRight,
  title,
  description,
  to,
  onClick,
  href,
  target,
  tag,
  disabled = false,
  children,
}: Props) => {
  const rightIcon = iconRight ? iconRight : <ChevronRightIcon className={style.chevron} aria-hidden />

  const content = (
    <>
      {iconLeft}
      <div className={style.nameContainer}>
        {tag && (
          <Tag color="primaryGradient" className={style.tag}>
            {tag}
          </Tag>
        )}
        {description ? (
          <>
            <h4>{title}</h4>
            <div className={style.description}>{description}</div>
          </>
        ) : (
          <span className={style.title}>{title}</span>
        )}
      </div>
    </>
  )

  if (to && !disabled) {
    return (
      <Link to={to} className={`${style.container} ${style.interactive}`} onClick={onClick}>
        {content}
        {rightIcon}
      </Link>
    )
  }

  if (href && !disabled) {
    return (
      <a href={href} className={`${style.container} ${style.interactive}`} target={target} onClick={onClick}>
        {content}
        {rightIcon}
      </a>
    )
  }

  if (onClick && !disabled) {
    return (
      <button className={`${style.container} ${style.interactive}`} onClick={onClick}>
        {content}
        {rightIcon}
      </button>
    )
  }

  return (
    <div className={`${style.container} ${disabled ? style.disabled : ""}`}>
      {content}
      {children}
    </div>
  )
}

export default CardButton
