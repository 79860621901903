import { keyframes } from "@emotion/react"

export const fadeInRight = (start: number) => keyframes`
from {
  opacity: 0;
  transform: translate3d(${start}px, 0, 0);
}

to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`

export default fadeInRight
