import graphql from "babel-plugin-relay/macro"
import { useCallback, useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useMutation } from "react-relay"

import settings from "../../config/settings"
import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import { useUserInfoContext } from "../../providers/UserInfoProvider"
import Button from "../Button"
import Dialog from "../Dialog"
import Checkbox from "../Checkbox"
import Spinner from "../Spinner"
import TrackImpression from "../TrackImpression"

import { ReactComponent as CheckIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/check.svg"

import style from "./style.module.css"

const acceptTermsDialogMutation = graphql`
  mutation AcceptTermsDialogMutation($version: String!) {
    acceptTerms(version: $version)
  }
`

export interface Props {
  dismissed: boolean
  onClose: (confirm: boolean) => void
}

const AcceptTermsDialog = ({ dismissed, onClose }: Props) => {
  const [commit, isInFlight] = useMutation(acceptTermsDialogMutation)
  const [error, setError] = useState<Error | null>(null)
  const [acceptTerms, setAcceptTerms] = useState(false)

  const { user } = useUbisoftWebAuthSessionContext()
  const { userInfo } = useUserInfoContext()
  const open = useMemo(() => !!user.ticket && !dismissed && !userInfo.acceptedTerms, [user, dismissed, userInfo])

  const commitMutation = useCallback(() => {
    setError(null)
    commit({
      variables: {
        version: settings.termsVersion,
      },
      onCompleted: () => onClose(true),
      onError: (error) => {
        setError(error)
      },
    })
  }, [commit, onClose])

  const handleCancel = useCallback(() => onClose(false), [onClose])
  const handleContinue = useCallback(() => commitMutation(), [commitMutation])

  return (
    <Dialog open={open} onClose={handleCancel} className={style.container} hideTopCloseButton fixedSize>
      <TrackImpression locationDetail="welcome" />
      <img className={style.picture} src={userInfo.avatar} alt="User profile" />
      <h3 className={style.title}>
        <FormattedMessage
          defaultMessage="Welcome {playerName}!"
          id="obMlU9"
          description="[Terms Dialog] Terms dialog title"
          values={{ playerName: userInfo.username }}
        />
      </h3>
      <div className={style.content}>
        <FormattedMessage
          defaultMessage="Before getting started, please read and accept our Terms of Use and Ubisoft Privacy Policy."
          id="tkzhdr"
          description="[Terms Dialog] Terms dialog content message"
        />
        <div className={style.input}>
          <Checkbox id="terms" checked={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)} />
          <label htmlFor="terms">
            <FormattedMessage
              defaultMessage="I agree to the Ubisoft Quartz <termsLink>Terms of Use</termsLink> and the Ubisoft <privacyLink>Privacy Policy</privacyLink>"
              id="64D/nG"
              description="[Terms Dialog] Terms dialog checkbox label"
              values={{
                termsLink: (chunks: string) => (
                  <a
                    href="https://legal.ubi.com/ubisoftquartzterms"
                    target="_blank"
                    rel="noreferrer"
                    className={style.link}
                  >
                    {chunks}
                  </a>
                ),
                privacyLink: (chunks: string) => (
                  <a
                    href="https://legal.ubi.com/privacypolicy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={style.link}
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </label>
        </div>
      </div>
      <Button
        invert
        icon={isInFlight ? <Spinner /> : <CheckIcon />}
        onClick={handleContinue}
        className={style.action}
        disabled={!acceptTerms || isInFlight}
      >
        <FormattedMessage
          defaultMessage="Accept"
          id="RfiX5U"
          description="[Terms Dialog] Terms dialog accept button label"
        />
      </Button>
      {error && (
        <div className={style.error}>
          <FormattedMessage
            defaultMessage="An error occurred, please try again later."
            id="oBrviZ"
            description="[Terms Dialog] error message"
          />
        </div>
      )}
      <Button onClick={handleCancel} className={style.action}>
        <FormattedMessage
          defaultMessage="Decline"
          id="GvrNO4"
          description="[Terms Dialog] Terms dialog decline button label"
        />
      </Button>
    </Dialog>
  )
}

export default AcceptTermsDialog
