import { Link } from "react-router-dom"
import { FormattedMessage, IntlShape } from "react-intl"

import style from "../style.module.css"

const sellingDigits = (intl: IntlShape) => [
  {
    id: "can-i-sell-my-digits",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Can I sell my Digits?",
      id: "H4+wtf",
      description: "Faq selling digits question 0",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Yes, this is part of the new opportunities Digits bring you. With Ubisoft Quartz, your in-game items are no longer bound to remain stuck in your inventory: once you’re done playing with them, you can simply put them on sale to other eligible Tom Clancy’s Ghost Recon® Breakpoint Ubisoft Connect PC players."
          id="BmqDuh"
          description="Faq selling digits response 0 paragraph 1"
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="All Digits can be put on sale on the authorized third-party marketplace listing the Digits, <link1>Rarible</link1>"
          id="uz3Xbr"
          description="Faq selling digits response 0 paragraph 2"
          values={{
            link1: (chunks: string) => (
              <a href="https://rarible.com/ubisoft-quartz" target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
          }}
        />
      </>
    ),
  },
  {
    id: "how-do-i-put-an-item-on-sale",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "How do I put an item on sale?",
      id: "vV0GhX",
      description: "Faq selling digits question 1",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Ubisoft Quartz is the primary place to get Digits. If you would like to put one of your Digits on sale, you will need to do it on the authorized third-party marketplace listing the Digits, <link1>Rarible</link1>. Note that those services are not provided by Ubisoft, and that third-party marketplaces operate under their own terms and conditions."
          id="VE/P5p"
          description="Faq selling digits response 1 paragraph 1"
          values={{
            link1: (chunks: string) => (
              <a href="https://rarible.com/ubisoft-quartz" target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
          }}
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="Make sure to connect to the marketplace with the same <link3>crypto-wallet</link3> as the one connected to Ubisoft Quartz, then follow the process!"
          id="M1QxwY"
          description="Faq selling digits response 1 paragraph 2"
          values={{
            link3: (chunks: string) => (
              <Link to="/faq/crypto-wallet/what-do-i-need-to-use-ubisoft-quartz">{chunks}</Link>
            ),
          }}
        />
      </>
    ),
  },
  {
    id: "are-there-any-commissions-and-fees-on-digit-resale",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Are there any commissions and fees on Digit resale?",
      id: "HmR2p9",
      description: "Faq selling digits question 2",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="On both authorized marketplaces for Digits, a fee will be charged by the secondary market platform operator on each transaction, and in some cases, by Ubisoft as well."
          id="u3+MpJ"
          description="Faq selling digits response 2 paragraph 1"
        />
        <FormattedMessage
          tagName="ul"
          defaultMessage="<listitem>The fee charged by the secondary market platform is at the sole discretion of the platform operator.</listitem><listitem>When it applies, the fee to Ubisoft will be a percentage of the value paid by the buyer.</listitem>"
          id="tVqMLm"
          description="Faq selling digits response 2 paragraph 2"
          values={{ listitem: (item: string) => <li className={style.listitem}>{item}</li> }}
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="Note that those services are not provided by Ubisoft, and that the marketplaces operate under their own terms and conditions."
          id="h0mG5o"
          description="Faq selling digits response 2 paragraph 3"
        />
      </>
    ),
  },
  {
    id: "do-i-need-to-pay-taxes-on-the-proceeds-of-the-sale-of-my-Digits",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Do I need to pay taxes on the proceeds of the sale of my Digits?",
      id: "DdKpNv",
      description: "Faq technology question 7",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="This depends on the regulation for the territory you reside in. Players are responsible for reviewing the personal income tax obligations related to any income that would be generated from the resale of Digits."
          id="BaqMLN"
          description="Faq technology response 7 paragraph 1"
        />
      </>
    ),
  },
]

export default sellingDigits
