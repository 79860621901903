import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useNavigate } from "react-router-dom"

import PageHeader from "../../components/PageHeader"
import PageLayout from "../../components/PageLayout"
import CardButton from "../../components/CardButton"
import LogoutConfirmDialog from "../../components/LogoutConfirmDialog"
import TrackPage from "../../components/TrackPage"

import { ReactComponent as LogoutIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/logout.svg"

import style from "./style.module.css"

const Settings = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"))

  const handleFaqClick = () => navigate("/faq")

  const handleLogoutClick = () => setConfirmDialogOpen(true)

  const handleDialogClose = () => {
    setConfirmDialogOpen(false)
  }

  return (
    <>
      <TrackPage siteSection="profile" pageName="settings" />
      <PageLayout
        header={
          isMobile && (
            <PageHeader
              title={intl.formatMessage({
                defaultMessage: "Settings",
                id: "7p7O/6",
                description: "[Settings] Page Title",
              })}
            />
          )
        }
        bodyClassName={style.body}
      >
        <div className={style.content}>
          <ul>
            <li className={style.item}>
              <CardButton
                title={intl.formatMessage({
                  defaultMessage: "FAQ",
                  id: "EXt8/j",
                  description: "[Settings] Faq button label",
                })}
                onClick={handleFaqClick}
              />
            </li>
            <li className={style.item}>
              <CardButton
                title={intl.formatMessage({
                  defaultMessage: "Log out",
                  id: "tZvIbV",
                  description: "[Settings] Log out button label",
                })}
                onClick={handleLogoutClick}
                iconRight={<LogoutIcon className={style.danger} />}
              />
            </li>
          </ul>
          <ul className={style.horizontalList}>
            <li>
              <a
                className={`${style.link} caption`}
                href="https://legal.ubi.com/privacypolicy"
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage
                  defaultMessage="Privacy Policy"
                  id="pfE51G"
                  description="[Settings] Privacy Policy link label"
                />
              </a>
            </li>
            <li>
              <a
                href="https://legal.ubi.com/ubisoftquartzterms"
                target="_blank"
                rel="noreferrer"
                className={`${style.link} caption`}
              >
                <FormattedMessage
                  defaultMessage="Terms of Use"
                  id="YQwjim"
                  description="[Settings] Terms of Use link label"
                />
              </a>
            </li>
          </ul>
        </div>
        <div className={style.version}>
          <span className="caption">
            <FormattedMessage defaultMessage="Version" id="nVbpJy" description="[Settings] version label" />
          </span>
          <span className="caption">{process.env.REACT_APP_VERSION}</span>
        </div>
        <LogoutConfirmDialog open={confirmDialogOpen} onClose={handleDialogClose} />
      </PageLayout>
    </>
  )
}

export default Settings
