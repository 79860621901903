/* TODO Add production config */
var config = {
    UAT: {
        ramp: {
            apiKey: "qgtv9vrn8zpw6jbqprevzhxwzojremomjgy3d4au",
            url: "https://ri-widget-staging.firebaseapp.com/",
        },
        moonpay: {
            apiKey: "pk_test_123",
            apiSecret: "sk_test_IqZr6xJHrPLRSi7mqOLX1RTyqi36bJuG",
            url: "https://buy-staging.moonpay.com",
        },
        wert: {
            url: "https://sandbox.wert.io/default/widget",
        },
    },
};
export default config;
