import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import graphql from "babel-plugin-relay/macro"
import { fetchQuery, Subscription } from "relay-runtime"

import { UserInfoProviderQuery } from "./__generated__/UserInfoProviderQuery.graphql"

import { getAvatar, getEntities, getUserInfo } from "../api/ubiservices"
import { UbisoftWebAuthSession, useUbisoftWebAuthSessionContext } from "../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import useRelayEnvironment from "../hooks/useRelayEnvironment"
import ubisoftServicesConfig from "../config/ubisoftServicesConfig"

import defaultAvatar from "https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/avatarPlaceholder.jpg"

const getEpicOrLunaState = (
  entities: Array<{ obj: { oncePlayedEpic: boolean; oncePlayedLuna: boolean } }>
): "unknown" | "played" | "unplayed" => {
  if (entities.length === 0) return "unknown"
  if (entities.some(({ obj }) => obj["oncePlayedEpic"] || obj["oncePlayedLuna"])) return "played"

  return "unplayed"
}

const userInfoQuery = graphql`
  query UserInfoProviderQuery {
    currentPlayer {
      id
      acceptedTerms
      walletAddress
      eligibility {
        acceptedTermsCriteria
        walletCriteria
        ageCriteria
        registeredCountryCriteria
        gameLevelCriteria
        platformCriteria
        exception
      }
    }
  }
`

export interface UserInfo {
  id: string
  country: string
  dateOfBirth: string
  email: string
  preferredLanguage: string
  username: string
  avatar: string
  acceptedTerms: null | string
  walletAddress: null | string
  eligibility: {
    acceptedTermsCriteria: boolean
    walletCriteria: boolean
    ageCriteria: boolean
    registeredCountryCriteria: boolean
    gameLevelCriteria: boolean
    platformCriteria: boolean
    exception: boolean
  }
  epicOrLunaState: ReturnType<typeof getEpicOrLunaState>
}

const EMPTY_USER_SESSION: UbisoftUserSession = {
  isLoading: true,
  userInfo: {
    id: "",
    country: "",
    dateOfBirth: "",
    email: "",
    preferredLanguage: "",
    username: "",
    avatar: defaultAvatar,
    acceptedTerms: null,
    walletAddress: null,
    eligibility: {
      acceptedTermsCriteria: false,
      walletCriteria: false,
      ageCriteria: false,
      registeredCountryCriteria: false,
      gameLevelCriteria: false,
      platformCriteria: true,
      exception: false,
    },
    epicOrLunaState: "unknown",
  },
  refresh: null,
  refreshQuartzInfo: null,
}

export const UserInfoContext = createContext<UbisoftUserSession>(EMPTY_USER_SESSION)

/*
 * Contains user's information.
 *
 * @property isLoading Is true when the user's information hasn't been loaded yet (used to display the loading screen while fetching the user's info).
 * @property userInfo Holds the current user info.
 * @property refresh Gives the possibility to refetch the lastest user info.
 *
 */
export interface UbisoftUserSession {
  isLoading: boolean
  userInfo: UserInfo
  refresh: (() => (() => void) | null) | null
  refreshQuartzInfo: (() => Subscription | null) | null
}

export const useUserInfoContext = () => {
  const context = useContext(UserInfoContext)
  if (context === undefined) {
    throw new Error("useUserInfoContext must be used within a UserInfoProvider")
  }
  return context
}

const useUserInfo = ({ user }: UbisoftWebAuthSession) => {
  const environment = useRelayEnvironment(user)
  const [userInfo, setUserInfo] = useState<UserInfo>(EMPTY_USER_SESSION.userInfo)
  const [isLoading, setIsLoading] = useState(true)

  const refresh = useCallback(() => {
    if (!user.ticket) {
      setUserInfo(EMPTY_USER_SESSION.userInfo)
      setIsLoading(false)

      return null
    }

    const { userInfo, cancelUserInfo } = getUserInfo(user.ticket, user.sessionID)
    const { avatar, cancelAvatar } = getAvatar(user.ticket, user.sessionID, user.userID)
    const { entities, cancelEntities } = getEntities(
      user.ticket,
      user.sessionID,
      user.userID,
      ubisoftServicesConfig.games["grb"].spaceId,
      "Quartz_PCPlatformsOncePlayed"
    )
    const quartzUserInfo = fetchQuery<UserInfoProviderQuery>(environment, userInfoQuery, {}).toPromise()

    setIsLoading(true)
    Promise.all([quartzUserInfo, userInfo, avatar, entities])
      .then(([quartzUserInfoData, userInfoData, avatarData, entities]) => {
        const epicOrLunaState = getEpicOrLunaState(entities.entities)
        setUserInfo((prevState) => ({
          ...prevState,
          ...userInfoData,
          username: userInfoData.nameOnPlatform || userInfoData.username,
          avatar: avatarData || defaultAvatar,
          ...quartzUserInfoData?.currentPlayer,
          epicOrLunaState,
        }))
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))

    return () => {
      cancelUserInfo()
      cancelAvatar()
      cancelEntities()
    }
  }, [environment, user])

  /* TODO Unused */
  const refreshQuartzInfo = useCallback(() => {
    if (!user.ticket) {
      setUserInfo(EMPTY_USER_SESSION.userInfo)

      return null
    }

    return fetchQuery<UserInfoProviderQuery>(environment, userInfoQuery, {}).subscribe({
      next: (quartzInfo) => {
        setUserInfo((prevState) => ({
          ...prevState,
          ...quartzInfo?.currentPlayer,
        }))
      },
    })
  }, [environment, user])

  useEffect(() => {
    const cancel = refresh()

    return () => {
      cancel && cancel()
    }
  }, [user, refresh])

  return {
    isLoading,
    userInfo,
    refresh,
    refreshQuartzInfo,
  }
}

interface Props {
  children: ReactNode
}

const UserInfoProvider = ({ children }: Props) => {
  const session = useUbisoftWebAuthSessionContext()
  const userInfo = useUserInfo(session)

  return <UserInfoContext.Provider value={userInfo}>{children}</UserInfoContext.Provider>
}

export default UserInfoProvider
