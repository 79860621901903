import graphql from "babel-plugin-relay/macro"
import { readInlineData } from "relay-runtime"

import { extractStatsNames_condition$key } from "./__generated__/extractStatsNames_condition.graphql"

interface ConditionExpr {
  key?: string
  args?: ReadonlyArray<ConditionExpr>
}

const extractFromArgs = (conditions: ReadonlyArray<ConditionExpr>) => {
  return Array.from(
    conditions.reduce((stats, condition) => {
      if (condition.key) {
        stats.add(condition.key)
      } else if (condition.args) {
        extractFromArgs(condition.args).forEach((key) => stats.add(key))
      }

      return stats
    }, new Set<string>())
  )
}

const extractStatsNames = (conditionRef: extractStatsNames_condition$key | null) => {
  const condition = readInlineData(
    graphql`
      fragment extractStatsNames_condition on SaleCondition @inline @relay(mask: false) {
        # AND
        expr {
          args {
            ... on SaleConditionStat {
              key
              spaceID
              type
            }
            ... on SaleConditionExpr {
              operator # EQ, GT, GTE, LT, LTE, NEQ
              args {
                ... on SaleConditionStat {
                  key
                  spaceID
                  type
                }
                ... on SaleConditionExpr {
                  args {
                    ... on SaleConditionStat {
                      key
                      spaceID
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    conditionRef
  )

  return condition?.expr?.args ? extractFromArgs(condition.expr?.args) : []
}

export default extractStatsNames
