export const buildCustomParameter = (
  game: string,
  archetypeName: string,
  serial: number | "",
  maxInstances: number,
  price: number,
  soldOut: boolean | "",
  freeDrop: boolean | ""
) => {
  const tokenValue = serial === "" ? "" : `${serial}/${maxInstances}`
  const soldOutValue = soldOut === "" ? "" : soldOut ? "yes" : "no"
  const dropTypeValue = freeDrop === "" ? "" : freeDrop ? "free drop" : "paid drop"
  return `${game} - ${archetypeName} - ${tokenValue} - ${price} - ${soldOutValue} - ${dropTypeValue}`
}

interface ClickEventParameters {
  location: string
  locationDetail: string
  category: string
  action: string
}

export const trackClick = (parameters: ClickEventParameters) => {
  window.adobeAnalytics.track("clickevent", parameters)
}
