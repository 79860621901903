import { ReactNode } from "react"
import { LinkProps, To } from "react-router-dom"

import Button from "../../Button"
import RainbowTitle from "../../RainbowTitle"

import style from "./style.module.css"

interface Props {
  title: string
  message: ReactNode
  buttonLabel: string
  buttonIcon: ReactNode
  to?: To
  state?: LinkProps["state"]
  onClick?: () => void
  buttonInvert?: boolean
}

const BasePurchaseError = ({
  title,
  message,
  buttonLabel,
  buttonIcon,
  to,
  state,
  onClick,
  buttonInvert = true,
}: Props) => (
  <div className={style.container}>
    <RainbowTitle className={style.title} text={title} />
    <p className="paragraph">{message}</p>
    <Button fullWidth invert={buttonInvert} icon={buttonIcon} to={to} state={state} onClick={onClick}>
      {buttonLabel}
    </Button>
  </div>
)

export default BasePurchaseError
