import { Link } from "react-router-dom"
import { FormattedMessage, IntlShape } from "react-intl"

const cryptoWallet = (intl: IntlShape) => [
  {
    id: "what-do-i-need-to-use-ubisoft-quartz",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "What do I need to use Ubisoft Quartz?",
      id: "uH41h7",
      description: "Faq crypto-wallet question 0",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="You need to connect a compatible crypto-wallet to the Ubisoft Quartz ecosystem in order to host your <link>Digits</link> once acquired. You can consult the list of compatible crypto-wallets on the <link>Crypto-wallet section</link> of the Ubisoft Quartz website"
          id="booS+3"
          description="Faq crypto-wallet response 0 paragraph 1"
          values={{
            link: (chunks: string) => <Link to="/faq/ubisoft-quartz-and-digits/what-is-a-digit">{chunks}</Link>,
          }}
        />
      </>
    ),
  },
  {
    id: "how-do-i-create-a-crypto-wallet",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "How do I create a crypto-wallet?",
      id: "+axu+9",
      description: "Faq crypto-wallet question 1",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="You can follow the flow on the website during the acquisition process or directly go to the <link>Crypto-wallet section</link>. We have listed the crypto-wallet providers that are compatible with our platform."
          id="DnrRTT"
          description="Faq crypto-wallet response 1 paragraph 1"
          values={{
            link: (chunks: string) => <Link to="/user/wallet">{chunks}</Link>,
          }}
        />
      </>
    ),
  },
  {
    id: "how-to-connect-my-existing-crypto-wallet-to-ubisoft-quartz",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "How to connect my existing crypto-wallet to Ubisoft Quartz?",
      id: "miEend",
      description: "Faq crypto-wallet question 2",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="If you already have a crypto-wallet that is compatible with both the <link>Tezos</link> blockchain and Ubisoft Quartz, you can <link>connect</link> it to our platform by clicking on the dedicated link. You can consult the list of compatible crypto-wallets on the <link>Crypto-wallet section</link> of the website."
          id="r31Me3"
          description="Faq crypto-wallet response 2 paragraph 1"
          values={{
            link: (chunks: string) => (
              <a href="https://tezos.com/learn/what-is-tezos" target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
          }}
        />
      </>
    ),
  },
  {
    id: "can-i-have-multiple-crypto-wallets-linked-to-ubisoft-quartz",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Can I have multiple crypto-wallets linked to Ubisoft Quartz?",
      id: "tkfZoG",
      description: "Faq crypto-wallet question 3",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="For both technical and security reasons, you can’t have more than one crypto-wallet connected to the Ubisoft Quartz platform at a single time."
          id="gS69Hm"
          description="Faq crypto-wallet response 3 paragraph 1"
        />
      </>
    ),
  },
  {
    id: "can-i-change-the-crypto-wallet-i-connected-to-ubisoft-quartz",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Can I change the crypto-wallet I connected to Ubisoft Quartz?",
      id: "eAWyuA",
      description: "Faq crypto-wallet question 4",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Yes. There are some cases in which you might want to connect another crypto-wallet. You will be able to do so but please note that, for security reasons, you cannot change your crypto-wallet more than once every 30 days."
          id="/VCTCm"
          description="Faq crypto-wallet response 4 paragraph 1"
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="If you want to switch to another crypto-wallet provider, please get in touch with the Ubisoft Support teams."
          id="CsHucY"
          description="Faq crypto-wallet response 4 paragraph 2"
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="Note that in the event of someone getting hold of your crypto-wallet, neither Ubisoft nor the crypto-wallet provider will be able to get the Digits back to another crypto-wallet."
          id="w0BItm"
          description="Faq crypto-wallet response 4 paragraph 2"
        />
      </>
    ),
  },
  {
    id: "is-my-ubisoft-wallet-a-crypto-wallet",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Is my Ubisoft Wallet a crypto-wallet?",
      id: "FgnjqT",
      description: "Faq crypto-wallet question 5",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="No, your crypto-wallet is not the same as your Ubisoft Wallet. Your crypto-wallet is not provided by Ubisoft and is not a Ubisoft service. It is provided by a third party which operates under its own terms and conditions."
          id="rVUunt"
          description="Faq crypto-wallet response 5 paragraph 1"
        />
      </>
    ),
  },
  {
    id: "how-do-i-keep-my-crypto-wallet-and-my-digits-secure",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "How do I keep my crypto-wallet and my Digits secure?",
      id: "MBqqTW",
      description: "Faq crypto-wallet question 6",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="You are responsible for the security of your crypto-wallet. Make sure to never share your crypto-wallet’s password, private key or seed phrase."
          id="gr2uzg"
          description="Faq crypto-wallet response 6 paragraph 1"
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="Note that Ubisoft will never ask you to share your private key."
          id="+C3Z4m"
          description="Faq crypto-wallet response 6 paragraph 2"
        />
      </>
    ),
  },
]

export default cryptoWallet
