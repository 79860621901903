import { IntlShape } from "react-intl"

const formatPlaytime = (intl: IntlShape, seconds: number, alwaysDisplayMinutes: boolean = false) => {
  const minutes = Math.floor(seconds / 60)
  const hours = minutes / 60
  const flooredHours = Math.floor(hours)
  const flooredMinutes = (hours - flooredHours) * 60

  if (!flooredHours) return intl.formatNumber(flooredMinutes, { style: "unit", unit: "minute" })

  if (!alwaysDisplayMinutes && !flooredMinutes) return intl.formatNumber(flooredHours, { style: "unit", unit: "hour" })

  return intl.formatList(
    [
      intl.formatNumber(flooredHours, { style: "unit", unit: "hour" }),
      intl.formatNumber(flooredMinutes, { style: "unit", unit: "minute" }),
    ],
    { type: "unit" }
  )
}

export default formatPlaytime
