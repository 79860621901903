/**
 * @generated SignedSource<<aa41f5a2a72b2a0e3c9bc33973113b89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TokenClaimFragment_variant$data = {
  readonly name: string;
  readonly categorization: {
    readonly game: {
      readonly id: string;
      readonly name: string;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ClaimSectionFragment_variant">;
  readonly " $fragmentType": "TokenClaimFragment_variant";
};
export type TokenClaimFragment_variant$key = {
  readonly " $data"?: TokenClaimFragment_variant$data;
  readonly " $fragmentSpreads": FragmentRefs<"TokenClaimFragment_variant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TokenClaimFragment_variant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClaimSectionFragment_variant"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Categorization",
      "kind": "LinkedField",
      "name": "categorization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Game",
          "kind": "LinkedField",
          "name": "game",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Variant",
  "abstractKey": null
};
})();

(node as any).hash = "fec8baa639bb4bec66cecefe28972f5a";

export default node;
