import { default as DrawerMui } from "@mui/material/Drawer"
import { ReactNode } from "react"

import IconButton from "../../components/IconButton"

import { ReactComponent as CloseIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/close.svg"

import style from "./style.module.css"

export interface Props {
  open: boolean
  onClose?: () => void

  icon?: ReactNode
  title: string
  children: ReactNode
  anchor?: "bottom" | "right"
}

const Drawer = ({ open, onClose, icon, title, children, anchor = "bottom" }: Props) => {
  const handleOnClose = () => onClose && onClose()

  return (
    <DrawerMui
      anchor={anchor}
      style={{ zIndex: 1500 }}
      classes={{ paper: style.container, paperAnchorRight: style.anchorRight }}
      open={open}
      onClose={onClose}
    >
      <header className={style.header}>
        {icon}
        <h4 className={style.title}>{title}</h4>
        <IconButton className={style.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </header>
      <div className={style.content}>{children}</div>
    </DrawerMui>
  )
}

export default Drawer
