/**
 * @generated SignedSource<<487ccd48588493f789a92b6beff94663>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClaimStatusSectionFragment_sale$data = {
  readonly endDate: string | null;
  readonly archetype: {
    readonly primaryVariant: {
      readonly name: string;
    };
  };
  readonly " $fragmentType": "ClaimStatusSectionFragment_sale";
};
export type ClaimStatusSectionFragment_sale$key = {
  readonly " $data"?: ClaimStatusSectionFragment_sale$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClaimStatusSectionFragment_sale">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClaimStatusSectionFragment_sale",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Archetype",
      "kind": "LinkedField",
      "name": "archetype",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Variant",
          "kind": "LinkedField",
          "name": "primaryVariant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Sale",
  "abstractKey": null
};

(node as any).hash = "15797d9820d956a243994c50d86084a3";

export default node;
