import { useMemo } from "react"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useIntl } from "react-intl"
import { Route, Routes, useNavigate, useLocation } from "react-router-dom"

import PageLayout from "../../components/PageLayout"
import PageHeader from "../../components/PageHeader"
import RainbowTitle from "../../components/RainbowTitle"
import FilterButton from "../../components/FilterButton"
import Question from "./components/Question"
import Answer from "./components/Answer"
import getCategories from "./utils/getCategories"
import TrackPage from "../../components/TrackPage"
import NotFound from "../NotFound"

import style from "./style.module.css"

const Faq = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { search } = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(search).get("categoryId")?.split(","), [search])
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"))
  const isPopularQuestionsDisplayed = useMediaQuery(useTheme().breakpoints.up("lg"))
  const activeCategories = useMemo<string[]>(() => queryParams || ["all"], [queryParams])
  const allSelected = useMemo(() => !queryParams, [queryParams])
  const categories = getCategories(intl)
  const popularQuestions = categories
    .map((category) => {
      const question = category.content.filter((question) => question.popular)
      return { id: category.id, content: question }
    })
    .filter((el) => el.content.length)

  const onClickQuestion = (location: string, categoryId: string, questionId: string) => {
    const nextUrl = `/faq/${categoryId}/${questionId}`
    location === "/faq" ? navigate(nextUrl) : navigate(nextUrl, { replace: true })
  }

  return (
    <Routes>
      <Route
        path=""
        element={
          <>
            <TrackPage siteSection="faq" pageName="main" />
            <PageLayout
              header={
                isMobile && (
                  <PageHeader
                    title={intl.formatMessage({
                      defaultMessage: "FAQ",
                      id: "rbUzNa",
                      description: "[FAQ] page header title",
                    })}
                  />
                )
              }
              bodyClassName={style.container}
            >
              <>
                <div>
                  <ul className={style.list}>
                    <li>
                      <FilterButton
                        id="all"
                        label={intl.formatMessage({
                          defaultMessage: "All",
                          id: "XydeXy",
                          description: "[FAQ] filter all button label",
                        })}
                        selected={allSelected}
                      />
                    </li>
                    {categories.map((category, index) => (
                      <li key={index}>
                        <FilterButton
                          id={category.id}
                          label={category.name}
                          count={category.content.length}
                          selected={activeCategories.includes(category.id)}
                        />
                      </li>
                    ))}
                  </ul>
                  <ul className={style.content}>
                    {categories.map(
                      (category) =>
                        (activeCategories.includes(category.id) || allSelected) && (
                          <li key={category.id} className={style.section}>
                            <RainbowTitle
                              text={intl.formatMessage(
                                {
                                  defaultMessage: "About {category}",
                                  id: "/+oFPw",
                                  description: "Faq section",
                                },
                                {
                                  category: category.name,
                                }
                              )}
                            />
                            <ul>
                              {category.content.map(({ id, question, response }) => (
                                <li key={id}>
                                  <Question
                                    id={id}
                                    question={question}
                                    response={response}
                                    categoryId={category.id}
                                    onClick={onClickQuestion}
                                  />
                                </li>
                              ))}
                            </ul>
                          </li>
                        )
                    )}
                  </ul>
                </div>
                {isPopularQuestionsDisplayed && (
                  <div className={style.popular}>
                    <RainbowTitle
                      text={intl.formatMessage({
                        defaultMessage: "Popular questions",
                        id: "Wl+OSn",
                        description: "Faq popular section",
                      })}
                    />
                    <ul>
                      {popularQuestions.map((category) =>
                        category.content.map(
                          ({ id, question, response, popular }) =>
                            popular && (
                              <li key={id}>
                                <Question
                                  id={id}
                                  question={question}
                                  response={response}
                                  categoryId={category.id}
                                  onClick={onClickQuestion}
                                />
                              </li>
                            )
                        )
                      )}
                    </ul>
                  </div>
                )}
              </>
            </PageLayout>
          </>
        }
      />
      <Route
        path={`:categoryId/:questionId`}
        element={<Answer categories={categories} onClickQuestion={onClickQuestion} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Faq
