import { useMemo } from "react"

import { WalletQuery$data } from "../__generated__/WalletQuery.graphql"

import usePendingTokens from "../../../hooks/usePendingTokens"
import { PendingProps } from "../components/Inventory"

const useInventory = (playerData?: WalletQuery$data["currentPlayer"] | null) => {
  const tokensIDs = useMemo(() => playerData?.inventory.map(({ id }) => id), [playerData])
  const pendingTokens = usePendingTokens(tokensIDs)
  const pendingClaimsOnDemand = useMemo(() => playerData?.reserved, [playerData])
  const claimsOnEndDate = useMemo(() => playerData?.claims, [playerData])

  const pendingClaims = useMemo(
    () => [
      ...(claimsOnEndDate
        ?.filter(
          (claim) =>
            claim.sale.endDate &&
            Date.parse(claim.sale.endDate) < Date.now() &&
            claim.state !== "MINTED" &&
            !playerData?.inventory.some(
              (token) => token.archetype.id === claim.sale.archetype.id // TODO: check with the backend if we can find a more reliable way to compare
            )
        )
        .map((claim) => {
          return {
            claim,
            id: claim.id,
            name: claim.sale.archetype.primaryVariant.name,
            endDate: claim.sale.endDate,
          }
        }) || []),
    ],
    [claimsOnEndDate, playerData]
  )

  return {
    claimed: [
      ...(claimsOnEndDate
        ?.filter(
          (claim) => claim.sale.endDate && Date.parse(claim.sale.endDate) > Date.now() && claim.state !== "MINTED"
        )
        .map((claim) => {
          return {
            claim,
            id: claim.id,
            name: claim.sale.archetype.primaryVariant.name,
            endDate: claim.sale.endDate,
          }
        }) || []),
    ],
    pending: [
      ...(pendingTokens.data?.map((token) => ({ token, id: token.id, pending: true } as PendingProps)) || []),
      ...(pendingClaimsOnDemand?.map((token) => ({ token, id: token.id, pending: true } as PendingProps)) || []),
      ...(pendingClaims.map((claim) => ({ ...claim } as PendingProps)) || []),
    ],
    received: [...(playerData?.inventory.map((token) => ({ token, id: token.id })) || [])],
  }
}

export default useInventory
