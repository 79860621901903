/**
 * @generated SignedSource<<2a23e701707adb17d8b40c692878d09d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TokenDetails_token$data = {
  readonly archetype: {
    readonly primaryVariant: {
      readonly name: string;
      readonly description: string;
    };
  };
  readonly releaseDate: string;
  readonly previousOwners: ReadonlyArray<{
    readonly playerUbiID: string | null;
    readonly txDate: string;
  }>;
  readonly " $fragmentType": "TokenDetails_token";
};
export type TokenDetails_token$key = {
  readonly " $data"?: TokenDetails_token$data;
  readonly " $fragmentSpreads": FragmentRefs<"TokenDetails_token">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TokenDetails_token",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Archetype",
      "kind": "LinkedField",
      "name": "archetype",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Variant",
          "kind": "LinkedField",
          "name": "primaryVariant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releaseDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PreviousOwner",
      "kind": "LinkedField",
      "name": "previousOwners",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "playerUbiID",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "txDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Token",
  "abstractKey": null
};

(node as any).hash = "b2f982007265f8d00dd5378cbbc1482a";

export default node;
