import { useEffect, useState } from "react"
import graphql from "babel-plugin-relay/macro"
import { useRelayEnvironment } from "react-relay"
import { fetchQuery } from "relay-hooks"

import { usePendingTokensQuery, usePendingTokensQuery$data } from "./__generated__/usePendingTokensQuery.graphql"

import { useUbisoftWebAuthSessionContext } from "../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import { getPendingTokens, setPendingTokens } from "../utils/pendingTokensStorage"

const tokensQuery = graphql`
  query usePendingTokensQuery($ids: [ID!]!) {
    tokens(ids: $ids) {
      id
      ...TokenCardFragment_token
    }
  }
`

const usePendingTokens = (excludedIDs: string[] | undefined) => {
  const { user } = useUbisoftWebAuthSessionContext()
  const relayEnvironment = useRelayEnvironment()
  const [queryState, setQueryState] = useState<{
    data: usePendingTokensQuery$data["tokens"] | null
    isLoading: boolean
    error: Error | null
  }>({ data: null, isLoading: false, error: null })

  useEffect(() => {
    if (!excludedIDs || !user) return

    const updatedPendingTokensIDs = getPendingTokens(user.userID).filter((id) => !excludedIDs.includes(id))
    setPendingTokens(user.userID, updatedPendingTokensIDs)

    setQueryState((prev) => ({ data: prev.data, isLoading: true, error: null }))

    fetchQuery<usePendingTokensQuery>(relayEnvironment, tokensQuery, {
      ids: updatedPendingTokensIDs,
    }).subscribe({
      next: (response) => {
        setQueryState({ data: response.tokens, isLoading: false, error: null })
      },
      error: (error: any) => setQueryState({ data: null, isLoading: false, error }),
    })
  }, [excludedIDs, relayEnvironment, user])

  return queryState
}

export default usePendingTokens
