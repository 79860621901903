import { useState } from "react"
import { Dialog } from "@mui/material"

import ipfsToHttpGateway from "../../utils/ipfsToHttpGateway"

import style from "./style.module.css"

interface Props {
  video: string
  image: string
  alt: string
  className?: string
  showControls?: boolean
}

const Video = ({ video, image, alt, className, showControls = false }: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <video
        className={`${style.video} ${className}`}
        poster={ipfsToHttpGateway(image)}
        autoPlay={true}
        muted={true}
        playsInline={true}
        loop={true}
        controls={showControls}
        controlsList="nodownload"
        data-testid="product-video"
        onClick={() => setOpen(true)}
      >
        <source src={ipfsToHttpGateway(video)} type="video/mp4" />
        <img src={ipfsToHttpGateway(image)} alt={alt} data-testid="product-image" />
      </video>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <video
          poster={ipfsToHttpGateway(image)}
          autoPlay={true}
          muted={true}
          playsInline={true}
          loop={true}
          controls={true}
          controlsList="nodownload"
          data-testid="product-video"
        >
          <source src={ipfsToHttpGateway(video)} type="video/mp4" />
          <img src={ipfsToHttpGateway(image)} alt={alt} data-testid="product-image" />
        </video>
      </Dialog>
    </>
  )
}

export default Video
