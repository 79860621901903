import { useCallback, useEffect, useState } from "react"
import graphql from "babel-plugin-relay/macro"
import { useQuery } from "relay-hooks"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { Navigate, useLocation } from "react-router-dom"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import { WalletQuery } from "./__generated__/WalletQuery.graphql"

import PageHeader from "../../components/PageHeader"
import PageLayout from "../../components/PageLayout"
import { useTezosContext } from "../../web3/Web3Provider"
import Button from "../../components/Button"
import Tag from "../../components/Tag"
import walletProviders from "../../web3/walletProviders"
import truncateText from "../../utils/truncateText"
import RainbowTitle from "../../components/RainbowTitle"
import { useUserInfoContext } from "../../providers/UserInfoProvider"
import { getEURLBalance } from "../../web3/utils/getBalance"
import RefreshButton from "./components/RefreshButton"
import CopyButton from "./components/CopyButton"
import Inventory from "./components/Inventory"
import TrackPage from "../../components/TrackPage"
import isFeatureEnabled from "../../utils/isFeatureEnabled"
import useInventory from "./hooks/useInventory"

import { ReactComponent as PlusIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/plus.svg"

import style from "./style.module.css"

const BALANCE_REFRESH_RATE_MS = 60000
const INVENTORY_REFRESH_COOLDOWN_MS = 3000

interface BalanceProps {
  walletAddress: string | null
}

const Balance = ({ walletAddress }: BalanceProps) => {
  const [balance, setBalance] = useState<number | null>(null)

  const refreshBalance = useCallback(async () => {
    if (!walletAddress) return

    try {
      const balance = await getEURLBalance(walletAddress)
      setBalance(balance)
    } catch (e) {
      console.error(e)
    }
  }, [walletAddress])

  useEffect(() => {
    refreshBalance()
    const handle = setInterval(refreshBalance, BALANCE_REFRESH_RATE_MS)

    return () => clearInterval(handle)
  }, [refreshBalance])

  return (
    <>
      <h2>
        {balance !== null ? (
          <>
            <FormattedNumber value={balance} /> <small>EURL</small>
          </>
        ) : (
          <div className={style.balancePlaceholder} />
        )}
      </h2>
      {/* TODO: implement USDC conversion to Fiat amount */}
      {isFeatureEnabled("euroValue") && <div className={style.balanceFiat}>X.XX Euro</div>}
    </>
  )
}

const walletQuery = graphql`
  query WalletQuery {
    currentPlayer {
      inventory {
        id
        archetype {
          id
          primaryVariant {
            name
          }
        }
        ...TokenCardFragment_token
      }
      reserved {
        id
        archetype {
          id
          primaryVariant {
            name
          }
        }
        ...TokenCardFragment_token
      }
      claims {
        id
        sale {
          archetype {
            id
            primaryVariant {
              name
            }
          }
          endDate
        }
        state
        ...PendingClaimCardFragment_claim
      }
    }
  }
`

const Content = () => {
  const intl = useIntl()
  const { data, isLoading: inventoryLoading, retry } = useQuery<WalletQuery>(walletQuery, {})
  const items = useInventory(data?.currentPlayer)

  useEffect(() => retry(), [retry])

  // TODO: Uncomment when history is done
  // const [tabIndex, setTabIndex] = useState(0)
  // const handleTabIndexChange = (event: React.ChangeEvent<{}>, newValue: number) => {
  //   setTabIndex(newValue)
  // }

  // const tabs = useMemo(
  //   () => [
  //     {
  //       id: "0",
  //       title: (
  //         <FormattedMessage
  //           defaultMessage="My digits ({total})"
  //           id="Ml1ME0"
  //           description="[Wallet] Wallet my digits tab title"
  //           values={{ total: data?.currentPlayer.inventory.length }}
  //         />
  //       ),
  //     },
  //     {
  //       id: "1",
  //       title: (
  //         <FormattedMessage defaultMessage="Transaction history" id="2ci++z" description="[Wallet] Wallet transaction history tab title" />
  //       ),
  //     },
  //   ],
  //   [data]
  // )

  return (
    <>
      {/* TODO Uncomment when history is done */}
      {/* <TabBar tabs={tabs} currentIndex={tabIndex} handleChange={handleTabIndexChange} /> */}

      <RainbowTitle
        text={intl.formatMessage({
          defaultMessage: "My Digits",
          id: "5L4U30",
          description: "[Wallet] Inventory title",
        })}
        smallText={intl.formatMessage(
          {
            defaultMessage: "({count})",
            id: "b0ImmQ",
            description: "[Wallet Inventory] Digit count",
          },
          { count: items.claimed.length + items.pending.length + items.received.length }
        )}
        callToAction={
          <RefreshButton onClick={retry} disabled={inventoryLoading} cooldownMs={INVENTORY_REFRESH_COOLDOWN_MS} />
        }
      />

      {/* {tabIndex === 0 && ( */}
      <Inventory items={items} loading={inventoryLoading} />
      {/* )} */}

      {/* TODO: add history of all trasactions */}
    </>
  )
}

const Wallet = () => {
  const { providerLoading, providerName } = useTezosContext()
  const location = useLocation()
  const { userInfo, isLoading } = useUserInfoContext()
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"))

  if (!providerLoading && !isLoading && !userInfo.walletAddress) {
    return <Navigate to={{ pathname: "/user/wallet/connect" }} state={{ referrer: location }} replace />
  }

  const providerInfo = providerName
    ? {
        name: walletProviders[providerName].name,
        logo: walletProviders[providerName].logo,
      }
    : null

  return (
    <>
      <TrackPage siteSection="wallet" pageName="main" />
      <PageLayout
        header={
          <>
            {isMobile && (
              <PageHeader
                title={
                  <FormattedMessage
                    defaultMessage="Crypto-wallet"
                    id="/hwiH4"
                    description="[Wallet] Wallet header title"
                  />
                }
                level="primary"
                hideBackButton
              />
            )}
            <div className={style.card}>
              <header>
                <h4 className={style.title}>
                  {!!providerInfo ? (
                    <>
                      <img className={style.logo} src={providerInfo.logo} alt={providerInfo.name} />
                      <span className={style.providerName}>{providerInfo.name}</span>
                    </>
                  ) : (
                    <Tag color="white">
                      <FormattedMessage
                        defaultMessage="Public address"
                        id="1xMsI9"
                        description="[Wallet] Wallet provider placeholder"
                      />
                    </Tag>
                  )}
                </h4>
                <div className={style.addressSection}>
                  <div className={style.address}>
                    {userInfo.walletAddress && (
                      <>
                        {!!providerInfo && (
                          <Tag color="white" className={style.walletAddressTag}>
                            <FormattedMessage
                              defaultMessage="Public address"
                              id="1xMsI9"
                              description="[Wallet] Wallet provider placeholder"
                            />
                          </Tag>
                        )}
                        <span title={userInfo.walletAddress}>{truncateText(userInfo.walletAddress)}</span>
                      </>
                    )}
                  </div>
                  <div className={style.copy}>
                    <CopyButton value={userInfo.walletAddress} />
                  </div>
                </div>
              </header>
              {isFeatureEnabled("walletBalance") && (
                <div className={style.body}>
                  <Balance walletAddress={userInfo.walletAddress} />

                  {isFeatureEnabled("walletTopup") && (
                    <div className={style.buttonGroup}>
                      <Button invert icon={<PlusIcon />} to="/user/wallet/top-up">
                        <FormattedMessage
                          defaultMessage="Top Up"
                          id="Y/IxlB"
                          description="[Wallet] Wallet top up button"
                        />
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        }
        bodyClassName={style.body}
      >
        <Content />
      </PageLayout>
    </>
  )
}

export default Wallet
