import { useFragment } from "react-relay"
import graphql from "babel-plugin-relay/macro"
import { LinkProps, To } from "react-router-dom"
import { useMemo } from "react"

import { ProductCardFragment_sale$key } from "./__generated__/ProductCardFragment_sale.graphql"
import { ProductCardFragment_token$key } from "./__generated__/ProductCardFragment_token.graphql"
import { ProductCardFragment_player$key } from "./__generated__/ProductCardFragment_player.graphql"

import ProductCard from "."
import buildConditionMap from "../../../utils/saleCondition/buildConditionMap"

export interface Props {
  gameID: string
  sale: ProductCardFragment_sale$key
  token: ProductCardFragment_token$key | null
  player: ProductCardFragment_player$key | null
  onEditSerialNumber?: () => void
  hidePrice?: boolean
  isLoading?: boolean
  className?: string
  to?: To
  state?: LinkProps["state"]
  showControls?: boolean
}

const ProductCardFragment = ({
  gameID,
  sale,
  token,
  player,
  onEditSerialNumber,
  hidePrice,
  isLoading,
  className = "",
  to,
  showControls = false,
}: Props) => {
  const saleData = useFragment(
    graphql`
      fragment ProductCardFragment_sale on Sale {
        id
        price
        startDate
        endDate
        condition {
          ...buildConditionMap_saleCondition
        }
        claimsCount {
          remaining
        }
        archetype {
          id
          maxInstances
          video
          variants {
            name
            image
            categorization {
              game {
                id
                name
              }
            }
          }
        }
      }
    `,
    sale
  )

  const tokenData = useFragment(
    graphql`
      fragment ProductCardFragment_token on Token {
        serialNumber
        video
      }
    `,
    token
  )

  const playerData = useFragment(
    graphql`
      fragment ProductCardFragment_player on Player {
        inventory {
          archetype {
            id
          }
        }
        reserved {
          archetype {
            id
          }
        }
        claims {
          sale {
            id
          }
        }
      }
    `,
    player
  )

  const variant = saleData.archetype.variants.find((variant) => variant.categorization?.game?.id === gameID)
  // TODO: wait for tokenCount feature to not query all tokens list which lead to performance issue
  // const availableTokens = saleData.tokens.edges.filter((token) => token.node?.available).length
  const remainingInstances = saleData.price === 0 ? saleData.claimsCount?.remaining : 0 // TODO: replace value with `availableTokens` variable when uncommented above
  const conditions = useMemo(() => (saleData.condition ? buildConditionMap(saleData.condition) : undefined), [saleData])
  const claimed = useMemo(
    () =>
      playerData?.claims.some((claim) => claim.sale.id === saleData.id) ||
      playerData?.reserved.some((token) => token.archetype.id === saleData.archetype.id) ||
      playerData?.inventory.some((token) => token.archetype.id === saleData.archetype.id),
    [playerData, saleData]
  )

  return (
    <ProductCard
      priceDeFi={saleData.price}
      startDate={new Date(saleData.startDate)}
      endDate={saleData.endDate ? new Date(saleData.endDate) : null}
      serialNumber={tokenData?.serialNumber}
      currencyDeFi="EURL" /* TODO currencyDeFi should not be hard coded */
      price={0} /* TODO convert crypto to fiat price */
      currency="EUR" /* TODO currency fiat should not be hard coded */
      variantName={variant?.name || ""}
      game={variant?.categorization?.game?.name || ""}
      image={variant?.image || ""}
      video={tokenData?.video || saleData.archetype.video || ""}
      remainingInstances={remainingInstances}
      saleConditions={conditions}
      maxInstances={saleData.archetype.maxInstances}
      onEditSerialNumber={onEditSerialNumber}
      hidePrice={hidePrice}
      isLoading={isLoading}
      className={className}
      to={to}
      showControls={showControls}
      claimed={claimed}
    />
  )
}

export default ProductCardFragment
