import { useMemo } from "react"
import { useIntl } from "react-intl"
import { Navigate, useParams } from "react-router-dom"

import EligibilityRecap from "../../components/EligibilityRecap"
import TrackPage from "../../components/TrackPage"
import { useGeolocationInfoContext } from "../../providers/GeolocationInfoProvider"
import { useUserInfoContext } from "../../providers/UserInfoProvider"
import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import isUserEligible from "../../utils/isUserEligible"

interface Props {
  type: "topup" | "purchase"
}

interface CriteriaMapProps {
  criteriaMap: {
    age: boolean
    localisation: boolean
    level: boolean
    "2fa": boolean
  }
}

const PurchaseEligibilityRecap = ({ criteriaMap }: CriteriaMapProps) => {
  const intl = useIntl()

  return (
    <EligibilityRecap
      headerTitle={intl.formatMessage({
        defaultMessage: "Eligibility",
        id: "g6URum",
        description: "[Eligibility] header title",
      })}
      bodyTitle={intl.formatMessage({
        defaultMessage: "You are currently not eligible!",
        id: "Nd8c+p",
        description: "[Eligibility] title",
      })}
      bodyDescription={intl.formatMessage({
        defaultMessage: "We’re sorry, for some reason you aren’t eligible to acquire Digits on Ubisoft Quartz.",
        id: "rWhh1i",
        description: "[Eligibility] description",
      })}
      criteriaMap={criteriaMap}
    />
  )
}

const TopUpEligibilityRecap = ({ criteriaMap }: CriteriaMapProps) => {
  const intl = useIntl()

  return (
    <EligibilityRecap
      headerTitle={intl.formatMessage({
        defaultMessage: "Eligibility",
        id: "8FGGbA",
        description: "Eligibility page title",
      })}
      bodyTitle={intl.formatMessage({
        defaultMessage: "You can't top up",
        id: "i1vPqQ",
        description: "Eligibility Recap: title",
      })}
      bodyDescription={intl.formatMessage({
        defaultMessage: "We're sorry, the top up provider isn't eligible in your country",
        id: "CrcnFW",
        description: "Eligibility Recap: description",
      })}
      criteriaMap={criteriaMap}
    />
  )
}

const Eligibility = ({ type }: Props) => {
  const { twoFaActive, isLoading: userLoading } = useUbisoftWebAuthSessionContext()
  const { userInfo, isLoading } = useUserInfoContext()
  const { isCurrentIpLocationAllowed, isLoading: geolocationIsLoading } = useGeolocationInfoContext()
  const { gameID } = useParams<{ gameID: string }>()
  const redirectTo = gameID ? `/${gameID}` : "/"

  const isEligible = useMemo(() => {
    // TODO: check allowed countries of top up provider
    if (type === "topup")
      return (
        (isCurrentIpLocationAllowed && userInfo.eligibility.registeredCountryCriteria) || userInfo.eligibility.exception
      )

    return isUserEligible(userInfo, isCurrentIpLocationAllowed) && twoFaActive
  }, [userInfo, isCurrentIpLocationAllowed, twoFaActive, type])

  const criteriaMap = useMemo(() => {
    /* Displays an empty list while loading */
    if (isLoading || userLoading || geolocationIsLoading)
      return { age: true, localisation: true, level: true, "2fa": true }

    if (type === "topup")
      // Check only location for top up
      return {
        age: true,
        level: true,
        localisation: userInfo.eligibility.registeredCountryCriteria && isCurrentIpLocationAllowed,
        "2fa": true,
      }

    return {
      age: userInfo.eligibility.ageCriteria,
      localisation: userInfo.eligibility.registeredCountryCriteria && isCurrentIpLocationAllowed,
      level: userInfo.eligibility.gameLevelCriteria && userInfo.epicOrLunaState !== "played",
      "2fa": twoFaActive,
    }
  }, [
    userInfo.eligibility,
    userInfo.epicOrLunaState,
    isLoading,
    twoFaActive,
    userLoading,
    isCurrentIpLocationAllowed,
    geolocationIsLoading,
    type,
  ])

  if (!isLoading && isEligible) {
    return <Navigate to={redirectTo} replace />
  }

  const customParameter = ` - ${criteriaMap.localisation ? "" : "region"} - ${criteriaMap.age ? "" : "age"} - ${
    criteriaMap.level ? "" : "platform"
  } - ${criteriaMap["2fa"] ? "" : "2fa"}`

  return (
    <>
      <TrackPage siteSection="ineligibility" pageName="main" customParameter={customParameter} />
      {type === "topup" ? (
        <TopUpEligibilityRecap criteriaMap={criteriaMap} />
      ) : (
        <PurchaseEligibilityRecap criteriaMap={criteriaMap} />
      )}
    </>
  )
}

export default Eligibility
