import { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import withStyles from "@mui/styles/withStyles"

import { ReactComponent as ChevronBottomIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/chevronBottom.svg"

import style from "./style.module.css"

const Accordion = withStyles({
  root: {
    color: "var(--white)",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    backgroundColor: "transparent",
    borderBottom: "1px solid var(--white-20)",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    margin: "1.5rem 0",
    justifyContent: "space-between",
    "& span": {
      display: "none",
      margin: "auto 0",
      fontSize: "0.75rem",
      fontWeight: 600,
      lineHeight: "1.333333",
      textTransform: "uppercase",
      color: "#f1f1f1",
      backgroundImage: "linear-gradient(90deg, var(--primary), var(--accent))",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom",
      backgroundSize: "100% 1px",
    },
    "&$expanded": {
      margin: "1.5rem 0",
    },
  },
  expanded: {
    "& span:nth-child(2)": {
      display: "flex",
    },
  },
  expandIconWrapper: {
    "& svg path:first-child": {
      fill: "var(--primary)",
    },
  },
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    fontSize: "1rem",
    lineHeight: "1.5",
    padding: "0.5rem 0 1rem",
    borderBottom: "1px solid var(--white-20)",
    "& p": {
      margin: "0.5rem 0",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
  },
}))(MuiAccordionDetails)

interface Props {
  id: string
  title: string
  content: ReactNode
  expanded: string | false
  handleChange: (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => void
}

const FaqItem = ({ id, title, content, expanded, handleChange }: Props) => {
  return (
    <Accordion square expanded={expanded === `panel${id}`} onChange={handleChange(`panel${id}`)}>
      <AccordionSummary
        aria-controls={`panel${id}d-content`}
        id={`panel${id}d-header`}
        expandIcon={<ChevronBottomIcon />}
      >
        <h4>{title}</h4>
        <FormattedMessage
          tagName="span"
          id="pxO526"
          description="[Home] Faq close button label"
          defaultMessage="close"
        />
      </AccordionSummary>
      <AccordionDetails>
        <div className={style.container}>{content}</div>
      </AccordionDetails>
    </Accordion>
  )
}

export default FaqItem
