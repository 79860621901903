import { CountdownRenderProps, default as CountdownReact } from "react-countdown"

import BasicCountdown from "./BasicCountdown"
import ClockCountdown from "./ClockCountdown"

interface Props {
  type: "basic" | "clock"
  endDate: Date | string
  zeroPadTime?: number
}

const COUNTDOWN_TYPE: Record<string, ((prop: CountdownRenderProps) => JSX.Element) | null> = {
  basic: BasicCountdown,
  clock: ClockCountdown,
}

const Countdown = ({ type, endDate, zeroPadTime = 0 }: Props) => {
  return <CountdownReact date={endDate} zeroPadTime={zeroPadTime} renderer={COUNTDOWN_TYPE[type] || undefined} />
}

export default Countdown
