/**
 * @generated SignedSource<<180cb3c24b7a5801e9b0001f824a4e29>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardPayingDropQuery$variables = {};
export type DashboardPayingDropQuery$data = {
  readonly allGames: ReadonlyArray<{
    readonly id: string;
    readonly sales: ReadonlyArray<{
      readonly id: string;
      readonly startDate: string;
      readonly archetype: {
        readonly primaryVariant: {
          readonly categorization: {
            readonly game: {
              readonly id: string;
            } | null;
          } | null;
        };
      };
      readonly " $fragmentSpreads": FragmentRefs<"SaleCardFragment_sale">;
    }>;
  }>;
};
export type DashboardPayingDropQuery = {
  variables: DashboardPayingDropQuery$variables;
  response: DashboardPayingDropQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Categorization",
  "kind": "LinkedField",
  "name": "categorization",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Game",
      "kind": "LinkedField",
      "name": "game",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardPayingDropQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Game",
        "kind": "LinkedField",
        "name": "allGames",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Sale",
            "kind": "LinkedField",
            "name": "sales",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Archetype",
                "kind": "LinkedField",
                "name": "archetype",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Variant",
                    "kind": "LinkedField",
                    "name": "primaryVariant",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SaleCardFragment_sale"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardPayingDropQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Game",
        "kind": "LinkedField",
        "name": "allGames",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Sale",
            "kind": "LinkedField",
            "name": "sales",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Archetype",
                "kind": "LinkedField",
                "name": "archetype",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Variant",
                    "kind": "LinkedField",
                    "name": "primaryVariant",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxInstances",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Variant",
                    "kind": "LinkedField",
                    "name": "variants",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "image",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Categorization",
                        "kind": "LinkedField",
                        "name": "categorization",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Game",
                            "kind": "LinkedField",
                            "name": "game",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7812de5314f8cf4566dde4224edfa585",
    "id": null,
    "metadata": {},
    "name": "DashboardPayingDropQuery",
    "operationKind": "query",
    "text": "query DashboardPayingDropQuery {\n  allGames {\n    id\n    sales {\n      id\n      startDate\n      archetype {\n        primaryVariant {\n          categorization {\n            game {\n              id\n            }\n          }\n          id\n        }\n        id\n      }\n      ...SaleCardFragment_sale\n    }\n  }\n}\n\nfragment SaleCardFragment_sale on Sale {\n  id\n  price\n  archetype {\n    id\n    maxInstances\n    variants {\n      name\n      image\n      categorization {\n        game {\n          id\n          name\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b6774cd8eeecf96bb456db93a54aa01a";

export default node;
