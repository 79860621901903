import graphql from "babel-plugin-relay/macro"
import { readInlineData } from "relay-runtime"

import { buildConditionMap_saleCondition$key } from "./__generated__/buildConditionMap_saleCondition.graphql"

type Operator = "EQ" | "GT" | "GTE" | "LT" | "LTE" | "NEQ"

const COMPARISON_OPERATORS = ["EQ", "GT", "GTE", "LT", "LTE", "NEQ"]

const buildConditionMap = (conditionRef: buildConditionMap_saleCondition$key) => {
  const condition = readInlineData(
    graphql`
      fragment buildConditionMap_saleCondition on SaleCondition @inline @relay(mask: false) {
        # AND
        expr {
          slug
          operator
          args {
            ... on SaleConditionStat {
              key
              spaceID
              type
            }
            ... on IntVal {
              int
            }
            ... on FloatVal {
              float
            }
            ... on StringVal {
              string
            }
            ... on BoolVal {
              bool
            }
            ... on SaleConditionExpr {
              slug # level, time
              operator # EQ, GT, GTE, LT, LTE, NEQ
              args {
                ... on SaleConditionStat {
                  key
                  spaceID
                  type
                }
                ... on IntVal {
                  int
                }
                ... on FloatVal {
                  float
                }
                ... on StringVal {
                  string
                }
                ... on BoolVal {
                  bool
                }
                ... on SaleConditionExpr {
                  operator # ADD
                  args {
                    ... on SaleConditionStat {
                      key
                      spaceID
                      type
                    }
                    ... on IntVal {
                      int
                    }
                    ... on FloatVal {
                      float
                    }
                    ... on StringVal {
                      string
                    }
                    ... on BoolVal {
                      bool
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    conditionRef
  )

  if (condition?.expr?.operator === "AND") {
    return condition?.expr?.args.reduce((conditionMap, { slug, operator, args }) => {
      if (!slug || !operator || !args || !COMPARISON_OPERATORS.includes(operator) || args.length !== 2)
        return conditionMap

      const operand = args[1]
      conditionMap[slug] = {
        operator: operator as Operator,
        value: operand.int || operand.float || 0,
      }

      return conditionMap
    }, {} as Record<string, { operator: Operator; value: number }>)
  }

  if (condition?.expr?.slug) {
    const operand = condition?.expr?.args[1]

    return {
      [condition.expr.slug]: {
        operator: condition?.expr?.operator as Operator,
        value: operand.int || operand.float || 0,
      },
    }
  }
}

export default buildConditionMap
