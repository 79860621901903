import Reveal from "react-awesome-reveal"
import { FormattedMessage } from "react-intl"

import fadeInLeft from "../../../../utils/animations/fade/fadeInLeft"
import fadeInRight from "../../../../utils/animations/fade/fadeInRight"
import Button from "../../../../components/Button"
import { trackClick } from "../../../../utils/analytics"

import { ReactComponent as LogoRarible } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/logo-rarible-white.svg"

import style from "./style.module.css"
import sharedStyle from "../../style.module.css"

const DoMoreSection = () => {
  const handleTrackClick = (marketplace: "rarible" | "objkt") => {
    trackClick({
      location: "ubisoft quartz",
      locationDetail: "home page",
      category: "action",
      action: `marketplace: ${marketplace}`,
    })
  }

  return (
    <>
      <section className={sharedStyle.section} id={style.section5}>
        <div>
          <Reveal duration={700} keyframes={fadeInLeft(20)} triggerOnce>
            <h1 className={sharedStyle.title}>
              <FormattedMessage
                id="0OzYsI"
                description="[Home] section title"
                defaultMessage="Do more{linebreak}<textGradient>&#x2014;
                you are</textGradient>{linebreak}<textGradient>in control!</textGradient>"
                values={{
                  linebreak: <br />,
                  textGradient: (text: string) => <span className={sharedStyle.highlight}>{text}</span>,
                }}
              />
            </h1>
          </Reveal>
          <Reveal delay={500} duration={700} keyframes={fadeInRight(20)} triggerOnce>
            <div className={`${sharedStyle.content} ${style.content}`}>
              <FormattedMessage
                tagName="p"
                id="577y39"
                description="[Home] section title"
                defaultMessage="Traditionally, items you purchase from an in-game store are bound to your game account."
              />
              <FormattedMessage
                tagName="p"
                id="2Zl3Gf"
                description="[Home] section title"
                defaultMessage="Digits take things a step further by giving you more control than ever! While they are first and foremost playable items, Digits enable you to put your items on sale to other eligible players, whenever you want and at the price you set. Similarly, you can purchase new items directly from other players."
              />
            </div>
          </Reveal>
          <Reveal delay={500} duration={700} keyframes={fadeInRight(20)} triggerOnce>
            <div className={style.buttons}>
              <Button
                fullWidth
                href="https://rarible.com/ubisoft-quartz"
                target="_blank"
                onClick={() => handleTrackClick("rarible")}
              >
                <LogoRarible />
              </Button>
            </div>
          </Reveal>
        </div>
      </section>
    </>
  )
}

export default DoMoreSection
