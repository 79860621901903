import { Link } from "react-router-dom"
import { FormattedMessage, IntlShape } from "react-intl"

const ubisoftQuartzGRB = (intl: IntlShape) => [
  {
    id: "how-can-i-play-with-my-digits-in-the-game",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "How can I play with my Digits in the game?",
      id: "1RdhZr",
      description: "Faq ubisoft quartz and GRB question 0",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Once acquired, the Digit is displayed in your <link>crypto-wallet</link> and synchronized with your Tom Clancy’s Ghost Recon® Breakpoint inventory. The in-game items tied to your Digits are automatically playable."
          id="iTSKMr"
          description="Faq ubisoft quartz and GRB response 0 paragraph 1"
          values={{
            link: (chunks: string) => (
              <Link to="/faq/crypto-wallet/what-do-i-need-to-use-ubisoft-quartz">{chunks}</Link>
            ),
          }}
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="Keep in mind that only Ubisoft connect players can see and use their Digits in-game. Ubisoft Quartz is only supported on Ubisoft Connect PC launcher."
          id="OF8sv0"
          description="Faq ubisoft quartz and GRB response 0 paragraph 2"
        />
      </>
    ),
  },
  {
    id: "my-digit-does-not-display-in-my-in-game-inventory",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "My Digit does not display in my in-game inventory. What should I do?",
      id: "OeCEJo",
      description: "Faq ubisoft quartz and GRB question 1",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="When you claim your Digit on Ubisoft Quartz, it will not be available to you until the claim period ends. Make sure to reboot your game as this is a necessary step for updating your in-game inventory! It can take up to a few minutes to validate the transaction. If after a few minutes you do not see your Digit in your crypto-wallet or in your in-game inventory, make sure your transaction has been processed by checking your crypto-wallet history. If your transaction went through and you still can’t see your item in your in-game inventory, please get in touch with our customer support."
          id="akBFSa"
          description="Faq ubisoft quartz and GRB response 1 paragraph 1"
        />
      </>
    ),
  },
  {
    id: "what-happens-when-i-sell-a-digit",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "What happens when I sell a Digit?",
      id: "U1Re19",
      description: "Faq ubisoft quartz and GRB question 2",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Once sold to another player, your Digit is removed from your game inventory. In the case of weapon skins, the weapon blueprint as well as all the crafted weapons will be removed from your inventory."
          id="JjcNcw"
          description="Faq ubisoft quartz and GRB response 2 paragraph 1"
        />
      </>
    ),
  },
  {
    id: "are-my-digits-available-on-all-my-saves-in-tom-clancys-ghost-recon-breakpoint",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Are my Digits available on all my saves in Tom Clancy’s Ghost Recon® Breakpoint?",
      id: "YJXTW7",
      description: "Faq ubisoft quartz and GRB question 3",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Yes. Digits are tied to your account, which makes them available on all of your saves. Likewise, when you sell a Digit, it is removed from all your saves. Crafted weapons will also be removed from all your saves."
          id="y4y34P"
          description="Faq ubisoft quartz and GRB response 3 paragraph 1"
        />
      </>
    ),
  },
  {
    id: "can-i-play-with-a-digit-in-game-at-the-same-time-it-is-being-sold",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Can I play with a Digit in-game at the same time it is being sold?",
      id: "H4k0xr",
      description: "Faq ubisoft quartz and GRB question 4",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="A Digit can be sold at any moment, even while being used in game. In any case, you can keep playing with it until the end of your current game session. At your next connection after the sale, the Digit and all crafted items will no longer appear in your inventory."
          id="6XI/qi"
          description="Faq ubisoft quartz and GRB response 4 paragraph 1"
        />
      </>
    ),
  },
  {
    id: "why-arent-all-the-tom-clancys-ghost-recon-breakpoint-items-available-on-the-ubisoft-quartz-platform",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage:
        "Why aren’t all the Tom Clancy’s Ghost Recon® Breakpoint items available on the Ubisoft Quartz platform?",
      id: "q9NQA0",
      description: "Faq ubisoft quartz and GRB question 5",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="This new experience is the first of its kind for a HD game. It is meant to be an experiment of a new type of in-game collectibles and playable items. Starting with a small number of Digits, we want to give players more choice and possibilities on top of the core Tom Clancy’s Ghost Recon® Breakpoint experience and gather feedback from our communities."
          id="NYlnXf"
          description="Faq ubisoft quartz and GRB response 5 paragraph 1"
        />
      </>
    ),
  },
  {
    id: "what-happens-to-my-digits-if-ubisoft-quartz-or-tom-clancys-ghost-recon-breakpoint-shut-down",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "What happens to my Digits if Ubisoft Quartz or Tom Clancy’s Ghost Recon® Breakpoint shut down?",
      id: "Rrf5EA",
      description: "Faq ubisoft quartz and GRB question 6",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="When acquiring a Digit on the Ubisoft Quartz platform or on a third-party marketplace, you become owner of a non-fungible token (or NFT). This NFT is stored on a decentralized network called <link1>blockchain</link1> that is completely independent from Ubisoft’s infrastructure and is also tied to a set of <link2>metadata</link2>. The NFT is a certificate of ownership linking to those metadata that include the video collectible of the playable in-game item. The Digit metadata are stored on a decentralized network called <link3>Aleph</link3>. This means your Digits will live on beyond the Ubisoft Quartz platform or the Tom Clancy’s Ghost Recon® Breakpoint game, and you will still be able to put them on sale on third-party marketplaces."
          id="boYEo1"
          description="Faq ubisoft quartz and GRB response 6 paragraph 1"
          values={{
            link1: (chunks: string) => <Link to="/faq/technology/what-technology-powers-ubisoft-quartz">{chunks}</Link>,
            link2: (chunks: string) => (
              <Link to="/faq/ubisoft-quartz-and-digits/what-are-metadata-how-do-i-access-them">{chunks}</Link>
            ),
            link3: (chunks: string) => <Link to="/faq/technology/what-is-aleph">{chunks}</Link>,
          }}
        />
      </>
    ),
  },
  {
    id: "will-digits-have-an-impact-on-gameplay",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Will Digits have an impact on gameplay?",
      id: "889X91",
      description: "Faq ubisoft quartz and GRB question 7",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="No. Digits are purely cosmetic items that do not influence the gameplay, be it PvE or PvP."
          id="alR9E0"
          description="Faq ubisoft quartz and GRB response 7 paragraph 1"
        />
      </>
    ),
  },
]

export default ubisoftQuartzGRB
