import { FormattedMessage, IntlShape } from "react-intl"

import img from "../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/quartzTezosEnvImpact.jpg"

import style from "../style.module.css"

const technology = (intl: IntlShape) => [
  {
    id: "what-technology-powers-ubisoft-quartz",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "What technology powers Ubisoft Quartz?",
      id: "/8P2bJ",
      description: "Faq technology question 0",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="To provide you with new possibilities and an unprecedented level of engagement with your game, Ubisoft Quartz relies on a new technology called blockchain. Unlike traditional databases, blockchain puts the concepts of decentralization and ownership at the core of the experience, which allows to both evolve the barriers and interconnections between virtual worlds, and track virtual items in a safer, easier and more efficient way than ever before."
          id="ycwdev"
          description="Faq technology response 0 paragraph 1"
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="While still in the early stages of its adoption across all industries, Ubisoft believes that an environmentally friendly form of blockchain will play a role in the future of gaming. With Ubisoft Quartz, this technology enables us to move beyond current limitations and to set the foundations of an ambitious ecosystem that brings new benefits and possibilities for both players and creators."
          id="KzyWTu"
          description="Faq technology response 0 paragraph 2"
        />
      </>
    ),
  },
  {
    id: "what-is-blockchain",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "What is blockchain?",
      id: "X842iD",
      description: "Faq technology question 1",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="A blockchain is a database that is shared across a network of computers. In other words, it is decentralized and has no ‘master’. Whenever someone tries to add or change data in the blockchain, all computers in the network check the details of the modification to make sure it is valid. Therefore, it is impossible for any individual to tamper with the blockchain data: the data is always secure."
          id="luTygA"
          description="Faq technology response 1 paragraph 1"
        />
      </>
    ),
  },
  {
    id: "couldnt-ubisoft-quartz-be-done-without-blockchain-technology",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Couldn’t Ubisoft Quartz be done without blockchain technology?",
      id: "5c6U+2",
      description: "Faq technology question 2",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Blockchain’s decentralized technology enables us to move beyond current limitations and set the foundations of an ambitious ecosystem. A platform that brings new benefits and possibilities to both players and creators in a secured environment: leave your mark in your favorite game and to be rewarded for your engagement, have items that live even outside the boundaries of the Ubisoft ecosystem, experience more control over your items and cash out if you choose to, and many more."
          id="pTdQdV"
          description="Faq technology response 2 paragraph 1"
        />
      </>
    ),
  },
  {
    id: "is-blockchain-a-privacy-friendly-technology",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "Is blockchain a privacy-friendly technology?",
      id: "C+uwgx",
      description: "Faq technology question 3",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Yes. Blockchain is recognized for being a secure technology as it guarantees the accuracy of data and prevent any data alteration. Also, Ubisoft Quartz has minimized the amount of personal data attached to the Digits (namely, Ubisoft player name and public crypto-wallet address) while still preserving the uniqueness and value of the Digits."
          id="Apbvrd"
          description="Faq technology response 3 paragraph 1"
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="Due to the nature of blockchain, the retention period of the personal data in the Digits is aligned with the duration of the blockchain, which means that the right of an individual to have all of its data deleted may not be available with respect to the metadata embedded in the Digits."
          id="EM0HHv"
          description="Faq technology response 3 paragraph 2"
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="However, Ubisoft Quartz endeavors to enforce all privacy rights available to you within the Ubisoft ecosystem. Also note that you can have your player name pseudonymized from your Digit metadata by contacting Ubisoft Support teams."
          id="mZnN7P"
          description="Faq technology response 3 paragraph 3"
        />
      </>
    ),
  },
  {
    id: "what-is-the-environmental-impact-of-ubisoft-quartz",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "What is the environmental impact of Ubisoft Quartz?",
      id: "pGUBct",
      description: "Faq technology question 4",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Thanks to the Tezos blockchain, Ubisoft Quartz’ energy consumption is equal to that of a standard database. In addition, Ubisoft commits to measuring its global environmental impact, with details on Ubisoft Quartz, and make it public."
          id="gxL2pH"
          description="Faq technology response 4 paragraph 1"
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="At Ubisoft we have been exploring blockchain technology for a number of years and the environmental impact of its early form has been one of the first limitations we identified. This observation led us to explore more advanced, energy-efficient alternatives, based on the Proof-of-Stake protocol, notably the one developed by Tezos, which powers the blockchain that Ubisoft Quartz relies on. Contrary to other blockchains such as Bitcoin and Ethereum, which are based on Proof-of-Work protocol, Tezos needs significantly less energy to operate. As an example, one transaction on Tezos consumes as much energy as 30 seconds of video streaming while a transaction on Bitcoin consumes the equivalent of one year of video streaming."
          id="sQELQw"
          description="Faq technology response 4 paragraph 2"
        />
        <img
          src={img}
          alt={intl.formatMessage({
            defaultMessage: "technology energy impact",
            id: "V5SJ/j",
            description: "technolgy image 1 alt text",
          })}
          className={style.img}
          aria-hidden="true"
        />
      </>
    ),
    popular: true,
    home: true,
  },
  {
    id: "what-is-tezos",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "What is Tezos?",
      id: "a8J3zD",
      description: "Faq technology question 5",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="Tezos is an open-source blockchain platform for assets and applications that can evolve by upgrading itself. Stakeholders govern upgrades to the core protocol, including upgrades to the amendment process itself."
          id="sbImJX"
          description="Faq technology response 5 paragraph 1"
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="For more information you can consult <link2>Tezos website</link2>."
          id="XT7S+r"
          description="Faq technology response 5 paragraph 2"
          values={{
            link2: (chunks: string) => (
              <a href="https://tezos.com/learn/what-is-tezos" target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
          }}
        />
      </>
    ),
  },
  {
    id: "what-is-aleph",
    date: new Date(2021, 11, 7),
    question: intl.formatMessage({
      defaultMessage: "What is Aleph?",
      id: "5H7pAw",
      description: "Faq technology question 6",
    }),
    response: (
      <>
        <FormattedMessage
          tagName="p"
          defaultMessage="<link3>Aleph</link3> is a decentralized storage solution that is used to store Ubisoft Quartz items metadata in secure and flexible way. The main advantages over other storage solutions are:"
          id="lz2XD7"
          description="Faq technology response 6 paragraph 1"
          values={{
            link3: (chunks: string) => (
              <a href="https://aleph.im" target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
          }}
        />
        <FormattedMessage
          tagName="ul"
          defaultMessage="<listitem>The ability to track changes to the metadata, as all document amendments are stored in a blockchain and anyone can check the history of the item metadata in a trustful manner.</listitem><listitem>Data availability guaranteed by locking Aleph tokens in the network</listitem>"
          id="NeJjJH"
          description="Faq technology response 6 paragraph 2"
          values={{ listitem: (item: string) => <li className={style.listitem}>{item}</li> }}
        />
        <FormattedMessage
          tagName="p"
          defaultMessage="Ubisoft Quartz system stores 3 different types of content on the Aleph network:"
          id="W1XR3y"
          description="Faq technology response 6 paragraph 3"
        />
        <FormattedMessage
          tagName="ul"
          defaultMessage="<listitem>Archetype definition</listitem><listitem>NFT transfer history</listitem><listitem>Owners Ubisoft player names</listitem>"
          id="3PkiGf"
          description="Faq technology response 6 paragraph 2"
          values={{ listitem: (item: string) => <li className={style.listitem}>{item}</li> }}
        />
      </>
    ),
  },
]

export default technology
