import { createContext, ReactNode, useEffect, useState } from "react"

const USER_SETUP_STATE_KEY = "setupState"

type setupState = "started" | "associate_prompt_dismissed" | "associate_done" | null

const useUserSetupState = () => {
  const [state, setState] = useState<setupState>(() => localStorage.getItem(USER_SETUP_STATE_KEY) as setupState)

  useEffect(() => {
    if (state) {
      localStorage.setItem(USER_SETUP_STATE_KEY, state)
    } else {
      localStorage.removeItem(USER_SETUP_STATE_KEY)
    }
  }, [state])

  return [state, setState] as [typeof state, typeof setState]
}

export const UserSetupStateContext = createContext<ReturnType<typeof useUserSetupState>>([
  null,
  () => {
    console.error("Provider not initialized")
  },
])

interface Props {
  children: ReactNode
}

const UserSetupStateProvider = ({ children }: Props) => {
  const userSetupState = useUserSetupState()

  return <UserSetupStateContext.Provider value={userSetupState}>{children}</UserSetupStateContext.Provider>
}

export default UserSetupStateProvider
