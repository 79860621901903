import { FormattedMessage, useIntl } from "react-intl"
import graphql from "babel-plugin-relay/macro"
import { useQuery } from "relay-hooks"
import { useParams } from "react-router-dom"
import { useTheme } from "@mui/system"
import { useMediaQuery } from "@mui/material"
import { useEffect, useMemo } from "react"

import { GameDashboardSalesQuery, GameDashboardSalesQuery$data } from "./__generated__/GameDashboardSalesQuery.graphql"
import { GameDashboardQuery, GameDashboardQuery$data } from "./__generated__/GameDashboardQuery.graphql"
import {
  GameDashboardPlayerQuery,
  GameDashboardPlayerQuery$data,
} from "./__generated__/GameDashboardPlayerQuery.graphql"

import Button from "../../components/Button"
import PageLayout from "../../components/PageLayout"
import RainbowTitle from "../../components/RainbowTitle"
import { trackClick } from "../../utils/analytics"
import ProductCardFragment from "../../components/Product/ProductCard/ProductCardFragment"
import useClaimStatus from "../../hooks/useClaimStatus"
import EmptySection from "../../components/EmptySection"
import NotFound from "../NotFound"
import toCssUrl from "../../utils/toCssUrl"
import TrackPage from "../../components/TrackPage"
import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import EligibilitySnackBar from "../../components/EligibilitySnackBar"

import { ReactComponent as LogoRarible } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/logo-rarible-black.svg"

import style from "./style.module.css"

type HeaderProps = {
  title: string
  logo?: string | undefined
  backgroundImage: string | undefined
  foregroundImage: string | undefined
}

const Header = ({ title, logo, backgroundImage, foregroundImage }: HeaderProps) => (
  <div
    className={style.header}
    style={{ backgroundImage: `${toCssUrl(foregroundImage)}, ${toCssUrl(backgroundImage)}` }}
  >
    {logo && <img className={style.logo} src={logo} alt={title} />}
  </div>
)

type BadgeProps = {
  logo: string | undefined
  backgroundImage: string | undefined
}

const Badge = ({ logo, backgroundImage }: BadgeProps) => {
  return (
    <div className={style.badge} style={{ backgroundImage: toCssUrl(backgroundImage) }} aria-hidden>
      {logo && <img src={logo} alt="" />}
    </div>
  )
}

const salesQuery = graphql`
  query GameDashboardSalesQuery($id: ID!) {
    game(id: $id) {
      name
      images {
        key
        url
      }
      sales {
        id
        price
        startDate
        endDate
        condition {
          ...buildConditionMap_saleCondition
        }
        ...ProductCardFragment_sale
        ...useClaimStatus_sale
        ...ClaimStatusSectionFragment_sale
      }
    }
  }
`

const playerQuery = graphql`
  query GameDashboardPlayerQuery {
    currentPlayer {
      ...useClaimStatus_player
      ...ProductCardFragment_player
    }
  }
`

const LoadingTokens = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const lg = useMediaQuery(theme.breakpoints.up("lg"))

  const range = useMemo(() => {
    if (lg) return 3
    if (md) return 2
    return 1
  }, [lg, md])

  return (
    <>
      {[...Array(range)].map((_, index) => (
        <li key={index}>
          <div className={style.cardLoading}>
            <div className={style.imageLoading} />
            <div className={style.descriptionLoading}>
              <div className={style.nameLoading} />
              <div className={style.gameTitleLoading} />
              <div className={style.infoLoading} />
            </div>
          </div>
        </li>
      ))}
    </>
  )
}

interface TokenCardProps {
  gameID: string
  sale: GameDashboardSalesQuery$data["game"]["sales"][number]
  player: GameDashboardPlayerQuery$data["currentPlayer"] | null
}

const TokenCard = ({ gameID, sale, player }: TokenCardProps) => {
  const claimStatus = useClaimStatus(sale, player)
  const to = {
    pathname: `/${gameID}/sales/${sale.id}`,
    state: { free: true, claimStatus },
  }

  return (
    <ProductCardFragment
      gameID={gameID}
      sale={sale}
      token={null}
      player={player}
      isLoading={false}
      to={to.pathname}
      state={to.state}
      hidePrice
      className={to && style.interactive}
    />
  )
}

const Sales = () => {
  const intl = useIntl()
  const { user } = useUbisoftWebAuthSessionContext()
  const { gameID } = useParams() as { gameID: string }
  const { data, isLoading, error } = useQuery<GameDashboardSalesQuery>(salesQuery, { id: gameID })
  const {
    data: playerData,
    isLoading: playerDataIsLoading,
    // TODO: Handle error currentplayer query error when logged
    // error: playerDataError,
    retry,
  } = useQuery<GameDashboardPlayerQuery>(playerQuery, {}, { skip: !user.ticket })

  useEffect(() => {
    user.ticket && retry()
  }, [retry, user])

  if (error) {
    return (
      <EmptySection
        title={intl.formatMessage({
          defaultMessage: "An error occured while trying to fetch the sales",
          id: "E6+kFw",
          description: "[Game Dashboard] sales fetch error message",
        })}
        buttonLabel={intl.formatMessage({
          defaultMessage: "Back to the Home",
          id: "4mzXg5",
          description: "[Game Dashboard] fetch error back button",
        })}
        to="/"
      />
    )
  }

  // Merge all free sales from all games in one array
  const freeSales = data?.game.sales
    .filter((sale) => sale.price === 0)
    .sort((a, b) => Date.parse(b.startDate) - Date.parse(a.startDate))

  return (
    <ul className={style.saleGrid}>
      {isLoading || playerDataIsLoading ? (
        <LoadingTokens />
      ) : (
        freeSales?.map((sale) => {
          return (
            <li key={sale.id}>
              <TokenCard gameID={gameID} sale={sale} player={playerData?.currentPlayer || null} />
            </li>
          )
        })
      )}
    </ul>
  )
}

const findImageUrl = (images: GameDashboardQuery$data["game"]["images"], imageKey: string) =>
  images.find(({ key }) => key === imageKey)?.url

const gameQuery = graphql`
  query GameDashboardQuery($id: ID!) {
    game(id: $id) {
      name
      images {
        key
        url
      }
    }
  }
`

const GameDashboard = () => {
  const { user } = useUbisoftWebAuthSessionContext()
  const { gameID } = useParams() as { gameID: string }
  const { data, error } = useQuery<GameDashboardQuery>(gameQuery, { id: gameID })

  if (error) {
    return <NotFound />
  }

  const handleTrackClick = (marketplace: "rarible" | "objkt") => {
    trackClick({
      location: "ubisoft quartz",
      locationDetail: "game dashboard page",
      category: "action",
      action: `marketplace: ${marketplace}`,
    })
  }

  const gameData = data?.game
  const headerBackgroundUrl = gameData && findImageUrl(gameData.images, "headerBackground")
  const headerImageUrl = gameData && findImageUrl(gameData.images, "headerImage")
  const headerLogoUrl = gameData && findImageUrl(gameData.images, "headerLogo")
  const badgeBackgroundUrl = gameData && findImageUrl(gameData.images, "badgeBackground")
  const badgeLogoUrl = gameData && findImageUrl(gameData.images, "badgeLogo")

  return (
    <PageLayout
      floatingNav
      headerClassName={style.fullScreen}
      header={
        <Header
          title={gameData?.name || ""}
          backgroundImage={headerBackgroundUrl}
          foregroundImage={headerImageUrl}
          logo={headerLogoUrl}
        />
      }
      mobileHeader={{ title: "" }}
    >
      <TrackPage siteSection="dashboard" pageName="main" siteSubSection={user.ticket ? "logged in" : "logged out"} />
      <header className={style.bodyHeader}>
        <Badge logo={badgeLogoUrl} backgroundImage={badgeBackgroundUrl} />
        <div className={style.description}>
          <RainbowTitle text={gameData?.name || ""} />
          <p className="paragraph">
            <FormattedMessage
              defaultMessage="Thank you to all Ghost Recon® Breakpoint players who claimed their first Digits!"
              id="4+vOIa"
              description="[Game Dashboard] header description"
              values={{
                br: <br />,
              }}
            />
          </p>
          <p className="paragraph">
            <FormattedMessage
              defaultMessage="You own a piece of the game and have left your mark in its history. As the last Digit for Ghost Recon® Breakpoint was released on 3/17/2022, stay tuned for more updates with features to the platform and future drops coming with other games!"
              id="ipw006"
              description="[Game Dashboard] header description"
              values={{
                br: <br />,
              }}
            />
          </p>
        </div>
        <div className={style.marketplaceLinks}>
          <Button
            fullWidth
            href="https://rarible.com/ubisoft-quartz"
            target="_blank"
            onClick={() => handleTrackClick("rarible")}
          >
            <LogoRarible />
          </Button>
        </div>
      </header>
      <div className={style.container}>
        <Sales />
      </div>
      <EligibilitySnackBar />
    </PageLayout>
  )
}

export default GameDashboard
