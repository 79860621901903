import { useCallback, useState } from "react"
import { useMutation } from "react-relay"
import graphql from "babel-plugin-relay/macro"

import { useUserInfoContext } from "../../../providers/UserInfoProvider"
import { TransactionErrorKey } from "../../TokenPurchase/hooks/useBuyToken"

const claimDigitMutation = graphql`
  mutation useClaimDigitMutation($saleID: ID!) {
    registerFreeDrop(saleID: $saleID)
  }
`

const useClaimDigit = (saleID: string) => {
  const [commit] = useMutation(claimDigitMutation)
  const [pending, setPending] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [success, setSuccess] = useState(false)
  const { userInfo } = useUserInfoContext()

  const reset = () => setError(null)

  const claimDigit = useCallback(async () => {
    setSuccess(false)

    if (!userInfo.walletAddress) {
      setError(new Error(TransactionErrorKey.NO_WALLET_ASSOCIATED))

      return
    }

    setPending(true)
    commit({
      variables: {
        saleID,
      },
      onError: (e) => {
        setError(e)
        setPending(false)
      },
      onCompleted: () => {
        setSuccess(true)
        setPending(false)
      },
    })
  }, [commit, saleID, userInfo.walletAddress])

  return { claimDigit, pending, success, error, reset }
}

export default useClaimDigit
