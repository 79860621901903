/**
 * @generated SignedSource<<b63d90aa14fb7e1a821f77b2dfc2ca1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClaimSectionFragment_sale$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSaleStatusFragment_sale" | "useClaimStatus_sale" | "useCanPurchaseFragment_sale" | "CannotPurchaseError_sale">;
  readonly " $fragmentType": "ClaimSectionFragment_sale";
};
export type ClaimSectionFragment_sale$key = {
  readonly " $data"?: ClaimSectionFragment_sale$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClaimSectionFragment_sale">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClaimSectionFragment_sale",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSaleStatusFragment_sale"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useClaimStatus_sale"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCanPurchaseFragment_sale"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CannotPurchaseError_sale"
    }
  ],
  "type": "Sale",
  "abstractKey": null
};

(node as any).hash = "105ff24dd42b648fcd79697f0824b73f";

export default node;
