import { CountdownRenderProps } from "react-countdown"
import { FormattedMessage } from "react-intl"

const BasicCountdown = ({ days, hours, minutes }: CountdownRenderProps) => {
  return (
    <>
      <span>
        <FormattedMessage
          defaultMessage="{days, plural, =0 {} one {1 day } other {# days }}"
          id="cM1eKq"
          description="[Dashboard] Days in countdown when my digit will be available"
          values={{
            days: days,
          }}
        />
      </span>
      <span>
        <FormattedMessage
          defaultMessage="{hours, plural, =0 {} one {1 hour } other {# hours }}"
          id="DVk2nZ"
          description="[Dashboard] Hours in countdown when my digit will be available"
          values={{
            hours: hours,
          }}
        />
      </span>
      <span>
        <FormattedMessage
          defaultMessage="{min, plural, =0 {} one {1 minute} other {# minutes}}"
          id="xUJzpM"
          description="[Dashboard] Minutes countdown when my digit will be available"
          values={{
            min: minutes,
          }}
        />
      </span>
    </>
  )
}

export default BasicCountdown
