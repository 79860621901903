import { useFragment } from "react-relay"
import graphql from "babel-plugin-relay/macro"
import { useMemo } from "react"

import { useTrackingCustomParameter_sale$key } from "./__generated__/useTrackingCustomParameter_sale.graphql"
import { useTrackingCustomParameter_token$key } from "./__generated__/useTrackingCustomParameter_token.graphql"

import { buildCustomParameter } from "../utils/analytics"

const useTrackingCustomParameter = (
  sale: useTrackingCustomParameter_sale$key,
  token: useTrackingCustomParameter_token$key | null
) => {
  const saleData = useFragment(
    graphql`
      fragment useTrackingCustomParameter_sale on Sale {
        price
        archetype {
          maxInstances
          primaryVariant {
            name
            categorization {
              game {
                name
              }
            }
          }
        }
      }
    `,
    sale
  )
  const tokenData = useFragment(
    graphql`
      fragment useTrackingCustomParameter_token on Token {
        serialNumber
      }
    `,
    token
  )

  const customParameter = useMemo(() => {
    const freeDrop = saleData?.price === 0
    // TODO: Update value for paid drops when tokenCount feature will be available for now value may be wrong
    const soldOut = freeDrop ? "" : false

    return buildCustomParameter(
      saleData.archetype.primaryVariant.categorization?.game?.name || "",
      saleData.archetype.primaryVariant.name,
      tokenData?.serialNumber || "",
      saleData?.archetype.maxInstances || 0,
      saleData?.price || 0,
      soldOut,
      freeDrop
    )
  }, [saleData, tokenData])

  return customParameter
}

export default useTrackingCustomParameter
