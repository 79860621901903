import { useCallback, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { TempleWallet } from "@temple-wallet/dapp"

import CardButton from "../../components/CardButton"
import RainbowTitle from "../../components/RainbowTitle"
import TempleButton from "../TempleButton"
import InfoBox from "../../components/InfoBox"
import UbisoftSpinner from "../../components/UbisoftSpinner"
import isUserEligible from "../../utils/isUserEligible"
import walletProviders, { ProviderName } from "../../web3/walletProviders"
import { trackClick } from "../../utils/analytics"
import { useTezosContext } from "../../web3/Web3Provider"
import { useUserInfoContext } from "../../providers/UserInfoProvider"
import { useGeolocationInfoContext } from "../../providers/GeolocationInfoProvider"
import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import { PurchaseStatus } from "../../hooks/useCanPurchase"

import style from "./style.module.css"

interface Props {
  onSelectedWallet: (name: string | null) => void
  purchaseStatus?: PurchaseStatus
}

const WalletConnectSection = ({ onSelectedWallet, purchaseStatus }: Props) => {
  const intl = useIntl()
  const { providerLoading, connectProvider } = useTezosContext()
  const { isCurrentIpLocationAllowed, isLoading: geolocationIsLoading } = useGeolocationInfoContext()
  const { userInfo, isLoading: isUserInfoLoading } = useUserInfoContext()
  const { twoFaActive, isLoading: userLoading, checkTwoFAStatus } = useUbisoftWebAuthSessionContext()
  const [templeState, setTempleState] = useState({ loading: true, available: false })

  useEffect(() => {
    TempleWallet.isAvailable().then((available) => setTempleState({ loading: false, available }))
  }, [])

  useEffect(() => {
    checkTwoFAStatus()
  }, [checkTwoFAStatus])

  const handleWalletClick = useCallback(
    (name: ProviderName) => async () => {
      trackProviderClick(name)
      try {
        await connectProvider(name).then(() => onSelectedWallet(name))
      } catch (e) {
        console.error(e)
      }
    },
    [connectProvider, onSelectedWallet]
  )

  const trackProviderClick = (name: ProviderName, uninstalled = false) => {
    trackClick({
      location: "ubisoft quartz",
      locationDetail: "wallet",
      category: "action",
      action: `connect : ${name}`,
    })
  }

  if (isUserInfoLoading || userLoading || geolocationIsLoading || templeState.loading || providerLoading) {
    return (
      <div className={style.loading}>
        <UbisoftSpinner className={style.spinner} contrast />
        <FormattedMessage
          defaultMessage="Initializing crypto-wallet provider..."
          id="N+EGnM"
          description="[Connect Wallet] provider loading message"
        />
      </div>
    )
  }

  if (!userInfo.walletAddress && (!isUserEligible(userInfo, isCurrentIpLocationAllowed) || !twoFaActive)) {
    return <Navigate to="/user/not-eligible/purchase" replace />
  }

  return (
    <section>
      <RainbowTitle
        text={
          purchaseStatus === PurchaseStatus.NO_ASSOCIATED_WALLET
            ? intl.formatMessage({
                defaultMessage: "Set up a crypto-wallet",
                id: "Ha80VU",
                description: "[Connect Wallet] page title",
              })
            : intl.formatMessage({
                defaultMessage: "Connect your crypto-wallet",
                id: "us5irD",
                description: "[Connect Wallet] page title",
              })
        }
      />
      <p className="paragraph">
        <FormattedMessage
          defaultMessage="You need to use a crypto-wallet to perform transactions in Ubisoft Quartz and to host your Digits once acquired."
          id="lHsM/c"
          description="[Connect Wallet] page description"
        />
      </p>
      <ul className={style.list}>
        <li className={style.walletItem}>
          <CardButton
            iconLeft={<img className={style.icon} src={walletProviders["kukai"].logo} alt="" aria-hidden />}
            title={walletProviders["kukai"].name}
            description={intl.formatMessage({
              defaultMessage: "Web service with social log in",
              id: "N9ZFPM",
              description: "[Connect Wallet] kukai description",
            })}
            onClick={handleWalletClick("kukai")}
          />
        </li>
        <li className={style.walletItem}>
          <TempleButton onConnectClick={handleWalletClick("temple")} available={templeState.available} />
        </li>
      </ul>
      <InfoBox>
        <FormattedMessage
          defaultMessage="By selecting any of these third-party crypto-wallet providers, you will be redirected to an external website. Specific terms and conditions such as fees may apply."
          id="n2M6Sl"
          description="[Connect Wallet] Crypto-wallet terms disclaimer"
        />
      </InfoBox>
    </section>
  )
}

export default WalletConnectSection
