import style from "./style.module.css"

export interface Props {
  picture: string
  username: string
  className: string
}

const ProfileDescription = ({ picture, username, className }: Props) => {
  return (
    <div className={`${style.container} ${className}`}>
      <img className={style.picture} src={picture} alt={username} />
      <p className={style.username}>{username}</p>
    </div>
  )
}

export default ProfileDescription
