import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import useTheme from "@mui/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"

import Button from "../../components/Button"
import PageLayout from "../PageLayout"
import PageHeader from "../PageHeader"

import rabbids from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/rabbidsGamepadFight.png"
import { ReactComponent as ArrowRight } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"

import style from "./style.module.css"

export interface Props {
  children?: ReactNode
  headerTitle?: string
  errorTitle: string
  errorIcon?: ReactNode
  errorImage?: string
  buttonLabel?: string
  buttonIcon?: ReactNode
  onClick?: () => void
}

const FullPageError = ({
  children,
  headerTitle,
  errorTitle,
  errorIcon,
  errorImage,
  buttonLabel,
  buttonIcon,
  onClick,
}: Props) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const goBack = () => {
    navigate(-1)
  }

  return (
    <PageLayout
      header={isMobile && headerTitle && <PageHeader title={headerTitle} />}
      bodyPosition="right"
      className={style.spaceBetween}
      bodyWrapperClassName={style.noFlexGrow}
    >
      <div className={style.container}>
        {errorIcon ? errorIcon : <img src={errorImage || rabbids} alt="" aria-hidden className={style.errorIcon} />}
        <h2 className={style.rainbow}>{errorTitle}</h2>
        {children}
        {buttonLabel && (
          <Button
            onClick={onClick ? onClick : goBack}
            icon={buttonIcon ? buttonIcon : <ArrowRight />}
            fullWidth
            className={style.button}
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    </PageLayout>
  )
}

export default FullPageError
