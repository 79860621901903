import { useLocation as useReactRouterLocation } from "react-router-dom"
import { Location, State } from "history"

function useLocation<T = unknown>() {
  type L = State & T

  const location = useReactRouterLocation()
  return location as Location<L>
}

export default useLocation
