import { HTMLProps } from "react"

import { ReactComponent as UbisoftSwirl } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/ubisoftSwirl.svg"

import style from "./style.module.css"

interface Props extends HTMLProps<HTMLDivElement> {
  contrast?: boolean
}

const UbisoftSpinner = ({ contrast, className, ...props }: Props) => {
  return (
    <div className={`${style.container} ${className}`} {...props}>
      <svg height="66" width="66" className={style.animation} aria-hidden>
        <defs>
          <linearGradient id="gradient" x1="0.8" x2="0" y1="0" y2="0.8">
            <stop offset="0%" stopColor="var(--accent)" />
            <stop offset="100%" stopColor="var(--primary)" />
          </linearGradient>
        </defs>
        <circle cx="33" cy="33" r="32" stroke="url(#gradient)" strokeWidth="2" fill="transparent" />
      </svg>
      <UbisoftSwirl aria-hidden className={`${style.logo} ${contrast ? style.contrast : ""}`} />
    </div>
  )
}

export default UbisoftSpinner
