import { FormattedMessage, useIntl } from "react-intl"

import Button from "../../components/Button"
import PageHeader from "../../components/PageHeader"
import PageLayout from "../../components/PageLayout"
import TrackPage from "../../components/TrackPage"

import { ReactComponent as ChevronLeftIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/chevronLeft.svg"

import style from "./style.module.css"

const NotFound = () => {
  const intl = useIntl()

  return (
    <>
      <TrackPage siteSection="error" pageName="404" />
      <PageLayout
        header={
          <PageHeader
            title={intl.formatMessage({
              defaultMessage: "Error",
              id: "s7lSjL",
              description: "[Page Not Found] header title",
            })}
          />
        }
        bodyClassName={style.container}
      >
        <h1 className="text-rainbow-horizontal">404</h1>
        <h2 className="text-rainbow-horizontal">
          <FormattedMessage defaultMessage="Page not found" id="QlAcj8" description="[Page Not Found] error title" />
        </h2>
        <Button invert icon={<ChevronLeftIcon />} to="/">
          <FormattedMessage
            defaultMessage="Go to Home"
            id="o1a6XZ"
            description="[Page Not Found] label of link to home"
          />
        </Button>
      </PageLayout>
    </>
  )
}

export default NotFound
