import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import graphql from "babel-plugin-relay/macro"
import { fetchQuery } from "relay-runtime"

import { GeolocationInfoProviderQuery } from "./__generated__/GeolocationInfoProviderQuery.graphql"

import { getGeolocation } from "../api/ubiservices"
import { UbisoftWebAuthSession, useUbisoftWebAuthSessionContext } from "../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import useRelayEnvironment from "../hooks/useRelayEnvironment"

const geolocationQuery = graphql`
  query GeolocationInfoProviderQuery {
    allowedCountries: getAllowedCountries
  }
`

const EMPTY_GEOLOCATION_SESSION = {
  isLoading: true,
  isCurrentIpLocationAllowed: false,
  refresh: null,
}

export const GeolocationInfoContext = createContext<UbisoftGeolocationInfo>(EMPTY_GEOLOCATION_SESSION)

/*
 * Contains user's information.
 *
 * @property isLoading Is true when data hasn't been loaded yet (used to display the loading screen while fetching the geolocation info).
 * @property geolocationInfo Holds the last retrieved geolocation info.
 * @property refresh Gives the possibility to refetch the lastest geolocation info.
 *
 */
export interface UbisoftGeolocationInfo {
  isLoading: boolean
  isCurrentIpLocationAllowed: boolean
  refresh: (() => void) | null
}

export const useGeolocationInfoContext = () => {
  const context = useContext(GeolocationInfoContext)
  if (context === undefined) {
    throw new Error("useGeolocationInfoContext must be used within a GeolocationInfoProvider")
  }
  return context
}

const useGeolocationInfo = ({ user }: UbisoftWebAuthSession) => {
  const environment = useRelayEnvironment(user)
  const [isCurrentIpLocationAllowed, setisCurrentIpLocationAllowed] = useState<boolean>(
    EMPTY_GEOLOCATION_SESSION.isCurrentIpLocationAllowed
  )
  const [isLoading, setIsLoading] = useState(true)

  const refresh = useCallback(() => {
    const { geolocationInfo, cancelGeolocationInfo } = getGeolocation()
    const allowedCountriesResponse = fetchQuery<GeolocationInfoProviderQuery>(
      environment,
      geolocationQuery,
      {}
    ).toPromise()

    setIsLoading(true)
    Promise.all([geolocationInfo, allowedCountriesResponse])
      .then(([geolocationInfoData, allowedCountriesData]) => {
        const isCurrentLocationInAllowedCountriesList = !!allowedCountriesData?.allowedCountries.find(
          (country) => country === geolocationInfoData.countryCode
        )
        setisCurrentIpLocationAllowed(isCurrentLocationInAllowedCountriesList)
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))

    return cancelGeolocationInfo
  }, [environment])

  useEffect(() => {
    return refresh()
  }, [refresh])

  return {
    isLoading,
    isCurrentIpLocationAllowed,
    refresh,
  }
}

interface Props {
  children: ReactNode
}

const GeolocationInfoProvider = ({ children }: Props) => {
  const session = useUbisoftWebAuthSessionContext()
  const userInfo = useGeolocationInfo(session)

  return <GeolocationInfoContext.Provider value={userInfo}>{children}</GeolocationInfoContext.Provider>
}

export default GeolocationInfoProvider
