import { FormattedMessage } from "react-intl"

import UbisoftSpinner from "../../components/UbisoftSpinner"

import style from "./style.module.css"

export interface Props {
  invert?: boolean
}

const Loading = ({ invert }: Props) => (
  <div className={`${style.verticalCenter} ${invert && style.invert}`} aria-hidden>
    <div className={style.loading}>
      <UbisoftSpinner className={style.spinner} />
      <FormattedMessage defaultMessage="Loading..." id="PNnFO2" description="App loading message" />
    </div>
  </div>
)

export default Loading
