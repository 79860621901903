import { ReactNode } from "react"
import { useLocation } from "react-router-dom"

import { ReactComponent as ChevronRightIcon } from "../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/chevronRight.svg"

import style from "./style.module.css"
interface Props {
  id: string
  question: string
  response: ReactNode
  categoryId: string
  onClick: (location: string, categoryId: string, questionId: string) => void
}

const Question = ({ id, question, response, categoryId, onClick }: Props) => {
  const { pathname } = useLocation()

  const handleOnClick = () => {
    onClick(pathname, categoryId, id)
  }

  return (
    <div className={style.container} onClick={handleOnClick}>
      <div>
        <h4 className={style.question}>{question}</h4>
        <div className={style.response}>{response}</div>
      </div>
      <div className={style.icon}>
        <ChevronRightIcon aria-hidden />
      </div>
    </div>
  )
}

export default Question
