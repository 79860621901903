import { ReactNode } from "react"
import { LinkProps, To } from "react-router-dom"

import Button from "../Button"

import { ReactComponent as ArrowRight } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"

import style from "./style.module.css"

export interface Props {
  title: string | ReactNode
  buttonLabel?: string
  buttonIcon?: ReactNode
  img?: string
  imgClassName?: string
  to?: To
  state?: LinkProps["state"]
  onClick?: () => void
  className?: string
}

const EmptySection = ({
  title,
  buttonLabel,
  buttonIcon,
  img,
  imgClassName = "",
  to,
  state,
  onClick,
  className = "",
}: Props) => {
  return (
    <div className={`${style.container} ${className}`}>
      <h4 className={style.title}>{title}</h4>
      {(to || onClick) && (
        <Button invert icon={buttonIcon ? buttonIcon : <ArrowRight />} to={to} state={state} onClick={onClick}>
          {buttonLabel}
        </Button>
      )}
      {img && <img src={img} alt="" aria-hidden className={imgClassName} />}
    </div>
  )
}

export default EmptySection
