import { ReactNode } from "react"
import Snackbar from "@mui/material/Snackbar"
import makeStyles from "@mui/styles/makeStyles"
import clsx from "clsx"
import { Link, To } from "react-router-dom"

import { useDisplayBottomNavContext } from "../BottomNav/BottomNavDisplayProvider"

import style from "./style.module.css"

export interface Props {
  message: string | ReactNode
  icon?: ReactNode
  open: boolean
  withBottomNav?: boolean
  to?: To
}

const useStyles = makeStyles((theme) => ({
  "@keyframes slideIn": {
    from: {
      transform: "translateY(100%)",
    },
    to: {
      transform: "translateY(0)",
    },
  },
  root: {
    display: "flex",
    left: 0,
    right: 0,
    bottom: 0,
    transform: "none",
    [theme.breakpoints.up("md")]: {
      position: "sticky",
    },
    animation: "$slideIn 1s ease-out 1s backwards",
    transition: "bottom 225ms ease-out",
  },
  rootWithBottomNav: {
    bottom: "var(--bottom-nav-height)",
    [theme.breakpoints.up("md")]: {
      bottom: 0,
    },
    zIndex: 100,
  },
}))

const SnackBar = ({ message, icon, open, withBottomNav = false, to }: Props) => {
  const classes = useStyles()
  const { visible } = useDisplayBottomNavContext()

  const content = to ? (
    <Link className={style.link} to={to}>
      <div className={style.container}>
        <div className={style.message}>{message}</div>
        {icon}
      </div>
    </Link>
  ) : (
    <div className={style.container}>
      <div className={style.message}>{message}</div>
    </div>
  )

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      className={style.wrapper}
      classes={{
        root: clsx(classes.root, { [classes.rootWithBottomNav]: withBottomNav && visible }),
      }}
    >
      {content}
    </Snackbar>
  )
}

export default SnackBar
