import { useIntl } from "react-intl"

import { useUbisoftWebAuthSessionContext } from "../../ubisoftWebAuth/UbisoftWebAuthSessionProvider"
import ConfirmDialog from "../ConfirmDialog"

import { ReactComponent as LogoutIcon } from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/logout.svg"

export interface Props {
  open: boolean
  onClose?: () => void
}

const LogoutConfirmDialog = ({ open, onClose }: Props) => {
  const intl = useIntl()
  const { logout } = useUbisoftWebAuthSessionContext()

  const handleDialogClose = (confirm: boolean) => {
    if (confirm) {
      logout()
    }
    onClose && onClose()
  }

  return (
    <ConfirmDialog
      invertButtonsColor
      open={open}
      onClose={handleDialogClose}
      icon={<LogoutIcon style={{ color: "var(--danger)" }} />}
      title={intl.formatMessage(
        {
          defaultMessage: "Are you sure {linebreak}you want to log out?",
          id: "hJJ8hw",
          description: "[Log out Dialog] title",
        },
        { linebreak: <br /> }
      )}
      content={intl.formatMessage({
        defaultMessage: "You will be logged out from all Ubisoft services.",
        id: "0sV1zA",
        description: "[Log out Dialog] description",
      })}
      confirmLabel={intl.formatMessage({
        defaultMessage: "Yes I want to Log out",
        id: "+h059Y",
        description: "[Log out Dialog] Confirm button label",
      })}
      confirmIcon={<LogoutIcon aria-hidden />}
      cancelLabel={intl.formatMessage({
        defaultMessage: "I changed my mind",
        id: "9iO30r",
        description: "[Log out Dialog] Cancel button label",
      })}
    />
  )
}

export default LogoutConfirmDialog
