import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import { useLocation, useMatch, useNavigate } from "react-router-dom"

import { UserSetupStateContext } from "../providers/UserSetupStateProvider/UserSetupStateProvider"
import config from "../config/ubisoftServicesConfig"

import {
  UbisoftWebAuthGetTicketResponse,
  UbisoftWebAuthConnectSDKParams as UbisoftWebAuthConnectSDKParameters,
} from "./types"
import {
  UbisoftWebAuthConnectProfilePayload,
  UbisoftWebAuthGetProfilesResponse,
  UbisoftWebAuthGetUserStatusResponse,
  UbisoftWebAuthLogoutResponse,
} from "./types"
import { get2FAStatus } from "../api/ubiservices"

const QUARTZ_ENV_SDK_PARAMS: UbisoftWebAuthConnectSDKParameters = {
  ConnectEnvironment: config.env,
  ConnectApplicationID: config.applicationId,
  ConnectGenomeID: config.genomeId,
  ConnectLang: config.connectLanguage,
  ConnectThirdPartyCookiesSupport: true,
  ConnectLoginURL: config.loginURL,
}

export const SessionContext = createContext<UbisoftWebAuthSession>({
  isLoading: true,
  user: {
    ticket: "",
    userID: "",
    sessionID: "",
  },
  twoFaActive: false,
  profiles: [],
  login: () => {},
  logout: () => {},
  checkTwoFAStatus: () => {},
})

export interface UbisoftWebAuthUser {
  ticket: string
  userID: string
  sessionID: string
}

/*
 * Contains user session information and callbacks for login and logout.
 *
 * @property user Holds the current user's ID, ticket, and session ID. They contain empty strings if the user is logged out or the login is pending.
 * @property twoFaStatus Holds the status for the 2FA activation.
 * @property profiles Holds the data returned by the Ubisoft WebAuth getProfiles() method. More information here: https://confluence.ubisoft.com/display/webservices/5.8+-+Ubisoft+WebAuth+-+Technical+Documentation+-+SDK+Documentation#id-5.8UbisoftWebAuthTechnicalDocumentationSDKDocumentation-getProfiles().
 * @property login Method to open the login popup.
 * @property logout Method to logout the user.
 * @property checkTwoFAStatus Method to compare ubiservices and webAuth 2FA status and prevent cross tracking issues.
 * @property isLoading Is true when the user's login is pending (used to display the loading screen while fetching the user's info).
 *
 */
export interface UbisoftWebAuthSession {
  user: UbisoftWebAuthUser
  twoFaActive: boolean
  profiles: UbisoftWebAuthConnectProfilePayload[]
  login: () => void
  logout: () => void
  checkTwoFAStatus: () => void
  isLoading: boolean
}

export const useUbisoftWebAuthSessionContext = () => {
  const context = useContext(SessionContext)
  if (context === undefined) {
    throw new Error("useSessionContext must be used within a SessionProvider")
  }
  return context
}

declare global {
  export interface Window {
    Connect: {
      init: (params: any) => void
      sdk: any
      _sdk: any
    }
  }
}

const useUbisoftWebAuthSession = () => {
  const [connectSDK] = useState(window.Connect)
  const [sdk, setSdk] = useState<any>()
  const [user, setUser] = useState<UbisoftWebAuthUser>({
    ticket: "",
    userID: "",
    sessionID: "",
  })
  const [twoFaActive, setTwoFaActive] = useState(false)
  const [userProfiles, setUserProfiles] = useState<UbisoftWebAuthConnectProfilePayload[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()
  const popupRouteMatch = useMatch("/popup_callback")
  const thirdPartyCookiesRouteMatch = useMatch("/tpc_callback")
  const nextUrl = `${process.env.REACT_APP_URL}/popup_callback`
  const callbackPage = `${process.env.REACT_APP_URL}/tpc_callback?redirect=${location.pathname}`
  const [, setUserSetupState] = useContext(UserSetupStateContext)

  const webAuthLoginURL = `${QUARTZ_ENV_SDK_PARAMS.ConnectLoginURL}?appId=${
    QUARTZ_ENV_SDK_PARAMS.ConnectApplicationID
  }&genomeId=${QUARTZ_ENV_SDK_PARAMS.ConnectGenomeID}&lang=${QUARTZ_ENV_SDK_PARAMS.ConnectLang}&nextUrl=${encodeURI(
    nextUrl
  )}`

  const resetUserState = () => {
    setUser({ ticket: "", sessionID: "", userID: "" })
    setTwoFaActive(false)
    setUserProfiles([])
  }

  const loginUbisoft = () => {
    window.open(webAuthLoginURL, "Ubisoft Connect", "width=520,height=720,toolbar=no,status=no")
    setUserSetupState("started")
  }

  const logoutUbisoft = useCallback(() => {
    if (!sdk) {
      throw new Error("sdk not found logout")
    } else {
      setIsLoading(true)

      sdk.getTicket().subscribe((getTicketResponse: UbisoftWebAuthGetTicketResponse) => {
        if (getTicketResponse.status === "ok") {
          sdk
            .logout(getTicketResponse.payload.ticket, getTicketResponse.payload.sessionId)
            .subscribe((logoutResponse: UbisoftWebAuthLogoutResponse) => {
              resetUserState()
              setIsLoading(false)
              if (logoutResponse.status === "ok") {
                navigate("/")
              } else {
                console.error(logoutResponse)
              }
            })
        } else {
          setIsLoading(false)
          navigate("/")
          window.location.reload()
        }
      })
    }
  }, [sdk, navigate])

  const checkTwoFAStatus = useCallback(() => {
    if (!sdk || !user.ticket) return

    const { status2FA, cancel2FAStatus } = get2FAStatus(user.ticket, user.sessionID)

    sdk
      .getUserStatus(user.ticket, user.sessionID, user.userID)
      .subscribe(function (getUserStatusResponse: UbisoftWebAuthGetUserStatusResponse) {
        const webAuthStatus = getUserStatusResponse.payload.twoFaStatus.active

        status2FA
          .then((status) => {
            if (status !== webAuthStatus) {
              // logout user if webAuth and ubiservices 2FA states don't match
              logoutUbisoft()
            } else if (webAuthStatus) {
              setTwoFaActive(true)
            } else return
          })
          .catch((err) => console.error(err))
      })

    return () => {
      cancel2FAStatus()
    }
  }, [sdk, user, logoutUbisoft])

  useEffect(() => {
    if (sdk) {
      return
    }

    setIsLoading(true)

    if (thirdPartyCookiesRouteMatch) {
      const urlQueryParams = new URLSearchParams(location.search)
      connectSDK.init({
        env: QUARTZ_ENV_SDK_PARAMS.ConnectEnvironment,
        localLoginExpirationMinutes: 5,
      })
      connectSDK.sdk.subscribe((sdk: any) => {
        sdk.getTicketFromSsoId(() => {
          window.location.href = process.env.REACT_APP_URL
            ? `${process.env.REACT_APP_URL}/${urlQueryParams.get("redirect")?.replace(/^\//, "") || ""}`
            : "/"
        })
      })
    } else if (popupRouteMatch) {
      if (window.opener != null) {
        window.opener.location.reload()
        window.close()
      }
    } else {
      connectSDK.init({
        env: QUARTZ_ENV_SDK_PARAMS.ConnectEnvironment,
        appId: QUARTZ_ENV_SDK_PARAMS.ConnectApplicationID,
        genomeId: QUARTZ_ENV_SDK_PARAMS.ConnectGenomeID,
        lang: QUARTZ_ENV_SDK_PARAMS.ConnectLang,
        nextUrl: callbackPage,
        thirdPartyCookiesSupport: QUARTZ_ENV_SDK_PARAMS.ConnectThirdPartyCookiesSupport,
        loginUrl: QUARTZ_ENV_SDK_PARAMS.ConnectLoginURL,
      })
      connectSDK.sdk.subscribe((sdk: any) => {
        setSdk(sdk)
        sdk.getTicket().subscribe((getTicketResponse: UbisoftWebAuthGetTicketResponse) => {
          if (getTicketResponse.status === "ok") {
            // User is logged in
            setUser({
              ticket: getTicketResponse.payload.ticket,
              userID: getTicketResponse.payload.userId,
              sessionID: getTicketResponse.payload.sessionId,
            })
            sdk
              .getProfiles(
                getTicketResponse.payload.ticket,
                getTicketResponse.payload.sessionId,
                getTicketResponse.payload.userId
              )
              .subscribe(function (getProfilesResponse: UbisoftWebAuthGetProfilesResponse) {
                setUserProfiles(getProfilesResponse.payload.profiles)
              })
            sdk
              .getUserStatus(
                getTicketResponse.payload.ticket,
                getTicketResponse.payload.sessionId,
                getTicketResponse.payload.userId
              )
              .subscribe(function (getUserStatusResponse: UbisoftWebAuthGetUserStatusResponse) {
                setTwoFaActive(getUserStatusResponse.payload.twoFaStatus.active)
              })
          } else {
            // User is not logged in
            resetUserState()
          }
          setIsLoading(false)
        })
      })
    }
  }, [connectSDK, sdk, callbackPage, nextUrl, popupRouteMatch, thirdPartyCookiesRouteMatch, location])

  useEffect(() => {
    checkTwoFAStatus()
  }, [checkTwoFAStatus])

  return {
    isLoading: isLoading,
    sdkParameters: QUARTZ_ENV_SDK_PARAMS,
    connectSDK: connectSDK,
    user: user,
    twoFaActive: twoFaActive,
    profiles: userProfiles,
    login: loginUbisoft,
    logout: logoutUbisoft,
    checkTwoFAStatus,
  }
}

interface Props {
  children: ReactNode
}

const UbisoftWebAuthSessionProvider = ({ children }: Props) => {
  const session = useUbisoftWebAuthSession()

  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>
}

export default UbisoftWebAuthSessionProvider
