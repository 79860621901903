import { ReactNode, useMemo } from "react"
import { useIntl } from "react-intl"
import graphql from "babel-plugin-relay/macro"
import { useFragment } from "react-relay"

import { SaleUnavailableError_variant$key } from "./__generated__/SaleUnavailableError_variant.graphql"

import BasePurchaseError from "../../../../../../../components/TokenPurchase/BasePurchaseError"
import { SaleStatus } from "../../../../../../../hooks/useSaleStatus"
import TrackPage from "../../../../../../../components/TrackPage"
import { buildCustomParameter } from "../../../../../../../utils/analytics"
import useDashboardRedirectInfo from "../../../../../../../hooks/useDashboardRedirectInfo"

import { ReactComponent as ArrowRightIcon } from "../../../../../../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/icons/arrowRight.svg"

interface ErrorProps {
  trackingCustomParameter: string
}

const UpcomingFreeSaleError = ({ trackingCustomParameter }: ErrorProps) => {
  const intl = useIntl()
  const { to, label } = useDashboardRedirectInfo()

  return (
    <>
      <TrackPage siteSection="product" pageName="message : coming soon" customParameter={trackingCustomParameter} />
      <BasePurchaseError
        title={intl.formatMessage({
          defaultMessage: "Coming soon",
          id: "G3bal3",
          description: "[Free Sale] Claim section title when free token not yet available",
        })}
        message={intl.formatMessage({
          defaultMessage: "Claim period is not opened yet.",
          id: "V7cYVY",
          description: "[Free Sale] Free token not available yet message",
        })}
        buttonLabel={label}
        buttonIcon={<ArrowRightIcon />}
        buttonInvert={false}
        to={to}
      />
    </>
  )
}

const AlreadyClaimedError = ({ trackingCustomParameter }: ErrorProps) => {
  const intl = useIntl()
  const { to, label } = useDashboardRedirectInfo()

  return (
    <>
      <TrackPage
        siteSection="product"
        pageName="message : nice try already claimed"
        customParameter={trackingCustomParameter}
      />
      <BasePurchaseError
        title={intl.formatMessage({
          defaultMessage: "Nice try!",
          id: "iny5ve",
          description: "[Free Sale] Claim section title when free token not available",
        })}
        message={intl.formatMessage({
          defaultMessage: "You already claimed a Digit from this Edition!",
          id: "o270OI",
          description: "[Free Sale] Free token not available message",
        })}
        buttonLabel={label}
        buttonIcon={<ArrowRightIcon />}
        buttonInvert={false}
        to={to}
      />
    </>
  )
}

const NoFreeTokenAvailableError = ({ trackingCustomParameter }: ErrorProps) => {
  const intl = useIntl()
  const { to, label } = useDashboardRedirectInfo()

  return (
    <>
      <TrackPage siteSection="product" pageName="message : too late" customParameter={trackingCustomParameter} />
      <BasePurchaseError
        title={intl.formatMessage({
          defaultMessage: "Too late!",
          id: "q1EHpE",
          description: "[Free Sale] Claim section title when free token not available",
        })}
        message={intl.formatMessage({
          defaultMessage:
            "All the Digits from this Edition have already been claimed. You can look for other Editions on the dashboard!",
          id: "R7/KUX",
          description: "[Free Sale] Free token not available message",
        })}
        buttonLabel={label}
        buttonIcon={<ArrowRightIcon />}
        buttonInvert={false}
        to={to}
      />
    </>
  )
}

const SalePeriodEndedError = ({ trackingCustomParameter }: ErrorProps) => {
  const intl = useIntl()
  const { to, label } = useDashboardRedirectInfo()

  return (
    <>
      <TrackPage siteSection="product" pageName="message : too late" customParameter={trackingCustomParameter} />
      <BasePurchaseError
        title={intl.formatMessage({
          defaultMessage: "Too late!",
          id: "JQgjLh",
          description: "[Free Sale] Claim section title when claim period ended",
        })}
        message={intl.formatMessage({
          defaultMessage: "Claim period has ended. Be on the lookout for new exclusive item drops!",
          id: "H/xZu/",
          description: "[Free Sale] Claim period ended error message",
        })}
        buttonLabel={label}
        buttonIcon={<ArrowRightIcon />}
        buttonInvert={false}
        to={to}
      />
    </>
  )
}

interface Props {
  error: SaleStatus
  variant: SaleUnavailableError_variant$key
}

const SaleUnavaiblableError = ({ error, variant }: Props) => {
  const variantData = useFragment(
    graphql`
      fragment SaleUnavailableError_variant on Variant {
        name
        categorization {
          game {
            name
          }
        }
      }
    `,
    variant
  )

  const trackingCustomParameter = useMemo(
    () => buildCustomParameter(variantData.categorization?.game?.name || "", variantData.name, "", 0, 0, "", true),
    [variantData]
  )

  const errorMessageMap: Record<SaleStatus, ReactNode> = {
    [SaleStatus.NOT_STARTED]: UpcomingFreeSaleError({ trackingCustomParameter }),
    [SaleStatus.END_DATE_REACHED]: SalePeriodEndedError({ trackingCustomParameter }),
    [SaleStatus.ALREADY_CLAIMED]: AlreadyClaimedError({ trackingCustomParameter }),
    [SaleStatus.NO_TOKEN_AVAILABLE]: NoFreeTokenAvailableError({ trackingCustomParameter }),
    [SaleStatus.AVAILABLE]: null,
  }

  return <>{errorMessageMap[error]}</>
}

export default SaleUnavaiblableError
