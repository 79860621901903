/**
 * @generated SignedSource<<37f79936cac71167cbaa025ac71fc472>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePendingTokensQuery$variables = {
  ids: ReadonlyArray<string>;
};
export type usePendingTokensQuery$data = {
  readonly tokens: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"TokenCardFragment_token">;
  }>;
};
export type usePendingTokensQuery = {
  variables: usePendingTokensQuery$variables;
  response: usePendingTokensQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePendingTokensQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Token",
        "kind": "LinkedField",
        "name": "tokens",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TokenCardFragment_token"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePendingTokensQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Token",
        "kind": "LinkedField",
        "name": "tokens",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serialNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Archetype",
            "kind": "LinkedField",
            "name": "archetype",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Variant",
                "kind": "LinkedField",
                "name": "primaryVariant",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Categorization",
                    "kind": "LinkedField",
                    "name": "categorization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Game",
                        "kind": "LinkedField",
                        "name": "game",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db9ab635bf1e6f83e9eaa7cdaf143586",
    "id": null,
    "metadata": {},
    "name": "usePendingTokensQuery",
    "operationKind": "query",
    "text": "query usePendingTokensQuery(\n  $ids: [ID!]!\n) {\n  tokens(ids: $ids) {\n    id\n    ...TokenCardFragment_token\n  }\n}\n\nfragment TokenCardFragment_token on Token {\n  id\n  serialNumber\n  archetype {\n    primaryVariant {\n      name\n      image\n      categorization {\n        game {\n          name\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7db6d16537e667bf0f8ab3cdfaac659d";

export default node;
