import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"

import Logo from "../Logo"
import ESRBPrivacy from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/esrb-privacy.jpg"
import ESRBMature from "../https://ubi-web-part.akamaized.net/quartz/prodhttps://ubi-web-part.akamaized.net/quartz/prod/assets/images/esrb-mature.jpg"

import style from "./style.module.css"

const SetCookiesButton = () => {
  const handleButtonClick = () => {
    window.adobeAnalytics.displayPrivacySettings()
  }

  return (
    <>
      <button onClick={handleButtonClick}>
        <FormattedMessage defaultMessage="Set Cookies" id="Bff7Z/" description="[Footer] Manage Cookies button label" />
      </button>
    </>
  )
}

interface Props {
  className?: string
}

const Footer = ({ className = "" }: Props) => {
  return (
    <footer className={`${style.wrapper} ${className}`}>
      <div className={style.container}>
        <nav className={style.generalLinks}>
          <ul>
            <li>
              <a href="https://www.ubisoft.com" target="_blank" rel="noreferrer">
                Ubisoft.com
              </a>
            </li>
            <li>
              <a href="https://support.ubi.com" target="_blank" rel="noreferrer">
                <FormattedMessage defaultMessage="Support" id="P2vNut" description="[Footer] Support link" />
              </a>
            </li>
            <li>
              <a href="https://news.ubisoft.com" target="_blank" rel="noreferrer">
                <FormattedMessage defaultMessage="Blog" id="KKjPKp" description="[Footer] Blog link" />
              </a>
            </li>
            <li>
              <a href="https://discussions.ubisoft.com" target="_blank" rel="noreferrer">
                <FormattedMessage defaultMessage="Forums" id="2ImiIV" description="[Footer] Forums link" />
              </a>
            </li>
          </ul>
          <Link to="/" className={style.logo} aria-label="Ubisoft Quartz">
            <Logo aria-hidden className="link-scale" />
          </Link>
        </nav>
        <nav className={style.termsLinks}>
          <ul>
            <li>
              <Link to="/faq">
                <FormattedMessage defaultMessage="FAQ" id="mEAbaV" description="[Footer] FAQ link" />
              </Link>
            </li>
            <li>
              <a href="https://legal.ubi.com/LegalInfoUbisoftQuartz" target="_blank" rel="noreferrer">
                <FormattedMessage defaultMessage="Legal notice" id="xya0l1" description="[Footer] Legal notice link" />
              </a>
            </li>
            <li>
              <a href="https://legal.ubi.com/ubisoftquartzterms" target="_blank" rel="noreferrer">
                <FormattedMessage
                  defaultMessage="Ubisoft Quartz Terms"
                  id="9cq743"
                  description="[Footer] Terms of Use link"
                />
              </a>
            </li>
            {/* TODO: add later when purchase feature is added */}
            {/* <li>
              <a href="https://legal.ubi.com/storetermsofsale" target="_blank" rel="noopener noreferrer">
                <FormattedMessage
                  defaultMessage="Terms of Sale"
                  id="WnY/Zr"
                  description="[Footer] Terms of Sale link"
                />
              </a>
            </li> */}
            <li>
              <a href="https://legal.ubi.com/privacypolicy" target="_blank" rel="noreferrer">
                <FormattedMessage
                  defaultMessage="Privacy Policy"
                  id="aXTHS6"
                  description="[Footer] Privacy Policy link"
                />
              </a>
            </li>
            <li>
              <SetCookiesButton />
            </li>
            <li>
              <a href="https://legal.ubi.com/DoNotSellMyPersonalInformation/en-US" target="_blank" rel="noreferrer">
                <FormattedMessage
                  defaultMessage="Do not sell my personal information"
                  id="EMouf4"
                  description="[Footer] Do not sell my personal information link"
                />
              </a>
            </li>
          </ul>
          <div className={style.esrb}>
            <a href="https://www.esrb.org/EPCConfirm/912" target="_blank" rel="noreferrer">
              <img src={ESRBPrivacy} alt="esrb privacy logo" />
            </a>
            <a href="https://www.esrb.org/ratings-guide" target="_blank" rel="noreferrer">
              <img src={ESRBMature} alt="esrb mature logo" />
            </a>
          </div>
        </nav>
        <div className={style.copyright}>
          <FormattedMessage
            defaultMessage="© 2021 Ubisoft Entertainment. All Rights Reserved.{linebreak}Tom Clancy’s, Ghost Recon, the Soldier Icon, Ubisoft, and the Ubisoft logo are registered or unregistered trademarks of Ubisoft Entertainment in the US and/or other countries."
            id="JoliyU"
            description="[Footer] Copyright disclaimer"
            values={{
              linebreak: <br />,
            }}
          />
        </div>
      </div>
    </footer>
  )
}

export default Footer
