import { SVGProps } from "react"

import { ReactComponent as Icon } from "./spinner.svg"

import style from "./style.module.css"

const Spinner = ({ className, ...props }: SVGProps<SVGSVGElement>) => {
  return <Icon className={`${style.animation} ${className}`} {...props} />
}

export default Spinner
